import React from "react";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { t } from "../../../localizationUtils";

type OrderFailedModalProps = {
  closingMethod: () => void;
};

export const OrderFailedModal = ({ closingMethod }: OrderFailedModalProps) => (
  <Modal>
    <ModalHeader label={t("order_dialog_note_title")} />
    <ModalBodyWrapper>{t("order_dialog_failed_order")}</ModalBodyWrapper>
    <ModalFooter saveLabel={t("ok")} onSave={() => closingMethod()} />
  </Modal>
);
