import { not } from "ramda";
import React from "react";

import { L10n } from "@encoway/l10n";

type DifferingShippingAddressNoteProps = {
  hasDifferingShippingAddress: boolean;
};

export const DifferingShippingAddressNote = (
  props: DifferingShippingAddressNoteProps,
) => {
  const { hasDifferingShippingAddress } = props;

  if (not(hasDifferingShippingAddress)) {
    return null;
  }

  return (
    <div className="differing-address-fields-opened">
      <span className="boldText">{`${L10n.format("order_dialog_note_title")}: `}</span>
      <span>{L10n.format("order_dialog_note")}</span>
      {/** TODO: comment out for later use (TT-1206) */}
      {/**
           <div className="radio-forklift-check">
             <span>{L10n.format("order_dialog_forklift")}</span>
             <CustomRadioButton
             label="order_dialog_forklift_available"
             name="forklifter"
             value={FORKLIFTER_AVAILABLE}
             checked={this.state.forklifter}
             onValueChange={this.onChangeHandler}
             />
             <CustomRadioButton
             label="order_dialog_forklift_unavailable"
             name="forklifter"
             value={FORKLIFTER_UNAVAILABLE}
             checked={this.state.forklifter}
             onValueChange={this.onChangeHandler}
             />
           </div>
         */}
    </div>
  );
};
