import { not, pathOr, pipe, slice } from "ramda";
import { ChangeEvent, useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import { InputMaxLength } from "../../../../../components/input/inputMaxLength";
import { DropDown } from "../../../../../components/simpleDropDown";
import IdentityStore from "../../../../../http/identityStore";
import { InputConstants } from "../../../../offerManagement/modifyOfferModal/inputConstants";
import {
  getAvailableCountryCodes,
  getPostCodeMaxLength,
  isPostCodeValid,
} from "../differingShippingAddressUtils";
import { DifferingShippingAddressState } from "./differingShippingAddress";

type DifferingShippingAddressContentProps = {
  identityStore: IdentityStore;
  state: DifferingShippingAddressState;
  reset: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setStateValue: (
    key: keyof DifferingShippingAddressState,
    value: string,
  ) => void;
};

export function DifferingShippingAddressContent(
  props: Readonly<DifferingShippingAddressContentProps>,
) {
  const { identityStore, state, reset, onChange, setStateValue } = props;
  const [postCodeValid, setPostCodeValid] = useState<boolean>(
    isPostCodeValid(state.countryCode, state.postCode),
  );

  useEffect(() => {
    setPostCodeValid(isPostCodeValid(state.countryCode, state.postCode));
  }, [state.countryCode, state.postCode]);

  if (not(state.hasDifferingShippingAddress)) {
    return null;
  }

  function postCodeChangeWrapper(event: ChangeEvent<HTMLInputElement>) {
    const maxLength = getPostCodeMaxLength(event.target.value);
    const postCode = pipe(pathOr("", ["postCode"]), slice(0, maxLength))(state);

    setStateValue("postCode", postCode);
    onChange(event);
  }

  return (
    <div>
      <div className="right-align-button custom-margin-1">
        <a className="button is-info is-blue-dark ">
          <div data-button-id={"order_dialog_reset_data"} onClick={reset}>
            {L10n.format("order_dialog_reset_data")}
          </div>
        </a>
      </div>
      <div className="two-columns custom-margin-2">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="companyName"
            value={state.companyName}
            onChange={onChange}
            label={L10n.format("order_dialog_company_name")}
            placeholder={L10n.format("order_dialog_company_name_placeholder")}
            maxLength={InputConstants.length.order_dialog_company_name}
            inputId={"order_dialog_company_name"}
          />
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="telephoneNumber"
            value={state.telephoneNumber}
            onChange={onChange}
            label={
              L10n.format("order_dialog_telephone_number") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_telephone_number_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_telephone_number}
            inputId={"order_dialog_telephone_number"}
          />
        </div>
      </div>

      <div className="two-columns with padding custom-margin-2 ">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="contactSalutation"
            value={state.contactSalutation}
            onChange={onChange}
            label={
              L10n.format("order_dialog_contact_salutation") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_contact_salutation_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_contact_salutation}
            inputId={"order_dialog_contact_salutation"}
          />
        </div>
        <div className="column-entry with-padding"></div>
      </div>

      <div className="two-columns custom-margin-2">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="contactFirstName"
            value={state.contactFirstName}
            onChange={onChange}
            label={
              L10n.format("order_dialog_contact_firstName") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_contact_firstName_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_contact_firstName}
            inputId={"order_dialog_contact_firstName"}
          />
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="contactLastName"
            value={state.contactLastName}
            onChange={onChange}
            label={
              L10n.format("order_dialog_contact_lastName") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_contact_lastName_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_contact_lastName}
            inputId={"order_dialog_contact_lastName"}
          />
        </div>
      </div>
      <div className="two-columns custom-margin-2">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="street"
            value={state.street}
            onChange={onChange}
            label={
              L10n.format("order_dialog_street") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("order_dialog_street_placeholder")}
            maxLength={InputConstants.length.order_dialog_street}
            inputId={"order_dialog_street"}
          />
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="houseNumber"
            value={state.houseNumber}
            onChange={onChange}
            label={
              L10n.format("order_dialog_house_number") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("order_dialog_house_number_placeholder")}
            maxLength={InputConstants.length.order_dialog_house_number}
            inputId={"order_dialog_house_number"}
          />
        </div>
      </div>
      <div className="two-columns custom-margin-2">
        <div className="column-entry multi-column-input">
          <label className="label">
            {L10n.format("order_dialog_post_code") +
              L10n.format("mandatory_symbol")}
          </label>
          <div className="columns with-padding">
            <div className="column is-two-quarter">
              <DropDown
                name="countryCode"
                possibleItems={getAvailableCountryCodes(identityStore)}
                value={state.countryCode}
                onChange={postCodeChangeWrapper}
                valueTextBold={true}
                dropdownId={"order_dialog_post_code"}
              />
            </div>
            <div className="column">
              <InputMaxLength
                name="postCode"
                value={state.postCode}
                onChange={onChange}
                placeholder={L10n.format("order_dialog_post_code_placeholder")}
                maxLength={pathOr(
                  InputConstants.length.order_dialog_post_code,
                  ["length", state.countryCode, "order_dialog_post_code"],
                  InputConstants,
                )}
                isInputValid={postCodeValid}
                hasWarning={state.countryCode === "DE"}
                warningLabel={"order_dialog_post_code_warning"}
                warningDuration={6000}
                inputId="order_dialog_post_code"
              />
            </div>
          </div>
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="city"
            value={state.city}
            onChange={onChange}
            label={
              L10n.format("order_dialog_city") + L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("order_dialog_city_placeholder")}
            maxLength={InputConstants.length.order_dialog_city}
            inputId="order_dialog_city"
          />
        </div>
      </div>
      <div className="bottom-space" />
    </div>
  );
}
