import { ArticleConfiguration } from "../types/cart";

export const expandConfigurationLoadTOIfNeeded = (
  configurationLoadTO: ArticleConfiguration,
  deliveryTimeWish: string,
) => {
  if (deliveryTimeWish) {
    return {
      ...configurationLoadTO,
      salesContext: {
        characteristics: {
          O_VBAK_AUART: deliveryTimeWish,
        },
      },
    };
  }
  return configurationLoadTO;
};
