import { join, nth, times } from "ramda";

/**
 * Generates random string and concatenates unix time in ms.
 * Probability of getting the same string in 2 points in time is 0%.
 * @return {string} - the randomly generated string
 */
export const randomName = (): string => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  const stringLength = 20;

  const getRandomChar = () => {
    const randomPosition = Math.floor(Math.random() * charset.length);
    return nth(randomPosition, charset);
  };

  return `${join("", times(getRandomChar, stringLength))}${Math.round(new Date().getTime())}`;
};
