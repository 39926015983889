import classnames from "classnames";
import { pathOr } from "ramda";
import React from "react";

import { Config } from "../../../config/config";

export const CategorySelectionItem = ({
  isActive,
  category,
  opened,
  onClickMethod,
}) => (
  <div
    className="card categorySelectionItem"
    data-category-id={category.id}
    onClick={() => onClickMethod(category.id)}
  >
    <header className="card-header">
      <p
        className={classnames({
          "card-header-title": true,
          "is-active": isActive,
        })}
      >
        {category.name}
      </p>
    </header>
    {opened && (
      <div>
        <img
          src={`${pathOr("", ["values", 0, "uri"], category.characteristicValues.Product_image)}${Config.ImageResolution.Medium}`}
          alt={category.name}
          data-image-id={category.id}
        />
        <div className="card-content">
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: pathOr(
                "",
                ["values", 0],
                category.characteristicValues.long_text_gui,
              ),
            }}
          />
        </div>
      </div>
    )}
  </div>
);
