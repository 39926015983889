import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { find, not, pathOr, propEq } from "ramda";
import { ReactNode, useContext } from "react";

import { Constants } from "@encoway/react-components";
import { specState } from "@encoway/rest-api";

import { AppContext } from "../../../../hooks/useApp";
import { getConflictFreeParameterTO } from "../../../../service/configurationService";
import { ParameterTO } from "../../../../types/@encoway/Parameter";
import { ConfiguratorComponentProps } from "../../../../types/configuration";
import { LvRow } from "../../../../types/lvTable";
import { t } from "../../../shoppingCart/localizationUtils";
import { LvDropdown } from "./LvDropdown";

export const ICON_MAP: Record<string, ReactNode> = {
  SET_BY_USER: (
    <FontAwesomeIcon
      icon={faUser}
      title={t("SET_BY_USER")}
      style={{ color: "#ef7b22", height: "1rem" }}
    />
  ),
  SET_BY_DEFAULT: <span className="zauberstab" title={t("SET_BY_DEFAULT")} />,
  SET_BY_SYSTEM: <span className="monitor" title={t("SET_BY_SYSTEM")} />,
  UNDO: (
    <FontAwesomeIcon
      icon={faTimes}
      title={t("undo")}
      style={{ color: "#ef7b22", height: "1rem" }}
    />
  ),
};

export const LvConfiguratorDropdownField = ({
  data,
  onValueChanged,
  options,
  config,
}: ConfiguratorComponentProps<
  ParameterTO,
  { lvParameters: LvRow["parameters"] }
>) => {
  const { language } = useContext(AppContext);
  const lvParameter = find<LvRow["parameters"][number]>(
    propEq("name", data.name),
    pathOr([], ["lvParameters"], options),
  );

  const selectedValue = {
    value: pathOr("", ["selectedValues", 0, "value"], data),
    translatedValue: pathOr("", ["selectedValues", 0, "translatedValue"], data),
  };

  const isUndoable =
    data.undoable && data.editable && specState(data) === "SET_BY_USER";

  return (
    <div className="field has-addons is-align-items-center" style={{ gap: 8 }}>
      <span className="icon is-small mr-2">{ICON_MAP[specState(data)]}</span>
      <span className="is-size-7 has-text-weight-bold" style={{ width: 150 }}>
        {data.translatedName}
      </span>
      <div className="control is-expanded">
        <LvDropdown
          values={data.values!}
          selectedValue={selectedValue}
          originalValue={lvParameter?.originalValue}
          onSelectValue={(value) => onValueChanged(data, value)}
          getParameterTO={() =>
            getConflictFreeParameterTO(config.id(), language, data.id)
          }
        />
      </div>
      <button
        className={classNames("button is-ghost is-small border-0", {
          "is-invisible": not(isUndoable),
        })}
        onClick={() => onValueChanged(data, Constants.Undo)}
      >
        <span className="icon is-small">{ICON_MAP.UNDO}</span>
      </button>
    </div>
  );
};
