import React from "react";

import { L10n } from "@encoway/l10n";

import { OfferManagementStore } from "./offerManagementUtils";
import { DispatcherActions } from "./useOfferManagementDispatcherActions";

type FilterableTableHeadProps = {
  store: OfferManagementStore;
  name: string;
  onChangeFilter: DispatcherActions["onChangeFilter"];
};

export const FilterableTableHead = ({
  store,
  name,
  onChangeFilter,
}: FilterableTableHeadProps) => (
  <div className="select">
    <select
      value={store.filter.value}
      onChange={({ target }) => onChangeFilter(name, "=", target.value)}
      data-filter-id="offer_management_table_status"
    >
      <option value="__undo__">{L10n.format("all")}</option>
      <option value="open" data-option-id="offer_management_table_status_OPEN">
        {L10n.format("offer_management_table_status_OPEN")}
      </option>
      <option
        value="ordered"
        data-option-id="offer_management_table_status_ORDERED"
      >
        {L10n.format("offer_management_table_status_ORDERED")}
      </option>
      <option
        value="shared"
        data-option-id="offer_management_table_status_SHARED"
      >
        {L10n.format("offer_management_table_status_SHARED")}
      </option>
      <option
        value="ordered_part"
        data-option-id="offer_management_table_status_ORDERED_PART"
      >
        {L10n.format("offer_management_table_status_ORDERED_PART")}
      </option>
    </select>
  </div>
);
