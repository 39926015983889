import { isNil, not, or } from "ramda";

import { Loading } from "../../../components/loading";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { t } from "../../shoppingCart/localizationUtils";

type VisualizationArCodeModalProps = {
  qrCode: string | null;
  show: boolean;
  loading: boolean;
  closeModal: () => void;
};

type QrCodeContainerProps = {
  loading: boolean;
  qrCode: string | null;
};

function QrCodeContainer({ loading, qrCode }: Readonly<QrCodeContainerProps>) {
  if (or(loading, isNil(qrCode))) {
    return <Loading />;
  }

  return (
    <div className="vis-ar-modal-qr-code-container">
      <img src={qrCode!} alt="qrCode" data-image-id="qrCode" />
    </div>
  );
}

export function VisualizationArCodeModal(
  props: Readonly<VisualizationArCodeModalProps>,
) {
  const { show, qrCode, loading, closeModal } = props;

  if (not(show)) {
    return null;
  }

  return (
    <Modal className="vis-ar-modal">
      <ModalHeader
        label={t("configuration_visualization_ar_modal_header")}
        onCancel={closeModal}
      />
      <ModalBodyWrapper>
        {t("configuration_visualization_ar_modal_text")}
        <QrCodeContainer loading={loading} qrCode={qrCode} />
      </ModalBodyWrapper>
      <ModalFooter>
        <button
          className="button is-info is-orange"
          onClick={closeModal}
          data-button-id="close"
        >
          {t("configuration_visualization_close_ar_modal")}
        </button>
      </ModalFooter>
    </Modal>
  );
}
