import { ReactNode, useMemo } from "react";
import ReactDOM from "react-dom";

/**
 * Injects the children into the element
 * @param {string} id the id
 * @param {object} children the child
 * @returns {React.ReactElement} react portal that extends the react element
 */
export function Portal({ id, children }: { id: string; children: ReactNode }) {
  const element = useMemo(() => document.getElementById(id), [id]);
  if (element) {
    return ReactDOM.createPortal(children, element);
  }
  return null;
}
