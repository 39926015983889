import {
  faExclamationTriangle,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FlexTableCell as Cell } from "../../../../../components/flexTable/flexTable";
import { TeckentrupCartArticleTO } from "../../../../../types/cart";
import {
  configurationIncompleteOrHasNoBruttoEpIncTzPrice,
  hasTeoReadyStateUnspecified,
} from "../../../../../utilities/cartUtils";

type WarningCellProps = {
  cartItem: TeckentrupCartArticleTO;
};

export const WarningCell = ({ cartItem }: WarningCellProps) => (
  <Cell data-cell-id={`${cartItem.positionNr}-${cartItem.teoReadyState}`}>
    {configurationIncompleteOrHasNoBruttoEpIncTzPrice(cartItem) ? (
      <FontAwesomeIcon icon={faExclamationTriangle} color={"#ef7b22"} />
    ) : (
      hasTeoReadyStateUnspecified(cartItem) && (
        <FontAwesomeIcon icon={faCircleQuestion} color={"#ef7b22"} />
      )
    )}
  </Cell>
);
