import { isNil } from "ramda";
import React, { useReducer, useState } from "react";

import { InputMaxLength } from "../../../components/input/inputMaxLength";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { ScrollWrapper } from "../../../components/scrollWrapper";
import { mapIndexed } from "../../../utilities/utilities";
import { InputConstants } from "../../offerManagement/modifyOfferModal/inputConstants";
import { Input } from "../../shoppingCart/components/modals/orderModal/input";
import { t } from "../../shoppingCart/localizationUtils";
import {
  checkIfCustomerNumberExists,
  createCustomerDialogDTO,
  isComplete,
  mergeInitialCustomerDialogState,
  prepareQuoteAddressState,
  quoteStateWithoutActiveElement,
} from "../customerManagementUtils";
import { CancelCustomerModal } from "./cancelCustomerModal";
import {
  CUSTOMER_MANAGEMENT_ACTIONS as ACTIONS,
  CustomerDialogActions,
} from "./customerModalActions";
import { QuoteAddressRows } from "./quoteAddressRows";
import { QuoteAddressWrapper } from "./quoteAddressWrapper";

import "./customerDialog.scss";

const CustomerInput = ({
  value = "",
  name,
  label,
  placeHolder,
  onChange,
  maxLength,
  className,
  children,
  inputId,
}) => (
  <div className="column-entry with-padding">
    {maxLength ? (
      <InputMaxLength
        {...{
          value,
          name,
          label,
          placeHolder,
          onChange,
          maxLength,
          className,
          inputId,
        }}
      />
    ) : (
      <Input
        {...{ value, name, label, placeHolder, onChange, className, inputId }}
      />
    )}
    {children}
  </div>
);

const QuoteAddressesWrapper = ({
  label,
  btnLabel,
  btnId,
  onAddAddress,
  children,
}) => (
  <React.Fragment>
    <div className="two-columns custom-margin-2">
      <div className="quote-addresses with-padding column-entry">
        <h2 className="subtitle has-text-weight-bold">{label}</h2>
      </div>
      <div className="quote-addresses with-padding column-entry">
        <button
          className="button is-info is-blue-dark is-pulled-right"
          onClick={onAddAddress}
          data-button-id={btnId}
        >
          <div className="is-pulled-left">{btnLabel}</div>
          <div className="is-pulled-right">
            <i
              className="image-icon neuen-vorgang-erstellen"
              aria-hidden="true"
            />
          </div>
        </button>
      </div>
    </div>
    <div className="quote-addresses-children">{children}</div>
  </React.Fragment>
);

const QuoteAddressNoEntries = ({ label }) => (
  <div className="quote-addresses with-padding no-entries">
    <p>{label}</p>
  </div>
);

const CustomerNumberExists = ({ label }) => (
  <div className="customer-number">
    <p data-label-id={"customer-number"} className="error-label">
      {label}
    </p>
  </div>
);

export const CustomerModal = ({ onCancel, onSave, customerState }) => {
  const initialState = mergeInitialCustomerDialogState(customerState);
  const [state, dispatch] = useReducer(CustomerDialogActions, {
    ...initialState,
    isComplete: isComplete(initialState),
    isLoading: false,
  });
  const [customerNumberTimeout, setCustomerNumberTimeout] = useState(null);

  const onChangeLoading = (isLoading) =>
    dispatch({ type: ACTIONS.MODAL_ON_CHANGE_LOADING_STATE, isLoading });

  const saveCustomer = async () => {
    onChangeLoading(true);
    const customerNumberExists =
      state.initialCustomerNumber !== state.customerNumber &&
      (await checkIfCustomerNumberExists(
        customerNumberTimeout,
        setCustomerNumberTimeout,
        state.customerNumber,
      ));
    dispatch({
      type: ACTIONS.MODAL_CHECK_IF_CUSTOMER_NUMBER_EXISTS,
      customerNumberExists,
    });
    !customerNumberExists && (await onSave(createCustomerDialogDTO(state)));
    onChangeLoading(false);
  };

  const onInputChange = ({ target: { name, value } }) =>
    !/^\s+$/.test(value) &&
    dispatch({ type: ACTIONS.MODAL_ON_INPUT_CHANGE, name, value });

  const onCustomerNumberChange = ({ target: { name, value } }) => {
    if (!/^\s+$/.test(value)) {
      dispatch({ type: ACTIONS.MODAL_ON_INPUT_CHANGE, name, value });
      state.initialCustomerNumber !== value &&
        checkIfCustomerNumberExists(
          customerNumberTimeout,
          setCustomerNumberTimeout,
          value,
        ).then((data) =>
          dispatch({
            type: ACTIONS.MODAL_CHECK_IF_CUSTOMER_NUMBER_EXISTS,
            customerNumberExists: data,
          }),
        );
    }
  };

  const onAddQuoteAddress = () => dispatch({ type: ACTIONS.QUOTE_ADDRESS_ADD });

  const onDeleteQuoteAddress = (id) => () =>
    dispatch({ type: ACTIONS.QUOTE_ADDRESS_DELETE, id });

  const onEditQuoteAddress = (id) => () =>
    dispatch({ type: ACTIONS.QUOTE_ADDRESS_EDIT, id });

  const onQuoteAddressInputChange =
    (id) =>
    ({ target: { name, value } }) =>
      dispatch({ type: ACTIONS.QUOTE_ADDRESS_INPUT_CHANGE, name, value, id });

  const onQuoteAddressTitleChange = (id) => (title) =>
    dispatch({
      type: ACTIONS.QUOTE_ADDRESS_INPUT_CHANGE,
      name: "title",
      value: title,
      id,
    });

  const onQuoteAddressClose = () =>
    dispatch({ type: ACTIONS.QUOTE_ADDRESS_CLOSE });

  const onQuoteAddressDeleteModalOpen = () =>
    dispatch({ type: ACTIONS.QUOTE_ADDRESS_DELETE_MODAL_OPEN });

  const onQuoteAddressDeleteModalClose = () =>
    dispatch({ type: ACTIONS.QUOTE_ADDRESS_DELETE_MODAL_CLOSE });

  const onCancelModalOpen = () =>
    dispatch({ type: ACTIONS.MODAL_ON_CANCEL_OPEN });

  const onCancelModalClose = () =>
    dispatch({ type: ACTIONS.MODAL_ON_CANCEL_CLOSE });

  return (
    <Modal>
      <ModalHeader
        label={
          isNil(customerState)
            ? t("customer_management_create_new_customer")
            : t("edit_customer")
        }
        onCancel={state.edited ? onCancelModalOpen : onCancel}
      />
      <ModalBodyWrapper overflowY={true}>
        <div className="quote-addresses with-padding column-entry custom-margin-2">
          <h2 className="subtitle has-text-weight-bold">{t("customer")}</h2>
        </div>
        <div className="two-columns custom-margin-2 input-head">
          <CustomerInput
            value={state.customerNumber}
            name="customerNumber"
            inputId={"customer-number"}
            className={{
              "customer-number": true,
              "customer-exists": state.customerNumberExists,
            }}
            label={`${t("modify_dialog_contact_customerNumber")} ${t("mandatory_symbol")}`}
            placeholder={t("modify_dialog_contact_customerNumber")}
            onChange={onCustomerNumberChange}
          >
            {state.customerNumberExists && (
              <CustomerNumberExists
                label={t("modify_dialog_company_exist_message")}
              />
            )}
          </CustomerInput>
          <CustomerInput
            name="customerName"
            inputId={"customer-name"}
            label={`${t("modify_dialog_company_name")} ${t("mandatory_symbol")}`}
            placeholder={t("modify_dialog_company_name")}
            value={state.customerName}
            onChange={onInputChange}
            maxLength={InputConstants.length.modify_dialog_company_name}
          />
          <span className="smallText with-padding">
            {t("mandatory_symbol_information")}
          </span>
        </div>
        <QuoteAddressesWrapper
          label={t("customer_management_offer_address")}
          btnLabel={t("customer_management_add_offer_address")}
          btnId={"add-quote-address"}
          onAddAddress={onAddQuoteAddress}
        >
          {state.companyContacts.length > 0 && (
            <div className="quote-address-wrapper quote-addresses-available">
              {mapIndexed(
                (quoteState, key) => (
                  <QuoteAddressRows
                    key={key}
                    className={state.quoteAddressEditOpen === key && "hide"}
                    mutatedState={prepareQuoteAddressState(quoteState)}
                    onCloseOrEdit={onEditQuoteAddress(key)}
                  >
                    {state.quoteAddressEditOpen === key && (
                      <ScrollWrapper>
                        <QuoteAddressWrapper
                          onDelete={onDeleteQuoteAddress(key)}
                          onClose={onQuoteAddressClose}
                          onInputChange={onQuoteAddressInputChange(key)}
                          onTitleChange={onQuoteAddressTitleChange(key)}
                          onDeleteModalOpen={onQuoteAddressDeleteModalOpen}
                          onDeleteModalOnCancel={onQuoteAddressDeleteModalClose}
                          onDeleteModalToggle={
                            state.quoteAddressDeleteModalToggle
                          }
                          quoteAddressState={state.companyContacts[key]}
                        />
                      </ScrollWrapper>
                    )}
                  </QuoteAddressRows>
                ),
                quoteStateWithoutActiveElement(state),
              )}
            </div>
          )}
          {state.quoteAddressOpen >= 0 && (
            <ScrollWrapper>
              <QuoteAddressWrapper
                onDelete={onDeleteQuoteAddress(state.quoteAddressOpen)}
                onClose={onQuoteAddressClose}
                onInputChange={onQuoteAddressInputChange(
                  state.quoteAddressOpen,
                )}
                onTitleChange={onQuoteAddressTitleChange(
                  state.quoteAddressOpen,
                )}
                onDeleteModalOnCancel={onQuoteAddressDeleteModalClose}
                onDeleteModalOpen={onQuoteAddressDeleteModalOpen}
                onDeleteModalToggle={state.quoteAddressDeleteModalToggle}
                quoteAddressState={
                  state.companyContacts[state.quoteAddressOpen]
                }
              />
            </ScrollWrapper>
          )}
          {state.companyContacts.length <= 0 && (
            <QuoteAddressNoEntries
              label={t("modify_dialog_contact_no_entries")}
            />
          )}
        </QuoteAddressesWrapper>
        {state.modalCancelToggle && (
          <CancelCustomerModal
            onCancel={onCancel}
            onSave={() => onSave(createCustomerDialogDTO(state))}
            onClose={onCancelModalClose}
            disabledOnSave={!state.isComplete}
          />
        )}
      </ModalBodyWrapper>
      <ModalFooter
        mandatoryText={`* ${t("customer_management_mandatory_input")}`}
        cancelLabel={t("cancel")}
        saveLabel={t("save")}
        onCancel={state.edited ? onCancelModalOpen : onCancel}
        onSave={() => saveCustomer()}
        disabled={!(state.isComplete && state.edited)}
        isLoading={state.isLoading}
      />
    </Modal>
  );
};
