import { createContext, useState } from "react";

import { ServiceFactoryBuilder } from "@encoway/cart-service-js-client";
import { L10n } from "@encoway/l10n";
import { CatalogService } from "@encoway/rest-api";

import { Config } from "../config/config";
import { createHttp } from "../http/setupHttp";
import configurationTranslations from "../localization/configurationTranslations.json";
import { AppStore } from "../types/app";
import { loadTranslations } from "../utilities/languageUtils";
import { MODE } from "./useIdentity";

const http = createHttp(null);

const initialState: AppStore = {
  language: "de-DE",
  baseUrl: `${Config.Service.BaseUrl}${Config.Service.BackendPath}`,
  CustomerManagementBaseUrl: `${Config.Service.CustomerManagementBaseUrl}`,
  loading: true,
  serviceFactory: new ServiceFactoryBuilder()
    .withHttp(http)
    .withBaseUrl(
      `${Config.Service.BaseUrl}${Config.Service.BackendPath}/api/cart`,
    )
    .withLocaleProvider(L10n.currentFullLocale)
    .withDefaultMappingProfile("NOTHING"),
  systemName: "",
  mode: MODE.PRODUCTION,

  init: function init() {
    throw new Error("init function not initialized");
  },
};

export const AppContext = createContext(initialState);
export const AppProvider = AppContext.Provider;

export function useApp(): AppStore {
  const [state, setState] = useState(initialState);

  async function init(mode: MODE, systemName: string) {
    const languageCode: string = language_code;
    const language = languageCode || state.language;
    try {
      L10n.currentLocale(language);
      const catalogService = new CatalogService(
        http,
        state.baseUrl,
        L10n.currentFullLocale(),
      );
      const translations = await loadTranslations(catalogService);
      L10n.source("configuration", configurationTranslations, true);
      L10n.source("ui", translations, true);
      await L10n.reloadResources(language);
      setState({
        ...state,
        catalogService,
        language,
        mode,
        systemName,
        loading: false,
      });
    } catch {
      setState({ ...state, mode, systemName, loading: false });
    }
  }

  return { ...state, init };
}
