import { ChangeEvent, PropsWithChildren } from "react";

import { Input } from "../../pages/shoppingCart/components/modals/orderModal/input";
import { InputMaxLength } from "../input/inputMaxLength";

type ModalInputProps = {
  inputId: string;
  name: string;
  label: string;
  placeholder: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const ModalInput = ({
  inputId,
  name,
  label,
  placeholder,
  value = "",
  onChange,
  maxLength,
  onBlur,
  disabled = false,
  children,
}: PropsWithChildren<ModalInputProps>) => (
  <div className="column-entry with-padding">
    {maxLength ? (
      <InputMaxLength
        {...{
          value,
          name,
          label,
          placeholder,
          onChange,
          onBlur,
          maxLength,
          inputId,
        }}
      />
    ) : (
      <Input
        {...{
          disabled,
          value,
          name,
          label,
          placeholder,
          onChange,
          onBlur,
          inputId,
        }}
      />
    )}
    {children}
  </div>
);
