import classNames from "classnames";
import { isNil } from "ramda";
import { ComponentPropsWithoutRef } from "react";

type InputProps = ComponentPropsWithoutRef<"input"> & {
  label?: string;
  inputId?: string;
};

export function Input(props: InputProps) {
  const {
    className,
    disabled,
    inputId,
    label,
    maxLength,
    name,
    placeholder,
    readOnly,
    value,
    onChange,
    onBlur,
  } = props;

  return (
    <>
      {!isNil(label) && <label className="label">{label}</label>}
      <input
        readOnly={readOnly}
        disabled={disabled}
        name={name}
        className={classNames(["input", className])}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        data-input-field-id={inputId}
      />
    </>
  );
}
