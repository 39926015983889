import { decodeJwt } from "jose";

import { getRoleAndRights } from "../service/authorizationService";
import { TokenPayload, UiAuthorities } from "../types/identity";
import { isNilOrEmpty } from "../utilities/utilities";

/**
 * Manages the authentication token which is communicated with every request.
 */
export default class IdentityStore {
  private roleAndRights: UiAuthorities | null = null;

  constructor(initialToken: string) {
    this.storeToken(initialToken);
  }

  /**
   * Saves the given token in jwt format.
   * The decoded information of this token will then be available via #getIdentity().
   * @param  {String} token The token
   * @return {void}
   */
  storeToken(token: string) {
    if (this.storageAvailable()) {
      window.localStorage.setItem("authToken", token);
    }
  }

  /**
   * Stores the authorities coming from the _ui_authorities endpoint.
   * @return {void}
   */
  async storeAuthorities() {
    const response = await getRoleAndRights();
    this.roleAndRights = response?.data;
  }

  getRoleAndRights() {
    return this.roleAndRights;
  }

  /**
   * Returns the raw, undecoded token string.
   * @return {String} The token string or an empty string if no token is present.
   */
  getToken() {
    return window.localStorage.getItem("authToken");
  }

  /**
   * Returns the decoded token or an empty Object if no token is present.
   * @return {TokenPayload | undefined} The decoded token.
   */
  getIdentity() {
    const token = this.getToken();
    if (isNilOrEmpty(token)) {
      return;
    }
    return decodeJwt(token!) as TokenPayload;
  }

  /**
   * Returns the company of the token or undefined if no token is present.
   * @return {FirmadesNutzers | undefined} The company of the decoded token.
   */
  getCompany() {
    const token = this.getToken();
    if (isNilOrEmpty(token)) {
      return;
    }
    return (decodeJwt(token!) as TokenPayload).FirmadesNutzers;
  }

  /**
   * Returns the user of the token or an empty Object if no token is present.
   * @return {Nutzer | undefined} The user of the decoded token.
   */
  getUser() {
    const token = this.getToken();
    if (isNilOrEmpty(token)) {
      return;
    }
    return (decodeJwt(token!) as TokenPayload).Nutzer;
  }

  /**
   * Determines whether browser localStorage should be used to store the token.
   * @return {Boolean} Whether to use browser localStorage for storing the token.
   */
  storageAvailable() {
    if (window.localStorage) {
      return true;
    }
    return false;
  }

  getRoles() {
    return this.roleAndRights?.roles;
  }

  getBooleanAuthorities() {
    return this.roleAndRights?.booleanAuthorities;
  }
}
