import React, { useState } from "react";

import { fetchGet, fetchPost } from "../service/fetch";

import "./topBar.scss";

export const TopBar = () => {
  const [priceDate, setPriceDate] = useState("");
  const [status, setStatus] = useState("");

  const onSubmitPriceChanger = async () => {
    try {
      const response = await fetchGet(
        `teckentrup-server/api/cart/setPriceDate/${priceDate}`,
      )()();
      setStatus(response?.status === 200 ? "Gesetzt!" : "Fehler!");
    } catch (e) {
      setStatus("Fehler!");
    }
    setTimeout(() => setStatus(""), 3000);
  };

  const onSubmitTestFile = (event) => {
    fetchPost("teckentrup-server/api/cart/transfer")(
      { encType: "multipart/form-data" },
      event.target.files[0],
    )();
  };

  return window.priceUpdateAllowed ? (
    <div className="row top-bar">
      <div className="col-6">
        <span>Preisdatum:</span>
        <input
          className="reset-this"
          value={priceDate}
          onChange={(event) => setPriceDate(event.target.value)}
          placeholder="yyyymmdd"
        />
        <button onClick={() => onSubmitPriceChanger()}>setzen</button>
        <span>(Format: yyyymmdd -&gt; 20191231) {status}</span>
      </div>
      <div className="col-6">
        Test File:{" "}
        <input
          type="file"
          name="file"
          id="file"
          onChange={(event) => onSubmitTestFile(event)}
        />
      </div>
    </div>
  ) : (
    <div className="whitespace" />
  );
};
