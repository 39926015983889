import {
  Modal,
  ModalBodyWrapper,
  ModalHeader,
} from "../../../../components/modal/modal";
import { Config } from "../../../../config/config";
import { CONSTANTS } from "../../../../http/constants";

import "./sparePartInformationModal.scss";

type SparePartInformationModalProps = {
  baseURL: string;
  label: string;
  image: string;
  shortText: string;
  longText: string;
  onCancel: () => void;
};

export function SparePartInformationModal(
  props: Readonly<SparePartInformationModalProps>,
) {
  const { baseURL, label, image, shortText, longText, onCancel } = props;

  return (
    <Modal onClick={onCancel}>
      <ModalHeader label={label} onCancel={onCancel} />
      <ModalBodyWrapper>
        <div className="spare-part-image has-text-centered">
          <img
            src={`${baseURL}/${CONSTANTS.Images.Catalog}${image}${Config.ImageResolution.Medium}`}
            alt={shortText}
          />
        </div>
        <div
          className="spare-part-long-text "
          contentEditable="true"
          dangerouslySetInnerHTML={{ __html: longText }}
        />
      </ModalBodyWrapper>
    </Modal>
  );
}
