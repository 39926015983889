import classNames from "classnames";
import { isEmpty, isNil, mapObjIndexed, values } from "ramda";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InputTitle } from "../../../components/input/inputTitle";
import { ModalInput } from "../../../components/modal/ModalInput";
import { ModalBodyWrapper, ModalFooter } from "../../../components/modal/modal";
import { TextArea } from "../../../components/textarea";
import { CartContext } from "../../../hooks/useCart";
import { IdentityContext } from "../../../hooks/useIdentity";
import { LvTable } from "../../../hooks/useLvTable";
import { ROUTES } from "../../../http/constants";
import { hasRightToSeeCommentaryExtraOM } from "../../../http/identityHelper";
import { createProductsInProcess } from "../../../service/lvService";
import { loadQuote } from "../../../service/offerManagementService";
import { getNonOpenedQuoteByOrderNumber } from "../../../utilities/cartUtils";
import { pickAddressFields } from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { InputConstants } from "../modifyOfferModal/inputConstants";
import { findMaxLength } from "../modifyOfferModal/modifyOfferDialogUtils";

const InitialLvHeaderData = {
  buildProject: "",
  referenceNumber: "",
  comment: "",
  title: "",
  firstname: "",
  surname: "",
  street: "",
  houseNumber: "",
  zip: "",
  city: "",
  phone: "",
  email: "",
};

export type LvHeaderData = typeof InitialLvHeaderData;

type AddressFields = Pick<
  LvHeaderData,
  | "firstname"
  | "surname"
  | "street"
  | "houseNumber"
  | "zip"
  | "city"
  | "phone"
  | "email"
>;

export type AddressFieldsWithTitle = AddressFields &
  Pick<LvHeaderData, "title">;

type CustomerInformationProps = {
  addressFields: { [key in keyof AddressFields]: string };
  getChangeHandler: (
    name: keyof AddressFields,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
};

const CustomerInformation = ({
  addressFields,
  getChangeHandler,
}: CustomerInformationProps) => (
  <div className="two-columns custom-margin-2">
    {values(
      mapObjIndexed(
        (value, key) => (
          <ModalInput
            key={key}
            name={key}
            label={t(`modify_dialog_contact_${key}`)}
            placeholder={t(`modify_dialog_contact_${key}`)}
            value={value}
            onChange={getChangeHandler(key)}
            maxLength={findMaxLength(`modify_dialog_contact_${key}`)}
            inputId={`lv_modify_dialog_contact_${key}`}
          />
        ),
        addressFields,
      ),
    )}
  </div>
);

type LvOfferTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  onCancel: () => void;
};

export const LvOfferTab = ({
  isHidden,
  lvTable,
  onCancel,
}: LvOfferTabProps) => {
  const navigate = useNavigate();
  const { identityStore } = useContext(IdentityContext);
  const { cart } = useContext(CartContext);
  const [lvHeaderData, setLvHeaderData] = useState(InitialLvHeaderData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getChangeHandler =
    (name: keyof LvHeaderData) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLvHeaderData({ ...lvHeaderData, [name]: event.target.value });
    };

  const handleTitleChange = (title: string) => {
    setLvHeaderData({ ...lvHeaderData, title });
  };

  const handleSaveClick = async () => {
    if (!identityStore) {
      return;
    }
    setIsLoading(true);
    try {
      const company = identityStore.getCompany();
      const user = identityStore.getUser();
      if (isNil(company) || isNil(user)) {
        throw new Error("company or user is undefined");
      }
      const { data } = await createProductsInProcess(
        lvTable.checkedLvRows,
        lvHeaderData,
        company,
        user,
      );
      const foundQuote = await getNonOpenedQuoteByOrderNumber(
        data.cartOrderNumber,
        user.Email,
      );
      if (isNil(foundQuote)) {
        throw new Error(
          `no quote found by order number ${data.cartOrderNumber}`,
        );
      }
      await loadQuote(foundQuote.cartTO.id);
      setErrorMessage("");
      onCancel();
      navigate(ROUTES.CART);
    } catch (error) {
      setErrorMessage("offer_management_create_lv_error_new_offer");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalBodyWrapper className={classNames({ "is-hidden": isHidden })}>
        {!isEmpty(errorMessage) && (
          <p className="has-text-danger mb-4 with-padding">{t(errorMessage)}</p>
        )}
        <div className="two-columns mb-2">
          <ModalInput
            inputId="lv_building_project"
            name="lvBuildProject"
            label={t("building_project") + t("mandatory_symbol")}
            placeholder={t("building_project")}
            value={lvHeaderData.buildProject}
            onChange={getChangeHandler("buildProject")}
            maxLength={InputConstants.length.building_project}
          />
          <ModalInput
            inputId="lv_reference_order_id"
            name="lvReferenceNumber"
            label={t("reference_order_id") + t("mandatory_symbol")}
            placeholder={t("reference_order_id")}
            value={lvHeaderData.referenceNumber}
            onChange={getChangeHandler("referenceNumber")}
            maxLength={InputConstants.length.modify_dialog_reference}
          />
          <span className="smallText with-padding">
            {t("mandatory_symbol_information")}
          </span>
        </div>
        <TextArea
          label={t("comment")}
          subLabel={
            hasRightToSeeCommentaryExtraOM(cart?.authorities.booleanAuthorities)
              ? t("will_not_be_transmitted_to_Teckentrup")
              : ""
          }
          value={lvHeaderData.comment}
          onInputChange={getChangeHandler("comment")}
          attributeId="lv_comment"
        />
        <div className="quote-addresses with-padding mb-2">
          <h2 className="subtitle has-text-weight-bold">
            {t("modify_dialog_customer_title")}
          </h2>
        </div>
        <InputTitle title={lvHeaderData.title} onClick={handleTitleChange} />
        <CustomerInformation
          addressFields={pickAddressFields(lvHeaderData)}
          getChangeHandler={getChangeHandler}
        />
      </ModalBodyWrapper>
      <ModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={t("cancel")}
        saveLabel={t("force_new_offer_dialog_title")}
        saveButtonId="save_import"
        onCancel={onCancel}
        onSave={handleSaveClick}
        disabled={
          isEmpty(lvHeaderData.buildProject) ||
          isEmpty(lvHeaderData.referenceNumber)
        }
        isLoading={isLoading}
      />
    </>
  );
};
