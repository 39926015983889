import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";

import { L10n } from "@encoway/l10n";

import { SmartInput } from "../../components/input/smartInput";
import { Loading } from "../../components/loading";
import { hasRightToImportLv } from "../../http/identityHelper";
import { ExtendedTeckentrupCartTO } from "../../types/cart";
import { OfferManagementDialogState } from "./offerManagement";
import { OfferManagementStore } from "./offerManagementUtils";
import { DispatcherActions } from "./useOfferManagementDispatcherActions";

type OfferManagementHeaderProps = {
  offerManagementState: OfferManagementDialogState;
  offerManagementSetState: Dispatch<SetStateAction<OfferManagementDialogState>>;
  offerManagementStore: OfferManagementStore;
  dispatcherActions: DispatcherActions;
  cart: ExtendedTeckentrupCartTO;
};

export const OfferManagementHeader = ({
  offerManagementState,
  offerManagementSetState,
  offerManagementStore,
  dispatcherActions,
  cart,
}: OfferManagementHeaderProps) => (
  <>
    <div className="columns">
      <div className="column">
        <div className="field">
          <p className="control has-icons-right">
            <SmartInput
              className="input"
              value={offerManagementStore.searchQuery}
              placeholder={L10n.format(
                "offer_management_search_offer_placeholder",
              )}
              onKeyPress={(event) => dispatcherActions.onOfferSearch(event)}
              onBlur={(event) => dispatcherActions.onOfferSearch(event)}
              type="text"
              autoComplete={"off"}
              attributeId="offer-management"
            />
            <span className="icon is-small is-right">
              {offerManagementState.loadingQuotes ? (
                <Loading />
              ) : (
                <FontAwesomeIcon icon={faSearch} />
              )}
            </span>
          </p>
        </div>
      </div>
      <div className="column field is-horizontal is-align-items-baseline pt-4">
        <div className="field-label is-normal">
          {L10n.format("offer_management_adjust_viewing_filter")}
        </div>
        <div className="field-body">
          <div className="select">
            <select
              data-filter-id="offer_management_adjust_viewing_filter"
              value={offerManagementStore.filterArchived}
              onChange={({ target }) =>
                dispatcherActions.onSetFilterArchived(target)
              }
            >
              <option
                value="ACTIVE"
                data-option-id="offer_management_viewing_filter_active"
              >
                {L10n.format("offer_management_viewing_filter_active")}
              </option>
              <option
                value="ARCHIVED"
                data-option-id="offer_management_viewing_filter_archived"
              >
                {L10n.format("offer_management_viewing_filter_archived")}
              </option>
            </select>
          </div>
        </div>
      </div>
      {hasRightToImportLv(cart.authorities.booleanAuthorities) && (
        <div className="column is-button">
          <button
            className="button is-info is-blue-dark"
            onClick={() =>
              offerManagementSetState({
                ...offerManagementState,
                uploadLvVisible: true,
              })
            }
            data-button-id="offer_management_create_lv_import"
          >
            <div className="is-pulled-left">
              {L10n.format("offer_management_create_lv_import")}
            </div>
            <div className="is-pulled-right">
              <i className="image-icon lv-import" aria-hidden="true" />
            </div>
          </button>
        </div>
      )}
      <div className="column is-button">
        <button
          className="button is-info is-blue-dark"
          onClick={() =>
            offerManagementSetState({
              ...offerManagementState,
              modifyOfferDialogVisible: true,
            })
          }
          data-button-id="offer_management_create_new_offer"
        >
          <div className="is-pulled-left">
            {L10n.format("offer_management_create_new_offer")}
          </div>
          <div className="is-pulled-right">
            <i
              className="image-icon neuen-vorgang-erstellen"
              aria-hidden="true"
            />
          </div>
        </button>
      </div>
    </div>
    <div className="checkbox-row is-flex">
      <div className="checkbox-wrapper">
        {L10n.format("offer_management_show_my_offers")}
        <input
          className="checkbox"
          type="checkbox"
          onChange={dispatcherActions.onToggleShowOnlyUserOffers}
          checked={offerManagementStore.showOnlyUserOffers}
          data-checkbox-id="show-only-user-offers"
        />
      </div>
      {
        // In TT-1660 deaktiviert: Funktion vorerst nicht benötigt. Erst zu einem späteren Zeitpunkt
        // TODO: Reaktivieren, wenn dieser button wieder relevant ist.
        /* <div className="checkbox-wrapper">
                          {L10n.format("offer_management_show_third_party_system_offers")}
                          <input
                            className="checkbox"
                            type="checkbox"
                            onChange={dispatcherActions.onToggleShowOnlyThirdPartySystemOffers}
                            checked={offerManagementStore.showOnlyThirdPartySystemOffers}
                          />
                        </div> */
      }
    </div>
  </>
);
