import { equals, isNil } from "ramda";
import { createContext, useEffect, useRef, useState } from "react";

import IdentityStore from "../http/identityStore";
import { getOrCreateCompany } from "../service/customerManagementService";
import { fetchPost } from "../service/fetch";
import type { AppStore } from "../types/app";
import { IdentityState } from "../types/identity";

export enum MODE {
  DEVELOP = "develop",
  PRODUCTION = "productive",
  ENVIRONMENT = "environment",
}

const initialState: IdentityState = {
  loading: true,
  conex: null,
  companyId: null,
  token: null,
  identityStore: null,
};

export const IdentityContext = createContext(initialState);
export const IdentityProvider = IdentityContext.Provider;

export function useIdentity(appStore: AppStore) {
  const [state, setState] = useState(initialState);
  const tokenRef = useRef<string | null>(null);
  const modeRef = useRef(MODE.PRODUCTION);

  useEffect(() => {
    (async () => {
      try {
        tokenRef.current = await teotoken; // teotoken is globally defined
        window.localStorage.setItem(MODE.ENVIRONMENT, MODE.PRODUCTION);
        modeRef.current = MODE.PRODUCTION;
      } catch (e) {
        tokenRef.current = window.localStorage.getItem("authToken");
        window.localStorage.setItem(MODE.ENVIRONMENT, MODE.DEVELOP);
        modeRef.current = MODE.DEVELOP;
      }
      if (isNil(tokenRef.current)) {
        await appStore.init(modeRef.current, "No System found");
        setState({ ...state, loading: false });
        return;
      }
      try {
        const login = await fetchPost("teckentrup-server/api/login")(
          {},
          { Authorization: `Bearer ${tokenRef.current}` },
        )();
        const {
          data: { returnValue },
        } = await getOrCreateCompany();
        const identityStore = new IdentityStore(tokenRef.current);
        await identityStore.storeAuthorities();
        await appStore.init(modeRef.current, login.headers.systemname);
        setState((prevState) => ({
          ...prevState,
          loading: false,
          conex: equals(login.headers.conex, "true"),
          companyId: returnValue.id,
          token: tokenRef.current,
          identityStore,
          ...identityStore.getRoleAndRights(),
        }));
      } catch (e) {
        await appStore.init(modeRef.current, "No System found");
        setState({ ...state, token: tokenRef.current, loading: false });
      }
    })();
  }, []);

  return state;
}
