import { pathOr } from "ramda";
import { useContext, useState } from "react";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { IdentityContext } from "../../../../hooks/useIdentity";
import { TeckentrupCartArticleTO } from "../../../../types/cart";
import { UseCartDropDownReturn } from "../../hooks/useCartDropDown";
import { t } from "../../localizationUtils";
import { buildFormatsArray, noFormatSelected } from "./downloadDialogUtils";

import "./downloadModal.scss";

type DownloadModalProps = {
  cartItem: TeckentrupCartArticleTO;
  closeDialogMethod: () => void;
  requestDrawingMethod: UseCartDropDownReturn["actions"]["requestDrawing"];
};

export function DownloadModal(props: Readonly<DownloadModalProps>) {
  const { cartItem, closeDialogMethod, requestDrawingMethod } = props;

  const { identityStore } = useContext(IdentityContext);
  const [requestDrawingOptions, setRequestDrawingOptions] = useState(
    JSON.parse(
      window.localStorage.getItem("requestDrawingOptions") ?? "{}",
    ) || { pdf: true, dwg: false },
  );

  function onCheckBoxChange(key: string): () => void {
    return () => {
      const newRequestDrawingOptions = {
        ...requestDrawingOptions,
        [key]: !requestDrawingOptions[key],
      };
      setRequestDrawingOptions(newRequestDrawingOptions);
      window.localStorage.setItem(
        "requestDrawingOptions",
        JSON.stringify(newRequestDrawingOptions),
      );
    };
  }

  const email = pathOr("", ["Nutzer", "Email"], identityStore!.getIdentity());

  return (
    <Modal className="download-modal">
      <ModalHeader
        label={t("download_dialog_title")}
        onCancel={closeDialogMethod}
      />
      <ModalBodyWrapper>
        {t("download_dialog_time_information")}
        <div className="has-text-weight-bold">{email}</div>
        <div className="columns">
          <h1 className="format column field is-horizontal has-text-weight-bold is-size-3">
            {t("download_dialog_format_header")}
          </h1>
        </div>
        <div className="checkbox-wrapper">
          <input
            data-checkbox-id="pdf"
            className="checkbox"
            type="checkbox"
            onChange={onCheckBoxChange("pdf")}
            checked={requestDrawingOptions.pdf}
          />
          pdf
        </div>
        <div className="checkbox-wrapper">
          <input
            className="checkbox"
            data-checkbox-id="dwg"
            type="checkbox"
            onChange={onCheckBoxChange("dwg")}
            checked={requestDrawingOptions.dwg}
          />
          dwg
          <div className="small-text">{t("dwg_format_information")}</div>
        </div>
      </ModalBodyWrapper>
      <ModalFooter
        saveLabel={t("request_drawing")}
        cancelLabel={t("cancel")}
        saveButtonId="request_drawing"
        onCancel={closeDialogMethod}
        onSave={() =>
          requestDrawingMethod(
            cartItem.positionNr,
            buildFormatsArray(requestDrawingOptions),
          )
        }
        disabled={noFormatSelected(requestDrawingOptions)}
      />
    </Modal>
  );
}
