import { LvTable } from "../../../hooks/useLvTable";
import { mapIndexed } from "../../../utilities/utilities";
import { t } from "../../shoppingCart/localizationUtils";
import { LvRowComponent } from "./LvRowComponent";

import "./LvTable.scss";

type LvTableProps = {
  lvTable: LvTable;
};

export const LvTableComponent = ({ lvTable }: LvTableProps) => {
  const { lvRows, toggleAllLvRowsChecked, areAllLvRowsChecked } = lvTable;

  return (
    <table id="lvTable" className="table is-fullwidth">
      <thead>
        <tr>
          <th>
            <div className="is-flex justify-content-center mt-1">
              <input
                className="checkbox"
                type="checkbox"
                checked={areAllLvRowsChecked}
                onChange={toggleAllLvRowsChecked}
              />
            </div>
          </th>
          <th>{t(`offer_management_lv_position`)}</th>
          <th>{t(`offer_management_lv_product`)}</th>
          <th>{t(`offer_management_lv_details`)}</th>
        </tr>
      </thead>
      <tbody>
        {mapIndexed(
          (row, rowIndex) => (
            <LvRowComponent
              key={row.detail.value}
              lvTable={lvTable}
              row={row}
              rowIndex={rowIndex}
            />
          ),
          lvRows,
        )}
      </tbody>
    </table>
  );
};
