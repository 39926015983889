import { ReducerWithoutAction } from "react";

export type StateReducer = ReducerWithoutAction<Record<string, any>>;
export const simpleReducer = (
  state: Record<string, any>,
  action: StateReducer,
) => action(state);

export const initialOrderModalState = {
  orderNumber: "",
  buildProject: "",
  dateOfDelivery: "",
  furtherNotes: "",
  allMandatoriesCompleted: true,
  isLoading: false,
  failed: false,
};

export const LoadCheckoutInformation =
  (checkoutInformation: Record<string, any>) =>
  (state: Record<string, any>) => ({
    ...state,
    orderNumber: checkoutInformation.referenceNumber,
    buildProject: checkoutInformation.buildProject,
    dateOfDelivery: checkoutInformation.dateOfDelivery,
    furtherNotes: checkoutInformation.furtherNotes,
    checkoutInformation,
  });

export const ChangeProperty =
  (name: string, value: any) => (state: Record<string, any>) => ({
    ...state,
    [name]: value,
  });
