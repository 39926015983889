import React, { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import { CartContext } from "../../hooks/useCart";
import { ConfigurationContext } from "../../hooks/useConfiguration";
import { ReconfiguringArticleLoadingModal } from "./reconfiguringArticleLoadingModal";

export function ReconfigurationFromProduct() {
  const { articleId, articleName, cartPosition, articleOrderStatus } =
    useParams();
  const { restart, loading, configurationId } =
    useContext(ConfigurationContext);
  const { cartActions } = useContext(CartContext);
  useEffect(() => {
    (async () => {
      await restart({
        articleId,
        articleName,
        cartPosition,
        articleOrderStatus,
      });
      cartActions.overwriteState({ reconfigurationPosition: cartPosition });
    })();
  }, []);

  if (!configurationId || loading) {
    return (
      <>
        <ReconfiguringArticleLoadingModal />
        <Loading />
      </>
    );
  }

  return (
    <Navigate
      to={`/reconfiguration/id/${configurationId}/${articleName}/${articleId}/${cartPosition}/${articleOrderStatus}`}
    />
  );
}
