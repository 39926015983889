import { omit, path } from "ramda";
import { ChangeEvent, useState } from "react";
import { Timeout } from "react-number-format/types/types";

import { L10n } from "@encoway/l10n";

import { ExtendedTeckentrupCartTO } from "../../../../../types/cart";
import { PrintOptionsValues } from "../../../../../types/print";
import { CartModalsReturn } from "../../../hooks/useCartModals";
import { determinePrintOptions } from "./printOptionsUtils";

export function usePrintOptionsModal(
  cart: ExtendedTeckentrupCartTO,
  modalActions: CartModalsReturn["modalActions"],
) {
  const [submitTimeout, setSubmitTimeout] = useState<Timeout | null>(null);

  const storedPrintOptions = window.localStorage.getItem("printOptions");
  const [printOptionsValues, setPrintOptionsValues] = useState(
    determinePrintOptions(
      storedPrintOptions ? JSON.parse(storedPrintOptions) : undefined,
      path(["headerData"], cart),
    ) ?? {
      salutation: L10n.format("printoptions_dialog_sal_placeholder"),
      documentType: "PDF",
      short: true,
      long: false,
      price: "net",
      techSpec: true,
      dimDrawing: true,
      logo: true,
      vat: true,
      manufacturer: true,
      priceDetails: true,
      language: L10n.currentFullLocale(),
      offerText: "",
      coverLetter: "",
      appendix: "",
    },
  );

  function updateLocalStorage(newValues: PrintOptionsValues): void {
    window.localStorage.setItem(
      "printOptions",
      JSON.stringify(omit(["salutation"], newValues)),
    );
  }

  function waitSavePrintOptions(
    printOptions: PrintOptionsValues,
  ): Promise<void> {
    submitTimeout && clearTimeout(submitTimeout);
    return new Promise(() => {
      setSubmitTimeout(
        setTimeout(
          () => modalActions.savePrintOptionsInHeaderData(printOptions),
          500,
        ),
      );
    });
  }

  function onInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    const newValues = {
      ...printOptionsValues,
      [event.target.name]: event.target.value,
    };
    setPrintOptionsValues(newValues);
    waitSavePrintOptions(newValues).then();
    updateLocalStorage(newValues);
  }

  function onCheckboxChange(key: keyof PrintOptionsValues): () => void {
    return (): void => {
      const newValues = {
        ...printOptionsValues,
        [key]: !printOptionsValues[key],
      };
      setPrintOptionsValues(newValues);
      updateLocalStorage(newValues);
    };
  }

  function onRadioOrDropdownChange(
    key: keyof PrintOptionsValues,
  ): (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void {
    return (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const newValues = { ...printOptionsValues, [key]: event.target.value };
      setPrintOptionsValues(newValues);
      updateLocalStorage(newValues);
    };
  }

  return {
    printOptionsValues,
    onInputChange,
    onCheckboxChange,
    onRadioOrDropdownChange,
  };
}

export type UsePrintOptionsModalReturn = ReturnType<
  typeof usePrintOptionsModal
>;
