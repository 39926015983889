import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "ramda";
import { useState } from "react";

import { L10n } from "@encoway/l10n";

import { BurgerMenuAction } from "./offerManagementTableUtils";

import "./itemRowMenu.scss";

type ItemRowMenuProps = {
  actions: BurgerMenuAction[];
  menuId: string;
};

const ItemRowMenuItem = ({ action, icon, label }: BurgerMenuAction) => (
  <button
    className="dropdown-item"
    onMouseDown={action}
    data-context-menu-entry={label}
  >
    <span className={`image-icon ${icon}`} />
    <span>{L10n.format(label)}</span>
  </button>
);

export const ItemRowMenu = ({ actions, menuId }: ItemRowMenuProps) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const toggleDropdownActiveOnClick = () => setDropdownActive(!dropdownActive);

  return (
    <div className="dropdown is-right is-active">
      <div className="dropdown-trigger">
        <button
          className="button"
          data-button-id={menuId}
          onClick={toggleDropdownActiveOnClick}
          onBlur={() => setDropdownActive(false)}
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </span>
        </button>
      </div>
      {dropdownActive && (
        <div className="dropdown-menu" data-context-menu={menuId}>
          <div className="dropdown-content">
            {map(
              (menuItem) => (
                <ItemRowMenuItem
                  key={menuItem.label}
                  action={menuItem.action}
                  icon={menuItem.icon}
                  label={menuItem.label}
                />
              ),
              actions,
            )}
          </div>
        </div>
      )}
    </div>
  );
};
