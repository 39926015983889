import Bacon from "baconjs";
import { isNil } from "ramda";
import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ConfigurationService, Constants, isReady } from "@encoway/rest-api";

import { createHttp } from "../http/setupHttp";
import { getVariantConditionPrices } from "../service/configurationService";
import { NonNullableValues } from "../types/app";
import { CartStore } from "../types/cart";
import {
  ConfigurationStore,
  ConfigurationStoreRestartParams,
  ConfigurationStoreStartParams,
  GuiTO,
  LocalStorageConfig,
} from "../types/configuration";
import { AppContext } from "./useApp";
import { expandConfigurationLoadTOIfNeeded } from "./useConfigurationUtils";

const initialConfiguration: ConfigurationStore = {
  configurationId: null,
  config: null,
  articleName: null,
  reconfiguration: null,
  variantConditionPrices: null,
  loading: false,

  remove: function remove() {
    throw new Error("remove function not initialized");
  },
  restart: function restart() {
    throw new Error("restart function not initialized");
  },
  save: function save() {
    throw new Error("save function not initialized");
  },
  start: function start() {
    throw new Error("start function not initialized");
  },
  onLoadCart: function onLoad() {
    throw new Error("load function not initialized");
  },
};

const initialState: ConfigurationStore = {
  ...initialConfiguration,
  guiTO: null,
  bus: null,
};

const LOCAL_STORAGE_CONFIGURATION = "configuration";

const initialLocalStorage: LocalStorageConfig = {
  configurationId: null,
  reconfiguration: null,
};

export const ConfigurationContext = createContext(initialState);
export const ConfigurationProvider = ConfigurationContext.Provider;

export function useConfiguration({
  cartActions,
}: NonNullableValues<CartStore>): ConfigurationStore {
  const { baseUrl, language } = useContext(AppContext);
  const http = useMemo(() => createHttp(null), []);
  const bus = useMemo(() => new Bacon.Bus(), []);
  const [configuration, setConfiguration] =
    useState<ConfigurationStore>(initialConfiguration);
  const [guiTO, setGuiTO] = useState<GuiTO | null>(null);

  async function remove() {
    if (configuration.config) {
      await configuration.config.stop();
      setConfiguration(initialState);
    }
  }

  async function start({
    articleName,
    configurationId,
  }: ConfigurationStoreStartParams) {
    cartActions.resetProperties();
    if (articleName || configurationId) {
      setConfiguration({ ...initialConfiguration, loading: true });
      const config = await ConfigurationService.create(
        http,
        baseUrl,
        { articleName, configurationId },
        language,
      );
      config.settings({
        mappingOptions: { mappingProfile: Constants.MappingProfile.Maximum },
      });
      sessionStorage.setItem(
        LOCAL_STORAGE_CONFIGURATION,
        JSON.stringify({
          configurationId: config.configurationId,
          reconfiguration: null,
        }),
      );
      const { data } = await getVariantConditionPrices(
        config.cfg.configurationId,
        language,
      );
      setGuiTO(await config.ui());
      setConfiguration({
        ...configuration,
        config,
        loading: false,
        reconfiguration: null,
        articleName: config.cfg.configuration.articleName,
        configurationId: config.cfg.configurationId,
        variantConditionPrices: data,
      });
    }
  }

  async function restart({
    articleId,
    articleName,
    cartPosition,
    articleOrderStatus,
    configurationId = null,
    deliveryTimeWish = "",
  }: ConfigurationStoreRestartParams) {
    setConfiguration({ ...initialConfiguration, loading: true });
    if (isNil(cartActions)) {
      return;
    }
    try {
      const configurationLoadTO =
        await cartActions.getArticleConfiguration(articleId);
      const config = await ConfigurationService.load(
        http,
        baseUrl,
        configurationId
          ? { configurationId }
          : expandConfigurationLoadTOIfNeeded(
              configurationLoadTO,
              deliveryTimeWish,
            ),
        language,
      );
      const {
        configurationStatus,
        conflictedParameters,
        failedParameters,
        unsuccessfulParameters,
      } = config.cfg;
      config.settings({
        mappingOptions: { mappingProfile: Constants.MappingProfile.Maximum },
      });
      sessionStorage.setItem(
        LOCAL_STORAGE_CONFIGURATION,
        JSON.stringify({
          configurationId: config.configurationId,
          reconfiguration: {
            articleId,
            articleName,
            cartPosition,
            articleOrderStatus,
          },
        }),
      );
      const { data } = await getVariantConditionPrices(
        config.configurationId,
        language,
      );
      setGuiTO(await config.ui());
      setConfiguration({
        ...configuration,
        reconfiguration: {
          configurationStatus,
          conflictedParameters,
          failedParameters,
          unsuccessfulParameters,
          articleId,
          cartPosition,
          articleOrderStatus,
        },
        loading: false,
        config,
        articleName,
        configurationId: config.configurationId,
        variantConditionPrices: data,
      });
    } catch (e) {
      const config = await ConfigurationService.create(
        http,
        baseUrl,
        { articleName },
        language,
      );
      config.settings({
        mappingOptions: { mappingProfile: Constants.MappingProfile.Maximum },
      });
      const { data } = await getVariantConditionPrices(
        config.configurationId,
        language,
      );
      setGuiTO(await config.ui());
      setConfiguration({
        ...configuration,
        reconfiguration: {
          configurationStatus: null,
          conflictedParameters: [],
          failedParameters: [],
          unsuccessfulParameters: [],
          articleId,
          cartPosition,
          articleOrderStatus,
        },
        loading: false,
        config,
        articleName,
        configurationId: config.configurationId,
        variantConditionPrices: data,
      });
    }
  }

  async function save(configurationId: string) {
    setConfiguration({ ...configuration, loading: true });
    if (configurationId && !isNil(cartActions)) {
      if (configuration.reconfiguration) {
        await cartActions
          .setPropertiesOnSave(configuration.reconfiguration.articleId)
          .then(() => {
            const readyState = isReady(guiTO?.rootContainer);
            cartActions.update(
              configuration.reconfiguration!.articleId,
              configurationId,
              configuration.reconfiguration!.articleOrderStatus,
              readyState,
            );
          });
      } else {
        await cartActions
          .saveConfiguration(configurationId)
          .then((cartResult: any) => {
            const articles = cartResult.articles.subArticles;
            cartActions.setPropertiesOnSave(
              articles[articles.length - 1].articleId,
            );
          });
      }
      sessionStorage.setItem(
        LOCAL_STORAGE_CONFIGURATION,
        JSON.stringify(initialLocalStorage),
      );
      setConfiguration({ ...initialConfiguration, loading: false });
    }
    // Could not save configuration, no configurationId set
  }

  useEffect(() => {
    (async () => {
      const lsConfig: LocalStorageConfig = JSON.parse(
        sessionStorage.getItem(LOCAL_STORAGE_CONFIGURATION) ?? "{}",
      );
      if (!isNil(lsConfig.configurationId)) {
        if (!isNil(lsConfig.reconfiguration)) {
          const { articleId, articleName, cartPosition, articleOrderStatus } =
            lsConfig.reconfiguration;
          await restart({
            articleId,
            articleName,
            cartPosition,
            articleOrderStatus,
          });
        } else {
          await start({ configurationId: lsConfig.configurationId });
        }
      }
    })();
    return bus
      .filter(({ event }: { event: string }) => event === "UpdateState")
      .onValue(
        (e: { rawState: SetStateAction<GuiTO | null> }) =>
          e.rawState && setGuiTO(e.rawState),
      );
  }, []);

  useEffect(() => {
    (async () => {
      if (configuration.configurationId) {
        const { data } = await getVariantConditionPrices(
          configuration.configurationId,
          language,
        );
        setConfiguration((prev) => ({ ...prev, variantConditionPrices: data }));
      }
    })();
  }, [language, guiTO]);

  return {
    ...configuration,
    guiTO,
    bus,
    start,
    restart,
    remove,
    save,
  };
}
