import { equals, forEach, isEmpty, isNil, not, pathOr, reduce } from "ramda";
import { Group, Material, Mesh, Object3D, Scene } from "three";

import {
  hasRightToSeeVisualization,
  hasRightToUseAr,
} from "../http/identityHelper";
import { ExtendedTeckentrupCartTO } from "../types/cart";

export const SHOW_VIS_PATH = [
  "product",
  "characteristicValues",
  "vis_anzeigen",
  "values",
  0,
  "value",
];
export const VIS_PATH = [
  "product",
  "characteristicValues",
  "visualization",
  "values",
  0,
  "uri",
];
export const VIS_AR_SCALING_PATH = [
  "product",
  "characteristicValues",
  "arScaleFactor",
  "values",
  0,
  "value",
];
export const VIS_AR_PLACEMENT_PATH = [
  "product",
  "characteristicValues",
  "arPlacement",
  "values",
  0,
  "value",
];

export const VIS_STATE_SHOW_WALL_KEY = "showWall";
export const VIS_STATE_SHOW_WALL_ON = "show";
export const VIS_STATE_SHOW_WALL_OFF = "hide";

export function removeHighlight(scene: Scene) {
  const clonedScene = scene.clone();
  clonedScene.traverse((obj: Object3D) => {
    const material = obj.userData.highlight;

    if (isNil(material)) {
      return;
    }

    delete obj.userData.highlight;
    obj.traverse((item: Object3D) => {
      if (equals("Mesh", item.type)) {
        if (
          not(equals(material.id, ((item as Mesh).material as Material).id))
        ) {
          delete item.userData.origMaterial;
          return;
        }
        if (item.userData.origMaterial) {
          delete item.userData.origMaterial;
        }
      }
    });
  });

  return clonedScene;
}

export function removeEmptyObjects(sceneGroup: Group) {
  const empty3dObjects: Object3D[] = [];

  const clonedSceneGroup = sceneGroup.clone();
  clonedSceneGroup.traverse((obj: Object3D) => {
    if (equals("Mesh", obj.type)) {
      const boundingBox = (obj as Mesh).geometry.boundingBox;
      if (
        equals(0, boundingBox?.getSize(new window.THREE.Vector3()).length())
      ) {
        empty3dObjects.push(obj);
      }
    }
  });

  forEach(
    (emptyObject) => emptyObject?.parent?.remove(emptyObject),
    empty3dObjects,
  );

  return clonedSceneGroup;
}

export const checkVisualizationPermission = (
  cart: ExtendedTeckentrupCartTO | null,
  product: any,
): boolean => {
  if (isNil(cart) || isNil(cart.authorities)) {
    return false;
  }
  return (
    equals(pathOr(0, SHOW_VIS_PATH, product), 1) &&
    !isEmpty(pathOr("", VIS_PATH, product)) &&
    hasRightToSeeVisualization(cart.authorities.booleanAuthorities)
  );
};

export const checkArPermission = (cart: ExtendedTeckentrupCartTO | null) => {
  if (isNil(cart)) {
    return false;
  }
  return hasRightToUseAr(cart.authorities.booleanAuthorities);
};

export const getVisualizationArScaling = (product: any) =>
  pathOr(0.001, VIS_AR_SCALING_PATH, product);

export const getVisualizationArPlacement = (product: any) =>
  pathOr("floor", VIS_AR_PLACEMENT_PATH, product);

export const prepareVisCharacteristics = (data: any) =>
  isEmpty(data)
    ? {}
    : reduce(
        (acc, cur: any) => ({
          ...acc,
          [cur.name]: pathOr("", ["selectedValues", 0, "value"], cur),
        }),
        {},
        data,
      );
