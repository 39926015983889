import { AxiosResponse } from "axios";
import classNames from "classnames";
import { pathOr } from "ramda";
import { useContext } from "react";

import { CartContext } from "../../../../hooks/useCart";
import { hasNoRightToModifyOffer } from "../../../../http/identityHelper";
import {
  canNotBeOrdered,
  cartHasArticles,
  hasQuote,
  hasUserUnsavedCart,
  quoteIsSavable,
} from "../../../../utilities/cartUtils";
import { CART_MODALS, CartModals, ModalData } from "../../hooks/useCartModals";
import { t } from "../../localizationUtils";
import { dateIsOlderThanSixWeeks } from "./cartInformationUtils";

import "./saveButtonRow.scss";

type ButtonProps = {
  label: string;
  icon: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const Button = ({ label, icon, className, ...rest }: ButtonProps) => (
  <button
    className={classNames("button cartButton", className)}
    {...rest}
    data-button-id={label}
  >
    <span>{t(label)}</span>
    <span>
      {icon && (
        <i className={classNames(["image-icon", icon])} aria-hidden="true" />
      )}
    </span>
  </button>
);

type ButtonRowProps = {
  hasRights: boolean;
  toggleModal: (modal: CartModals) => (data?: ModalData) => void;
  toggleModalLoading: (modal: CartModals) => () => void;
  saveQuoteAndClose: () => Promise<AxiosResponse>;
  modalLoadingStates: { [key: string]: boolean };
};

export const ButtonRow = (props: ButtonRowProps) => {
  const { cart } = useContext(CartContext);
  const isSavable = quoteIsSavable(cart);
  const quoteAvailable = hasQuote(cart);

  const {
    hasRights,
    toggleModal,
    toggleModalLoading,
    saveQuoteAndClose,
    modalLoadingStates,
  } = props;

  const saveQuoteOrShowCreateQuoteWarning = hasUserUnsavedCart(cart)
    ? toggleModal(CART_MODALS.CLEAR_CART)
    : () => {
        toggleModalLoading(CART_MODALS.CLEAR_CART)();
        saveQuoteAndClose().then(toggleModalLoading(CART_MODALS.CLEAR_CART));
      };

  const toggleModifyOfferModal = !hasNoRightToModifyOffer(
    pathOr([], ["authorities", "booleanAuthorities"], cart),
  )
    ? toggleModal(CART_MODALS.MODIFY_OFFER)
    : undefined;

  const onClearEvent =
    isSavable && hasRights ? saveQuoteOrShowCreateQuoteWarning : undefined;
  const onSaveEvent =
    isSavable && hasRights ? toggleModal(CART_MODALS.MODIFY_OFFER) : undefined;

  return (
    <div className="save-buttons">
      <div className="buttons">
        {!hasQuote(cart) ? (
          <Button
            label={"cart_clear_positions"}
            icon="neuen-vorgang-erstellen"
            disabled={!cartHasArticles(cart) || !isSavable || !hasRights}
            onClick={onClearEvent}
          />
        ) : (
          <Button
            label="cart_cancel_process"
            icon="neuen-vorgang-erstellen"
            onClick={saveQuoteOrShowCreateQuoteWarning}
          />
        )}
        {quoteAvailable ? (
          <Button
            label="modify_dialog_title"
            icon="notiz"
            disabled={hasNoRightToModifyOffer(
              pathOr([], ["authorities", "booleanAuthorities"], cart),
            )}
            onClick={toggleModifyOfferModal}
          />
        ) : (
          <Button
            label="save_shopping_cart"
            icon="warenkorb-speichern"
            disabled={
              !isSavable ||
              !hasRights ||
              modalLoadingStates[CART_MODALS.MODIFY_OFFER]
            }
            onClick={onSaveEvent}
          />
        )}
        {cart && !canNotBeOrdered(cart) && (
          <Button
            className={classNames({
              highlighted: dateIsOlderThanSixWeeks(new Date(cart.priceDate)),
            })}
            label="update_price_dialog_title"
            disabled={!cartHasArticles(cart)}
            icon="calc"
            onClick={toggleModal(CART_MODALS.UPDATE_PRICES)}
          />
        )}
      </div>
    </div>
  );
};
