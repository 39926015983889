import classnames from "classnames";
import { isEmpty, mergeRight, pathOr } from "ramda";
import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { L10n } from "@encoway/l10n";
import { Constants } from "@encoway/react-components";
import {
  Constants as RestApiConstants,
  selectedValue,
} from "@encoway/rest-api";

import { ConfigurationContext } from "../../../hooks/useConfiguration";
import { ParameterTO } from "../../../types/@encoway/Parameter";
import { ConfiguratorComponentProps } from "../../../types/configuration";

const defaultStyle = {
  showAnnotation: true,
  maxLength: 0,
};

// Reconstruction of the textField.js from the react-components-develop repository,
// which was only adjusted in terms of the loading state.
export default function InputField(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const { data, options, loading, style, setLoading, onFocus, onValueChanged } =
    props;
  const { config } = useContext(ConfigurationContext);
  const [value, setValue] = useState(selectedValue(data, ""));
  const [writeLock, setWriteLock] = useState(false);
  const lastValueRef = useRef(value);

  useEffect(() => {
    setWriteLock(false);
    const newValue = selectedValue(data, "");
    setValue(newValue);
    lastValueRef.current = newValue;
    setLoading(false);
  }, [data, options]);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function submitChange() {
    onFocus(data.id, data.name, true);
    if (value === lastValueRef.current || loading || writeLock) {
      options.eventBus.push({
        id: data.id,
        event: "ParameterNotChanged",
        args: config?.cfg,
        message: null,
      });
      return;
    }
    setWriteLock(true);
    const newValue = isEmpty(value) ? Constants.Undo : value;
    setLoading(true);
    onValueChanged(data, newValue, RestApiConstants.ValueFormat.Formatted);
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      submitChange();
    }
  }

  const { placeholder, maxLength, showAnnotation } = mergeRight(
    defaultStyle,
    style,
  );

  const unit = pathOr("", ["displayUnit", "translatedUnit"], data);

  const context = {
    minValue:
      !data.minValue || data.minValue.indexOf("inf") >= 0
        ? null
        : data.minValue,
    maxValue:
      !data.maxValue || data.maxValue.indexOf("inf") >= 0
        ? null
        : data.maxValue,
    unit,
  };

  const hasUnit = !isEmpty(unit);

  const css = classnames("control is-textfield", {
    "is-loading": loading,
    "has-unit": hasUnit,
    "has-unit-right": hasUnit,
  });

  const inputCss = classnames("input data-configurator-textfield", {
    "is-warning": !data.terminal && data.mandatory,
  });

  return (
    <p className={css}>
      <input
        className={inputCss}
        type="text"
        disabled={!data.editable}
        value={value}
        title={data.shortText}
        onFocus={() => onFocus(data.id, data.name, false)}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={submitChange}
        data-configurator-textfield-id={data.name}
        {...(placeholder && { placeholder: L10n.format(placeholder, context) })}
        {...(maxLength > 0 && { maxLength })}
      />
      {hasUnit && !loading && <span className="unit">{unit}</span>}
      {showAnnotation && (
        <span className="help is-info">
          {L10n.format("annotation", context)}
        </span>
      )}
    </p>
  );
}
