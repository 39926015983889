import classNames from "classnames";

import { L10n } from "@encoway/l10n";

import { Config } from "../../config/config";
import { isNewSalutation } from "../../pages/offerManagement/modifyOfferModal/modifyOfferDialogUtils";

import "./inputTitle.scss";

type DeleteButtonProps = {
  onClick: () => void;
};

function DeleteButton({ onClick }: Readonly<DeleteButtonProps>) {
  return (
    <div className="quote-address-delete-button">
      <button
        className="button is-pulled-right"
        onClick={onClick}
        data-button-id={"delete-offer-address"}
      >
        <div className="is-pulled-left">
          {L10n.format("modify_dialog_contact_delete")}
        </div>
        <div className="is-pulled-right">
          <i className="image-icon loeschen" aria-hidden="true" />
        </div>
      </button>
    </div>
  );
}

type InputTitleProps = {
  title: string;
  onClick: (title: string) => void;
  onDeleteModalOpen?: () => void;
};

export function InputTitle(props: Readonly<InputTitleProps>) {
  const { title, onClick, onDeleteModalOpen } = props;
  return (
    <>
      <div className="quote-address-salutation-label">
        <p>{L10n.format("modify_dialog_contact_title")}</p>
      </div>
      <div className="quote-address-salutation-wrapper">
        <div className="quote-address-salutation-bg">
          <button
            className={classNames(["quote-address-salutation", "no-border"], {
              active: title === L10n.format("modify_dialog_contact_mr"),
            })}
            onClick={() => onClick(L10n.format("modify_dialog_contact_mr"))}
            data-switch-id="modify_dialog_contact_mr"
          >
            <p>{L10n.format("modify_dialog_contact_mr")}</p>
          </button>
          <button
            className={classNames(["quote-address-salutation", "no-border"], {
              active: title === L10n.format("modify_dialog_contact_mrs"),
            })}
            onClick={() => onClick(L10n.format("modify_dialog_contact_mrs"))}
            data-switch-id="modify_dialog_contact_mrs"
          >
            <p>{L10n.format("modify_dialog_contact_mrs")}</p>
          </button>
          {L10n.currentFullLocale() === Config.Lang.English && (
            <div
              className={classNames(["quote-address-salutation", "no-border"], {
                active:
                  title ===
                  L10n.format("modify_dialog_contact_additional_title"),
              })}
              onClick={() =>
                onClick(L10n.format("modify_dialog_contact_additional_title"))
              }
            >
              <p>{L10n.format("modify_dialog_contact_additional_title")}</p>
            </div>
          )}
        </div>
        {!isNewSalutation(title) && (
          <div className={"quote-address-old-salutation-hint"}>
            <p>
              {L10n.format("modify_offer_dialog_old_title_message")} {title}
            </p>
          </div>
        )}
        {onDeleteModalOpen && <DeleteButton onClick={onDeleteModalOpen} />}
      </div>
    </>
  );
}
