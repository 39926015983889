import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { FlexTableCell } from "../../../../../components/flexTable/flexTable";

import "./checkboxCell.scss";

type CheckboxCellProps = {
  optional: boolean;
  setOptional: () => void;
  className?: string;
  dataCheckboxId?: string;
};

export const CheckboxCell = ({
  optional,
  setOptional,
  className,
  dataCheckboxId,
}: CheckboxCellProps) => (
  <FlexTableCell>
    <div
      className={classNames("table-checkbox", className)}
      data-checkbox-id={dataCheckboxId}
      onClick={setOptional}
    >
      {optional ? (
        <FontAwesomeIcon icon={faSquareCheck} style={{ color: "#8f8f9d" }} />
      ) : (
        <FontAwesomeIcon icon={faSquare} style={{ color: "#8f8f9d" }} />
      )}
    </div>
  </FlexTableCell>
);
