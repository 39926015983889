import { isEmpty, split } from "ramda";

import { L10n } from "@encoway/l10n";

import { LvTable } from "../../../hooks/useLvTable";
import { LvRow } from "../../../types/lvTable";
import { LvInputField } from "./components/LvInputField";
import { LvProductField } from "./components/LvProductField";

type LvRowProps = {
  lvTable: LvTable;
  row: LvRow;
  rowIndex: number;
};

export const LvRowComponent = ({ lvTable, row, rowIndex }: LvRowProps) => {
  const { toggleLvRowChecked, lvHtml } = lvTable;

  const scrollToPosition = (elementId: string) => {
    const htmlWindow = window.open();
    if (!htmlWindow) {
      return;
    }
    htmlWindow.document.body.innerHTML = lvHtml;
    const position = htmlWindow.document.getElementById(elementId);
    // Scrolling does not work if it is not in setTimeout
    // Checking for htmlDocument.readyState is not working
    const scrollTimer = setTimeout(() => {
      position?.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 10);
    return () => {
      clearTimeout(scrollTimer);
    };
  };

  return (
    <tr>
      <td>
        <div className="is-flex justify-content-center mt-2">
          <input
            className="checkbox"
            type="checkbox"
            checked={row.checked}
            onChange={() => toggleLvRowChecked(rowIndex)}
            disabled={isEmpty(row.product.value)}
          />
        </div>
      </td>
      <td>
        <LvInputField
          rowIndex={rowIndex}
          parameterKey="position"
          lvTable={lvTable}
        />
        <LvInputField
          rowIndex={rowIndex}
          parameterKey="reference"
          lvTable={lvTable}
        />
        <LvInputField
          rowIndex={rowIndex}
          parameterKey="quantity"
          lvTable={lvTable}
          isNumber
        />
      </td>
      <td>
        <LvProductField rowIndex={rowIndex} lvTable={lvTable} />
      </td>
      <td>
        <button
          className="button is-small is-info is-blue-dark"
          onClick={() => scrollToPosition(row.detail.value)}
        >
          {L10n.format("offer_management_lv_position")}{" "}
          {split("_", row.detail.value)[1]}
        </button>
      </td>
    </tr>
  );
};
