import {
  faQrcode,
  faUpRightAndDownLeftFromCenter,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useMemo } from "react";

import "./visualizationButtonRow.scss";

type VisualizationButtonRowProps = {
  showArButton: boolean;
  showWall: boolean;
  toggleWall: () => void;
  loadAr: () => void;
  openModal: () => void;
};

export function VisualizationButtonRow(
  props: Readonly<VisualizationButtonRowProps>,
) {
  const { showArButton, showWall, toggleWall, loadAr, openModal } = props;

  const showWallTextKey = useMemo(
    () =>
      showWall
        ? "configuration_visualization_hide_wall"
        : "configuration_visualization_show_wall",
    [showWall],
  );

  return (
    <div className="vis-buttons">
      <button
        className={classNames(
          "button",
          { "is-info is-blue-dark": !showWall },
          { "is-orange": showWall },
        )}
        onClick={toggleWall}
        data-button-id={showWallTextKey}
      >
        <FontAwesomeIcon icon={faWarehouse} />
      </button>
      {showArButton && (
        <button
          className="button is-info is-blue-dark"
          onClick={loadAr}
          data-button-id="qrCode"
        >
          <FontAwesomeIcon icon={faQrcode} />
        </button>
      )}
      <button
        data-button-id="configuration_visualization_fullscreen"
        className="button is-info is-blue-dark"
        onClick={openModal}
      >
        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
      </button>
    </div>
  );
}
