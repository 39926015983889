import classNames from "classnames";
import { ComponentPropsWithoutRef } from "react";

import { L10n } from "@encoway/l10n";

import "./radioButton.scss";

type RadioButtonProps = ComponentPropsWithoutRef<"input"> & {
  label?: string;
};

export const RadioButton = ({
  label,
  className,
  ...rest
}: RadioButtonProps) => (
  <label className={classNames(["input-overlay", className])}>
    <input type="radio" {...rest} data-radio-button-id={label} />
    <span className="overlay" />
    {label && <span>{L10n.format(label)}</span>}
  </label>
);

type CustomRadioButtonProps = Omit<
  ComponentPropsWithoutRef<"input">,
  "onChange"
> & {
  label: string;
  onValueChange: ComponentPropsWithoutRef<"input">["onChange"];
};

export const CustomRadioButton = ({
  label,
  name,
  value,
  checked,
  onValueChange,
}: CustomRadioButtonProps) => (
  <label className="radio is-fullwidth">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked === value}
      onChange={onValueChange}
    />
    <span>{L10n.format(label)}</span>
  </label>
);
