import { isEmpty, map } from "ramda";

import { L10n } from "@encoway/l10n";

import { QuoteShareTO } from "../../../../types/cart";
import { getFormattedDate } from "../../../../utilities/dateUtils";
import { ProjectInformationShared } from "../modifyOfferModalActions";

import "./projectShareInformation.scss";

type ShareInformationEntryProps = {
  quote: QuoteShareTO;
};

type ProjectShareInformationProps = {
  projectInformationShared: ProjectInformationShared;
};

const ShareInformationEntry = ({
  quote,
}: Readonly<ShareInformationEntryProps>) => (
  <>
    <span>{quote.quoteOrderNumber}</span>
    <span>{quote.userId}</span>
    <span>{getFormattedDate(quote.creationDate)}</span>
    <span>{quote.companyNumber ?? quote.accountId}</span>
    <span>{quote.userName}</span>
  </>
);

export const ProjectShareInformation = ({
  projectInformationShared,
}: Readonly<ProjectShareInformationProps>) => {
  const { parent, children } = projectInformationShared;
  return (
    <>
      {!isEmpty(children) && (
        <div className="sharedQuoteInformation with-padding">
          <div className="custom-margin-2">
            <h2 className="subtitle has-text-weight-bold">
              {"Daten zum geteilten Vorgang"}
            </h2>
          </div>

          <div className="shareInfo">
            <div className="grid">
              <span className="has-text-weight-bold has-background-grey-lighter">
                {L10n.format("modify_dialog_shareInformation_teo_number")}
              </span>
              <span className="has-text-weight-bold has-background-grey-lighter">
                {L10n.format("modify_dialog_shareInformation_userId")}
              </span>
              <span className="has-text-weight-bold has-background-grey-lighter">
                {L10n.format("modify_dialog_shareInformation_shareDate")}
              </span>
              <span className="has-text-weight-bold has-background-grey-lighter">
                {L10n.format(
                  "modify_dialog_shareInformation_companyNumberOrAccountId",
                )}
              </span>
              <span className="has-text-weight-bold has-background-grey-lighter">
                {L10n.format("modify_dialog_shareInformation_userName")}
              </span>
              {map(
                (quote) => (
                  <ShareInformationEntry quote={quote} />
                ),
                children,
              )}
            </div>
          </div>
        </div>
      )}
      <br />
      {parent && (
        <div>
          <span className="has-text-weight-bold">
            {`${L10n.format("modify_dialog_shareInformation_userId")}: `}
          </span>
          <span>{parent.userId}</span>
        </div>
      )}
    </>
  );
};
