import { AxiosResponse } from "axios";
import { curry } from "ramda";

import { ParameterTO } from "../types/@encoway/Parameter";
import { Value } from "../types/@encoway/Value";
import { fetchDelete, fetchGet, fetchPut } from "./fetch";

export const getVariantConditionPrices = (
  configurationId: string,
  language: string,
): Promise<AxiosResponse> =>
  fetchGet(
    `teckentrup-server/api/pricing/${configurationId}/VariantConditionPrices`,
  )({}, { "Accept-Language": language })();

export const undoConfigurationParameter = (
  configurationId: string,
  parameterId: string,
  language: string,
): Promise<AxiosResponse> =>
  fetchDelete(
    `teckentrup-server/api/configuration/configurations/${configurationId}/parameters/${parameterId}`,
  )({}, { "Accept-Language": language })();

export const setConfigurationParameter = (
  configurationId: string,
  parameterId: string,
  language: string,
  selectedValue: Value,
  viewPort: string,
): Promise<AxiosResponse> => {
  const format = viewPort === "inputField" ? "FORMATTED" : "UNFORMATTED";
  const value =
    viewPort === "inputField"
      ? selectedValue.translatedValue
      : selectedValue.value;
  return fetchPut(
    `teckentrup-server/api/configuration/configurations/${configurationId}/parameters/${parameterId}`,
  )(
    {},
    { "Accept-Language": language },
  )({ value: `${value}`, format });
};

export const getConflictFreeParameterTO = curry(
  (configurationId: string, language: string, parameterId: string) =>
    fetchGet<ParameterTO>(
      `teckentrup-server/api/cart/custom/configuration/${configurationId}/parameter/${parameterId}`,
    )({}, { "Accept-Language": language })(),
);
