import { isEmpty, isNil } from "ramda";
import { useEffect, useState } from "react";

import { CharacteristicViewport } from "@encoway/catalog-components";
import { L10n } from "@encoway/l10n";

import { mapIndexed } from "../../../utilities/utilities";
import { Attribute } from "./attribute";
import { getCharacteristics, getCharValue } from "./productAttributeUtils";

const getProductImage = (characteristic) => {
  if (
    typeof characteristic.characteristicValues.Product_image === "undefined"
  ) {
    return null;
  }
  return characteristic?.characteristicValues?.Product_image?.values[0]?.uri;
};

const mapCharacteristics = (currentProduct, characteristic) => ({
  characteristicId: characteristic.id,
  characteristicName: characteristic.name,
  documentationUrl: CharacteristicViewport.render(
    characteristic,
    getCharValue(currentProduct, characteristic),
  ),
  productImageUri: getProductImage(characteristic),
});

export const ProductAttributes = ({
  currentProduct,
  headline,
  characteristicName,
  displayAsLink,
}) => {
  const [attributes, setAttributes] = useState(null);

  useEffect(() => {
    (async () => {
      await setAttributes(
        getCharacteristics(currentProduct, characteristicName),
      );
    })();
  }, [currentProduct]);

  if (isNil(attributes) || isEmpty(attributes)) {
    return null;
  }

  return (
    <div className="attribute">
      <div className="headline">{L10n.format(headline)}</div>
      <div className="value-container">
        <table>
          <tbody>
            {mapIndexed(
              (char, index) => (
                <Attribute
                  key={index}
                  displayAsLink={displayAsLink}
                  characteristic={mapCharacteristics(currentProduct, char)}
                />
              ),
              attributes,
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
