import { Config } from "../config/config";
import {
  CompanyDto,
  CustomerDto,
} from "../pages/customerManagement/customerManagementUtils";
import {
  AddCustomerPayload,
  CustomerExistsPayload,
  RemoveCustomerPayload,
  convertToFormData,
  withCompanyId,
} from "../utilities/fetchUtils";
import { fetchPost } from "./fetch";

type StatusDto<T> = {
  message: string;
  code: string;
  type: "SUCCESS" | "FAILED";
  returnValue: T;
};

type CustomerResponse = {
  pagedCustomers: CustomerDto[];
  totalAmountOfCustomers: number;
};

const customerPath = `${Config.Service.CustomerManagementBaseUrl}/teckentrup-customermanagement/customermanagement`;

function fetchCustomerPost<T>(url: string, absolute: boolean) {
  const token = localStorage.getItem("authToken");
  return fetchPost<T>(url, absolute)(undefined, {
    Authorization: token,
  });
}

export const getCustomers = () =>
  fetchCustomerPost<CustomerResponse>(`${customerPath}/customers`, true)();

export const getCustomer = fetchCustomerPost<StatusDto<CustomerDto>>(
  `${customerPath}/getCustomer`,
  true,
);

export const addCustomer = (data: AddCustomerPayload) =>
  fetchCustomerPost<StatusDto<CustomerDto>>(
    `${customerPath}/addCustomer`,
    true,
  )(withCompanyId(data));

export const updateCustomer = fetchCustomerPost<StatusDto<CustomerDto>>(
  `${customerPath}/updateCustomer`,
  true,
);

export const removeCustomer = (data: RemoveCustomerPayload) =>
  fetchCustomerPost<StatusDto<null>>(
    `${customerPath}/removeCustomer`,
    true,
  )(convertToFormData(data));

export const customerExists = (data: CustomerExistsPayload) =>
  fetchCustomerPost<boolean>(
    `${customerPath}/customerExists`,
    true,
  )(withCompanyId(data));

export const getOrCreateCompany = () =>
  fetchCustomerPost<StatusDto<CompanyDto>>(
    `${customerPath}/getOrCreateCompany`,
    true,
  )();
