import {
  faCheck,
  faComment,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, map, omit, path, values } from "ramda";
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

import {
  FlexTableCell as Cell,
  FlexTable,
} from "../../../components/flexTable/flexTable";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { CartContext } from "../../../hooks/useCart";
import { ConfigurationContext } from "../../../hooks/useConfiguration";
import { IdentityContext } from "../../../hooks/useIdentity";
import { hasRightToSeeCommentaryExtraOM } from "../../../http/identityHelper";
import IdentityStore from "../../../http/identityStore";
import { CartActions, ExtendedTeckentrupCartTO } from "../../../types/cart";
import { ConfigurationStore } from "../../../types/configuration";
import { getFormattedDate } from "../../../utilities/dateUtils";
import { isNilOrEmpty } from "../../../utilities/utilities";
import { t } from "../../shoppingCart/localizationUtils";
import { CartWarningWrapper } from "../cartWarningWrapper";
import { FilterableTableHead } from "../filterableTableHead";
import { ModifyOfferModal } from "../modifyOfferModal/modifyOfferModal";
import { OfferManagementDialogState } from "../offerManagement";
import { OfferManagementStore } from "../offerManagementUtils";
import { DispatcherActions } from "../useOfferManagementDispatcherActions";
import { ItemRowMenu } from "./itemRowMenu";
import {
  InitialOfferManagementTableState,
  onCommentModalInputChange,
  onDeleteQuote,
  onSaveQuote,
  prepareOfferManagementTableState,
} from "./offerManagementTableActions";
import {
  DialogState,
  OfferManagementTableRow,
  SortHeader,
  findQuote,
  isAdminOrClientIdEqual,
  isQuoteOrdered,
  prepareActions,
} from "./offerManagementTableUtils";
import { SortableTableHead } from "./sortableTableHead";

import "./offerManagementTable.scss";

type TableCellProps = ComponentPropsWithoutRef<"div"> & {
  firstData: string;
  secondData?: string;
};

type CommentModalProps = CommentModalWrapperProps & {
  setCommentModalVisible: Dispatch<SetStateAction<boolean>>;
};

type CommentModalWrapperProps = {
  item: typeof InitialOfferManagementTableState;
  onCommentChange: (
    commentState: string,
    setCommentModalVisible: Dispatch<SetStateAction<boolean>>,
  ) => void;
};

type OfferManagement = {
  state: OfferManagementDialogState;
  setState: Dispatch<SetStateAction<OfferManagementDialogState>>;
  store: OfferManagementStore;
};

type BuildBodyProps = {
  item: OfferManagementTableRow;
  identityStore: IdentityStore | null;
  cart: ExtendedTeckentrupCartTO;
  dispatcherActions: DispatcherActions;
  setModifyOfferDialogVisible: Dispatch<SetStateAction<DialogState>>;
  setDeleteCartWarningVisible: Dispatch<SetStateAction<DialogState>>;
  offerManagement: OfferManagement;
  configuration: ConfigurationStore;
  cartActions: CartActions;
};

type OfferManagementTableProps = {
  offerManagementState: OfferManagementDialogState;
  offerManagementSetState: Dispatch<SetStateAction<OfferManagementDialogState>>;
  offerManagementStore: OfferManagementStore;
  dispatcherActions: DispatcherActions;
};

const TableCellWrapper = ({
  firstData,
  secondData,
  ...props
}: TableCellProps) => (
  <div className="table-cell-wrapper" {...props}>
    <div>{firstData}</div>
    {secondData && <div className="small-text">{secondData}</div>}
  </div>
);

const CommentModal = ({
  item,
  setCommentModalVisible,
  onCommentChange,
}: CommentModalProps) => {
  const { cart } = useContext(CartContext);
  const [commentState, setCommentState] = useState(item.comment);
  const labelExtra = hasRightToSeeCommentaryExtraOM(
    cart.authorities.booleanAuthorities,
  )
    ? t("will_not_be_transmitted_to_Teckentrup")
    : "";
  return (
    <Modal>
      <ModalHeader
        label={`${t("offer_management_edit_comment_title")} ${labelExtra}`}
        onCancel={() => setCommentModalVisible(false)}
      />
      <ModalBodyWrapper>
        <textarea
          className="textarea"
          value={commentState}
          onChange={(event) => setCommentState(event.target.value)}
        />
      </ModalBodyWrapper>
      <ModalFooter
        onCancel={() => setCommentModalVisible(false)}
        cancelLabel={t("cancel")}
        saveLabel={t("submit")}
        onSave={() => onCommentChange(commentState, setCommentModalVisible)}
        saveIcon={<FontAwesomeIcon icon={faCheck} />}
      />
    </Modal>
  );
};

const CommentWrapper = ({
  item,
  onCommentChange,
}: CommentModalWrapperProps) => {
  const [commentModalVisible, setCommentModalVisible] = useState(false);

  if (isNilOrEmpty(item.comment)) {
    return null;
  }

  return (
    <div className="comment-icon-wrapper">
      <span
        className="icon is-small is-right"
        onClick={() => setCommentModalVisible(true)}
      >
        <FontAwesomeIcon icon={faComment} />
      </span>
      {commentModalVisible && (
        <CommentModal
          item={item}
          onCommentChange={onCommentChange}
          setCommentModalVisible={setCommentModalVisible}
        />
      )}
    </div>
  );
};

const RequestWrapper = () => (
  <div className="comment-icon-wrapper">
    <span className="icon is-small is-right">
      <FontAwesomeIcon icon={faEnvelope} />
    </span>
  </div>
);

const buildHead = (
  offerManagementStore: OfferManagementStore,
  dispatcherActions: DispatcherActions,
) => [
  ...map(
    (tableHead) => (
      <SortableTableHead
        label={t(tableHead.label)}
        sortKey={tableHead.sortKey}
        type={tableHead.type}
        store={offerManagementStore}
        onChangeSort={dispatcherActions.onChangeSort}
      />
    ),
    SortHeader,
  ),
  <FilterableTableHead
    key={"filterableTableHead"}
    store={offerManagementStore}
    name="_headerData.status"
    onChangeFilter={dispatcherActions.onChangeFilter}
  />,
  <Cell key={"custom-cell-icon-1"} className="custom-cell-icon" />,
  <Cell key={"custom-cell-icon-2"} className="custom-cell-icon" />,
  <Cell key={"custom-cell-icon-3"} className="custom-cell-icon" />,
];

const buildBody = ({
  item,
  identityStore,
  cart,
  dispatcherActions,
  ...restParams
}: BuildBodyProps) => {
  const CompleteItem = {
    ...omit(
      [
        "userId",
        "creatorUserId",
        "currentUserId",
        "clientId",
        "active",
        "accountId",
        "createdBy",
        "lastModifiedBy",
        "orderedDate",
        "id",
      ],
      item,
    ),

    orderNumber: (
      <TableCellWrapper
        firstData={item.orderNumber}
        data-order-number={item.orderNumber}
      />
    ),

    creationDate: (
      <TableCellWrapper
        firstData={getFormattedDate(item.creationDate)}
        secondData={item.createdBy}
      />
    ),

    modificationDate: (
      <TableCellWrapper
        firstData={getFormattedDate(item.modificationDate)}
        secondData={item.lastModifiedBy ? item.lastModifiedBy : item.createdBy}
      />
    ),

    status:
      item.status === "ORDERED" || item.status === "SHARED" ? (
        <TableCellWrapper
          firstData={t(`offer_management_table_status_${item.status}`)}
          secondData={
            isQuoteOrdered(item.status)
              ? getFormattedDate(item.orderedDate)
              : getFormattedDate(item.modificationDate)
          }
          data-archived={item?.archived}
          data-status-order={`offer_management_table_status_${item.status}`}
        />
      ) : (
        <TableCellWrapper
          firstData={t(`offer_management_table_status_${item.status}`)}
          data-archived={item?.archived}
          data-status-order={`offer_management_table_status_${item.status}`}
        />
      ),

    comment: isAdminOrClientIdEqual(identityStore, item, cart?.authorities) ? (
      <Cell className="custom-cell-icon">
        <CommentWrapper
          item={item}
          onCommentChange={onCommentModalInputChange(
            item,
            dispatcherActions.onSetHeader,
          )}
        />
      </Cell>
    ) : (
      <Cell className="custom-cell-icon" />
    ),

    isRequest: path(["isRequest"], item) ? (
      <RequestWrapper />
    ) : (
      <Cell className="custom-cell-icon" />
    ),

    menu: (
      <Cell className="custom-cell-icon">
        <ItemRowMenu
          actions={prepareActions({
            item,
            identityStore,
            cart,
            dispatcherActions,
            ...restParams,
          })}
          menuId={item.orderNumber}
        />
      </Cell>
    ),

    referenceNumber: isAdminOrClientIdEqual(
      identityStore,
      item,
      cart?.authorities,
    ) ? (
      item.referenceNumber
    ) : (
      <Cell />
    ),

    companyName: isAdminOrClientIdEqual(
      identityStore,
      item,
      cart?.authorities,
    ) ? (
      item.companyName
    ) : (
      <Cell />
    ),
  };

  if (cart.orderNumber === item.orderNumber) {
    return values({
      ...CompleteItem,
      className: { className: "flex-row flex-row-body is-active" },
    });
  }

  if (item.active && item.userId !== item.currentUserId) {
    return values({
      ...CompleteItem,
      className: {
        className: "flex-row flex-row-body is-active-different-user",
      },
    });
  }
  return values(CompleteItem);
};

export const OfferManagementTable = ({
  offerManagementState,
  offerManagementSetState,
  offerManagementStore,
  dispatcherActions,
}: OfferManagementTableProps) => {
  const { identityStore } = useContext(IdentityContext);
  const { cart, cartActions } = useContext(CartContext);
  const configuration = useContext(ConfigurationContext);
  const [modifyOfferDialogVisible, setModifyOfferDialogVisible] = useState({
    quoteId: "",
    visible: false,
  });
  const [deleteCartWarningVisible, setDeleteCartWarningVisible] = useState({
    quoteId: "",
    visible: false,
  });
  const offerManagement = {
    store: offerManagementStore,
    state: offerManagementState,
    setState: offerManagementSetState,
  };

  return (
    <div
      className="offer-management-table"
      data-table-id="offer-management-table"
    >
      <FlexTable
        tHead={buildHead(offerManagementStore, dispatcherActions)}
        tBody={
          !isEmpty(offerManagementStore.quotes)
            ? values(
                map(
                  (item) =>
                    buildBody({
                      item,
                      identityStore,
                      cart,
                      setModifyOfferDialogVisible,
                      setDeleteCartWarningVisible,
                      dispatcherActions,
                      offerManagement,
                      configuration,
                      cartActions,
                    }),
                  prepareOfferManagementTableState(offerManagementStore),
                ),
              )
            : [
                [
                  t("offer_management_table_no_quotes_found"),
                  { className: "offer-management-no-quotes-found" },
                ],
              ]
        }
      />

      {modifyOfferDialogVisible.visible && (
        <ModifyOfferModal
          closeDialogMethod={() =>
            setModifyOfferDialogVisible({ quoteId: "", visible: false })
          }
          saveQuote={onSaveQuote(
            findQuote(
              offerManagementStore.quotes,
              modifyOfferDialogVisible.quoteId,
            ),
            setModifyOfferDialogVisible,
            dispatcherActions.onSetHeader,
          )}
          quote={
            findQuote(
              offerManagementStore.quotes,
              modifyOfferDialogVisible.quoteId,
            )?.cartTO
          }
        />
      )}

      {deleteCartWarningVisible.visible && (
        <CartWarningWrapper
          headerLabel={t("offer_management_delete_cart_warning_title")}
          bodyLabel={t("offer_management_delete_cart_warning_text")}
          footerCancelLabel={t("no")}
          onCancel={() =>
            setDeleteCartWarningVisible({ quoteId: "", visible: false })
          }
          footerSaveLabel={t("offer_management_delete_cart_warning_ok")}
          onSave={() =>
            onDeleteQuote(
              deleteCartWarningVisible.quoteId,
              setDeleteCartWarningVisible,
              dispatcherActions,
            )
          }
          saveIcon={<FontAwesomeIcon icon={faCheck} />}
          dialogId={"delete_offer_dialog"}
        />
      )}
    </div>
  );
};
