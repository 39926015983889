import { isNil } from "ramda";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { CatalogService, Constants, ProductContainer } from "@encoway/rest-api";
import { Visualization } from "@encoway/visual-editor";

import { Config } from "../config/config";
import {
  getVisCharacteristics,
  uploadVisualizationGlb,
} from "../service/visualizationService";
import { AppContext } from "./useApp";
import { CartContext } from "./useCart";
import {
  checkArPermission,
  checkVisualizationPermission,
  getVisualizationArPlacement,
  getVisualizationArScaling,
  removeEmptyObjects,
  removeHighlight,
  VIS_STATE_SHOW_WALL_KEY,
  VIS_STATE_SHOW_WALL_OFF,
  VIS_STATE_SHOW_WALL_ON,
} from "./visualizationUtils";

type VisualizationState = {
  context: Visualization | null;
  node: object | null;
};

const loadVisualization = async (
  articleName: string,
  configurationId: string,
  language: string,
  catalogService: CatalogService,
) => {
  const { baseUrl, version, token } = Config.Visualization;

  const context = await Visualization.load(baseUrl, version, token);
  context.cloud.addServer({
    name: "default",
    baseUrl: `${Config.Service.BaseUrl}/teckentrup-server`,
    http: catalogService.http,
    locale: language,
    options: {
      configuration: {
        mappingOptions: { mappingProfile: Constants.MappingProfile.Maximum },
      },
    },
  });

  const node = await context.cloud.mount(
    null,
    "product",
    "article",
    {
      id: articleName,
      configurationId: configurationId,
    },
    "default",
    true,
  );

  return {
    node,
    context: context,
  };
};

const exportVisualizationScene = async (
  context: Visualization | null,
  arScaleFactor: number,
  cleanup = false,
) => {
  if (isNil(context)) {
    return;
  }
  const { exporterVersion, token } = Config.Visualization;
  const scene = context.cloud.graph().scene();

  const sceneGroup = new window.THREE.Group();
  sceneGroup.add(removeHighlight(scene).clone());
  sceneGroup.scale.multiplyScalar(arScaleFactor);

  const exporter = await context.useExtension(
    "3d-exporter",
    exporterVersion,
    token,
  );
  // @ts-ignore
  const { content } = await exporter.exportScene(
    "glb",
    cleanup ? removeEmptyObjects(sceneGroup) : sceneGroup,
    { compress: true },
  );

  return Buffer.from(content).toString("base64");
};

export function useVisualization(
  articleName: string,
  configurationId: string,
  catalogService: CatalogService | undefined,
) {
  const { language } = useContext(AppContext);
  const { cart } = useContext(CartContext);

  const [vis, setVis] = useState<VisualizationState>({
    context: null,
    node: null,
  });
  const [product, setProduct] = useState<ProductContainer | undefined>(
    undefined,
  );

  const [showWall, setShowWall] = useState<boolean>(false);

  const showVisualization = useMemo(
    () => checkVisualizationPermission(cart, product),
    [cart, product],
  );
  const showArButton = useMemo(() => checkArPermission(cart), [cart]);

  const visRef = useRef<any>(null);
  const modalRef = useRef(null);

  const reloadVisualizationCharacteristics = async () => {
    getVisCharacteristics(articleName, configurationId)
      // @ts-ignore
      .then((data) => !isNil(vis.node) && vis.node.state.setState(data))
      // @ts-ignore
      .then(() => vis.node.update())
      .catch(() => {
        console.error("Visualization Characteristics couldn't be loaded.");
        // @ts-ignore
        vis.node.update();
      });
  };

  const load = useCallback(async () => {
    if (catalogService) {
      setVis(
        await loadVisualization(
          articleName,
          configurationId,
          language,
          catalogService,
        ),
      );

      try {
        // @ts-ignore
        vis.node.state.setState({
          [VIS_STATE_SHOW_WALL_KEY]: VIS_STATE_SHOW_WALL_OFF,
        });
        // @ts-ignore
        vis.node.update();
        reloadVisualizationCharacteristics().then();
      } catch (e) {
        console.error("Visualization Characteristics couldn't be loaded.");
        // @ts-ignore
        vis.node.update();
      }
    }
  }, [articleName, configurationId, catalogService]);

  const uploadGlb = async () => {
    if (product) {
      uploadVisualizationGlb(
        configurationId,
        await exportVisualizationScene(
          vis.context,
          getVisualizationArScaling(product),
        ),
        getVisualizationArPlacement(product),
      );
    }
  };

  const toggleWall = () => {
    setShowWall((prev) => {
      const newShowWallState = prev
        ? VIS_STATE_SHOW_WALL_OFF
        : VIS_STATE_SHOW_WALL_ON;
      // @ts-ignore
      vis.node.state.setState({ [VIS_STATE_SHOW_WALL_KEY]: newShowWallState });
      // @ts-ignore
      vis.node.update();
      return !prev;
    });
  };

  useEffect(() => {
    if (catalogService) {
      catalogService
        .product(articleName)
        .then((_product) => setProduct(_product));
    }
  }, [cart, articleName, catalogService]);

  return {
    load,
    vis,
    visRef,
    modalRef,
    showVisualization,
    showArButton,
    reloadVisualizationCharacteristics,
    uploadGlb,
    showWall,
    toggleWall,
  };
}
