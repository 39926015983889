import { equals, filter, flatten, has, keys, length, map, path } from "ramda";

const SPARE_PART_CHARACTERISTIC_ORDER = [
  "sp_materialnummer",
  "sp_bezeichnung",
  "sp_nachfolger_fuer",
  "sp_beschreibung_katalog",
  "sp_verwendung_fuer",
];

/**
 * Gets the value of the characteristic set on the product or null if none found.
 *
 * @param {string} characteristicId The id of the characteristic.
 * @param {string} valueId The id of the value.
 * @param {object} currentProduct The current product.
 * @returns {object|null} the value or null
 */
export const getCharacteristicValueOverwrite = (
  characteristicId,
  valueId,
  currentProduct,
) => {
  const characteristic = currentProduct.characteristics.filter(
    (c) => c.id === characteristicId,
  )[0];
  if (has("possibleValues", characteristic)) {
    return keys(characteristic.possibleValues)
      .map((k) => characteristic.possibleValues[k])
      .filter((pv) => pv.id === valueId && pv.characteristicValues.benennung)
      .map((pv) => pv.characteristicValues.benennung.values[0])[0];
  }

  return null;
};

/**
 * Gets all characteristics of the product, which should be shown in the view.
 *
 * @param {object} currentProduct The product currently viewed.
 * @param {object} characteristicName The name of the characteristic by which the characteristics are filtered.
 * @return {object} the characteristic object
 */
export const getCharacteristics = (currentProduct, characteristicName) => {
  let characteristics = currentProduct.characteristics;
  if (!currentProduct.product.configurable) {
    characteristics = flatten(
      map(
        (sparePartId) =>
          filter(
            (characteristic) => equals(characteristic.id, sparePartId),
            characteristics,
          ),
        SPARE_PART_CHARACTERISTIC_ORDER,
      ),
    );
  }

  return characteristics.filter(
    (char) =>
      char.characteristicValues[characteristicName] &&
      char.characteristicValues[characteristicName].values[0].value === 1,
  );
};

/**
 * Gets the value of the characteristic set at the product.
 *
 * @param {object} currentProduct the product currently viewed.
 * @param {object} characteristic The characteric, for which the value is searched.
 */
export const getCharValue = (currentProduct, characteristic) => {
  const characteristicValues = path(
    ["product", "characteristicValues", characteristic.id, "values"],
    currentProduct,
  );

  if (length(characteristicValues) > 1) {
    return characteristicValues;
  } else if (length(characteristicValues) === 1) {
    return characteristicValues[0];
  }
  return "";
};
