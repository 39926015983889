import { isNil } from "ramda";

import { L10n } from "@encoway/l10n";

import { Config } from "../config/config";
import { prepareVisCharacteristics } from "../hooks/visualizationUtils";
import { fetchGet, fetchPost } from "./fetch";

const VIS_CHARACTERISTICS_BASE_URL =
  "teckentrup-server/api/cart/visualization/parameters";

type VisArGlbParams = {
  id: string;
  placement: string;
  retentionTime: number;
  glb: string;
};

export const getVisCharacteristics = (
  articleName: string,
  configurationId: string,
) =>
  fetchGet(`${VIS_CHARACTERISTICS_BASE_URL}/${articleName}/${configurationId}`)(
    undefined,
    { "Accept-Language": L10n.currentFullLocale() },
  )().then(({ data }) => prepareVisCharacteristics(data));

export const sendVisArGlb = async (data: VisArGlbParams) => {
  const fetchInstance = fetchPost(`${Config.ArService.baseUrl}/upload`, true)(
    {
      withCredentials: true,
    },
    {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Config.Visualization.token}`,
    },
  );

  return fetchInstance(data).then(({ data }) => data);
};

/**
 * The ID can only contain characters and dashes,
 * underscores etc. are not allowed and will lead to an error!
 */
export const uploadVisualizationGlb = (
  configurationId: string,
  glb: string | undefined,
  arPlacement: string,
) => {
  if (isNil(glb)) {
    return;
  }
  return sendVisArGlb({
    id: configurationId,
    placement: arPlacement,
    retentionTime: Config.ArService.retentionTimeInDays,
    glb: glb,
  });
};
