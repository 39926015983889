import { equals, without } from "ramda";

/**
 * Checks if a change between the delivery classes "ZAG" and "ZSP" or "ZAG" and "ZSN" is possible.
 *
 * @param {array} deliveryClassRangeFromCatalog The characteristic values, which are available for the product.
 * @returns {boolean} true, if a delivery time change is possible; false, otherwise.
 */
export const isChangePossible = (deliveryClassRangeFromCatalog: string[]) =>
  equals(["ZAG", "ZSP"], deliveryClassRangeFromCatalog) ||
  equals(["ZAG", "ZSN"], deliveryClassRangeFromCatalog);

export const getPossibleDeliveryTimeWish = (
  currentDeliveryClass: string,
  deliveryClassRange: string[],
) => without([currentDeliveryClass], deliveryClassRange)[0];
