import { equals, not } from "ramda";
import { useEffect, useRef, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { useLocaleNumberFormat } from "../../hooks/useLocaleNumberFormat";

type NumberInputProps = Omit<
  NumericFormatProps,
  "value" | "defaultValue" | "type"
> & {
  value: number;
  setValue: (value: number) => void;
  minimalValue?: number;
  decimalScale?: number;
  isCurrency?: boolean;
  selectOnFocus?: boolean;
  resetInternalValue?: boolean;
};

export const NumberInput = ({
  value,
  setValue,
  minimalValue = 0,
  decimalScale = 0,
  isCurrency,
  selectOnFocus = true,
  resetInternalValue,
  ...props
}: NumberInputProps) => {
  const [internalValue, setInternalValue] = useState<number | undefined>(value);
  const initialValueRef = useRef(value);
  const { getOptions } = useLocaleNumberFormat();

  function setInputValues() {
    setInternalValue(value);
    initialValueRef.current = value;
  }

  useEffect(() => {
    setInputValues();
  }, [value]);

  useEffect(() => {
    if (resetInternalValue) {
      setInputValues();
    }
  }, [resetInternalValue]);

  function onSetValue() {
    const newValue = internalValue ?? minimalValue;
    if (!equals(newValue, internalValue)) {
      setInternalValue(newValue);
    }
    if (not(equals(newValue, initialValueRef.current))) {
      setValue(newValue);
    }
  }

  return (
    <NumericFormat
      value={internalValue}
      onValueChange={(values) => setInternalValue(values.floatValue)}
      onBlur={onSetValue}
      onKeyDown={(event) => event.key === "Enter" && onSetValue()}
      onFocus={(event) => selectOnFocus && event.target.select()}
      allowNegative={false}
      decimalScale={decimalScale}
      {...props}
      {...getOptions(isCurrency)}
    />
  );
};
