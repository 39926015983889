import {
  append,
  defaultTo,
  filter,
  find,
  findIndex,
  isEmpty,
  last,
  omit,
  pathOr,
  pick,
  propEq,
  take,
  test,
  update,
} from "ramda";

import {
  addCustomer,
  getCustomers,
  updateCustomer,
} from "../../../service/customerManagementService";
import { PartialBy } from "../../../types/app";
import {
  CartTO,
  ExtendedTeckentrupCartTO,
  HeaderData,
  QuoteShareTO,
} from "../../../types/cart";
import {
  ContactDto,
  CustomerDto,
} from "../../customerManagement/customerManagementUtils";
import { escapeRegExCharacters, sortItems } from "../offerManagementUtils";
import {
  findCustomer,
  findCustomerFromState,
  getFoundNumberOrName,
  isComplete,
} from "./modifyOfferDialogUtils";

export const InitialModifyOfferDialogState: ModifyOfferDialogState = {
  projectInformationReadOnly: {
    status: "",
    orderNumber: "",
    creationDate: "",
    createdBy: "",
    modificationDate: "",
    lastModifiedBy: "",
  },
  projectInformation: {
    buildProject: "",
    referenceNumber: "",
    orderedDate: "",
    followUpDate: "",
    comment: "",
  },
  projectInformationExtended: {
    accountId: "",
    clientId: "",
    creatorUserId: "",
    priceDate: "",
    currencyConversionRate: 1,
    checkoutDate: "",
    contributionMarginNumber: "",
    copiedFrom: "",
    parentOrder: "",
  },
  projectInformationShared: {
    children: [],
    parent: null,
  },
  customerInformation: {
    customerNumber: "",
    customerName: "",
    title: "",
    firstname: "",
    surname: "",
    street: "",
    houseNumber: "",
    zip: "",
    city: "",
    phone: "",
    email: "",
    id: null,
  },
  customerSearch: {
    customers: [],
    selectedCustomer: null,
    selectedAddress: null,
    searchResult: [],
    searchValue: "",
    toggleCustomerAddress: false,
    disableSearchResult: false,
  },
  shippingAddress: {
    companyName: "",
    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    telephoneNumber: "",
    street: "",
    houseNumber: "",
    postCode: "",
    city: "",
    countryCode: "",
  },
  thirdPartySystemInformation: {
    thirdPartySystemName: "-",
    thirdPartySystemRequestId: "-",
    thirdPartySystemReturnAddress: "-",
  },

  status: -1,
  customerAddLoading: false,
  shippingAddressMandatoriesCompleted: true,
  customerAddLoadingToolTip: false,
  isComplete: false,
  edited: false,
  toggleCancelDialog: false,
  customerNumberEmpty: false,
  customerNameEmpty: false,
  customerExists: false,
};

export type ProjectInformationReadOnly = {
  status: string;
  orderNumber: string;
  creationDate: string;
  createdBy: string;
  modificationDate: string;
  lastModifiedBy: string;
};

export type ProjectInformation = {
  buildProject: string;
  referenceNumber: string;
  orderedDate: string;
  followUpDate: string;
  comment: string;
};

export type ProjectInformationExtended = {
  accountId: string;
  clientId: string;
  creatorUserId: string;
  priceDate: string;
  currencyConversionRate: number;
  checkoutDate: string;
  contributionMarginNumber: string;
  copiedFrom: string;
  parentOrder: string;
};

export type ProjectInformationShared = {
  children: QuoteShareTO[];
  parent: QuoteShareTO | null;
};

export type CustomerInformation = {
  customerNumber: string;
  customerName: string;
  title: string;
  firstname: string;
  surname: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
  id: string | number | null;
};

export type CustomerSearch = {
  customers: CustomerDto[];
  selectedCustomer: CustomerDto | null;
  selectedAddress: ContactDto | null;
  searchResult: CustomerDto[] | null;
  searchValue: string;
  toggleCustomerAddress: boolean;
  disableSearchResult: boolean;
};

export type ShippingAddress = {
  companyName: string;
  contactSalutation: string;
  contactFirstName: string;
  contactLastName: string;
  telephoneNumber: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  countryCode: string;
};

export type ThirdPartySystemInformation = {
  thirdPartySystemName: string;
  thirdPartySystemRequestId: string;
  thirdPartySystemReturnAddress: string;
};

export type ModifyOfferDialogState = {
  projectInformationReadOnly: ProjectInformationReadOnly;
  projectInformation: ProjectInformation;
  projectInformationExtended: ProjectInformationExtended;
  projectInformationShared: ProjectInformationShared;
  customerInformation: CustomerInformation;
  customerSearch: CustomerSearch;
  shippingAddress: ShippingAddress;
  thirdPartySystemInformation: ThirdPartySystemInformation;

  status: number;
  customerAddLoading: boolean;
  shippingAddressMandatoriesCompleted: boolean;
  customerAddLoadingToolTip: boolean;
  isComplete: boolean;
  edited: boolean;
  toggleCancelDialog: boolean;
  customerNumberEmpty: boolean;
  customerNameEmpty: boolean;
  customerExists: boolean;
};

export type ModifyOfferDialogReducedState = PartialBy<
  ModifyOfferDialogState,
  "thirdPartySystemInformation"
>;

export type ModifyOfferDialogStateObjectKeys = keyof Pick<
  ModifyOfferDialogState,
  | "projectInformationReadOnly"
  | "projectInformation"
  | "projectInformationExtended"
  | "projectInformationShared"
  | "customerInformation"
  | "customerSearch"
  | "shippingAddress"
  | "thirdPartySystemInformation"
>;

const prepareProjectInformationReadOnlyState = (cartTO: CartTO) => ({
  ...InitialModifyOfferDialogState.projectInformationReadOnly,
  ...pick(["orderNumber", "creationDate", "modificationDate"], cartTO),
  ...pick(["status", "createdBy", "lastModifiedBy"], cartTO.headerData),
});

const prepareProjectInformationState = (headerData: HeaderData) => ({
  ...InitialModifyOfferDialogState.projectInformation,
  ...pick(
    [
      "buildProject",
      "referenceNumber",
      "orderedDate",
      "followUpDate",
      "comment",
    ],
    headerData,
  ),
});

const prepareProjectInformationExtendedState = (cartTO: CartTO) => ({
  ...InitialModifyOfferDialogState.projectInformationExtended,
  ...pick(
    ["accountId", "clientId", "subOrders", "parentOrder", "copiedFrom"],
    cartTO.headerData,
  ),
  ...pick(
    [
      "creatorUserId",
      "priceDate",
      "checkoutDate",
      "contributionMarginNumber",
      "currencyConversionRate",
    ],
    cartTO,
  ),
});

const prepareCustomerInformationState = ({ address }: HeaderData) => ({
  ...InitialModifyOfferDialogState.customerInformation,
  ...(address && {
    ...pick(["email", "street", "houseNumber", "city"], address),
    customerName: address.companyName,
    customerNumber: address.customerNo,
    title: address.contactSalutation,
    firstname: address.contactFirstName,
    surname: address.contactLastName,
    phone: address.telephoneNumber,
    zip: address.postCode,
  }),
});

const prepareThirdPartySystemInformationState = (headerData: HeaderData) => ({
  ...InitialModifyOfferDialogState.thirdPartySystemInformation,
  ...pick(
    [
      "thirdPartySystemName",
      "thirdPartySystemRequestId",
      "thirdPartySystemReturnAddress",
    ],
    headerData,
  ),
});

const prepareProjectInformationSharedState = (headerData: HeaderData) => {
  const shareInformation = headerData?.shareInformationTO;

  if (shareInformation) {
    return {
      ...InitialModifyOfferDialogState.projectInformationShared,
      ...pick(["parent"], headerData?.shareInformationTO),
      children: Object.values(shareInformation?.children || []),
    };
  }

  return {
    ...InitialModifyOfferDialogState.projectInformationShared,
  };
};

const prepareShippingAddressState = (headerData: HeaderData) => {
  const shippingAddress = headerData?.shippingAddress;
  if (shippingAddress) {
    return {
      ...shippingAddress,
    };
  }
  return {
    ...InitialModifyOfferDialogState.shippingAddress,
  };
};

export const prepareModifyOfferDialogState = (
  cartTO: ExtendedTeckentrupCartTO,
): ModifyOfferDialogReducedState => {
  const { headerData } = cartTO;

  const preparedModifyOfferDialogState = {
    ...InitialModifyOfferDialogState,
    projectInformationReadOnly: {
      ...prepareProjectInformationReadOnlyState(cartTO),
    },
    projectInformation: {
      ...prepareProjectInformationState(headerData),
    },
    customerInformation: {
      ...prepareCustomerInformationState(headerData),
    },
    thirdPartySystemInformation: {
      ...prepareThirdPartySystemInformationState(headerData),
    },
    projectInformationExtended: {
      ...prepareProjectInformationExtendedState(cartTO),
    },
    projectInformationShared: {
      ...prepareProjectInformationSharedState(headerData),
    },
    shippingAddress: { ...prepareShippingAddressState(headerData) },
  };

  return headerData.isRequest
    ? preparedModifyOfferDialogState
    : omit(["thirdPartySystemInformation"], preparedModifyOfferDialogState);
};

export const prepareInformationDTO = ({
  customerInformation,
  shippingAddress,
  projectInformation: { buildProject, referenceNumber, comment, followUpDate },
  projectInformationReadOnly: { lastModifiedBy },
}: {
  customerInformation: ModifyOfferDialogState["customerInformation"];
  shippingAddress: ModifyOfferDialogState["shippingAddress"];
  projectInformation: ModifyOfferDialogState["projectInformation"];
  projectInformationReadOnly: ModifyOfferDialogState["projectInformationReadOnly"];
}) => ({
  buildProject,
  referenceNumber,
  comment,
  lastModifiedBy,
  followUpDate,
  address: {
    ...pick(["email", "street", "houseNumber", "city"], customerInformation),
    companyName: customerInformation.customerName,
    customerNo: customerInformation.customerNumber,
    contactSalutation: customerInformation.title,
    contactFirstName: customerInformation.firstname,
    contactLastName: customerInformation.surname,
    telephoneNumber: customerInformation.phone,
    postCode: customerInformation.zip,
  },
  shippingAddress: {
    companyName: shippingAddress.companyName,
    contactSalutation: shippingAddress.contactSalutation,
    contactFirstName: shippingAddress.contactFirstName,
    contactLastName: shippingAddress.contactLastName,
    telephoneNumber: shippingAddress.telephoneNumber,
    street: shippingAddress.street,
    houseNumber: shippingAddress.houseNumber,
    postCode: shippingAddress.postCode,
    city: shippingAddress.city,
    countryCode: shippingAddress.countryCode,
  },
});

export const InputChange =
  (name: string, value: string, prop: ModifyOfferDialogStateObjectKeys) =>
  (state: ModifyOfferDialogReducedState) => {
    const newState = { ...state, [prop]: { ...state[prop], [name]: value } };
    return {
      ...newState,
      isComplete: isComplete(
        newState.projectInformation,
        newState.shippingAddressMandatoriesCompleted,
      ),
      edited: true,
    };
  };

export const ShippingAddressChange =
  (name: string, value: string) => (state: ModifyOfferDialogReducedState) => {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        [name]: value,
      },
    };
  };

export const SetShippingAddress =
  (value: ModifyOfferDialogState["shippingAddress"]) =>
  (state: ModifyOfferDialogReducedState) => {
    return {
      ...state,
      shippingAddress: value,
    };
  };

export const ShippingAddressMandatoriesCompleted =
  (value: ModifyOfferDialogState["shippingAddressMandatoriesCompleted"]) =>
  (state: ModifyOfferDialogReducedState) => {
    return {
      ...state,
      shippingAddressMandatoriesCompleted: value,
      isComplete: isComplete(state.projectInformation, value),
    };
  };

export const CustomerInputChange =
  (name: string, value: string) => (state: ModifyOfferDialogReducedState) => {
    const newState = {
      ...state,
      customerInformation: {
        ...state.customerInformation,
        [name]: value,
        id: null,
      },
    };

    return {
      ...newState,
      isComplete: isComplete(
        newState.projectInformation,
        state.shippingAddressMandatoriesCompleted,
      ),
      edited: true,
    };
  };

export const CustomerNumberChange =
  (value: string) => (state: ModifyOfferDialogReducedState) => {
    const foundCustomer = findCustomer(value, state.customerSearch.customers);
    return {
      ...state,
      customerInformation: {
        ...state.customerInformation,
        customerNumber: value,
        id: null,
      },
      isComplete: isComplete(
        state.projectInformation,
        state.shippingAddressMandatoriesCompleted,
      ),
      customerExists: Boolean(foundCustomer),
      edited: true,
    };
  };

export const ResetCustomerNumberTag = (
  state: ModifyOfferDialogReducedState,
) => ({
  ...state,
  customerExists: false,
  customerInformation: {
    ...state.customerInformation,
    selectedCustomer: null,
  },
});

export const SetCustomerNumberExists = (
  state: ModifyOfferDialogReducedState,
) => {
  const foundCustomer = findCustomer(
    state.customerInformation.customerNumber,
    state.customerSearch.customers,
  );
  return {
    ...state,
    customerInformation: {
      ...state.customerInformation,
      id: null,
    },
    customerExists: Boolean(foundCustomer),
  };
};

export const ToggleCancelDialog = (state: ModifyOfferDialogReducedState) => ({
  ...state,
  toggleCancelDialog: !state.toggleCancelDialog,
});

// SearchCustomer
export const GetCustomers = async (companyId: number | null | undefined) => {
  const {
    status,
    data: { pagedCustomers },
  } = await getCustomers();
  return (state: ModifyOfferDialogReducedState) => ({
    ...state,
    status,
    customerSearch: {
      ...state.customerSearch,
      customers: pagedCustomers,
    },
    companyId,
  });
};

export const SearchValueChange =
  (searchValue: string) => (state: ModifyOfferDialogReducedState) => {
    const filteredCustomers = filter(({ customerNumber, customerName }) => {
      const regExString = new RegExp(
        `${escapeRegExCharacters(searchValue)}.*`,
        "gi",
      );
      return (
        test(regExString, customerNumber.toLowerCase()) ||
        test(regExString, customerName.toLowerCase())
      );
    }, state.customerSearch.customers);

    return {
      ...state,
      customerSearch: {
        ...state.customerSearch,
        disableSearchResult: false,
        searchResult: isEmpty(searchValue)
          ? []
          : sortItems<CustomerDto>(
              "customerName",
              "asc",
              take(11, filteredCustomers),
            ),
        searchValue,
      },
    };
  };

export const ToggleSelectedCustomer =
  (selectedCustomer: CustomerDto) => (state: ModifyOfferDialogReducedState) => {
    const sortedCompanyContacts = sortItems<ContactDto>(
      "surname",
      "asc",
      selectedCustomer.companyContacts,
    );
    return {
      ...state,
      customerSearch: {
        ...state.customerSearch,
        toggleCustomerAddress: true,
        selectedAddress: sortedCompanyContacts![0],
        selectedCustomer: {
          ...selectedCustomer,
          companyContacts: sortedCompanyContacts ?? [],
        },
      },
    };
  };

export const SetCustomerAddress =
  (
    selectedAddress: ModifyOfferDialogState["customerSearch"]["selectedAddress"],
  ) =>
  (state: ModifyOfferDialogReducedState) => ({
    ...state,
    customerSearch: {
      ...state.customerSearch,
      selectedAddress,
    },
  });

export const CustomerSearchSave = (
  state: ModifyOfferDialogReducedState,
): ModifyOfferDialogReducedState => ({
  ...state,
  edited: false,
  customerInformation: {
    ...state.customerInformation,
    ...state.customerSearch.selectedAddress,
    customerNumber: pathOr(
      InitialModifyOfferDialogState.customerInformation.customerNumber,
      ["customerSearch", "selectedCustomer", "customerNumber"],
      state,
    ),
    customerName: pathOr(
      InitialModifyOfferDialogState.customerInformation.customerName,
      ["customerSearch", "selectedCustomer", "customerName"],
      state,
    ),
  },
});

export const ResetSearchValue = (state: ModifyOfferDialogReducedState) => ({
  ...state,
  customerSearch: {
    ...state.customerSearch,
    searchValue: "",
    searchResult: [],
    selectedCustomer: null,
    selectedAddress: null,
    toggleCustomerAddress: false,
  },
});

export const AddCustomer = async (
  customer: CustomerInformation,
  companyId: number | undefined | null,
) => {
  const newCustomer = {
    ...pick(["customerNumber", "customerName"], customer),
    companyId,
    companyContacts: [omit(["id"], customer)],
  };
  const {
    status,
    data: { returnValue },
  } = await addCustomer(newCustomer);
  return (state: ModifyOfferDialogReducedState) => ({
    ...state,
    edited: false,
    status,
    customerNumberEmpty: false,
    customerNameEmpty: false,
    customerSearch: {
      ...state.customerSearch,
      customers: append(returnValue, state.customerSearch.customers),
    },
  });
};

function findCustomerAddress(
  customerAddress: ModifyOfferDialogState["customerInformation"],
  selectedCustomer: CustomerDto | undefined | null,
  customers: CustomerDto[],
): CustomerDto | undefined {
  if (selectedCustomer) {
    return selectedCustomer;
  }

  return find<CustomerDto>(propEq("id", customerAddress.id), customers);
}

const prepareNewCustomer = (
  customerAddress: ModifyOfferDialogState["customerInformation"],
  selectedCustomerAddress: CustomerDto | undefined,
) => ({
  ...omit(["companyNumber", "deliveryContacts"], selectedCustomerAddress),
  customerNumber: customerAddress.customerNumber,
  customerName: customerAddress.customerName,
  companyContacts: [
    ...(selectedCustomerAddress ? selectedCustomerAddress.companyContacts : []),
    omit(["id"], customerAddress),
  ],
});

const findCustomerAndAddTheChangedAddress = (
  customers: CustomerDto[],
  returnValue: CustomerDto,
) =>
  update(
    findIndex(propEq("id", returnValue.id))(customers),
    returnValue,
    customers,
  );

/**
 * Edits the companyContacts of an existing customer or adds a new customer to the customers.
 * @param {*} customerAddress the given customerAdress
 * @param {*} selectedCustomer the selected Customer
 */
export const EditCustomer = async (
  customerAddress: ModifyOfferDialogState["customerInformation"],
  selectedCustomer: CustomerDto,
  customers: CustomerDto[],
) => {
  const selectedCustomerAddress = findCustomerAddress(
    customerAddress,
    selectedCustomer,
    customers,
  );
  const newCustomer = prepareNewCustomer(
    customerAddress,
    selectedCustomerAddress,
  );
  const {
    status,
    data: { returnValue },
  } = await updateCustomer(newCustomer);
  const selectedAddress = defaultTo(null, last(returnValue.companyContacts));
  return (
    state: ModifyOfferDialogReducedState,
  ): ModifyOfferDialogReducedState => ({
    ...state,
    edited: false,
    status,
    customerNumberEmpty: false,
    customerNameEmpty: false,
    customerSearch: {
      ...state.customerSearch,
      selectedAddress,
      selectedCustomer: returnValue,
      customers: findCustomerAndAddTheChangedAddress(
        state.customerSearch.customers,
        returnValue,
      ),
    },
  });
};

export const ToggleAddCustomerLoading =
  (showToolTip = false) =>
  (state: ModifyOfferDialogReducedState) => ({
    ...state,
    customerAddLoadingToolTip: showToolTip,
    customerAddLoading: !state.customerAddLoading,
  });

export const ToggleCustomerAddedToolTip =
  (showToolTip = false) =>
  (state: ModifyOfferDialogReducedState) => ({
    ...state,
    customerAddLoadingToolTip: showToolTip && !state.customerAddLoadingToolTip,
  });

export const ToggleCustomerNumberNotSetWarning =
  (
    numberValid: ModifyOfferDialogState["customerNumberEmpty"],
    nameValid: ModifyOfferDialogState["customerNameEmpty"],
  ) =>
  (state: ModifyOfferDialogReducedState) => ({
    ...state,
    edited: false,
    customerNumberEmpty: numberValid,
    customerNameEmpty: nameValid,
  });

export const PreSelectCustomer = (
  state: ModifyOfferDialogReducedState,
): ModifyOfferDialogReducedState => {
  const {
    customerInformation: { customerNumber, customerName },
    customerSearch: { searchValue },
  } = state;

  if (
    isEmpty(customerNumber) &&
    isEmpty(customerName) &&
    !isEmpty(searchValue)
  ) {
    return state;
  }

  const foundCustomer = findCustomerFromState(state);
  const foundCustomerNumberOrName = getFoundNumberOrName(foundCustomer, state);
  const searchResultState = SearchValueChange(foundCustomerNumberOrName)(state);

  return {
    ...state,
    customerSearch: {
      ...state.customerSearch,
      searchValue: foundCustomerNumberOrName,
      searchResult: searchResultState.customerSearch.searchResult,
      selectedCustomer: foundCustomer,
      selectedAddress: foundCustomer ? foundCustomer.companyContacts[0] : null,
      toggleCustomerAddress: foundCustomer
        ? Boolean(foundCustomer.companyContacts)
        : false,
    },
  };
};

export type HeaderDataPayload = Partial<
  ReturnType<typeof prepareInformationDTO>
>;
