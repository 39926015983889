import {
  faChevronLeft,
  faChevronRight,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { equals, gte, isEmpty, lte, test } from "ramda";
import { ChangeEvent, Dispatch, FocusEvent, SetStateAction } from "react";
import { Page } from "react-pdf";

import { L10n } from "@encoway/l10n";

import { LvImportOptions } from "./LvImportTab";
import {
  ChooseFileInput,
  IconButton,
  NumberInputField,
} from "./LvImportTabComponents";

import "./PdfDocument.scss";

const DIN_A4_HEIGHT_IN_CM = 29;

type PdfDocumentSkeletonProps = {
  errorMessage: string;
  handleInputClick?: () => void;
  isLoading?: boolean;
};

export const PdfDocumentSkeleton = ({
  errorMessage,
  handleInputClick,
  isLoading = false,
}: PdfDocumentSkeletonProps) => (
  <div className="columns with-padding">
    <div className="column">
      <div className="is-flex is-justify-content-space-between">
        <IconButton icon={faChevronLeft} disabled />
        <p className="mt-2 mb-2 text-center has-text-weight-bold">
          {L10n.format("offer_management_create_lv_import_page_info", {
            currentPage: 0,
            totalPages: 0,
          })}
        </p>
        <IconButton icon={faChevronRight} disabled />
      </div>
      <div
        className="border has-background-light is-flex is-justify-content-center is-align-items-center
        is-flex-direction-column page-skeleton"
      >
        <p className="has-text-weight-bold is-size-5 mb-4 has-text-grey">
          {L10n.format("offer_management_create_lv_import_select_lv")}
        </p>
        <FontAwesomeIcon icon={faFilePdf} size="5x" className="has-text-grey" />
      </div>
    </div>
    <div className="column w-100">
      <p className="mb-2 margin-top-34">
        {L10n.format("offer_management_create_lv_info_text")}
      </p>
      {!isEmpty(errorMessage) && (
        <p className="has-text-danger mb-2">{L10n.format(errorMessage)}</p>
      )}
      <ChooseFileInput
        label="offer_management_create_lv_import_selected_lv"
        onClick={handleInputClick}
        isLoading={isLoading}
      />
      <h2 className="mt-6 mb-2 subtitle has-text-weight-bold">
        {L10n.format("offer_management_create_lv_import_expert_settings")}
      </h2>
      <NumberInputField
        label="offer_management_create_lv_import_first_page"
        disabled
      />
      <NumberInputField
        label="offer_management_create_lv_import_last_page"
        disabled
      />
      <NumberInputField
        label="offer_management_create_lv_import_header_height"
        addon="cm"
        disabled
      />
      <NumberInputField
        label="offer_management_create_lv_import_footer_height"
        addon="cm"
        disabled
      />
    </div>
  </div>
);

type PdfDocumentProps = {
  selectedFile: File | null;
  currentPage: number;
  totalPages: number;
  lvImportOptions: LvImportOptions;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setLvImportOptionsValue: (
    name: keyof LvImportOptions,
    value: string | number,
  ) => void;
  unsetAlreadyImported: () => void;
  handleInputClick: () => void;
  errorMessage: string;
};

export const PdfDocument = ({
  selectedFile,
  currentPage,
  totalPages,
  lvImportOptions,
  setCurrentPage,
  setLvImportOptionsValue,
  unsetAlreadyImported,
  handleInputClick,
  errorMessage,
}: PdfDocumentProps) => {
  const handleOnChange =
    (name: keyof LvImportOptions) => (event: ChangeEvent<HTMLInputElement>) => {
      setLvImportOptionsValue(name, event.target.value);
      unsetAlreadyImported();
    };

  const handleOnBlur =
    (name: keyof LvImportOptions, emptyValue: number, noDecimals = false) =>
    (event: FocusEvent<HTMLInputElement>) => {
      const value = lvImportOptions[name];
      const { min, max } = event.target;

      if (isEmpty(value)) {
        setLvImportOptionsValue(name, emptyValue);
        return;
      }

      if (gte(parseInt(value), parseInt(max))) {
        setLvImportOptionsValue(name, parseInt(max));
        return;
      }

      if (lte(parseInt(value), parseInt(min))) {
        setLvImportOptionsValue(name, parseInt(min));
        return;
      }

      if (noDecimals && test(/[.,]/, value)) {
        setLvImportOptionsValue(name, parseInt(value));
      }
    };

  return (
    <div className="columns with-padding">
      <div className="column">
        <div className="is-flex is-justify-content-space-between">
          <IconButton
            icon={faChevronLeft}
            onClick={() => setCurrentPage((prev) => prev - 1)}
            disabled={equals(currentPage, 1)}
          />
          <p className="mt-2 mb-2 text-center has-text-weight-bold">
            {L10n.format("offer_management_create_lv_import_page_info", {
              currentPage,
              totalPages,
            })}
          </p>
          <IconButton
            icon={faChevronRight}
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={equals(currentPage, totalPages)}
          />
        </div>
        <Page
          height={845}
          pageNumber={currentPage}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          className="border"
        />
      </div>
      <div className="column w-100">
        <p className="mb-2 margin-top-34">
          {L10n.format("offer_management_create_lv_info_text")}
        </p>
        {!isEmpty(errorMessage) && (
          <p className="has-text-danger mb-2">{L10n.format(errorMessage)}</p>
        )}
        <ChooseFileInput
          label="offer_management_create_lv_import_selected_lv"
          value={selectedFile?.name}
          onClick={handleInputClick}
        />
        <h2 className="mt-6 mb-2 subtitle has-text-weight-bold">
          {L10n.format("offer_management_create_lv_import_expert_settings")}
        </h2>
        <NumberInputField
          label="offer_management_create_lv_import_first_page"
          min={1}
          max={lvImportOptions.lastPage}
          value={lvImportOptions.firstPage}
          onChange={handleOnChange("firstPage")}
          onBlur={handleOnBlur("firstPage", 1, true)}
        />
        <NumberInputField
          label="offer_management_create_lv_import_last_page"
          min={lvImportOptions.firstPage}
          max={totalPages}
          value={lvImportOptions.lastPage}
          onChange={handleOnChange("lastPage")}
          onBlur={handleOnBlur("lastPage", totalPages, true)}
        />
        <NumberInputField
          label="offer_management_create_lv_import_header_height"
          min={0}
          max={DIN_A4_HEIGHT_IN_CM}
          addon="cm"
          value={lvImportOptions.headerHeightCm}
          onChange={handleOnChange("headerHeightCm")}
          onBlur={handleOnBlur("headerHeightCm", 0, false)}
        />
        <NumberInputField
          label="offer_management_create_lv_import_footer_height"
          min={0}
          max={DIN_A4_HEIGHT_IN_CM}
          addon="cm"
          value={lvImportOptions.footerHeightCm}
          onChange={handleOnChange("footerHeightCm")}
          onBlur={handleOnBlur("footerHeightCm", 0, false)}
        />
      </div>
    </div>
  );
};
