import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { t } from "../../shoppingCart/localizationUtils";

export const CancelCustomerModal = ({
  onSave,
  onCancel,
  onClose,
  disabledOnSave,
}) => (
  <Modal>
    <ModalHeader
      label={t("modify_dialog_cancel_modal_title")}
      onCancel={onClose}
    />
    <ModalBodyWrapper label={t("modify_dialog_cancel_modal_message")} />
    <ModalFooter
      cancelLabel={t("modify_dialog_cancel_modal_cancel_button")}
      saveLabel={t("modify_dialog_cancel_modal_save_button")}
      onCancel={onCancel}
      onSave={onSave}
      disabled={disabledOnSave}
    />
  </Modal>
);
