import classNames from "classnames";
import { equals, isNil } from "ramda";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { L10n } from "@encoway/l10n";

import { ConfigurationContext } from "../hooks/useConfiguration";
import { CONSTANTS, ROUTES } from "../http/constants";

function Tab({ to, name, active = false, disabled = false }) {
  return (
    <li
      className={classNames("tab", {
        "is-active": active,
        "is-disabled": disabled,
        disabled,
      })}
    >
      {disabled ? (
        <a>{L10n.format(name)}</a>
      ) : (
        <Link to={to}>{L10n.format(name)}</Link>
      )}
    </li>
  );
}

export function Tabs() {
  const { configurationId, reconfiguration, articleName } =
    useContext(ConfigurationContext);
  const { pathname } = useLocation();
  return (
    <div
      style={{ marginBottom: ".5em" }}
      className="tabs is-centered is-medium is-fullwidth"
    >
      <ul>
        <Tab
          to={ROUTES.PRODUCTS}
          name={CONSTANTS.Tabs.productSelection}
          active={equals(ROUTES.PRODUCTS, pathname)}
        />
        {reconfiguration ? (
          <Tab
            to={`/reconfiguration/id/${configurationId}/${articleName}/${reconfiguration.articleId}/${reconfiguration.cartPosition}/${reconfiguration.articleOrderStatus}`}
            name={CONSTANTS.Tabs.configuration}
            active={equals(
              `/reconfiguration/id/${configurationId}/${articleName}/${reconfiguration.articleId}/${reconfiguration.cartPosition}/${reconfiguration.articleOrderStatus}`,
              pathname,
            )}
            disabled={isNil(configurationId)}
          />
        ) : (
          <Tab
            to={`/configuration/id/${configurationId}`}
            name={CONSTANTS.Tabs.configuration}
            active={equals(`/configuration/id/${configurationId}`, pathname)}
            disabled={isNil(configurationId)}
          />
        )}
        <Tab
          to={ROUTES.CART}
          active={equals(ROUTES.CART, pathname)}
          name={CONSTANTS.Tabs.shoppingCart}
        />
      </ul>
    </div>
  );
}
