import { has, isNil } from "ramda";
import { useContext } from "react";
import {
  InputAttributes,
  NumericFormatProps,
  numericFormatter,
} from "react-number-format";

import { generateOptions, getLocale } from "./localeNumberFormatUtils";
import { CartContext } from "./useCart";
import { IdentityContext } from "./useIdentity";

// Useful Links:
// 1. https://www.destatis.de/Europa/DE/Staat/Laendercodes.html
// 2. https://en.wikipedia.org/wiki/IETF_language_tag
// 3. https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes

// Challenges:
// 1. If you use Intl.NumberFormat some currency symbols are only displayed in combination with a matching language, otherwise the ISO code is returned
//    - For example: DKK requires DA for kr
// 2. In some countries, currency values are formatted differently from all other values
//    - For example: In Switzerland dot is used as a decimal separator for currency values, but a comma is used for all other values

export function useLocaleNumberFormat() {
  const { identityStore } = useContext(IdentityContext);
  const { cart } = useContext(CartContext);

  if (isNil(identityStore)) {
    throw new Error("Context should be used within ContextProvider!");
  }

  const countryLocale = getLocale(identityStore.getRoleAndRights());
  const numberOptions = generateOptions(countryLocale);
  const currencyOptions = generateOptions(
    countryLocale,
    cart.currencyCode,
    cart.validCurrencyConversionRateFound,
  );

  return {
    formatNumber: (
      value: string | number,
      options: NumericFormatProps<InputAttributes> = {},
    ) => numericFormatter(String(value), { ...numberOptions, ...options }),
    formatPrice: (value: string | number | { price: string | number } = "") => {
      const priceValue = has("price", value) ? value.price : value;
      const roundedPriceValue = Number(priceValue).toFixed(2);
      return numericFormatter(roundedPriceValue, currencyOptions);
    },
    getOptions: (isCurrency = false) => ({
      ...numberOptions,
      ...(isCurrency && currencyOptions),
    }),
  };
}
