import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { has, length, map, pathOr } from "ramda";
import React, { useMemo } from "react";

import { L10n } from "@encoway/l10n";
import { Icon, Viewport } from "@encoway/react-components";
import { isReady } from "@encoway/rest-api";

import { selectTab } from "./helper/tabsHelper";
import { useTabs } from "./hook/useTabs";
import { containerIsEmpty } from "./utils/tabsUtils";

const getSections = (props, currentTab) => {
  const viewport = Viewport.parse(
    currentTab.name,
    Viewport.orElse(currentTab.viewPort, "section"),
  );
  return Viewport.instance(viewport.name, {
    key: currentTab.id || `injected_${currentTab.name}`,
    data: has("id", currentTab) ? currentTab : props.data,
    style: viewport.style,
    options: props.options,
    error: props.error,
    mediaLink: props.mediaLink,
    onValueChanged: props.onValueChanged,
    onFocus: props.onFocus,
    level: 1,
  });
};

const MenuItem = ({
  containerFilter,
  props,
  currentTab,
  container,
  iconPosition,
  setCurrentTab,
}) => {
  if (
    (containerFilter && containerFilter(container) === false) ||
    containerIsEmpty(container)
  ) {
    return null;
  }
  const ready = isReady(container);
  const classNames = classnames({
    "is-active": container.name === currentTab,
    "is-mandatory": !ready,
    "is-ready": ready,
  });
  const vp = Viewport.parse(`${props.data.name}State`, "containerState");
  return (
    <li key={container.name} className={classNames}>
      <a
        onClick={() =>
          selectTab(
            props.options.eventBus,
            currentTab,
            setCurrentTab,
            container.name,
          )
        }
        data-tab-id={container.name}
        data-ready-state-id={ready ? "READY" : "NOT_READY"}
      >
        {iconPosition === "left" && <span>{container.translatedName}</span>}
        {Viewport.instance(vp.name, { ...props, ...{ data: container } })}
        {iconPosition !== "left" && <span>{container.translatedName}</span>}
      </a>
    </li>
  );
};

const MenuItems = ({
  props,
  currentTab,
  containerFilter,
  setCurrentTab,
  iconPosition,
}) =>
  useMemo(
    () =>
      props.data.children
        .map((container) => (
          <MenuItem
            key={container.id}
            containerFilter={containerFilter}
            props={props}
            currentTab={currentTab}
            container={container}
            iconPosition={iconPosition}
            setCurrentTab={setCurrentTab}
          />
        ))
        .filter(Boolean),
    [props.data.children, containerFilter, currentTab, iconPosition],
  );

const CustomMenuItem = ({
  currentTab,
  item,
  iconPosition,
  eventBus,
  setCurrentTab,
}) => (
  <li
    key={item.name}
    className={classnames({ "is-active": item.name === currentTab })}
  >
    <a
      onClick={() => selectTab(eventBus, currentTab, setCurrentTab, item.name)}
    >
      {iconPosition === "left" && <span>{item.translatedName}</span>}
      {item.icon && (
        <span className="icon is-small">
          <Icon src={item.icon} />
        </span>
      )}
      {iconPosition !== "left" && <span>{item.translatedName}</span>}
    </a>
  </li>
);

const Navigation = ({
  previous,
  previousClickHandler,
  style,
  next,
  nextClickHandler,
}) => (
  <nav
    className="pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <a
      className="pagination-previous"
      disabled={previous === null}
      data-button-id={previous}
      onClick={previousClickHandler}
    >
      <FontAwesomeIcon icon={faAngleLeft} width="7px" />
      <span className="has-margin-left is-hidden-mobile">
        {L10n.format("previous_page")}
      </span>
    </a>
    <a
      className="pagination-next"
      disabled={next === null}
      data-button-id={next}
      onClick={nextClickHandler}
    >
      <span className="has-margin-right is-hidden-mobile">
        {L10n.format("next_page")}
      </span>
      <FontAwesomeIcon icon={faAngleRight} width="7px" />
    </a>
    <ul className="pagination-list" />
  </nav>
);

const Tab = ({
  tabStyle,
  props,
  currentTab,
  iconPosition,
  setCurrentTab,
  containerFilter,
}) => (
  <div className={tabStyle}>
    <ul>
      {map(
        (item) => (
          <CustomMenuItem
            key={currentTab}
            currentTab={currentTab}
            item={item}
            iconPosition={iconPosition}
            eventBus={props.options.eventBus}
            setCurrentTab={setCurrentTab}
          />
        ),
        pathOr([], ["inject", "before"], props),
      )}
      <MenuItems
        props={props}
        currentTab={currentTab}
        containerFilter={containerFilter}
        setCurrentTab={setCurrentTab}
        iconPosition={iconPosition}
      />
      {map(
        (item) => (
          <CustomMenuItem
            key={currentTab}
            currentTab={currentTab}
            item={item}
            iconPosition={iconPosition}
            eventBus={props.options.eventBus}
            setCurrentTab={setCurrentTab}
          />
        ),
        pathOr([], ["inject", "after"], props),
      )}
    </ul>
  </div>
);

const Tabs = (props) => {
  const {
    style,
    tabStyle,
    containerFilter,
    current,
    currentTab,
    setCurrentTab,
    previous,
    next,
    previousClickHandler,
    nextClickHandler,
    tabs,
  } = useTabs(props);

  if (!currentTab) {
    return <div className="container" />;
  }

  if (length(tabs) === 1 || style.hideSingleMenu === true) {
    return <div className="container">{getSections(props, current)}</div>;
  }

  return (
    <div className="container">
      <Tab
        tabStyle={tabStyle}
        props={props}
        currentTab={currentTab}
        iconPosition={style.iconPosition}
        setCurrentTab={setCurrentTab}
        containerFilter={containerFilter}
      />
      {getSections(props, current)}
      {style.showNavigation === true && (
        <Navigation
          previous={previous}
          previousClickHandler={previousClickHandler}
          style={style}
          next={next}
          nextClickHandler={nextClickHandler}
        />
      )}
    </div>
  );
};

export default class ConfiguratorTabs extends React.Component {
  shouldComponentUpdate(newProps) {
    return newProps.shouldUpdate;
  }

  render() {
    return <Tabs {...this.props} />;
  }
}
