import { Http } from "@encoway/rest-api";

export const createHttp = (store: null) => {
  const args = { store: store };
  return new Http(
    (fetchParams: Record<string, unknown>) => ({
      ...fetchParams,
      credentials: "include",
    }),
    args,
  );
};
