import {
  all,
  any,
  comparator,
  concat,
  equals,
  filter,
  find,
  forEach,
  includes,
  isNil,
  length,
  lensPath,
  pathOr,
  propEq,
  set,
  sortWith,
  toLower,
  uniq,
  without,
} from "ramda";

import {
  Aggregation,
  Characteristics,
  Filter,
  Selection,
  Sort,
} from "@encoway/rest-api";

import { MAX_DATA_GET_LIMIT } from "./products";

const CATEGORY_ID_SPARE_PARTS = "spare_parts";
const CHARACTERISTIC_ID_SPARE_PARTS_VALID_FROM_DATE = "sp_gueltig_ab";
const CHARACTERISTIC_ID_SPARE_PARTS_VALID_TILL_DATE = "sp_gueltig_bis";
export const COUNTRY_AVAILABILITY = "laenderverfuegbarkeit";
export const ROLE_AVAILABILITY = "rollenverfuegbarkeit";

export const getDefaultSelection = (limit = MAX_DATA_GET_LIMIT, offset = 0) =>
  new Selection()
    .limit(limit)
    .offset(offset)
    .characteristics(
      new Characteristics()
        .viewId("property_type_group_overview")
        .viewId("property_type_group_selection")
        .id("groupId"),
    )
    .aggregation(new Aggregation().viewId("property_type_group_selection"))
    .sort(new Sort().characteristic("groupId").asc().name().asc());

export const getNonLimitedSelection = (limit = MAX_DATA_GET_LIMIT) =>
  new Selection()
    .limit(limit)
    .characteristics(
      new Characteristics()
        .viewId("property_type_group_overview")
        .viewId("property_type_group_selection")
        .id("groupId"),
    )
    .aggregation(new Aggregation().viewId("property_type_group_selection"))
    .sort(new Sort().characteristic("groupId").asc().name().asc());

export const formatDateToNumberString = (date) =>
  `${date.getFullYear().toString().padStart(4, "0")}${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}`;

const getShouldFilters = (id, value) =>
  Filter.boolFilter()
    .should(Filter.characteristicFilter().id(id).equal(value))
    .should(Filter.characteristicFilter().id(id).equal("*"));

export const buildFilters = (
  category,
  filters,
  tokenCountry,
  tokenRoles,
  canSeeAllProducts,
  favoriteProductIds = [],
) => {
  const boolFilter = Filter.boolFilter().must(
    Filter.productGroupFilter().id(category).includeSubgroups(true),
  );

  if (!canSeeAllProducts) {
    let visibilityFilter = Filter.boolFilter();
    forEach(
      (role) =>
        (visibilityFilter = visibilityFilter.should(
          getShouldFilters(ROLE_AVAILABILITY, role),
        )),
      tokenRoles,
    );
    boolFilter
      .must(getShouldFilters(COUNTRY_AVAILABILITY, tokenCountry))
      .must(visibilityFilter);
  }

  filters.forEach((filter) => {
    boolFilter.must(
      Filter.characteristicFilter()
        .id(filter.characteristicId)
        .equal(filter.value),
    );
  });

  if (equals(category, CATEGORY_ID_SPARE_PARTS)) {
    const dateToday = formatDateToNumberString(new Date());
    boolFilter
      .must(
        Filter.characteristicFilter()
          .id(CHARACTERISTIC_ID_SPARE_PARTS_VALID_FROM_DATE)
          .lessOrEqual(parseInt(dateToday)),
      )
      .must(
        Filter.characteristicFilter()
          .id(CHARACTERISTIC_ID_SPARE_PARTS_VALID_TILL_DATE)
          .greaterOrEqual(parseInt(dateToday)),
      );
  }

  if (length(favoriteProductIds) > 0) {
    const productsFilter = Filter.productsFilter();
    favoriteProductIds.forEach((id) => productsFilter.id(id));
    boolFilter.must(productsFilter);
  }

  return boolFilter;
};

export const mutateForCategory = (category, oldState, value) =>
  set(lensPath([category]), value, oldState);

export const mutateForPropCategory = (category, prop, oldState, value) =>
  set(lensPath([category, prop]), value, oldState);

const groupIdComparator = comparator((a, b) => {
  if (a.characteristicValues.groupId && b.characteristicValues.groupId) {
    return (
      a.characteristicValues.groupId.values[0] <
      b.characteristicValues.groupId.values[0]
    );
  }
  return a.name < b.name;
});
const nameComparator = (sortKey) =>
  comparator((a, b) => toLower(a[sortKey]) < toLower(b[sortKey]));

export const mergeForCategory = (category, oldState, value, sortKey = null) => {
  const newProducts = uniq(concat(oldState[category].products, value.products));

  return set(
    lensPath([category]),
    {
      ...value,
      products: sortKey
        ? sortWith([groupIdComparator, nameComparator(sortKey)], newProducts)
        : newProducts,
    },
    oldState,
  );
};

const characteristicFilter = (filters, searchTerm) => (product) =>
  any(
    (value) => includes(toLower(searchTerm), toLower(value)),
    [product.name, product.id],
  ) &&
  all(
    (filter) =>
      pathOr(
        "",
        ["characteristicValues", filter.characteristicId, "values", 0],
        product,
      ) === filter.value,
    filters,
  );

export const mergeWithFavorites = (
  category,
  favoriteState,
  value,
  searchTerm,
  filters,
  sortKey = null,
) => {
  const filteredFavprotes = filter(
    characteristicFilter(filters, searchTerm),
    favoriteState[category].products,
  );
  const newProducts = uniq(concat(filteredFavprotes, value.products));
  return set(
    lensPath([category]),
    {
      ...value,
      products: sortKey
        ? sortWith([groupIdComparator, nameComparator(sortKey)], newProducts)
        : newProducts,
    },
    favoriteState,
  );
};

export const removeByIdInCategory = (category, oldState, productId) => {
  const removedProduct = find(
    propEq("id", productId),
    oldState[category].products,
  );
  return set(lensPath([category]), {
    ...oldState,
    products: without([removedProduct], oldState[category].products),
  });
};

export const getCharacteristicValue = (characteristic, value) =>
  pathOr(
    value.value,
    [
      "possibleValues",
      value.id,
      "characteristicValues",
      "benennung",
      "values",
      0,
    ],
    characteristic,
  );

export const isValuePossible = (
  aggregatedCharacteristicValues,
  value,
  characteristic,
) =>
  aggregatedCharacteristicValues[characteristic.id].some(
    (element) => element.value === value.id,
  );

export const checkFilterValidityAndReset = (
  currentFilters,
  characteristics,
  resetFilters,
) => {
  currentFilters.forEach((filter) => {
    characteristics.forEach((char) => {
      if (
        char.id === filter.characteristicId &&
        isNil(char.possibleValues[filter.value])
      ) {
        resetFilters();
      }
    });
  });
};
