import { Viewport } from "@encoway/react-components";

import { CustomExtendedRadioButton } from "../../../components/customExtendedRadioButton";
import PropertyTextField from "../../../components/input/propertyTextField";
import ConfiguratorDropDown from "./configuratorDropDownWrapper";
import { DropdownImageButton } from "./dropdownImageButton";
import Epri from "./epri";
import ImageButton from "./imageButton";
import InputField from "./inputField";
import Parameter from "./parameter";
import { Section } from "./section";
import ConfiguratorTabs from "./tabs";
import ContainerState from "./teoContainerState";
import ParameterState from "./teoParameterState";

export const configure = () => {
  Viewport.settings({
    radioButton: { displayAs: "dropDown" },
    section: { style: { compact: true } },
    tabs: { style: { showNavigation: true } },
    parameter: {
      style: {
        systemIcon: {
          css: "monitor",
        },
        defaultIcon: {
          css: "zauberstab",
        },
        descriptionIcon: {
          css: "zusatzinformationen",
        },
        hasUndo: true,
      },
    },
  });

  Viewport.register("tabs", ConfiguratorTabs);
  Viewport.register("parameter", Parameter);
  Viewport.register("containerState", ContainerState);
  Viewport.register("parameterState", ParameterState);
  Viewport.register("dropDownImageButton", DropdownImageButton);
  Viewport.register("extendedRadioButton", CustomExtendedRadioButton);
  Viewport.register("dropDown", ConfiguratorDropDown);
  Viewport.register("epri", Epri);
  Viewport.register("propertyTextField", PropertyTextField);
  Viewport.register("imageButton", ImageButton);
  Viewport.register("inputField", InputField);
  Viewport.register("section", Section);
};
