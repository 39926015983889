import {
  find,
  isEmpty,
  isNil,
  length,
  map,
  not,
  path,
  pathOr,
  propEq,
} from "ramda";

import { L10n } from "@encoway/l10n";

import {
  ExtendedTeckentrupCartTO,
  LocalizedShippingMethodTO,
} from "../../../../../types/cart";

const NO_SHIPPING_METHOD_FOUND = "NO_SHIPPING_METHOD_FOUND";

/**
 * Builds Teckentrup-Dropdown-Entry-Objects. Teckentrup Dropdown need a special list of configuration
 * objects defining available entries. Css determines if entry is selected or not.
 */
export const buildShippingMethodDropDown = (
  allowedShippingMethods: LocalizedShippingMethodTO[],
  activeShippingMethod: LocalizedShippingMethodTO,
  shippingMethodTranslation: Record<string, string>,
) =>
  map(
    (shippingMethod) => ({
      id: shippingMethod.id,
      translationKey: shippingMethod.translationKey,
      value: pathOr(
        L10n.format("no_shipping_type_exist"),
        [shippingMethod.id],
        shippingMethodTranslation,
      ),
      css:
        shippingMethod.id === path(["id"], activeShippingMethod)
          ? "is-selected"
          : "selectable",
    }),
    allowedShippingMethods,
  );

export function hasNoAllowedShippingMethod(
  allowedShippingMethods: LocalizedShippingMethodTO[],
) {
  if (isEmpty(allowedShippingMethods)) {
    return true;
  }
  const foundNoShippingMethod = find(
    propEq("id", NO_SHIPPING_METHOD_FOUND),
    allowedShippingMethods,
  );
  return not(isNil(foundNoShippingMethod));
}

export const calculationViews = (
  {
    expresskostenZuschlag,
    expresskostenZuschlagPartOrder,
    processingSurcharge,
    processingSurchargePartOrder,
    allowedShippingMethods,
    allowedShippingMethodsPartOrder,
  }: ExtendedTeckentrupCartTO,
  isOrder: boolean,
) => {
  const hasExpressSurcharge = isOrder
    ? expresskostenZuschlagPartOrder?.price > 0
    : expresskostenZuschlag?.price > 0;

  const hasProcessingSurcharge = isOrder
    ? processingSurchargePartOrder?.price > 0
    : processingSurcharge?.price > 0;

  const hasShippingMethod = isOrder
    ? allowedShippingMethodsPartOrder &&
      length(allowedShippingMethodsPartOrder) > 1
    : allowedShippingMethods && length(allowedShippingMethods) > 1;

  return { hasExpressSurcharge, hasProcessingSurcharge, hasShippingMethod };
};
