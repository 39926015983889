import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Locale, format } from "date-fns";
import { de, enGB as en, fr } from "date-fns/locale";
import { useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { L10n } from "@encoway/l10n";

import { Config } from "../../../../../config/config";

import "./dateInput.scss";

type DateInputProps = {
  placeholder: string;
  label: string;
  name: string;
  onChange: (date: Date) => void;
  selectedDate?: Date;
  inputId: string;
  minDate?: Date;
};

type LocalesObject = {
  [key: string]: Locale;
};

type CustomHeaderProps = ReactDatePickerCustomHeaderProps & {
  locale: string;
};

const locales: LocalesObject = {
  en,
  fr,
  de,
};

const CustomHeader = ({
  locale,
  date,
  decreaseMonth,
  increaseMonth,
}: CustomHeaderProps) => (
  <div className="customHeader">
    <button
      className="button is-text"
      title={L10n.format("previousMonth")}
      onClick={decreaseMonth}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </button>
    <div className="react-datepicker__current-month">
      {format(date, "MMMM yyyy", { locale: locales[locale] })}
    </div>
    <button
      className="button is-text"
      title={L10n.format("nextMonth")}
      onClick={increaseMonth}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </button>
  </div>
);

/**
 * Date input for order dialog.
 *
 * @class DateInput
 * @extends React.Component
 */
export const DateInput = ({
  placeholder,
  label,
  name,
  onChange,
  selectedDate,
  inputId,
  minDate,
}: DateInputProps) => {
  const [startDate, setStartDate] = useState(
    selectedDate ? new Date(selectedDate) : null,
  );
  const locale = L10n.currentLocale();

  const setNewDate = (date: Date): void => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <>
      <label className="label">{label}</label>
      <DatePicker
        className="input"
        renderCustomHeader={(props) => CustomHeader({ ...props, locale })}
        placeholderText={placeholder}
        selected={startDate}
        onChange={(date: Date) => setNewDate(date)}
        dateFormat={Config.DateFormat.Default}
        locale={locales[locale]}
        withPortal
        name={name}
        customInput={<input type="text" data-input-field-id={inputId} />}
        minDate={minDate}
      />
    </>
  );
};
