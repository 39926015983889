import { isNil, path } from "ramda";

import { CatalogService } from "@encoway/rest-api";

import { fetchGet } from "../service/fetch";

const TRANSLATION_JSON_ID = "ui-translations";
const URI_PATH = [
  "product",
  "characteristicValues",
  TRANSLATION_JSON_ID,
  "values",
  0,
  "uri",
];

export const loadTranslations = async (
  catalogService: CatalogService,
): Promise<{ [translationKey: string]: unknown } | null> => {
  const translationProduct = await catalogService.product(TRANSLATION_JSON_ID);
  const completeJsonUri = path<string>(URI_PATH, translationProduct);
  if (isNil(completeJsonUri)) {
    return null;
  }
  const jsonUri = completeJsonUri.replace(/^http(s)?:\/{2}[^/]+\/(.*)/, "$2");
  const translations = await fetchGet<{ [translationKey: string]: unknown }>(
    jsonUri,
  )()();
  return translations.data;
};
