import classNames from "classnames";
import { length, not } from "ramda";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { ModalBodyWrapper, ModalFooter } from "../../../components/modal/modal";
import { LvTable } from "../../../hooks/useLvTable";
import { LV_TAB_ID, LvTabId } from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvTableComponent } from "../lvTable/LvTableComponent";

import "./lvTableTab.scss";

type LvTableTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  selectedFile: File | null;
  onCancel: () => void;
  setSelectedTab: Dispatch<SetStateAction<LvTabId>>;
};

export const LvTableTab = ({
  isHidden,
  lvTable,
  selectedFile,
  onCancel,
  setSelectedTab,
}: LvTableTabProps) => {
  const modalBodyWrapperRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // needed because of a scroll bug in Firefox, that doesn't reset the scroll state on unmount
    modalBodyWrapperRef.current?.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <ModalBodyWrapper
        className={classNames("select-positions-tab", {
          "is-hidden": isHidden,
        })}
        ref={modalBodyWrapperRef}
      >
        <div className="is-flex is-justify-content-space-between mb-3 with-padding">
          <div className="is-flex">
            <p>{t("offer_management_create_lv_import_read_lv")}:</p>
            <p className="ml-1 has-text-weight-bold">{selectedFile?.name}</p>
          </div>
          <div className="is-flex is-flex-direction-column has-text-success-dark">
            <div className="is-flex">
              <p>{t("offer_management_create_lv_positions_read")}:</p>
              <p className="ml-1 has-text-weight-bold">
                {lvTable.lvRowDTOsLength}
              </p>
            </div>
            <div className="is-flex selected-positions">
              <p>{t("offer_management_create_lv_positions_selected")}:</p>
              <p className="ml-1 has-text-weight-bold">
                {length(lvTable.checkedLvRows)}
              </p>
            </div>
          </div>
        </div>
        <InfiniteScroll
          pageStart={0}
          loadMore={lvTable.getUnloadedLvRows}
          hasMore={not(lvTable.areAllLvRowsLoaded)}
          loader={<div className="loader" key={0} />}
          useWindow={false}
        >
          <LvTableComponent lvTable={lvTable} />
        </InfiniteScroll>
      </ModalBodyWrapper>
      <ModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={t("cancel")}
        saveLabel={t("next")}
        saveButtonId="lv_positions_next"
        onCancel={onCancel}
        onSave={() => setSelectedTab(LV_TAB_ID.LV_HEADER_DATA)}
        disabled={!lvTable.isAnyLvRowChecked}
      />
    </>
  );
};
