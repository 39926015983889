import { equals, isNil, path, pathOr } from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";

import { AppContext } from "../../../hooks/useApp";
import { AppStore } from "../../../types/app";
import {
  CartActions,
  CartArticleTO,
  CartService,
  TeckentrupCartTO,
} from "../../../types/cart";
import {
  CART_STATUS_ORDERED_PART,
  ViewsState,
  getInitialCartView,
  getViews,
} from "../../../utilities/cartUtils";

export type CartViewsState = {
  view: ViewName;
  basedOn: BasedOn;
};

export type CartViews = {
  isSales: boolean;
  isBuyer: boolean;
  isCustomer: boolean;
  isOrder: boolean;
  discount: boolean;
  surcharge: boolean;
};

export type ViewActions = {
  togglePartialOrder: () => Promise<void>;
  togglePartialOrderAll: (partOrderFlag: boolean) => Promise<void>;
  setFlagAsPartOrder: (
    cartItem: CartArticleTO,
    value: string,
    refreshCart?: boolean,
  ) => Promise<void>;
};

type UseCartView = {
  cartView: CartViewsState;
  changeCartView: (
    name: keyof CartViewsState,
    newView: ViewName | BasedOn,
  ) => void;
  cartViews: ViewsState;
  isAlreadyPartiallyOrdered: boolean;
  partialOrderActivated: boolean;
  viewActions: ViewActions;
};

export const cartViews = {
  view: {
    buyerView: {
      name: "buyerView",
      l10n: "cart_cost_calculation",
      canSwitchCalculationBasis: false,
    },
    salesView: {
      name: "salesView",
      l10n: "cart_sales_calculation",
      canSwitchCalculationBasis: true,
    },
    customerView: {
      name: "customerView",
      l10n: "cart_customer_view",
      canSwitchCalculationBasis: false,
    },
    orderView: {
      name: "orderView",
      l10n: "cart_order_view",
      canSwitchCalculationBasis: false,
    },
  },
  basedOn: {
    surchargeBased: "surchargeBased",
    discountBased: "discountBased",
  },
} as const;

export const LOCAL_STORAGE_CART_VIEW_KEY = "view";
export const LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY = "basedOn";

export type ViewName = keyof typeof cartViews.view;
export type BasedOn = keyof typeof cartViews.basedOn;

export const useCartView = (
  cart: TeckentrupCartTO | null,
  cartActions: CartActions,
): UseCartView => {
  const { serviceFactory } = useContext<AppStore>(AppContext);
  const cartService = useMemo<CartService>(
    () => serviceFactory.createCartService("UI"),
    [],
  );
  const getBasedOn = (): BasedOn | undefined =>
    path(["calculationBasis"], cart);
  const initialCartView = getInitialCartView();
  const [cartView, setCartView] = useState<CartViewsState>(initialCartView);
  const isAlreadyPartiallyOrdered =
    cart?.headerData?.status === CART_STATUS_ORDERED_PART;
  const [partialOrderActivated, setPartialOrderActivated] = useState<boolean>(
    isAlreadyPartiallyOrdered || pathOr(false, ["flaggedForPartOrder"], cart),
  );

  useEffect(() => {
    setPartialOrderActivated(
      isAlreadyPartiallyOrdered || cart!.flaggedForPartOrder,
    );
  }, [isAlreadyPartiallyOrdered, cart!.flaggedForPartOrder]);

  /**
   * Changes the view of the cart and sets the current value into the local storage.
   * Either the LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY or the LOCAL_STORAGE_CART_VIEW_KEY
   * @param {keyof CartViewsState} name the name of the cart view based on the constants.
   * @param {ViewName | BasedOn} newView the view to be set
   * @returns {void}
   */
  const changeCartView = (
    name: keyof CartViewsState,
    newView: ViewName | BasedOn,
  ): void => {
    setCartView({ ...cartView, [name]: newView });
    window.localStorage.setItem(name, newView);
  };

  /**
   * Toggles partial order view for cart.
   * @returns {void}
   */
  const togglePartialOrder = async (): Promise<void> => {
    if (!isAlreadyPartiallyOrdered) {
      await cartService.performOperation("_isFlaggedForPartOrder", {
        isFlaggedForPartOrder: !partialOrderActivated,
      });
      await cartActions.getCart();
      setPartialOrderActivated(!partialOrderActivated);
    }
  };

  const togglePartialOrderAll = async (
    partOrderFlag: boolean,
  ): Promise<void> => {
    await cartService.performOperation("setFlagAsPartOrder", {
      orderStatus: partOrderFlag ? "FLAGGED" : "UNORDERED",
      isGroup: true,
      allPositions: true,
    });
    await cartActions.getCart();
  };

  const setFlagAsPartOrder = async (
    cartItem: CartArticleTO,
    value: string,
    refreshCart = true,
  ): Promise<void> => {
    const { articleId, group } = cartItem;
    await cartService.performArticleOperation(articleId, "setFlagAsPartOrder", {
      orderStatus: value,
      isGroup: group,
      allPositions: false,
    });
    if (refreshCart) {
      await cartActions.getCart();
    }
  };

  useEffect(() => {
    const basedOn = getBasedOn();
    if (basedOn && !equals(basedOn, cartView.basedOn)) {
      setCartView({
        ...cartView,
        basedOn,
      });
    } else if (cart && isNil(basedOn)) {
      cartActions.setCalculationBasis(cartView.basedOn);
    }
  }, [cart]);

  return {
    cartView,
    changeCartView,
    cartViews: getViews(cartView),
    isAlreadyPartiallyOrdered,
    partialOrderActivated,
    viewActions: {
      togglePartialOrder,
      togglePartialOrderAll,
      setFlagAsPartOrder,
    },
  };
};

export type CartViewReturn = ReturnType<typeof useCartView>;
