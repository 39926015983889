import { Config } from "../config/config";

const defaultDateOption: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

/**
 * Formats a ISO 8601 date
 * @param {string} date the provided date
 * @param  {object | undefined} option an object that contains one or more properties that specify comparison options
 * @returns {string} returns the formatted date as a string
 */
export const getFormattedDate = (
  date: string | number,
  option = defaultDateOption,
): string => new Date(date).toLocaleDateString(Config.Lang.Default, option);

/**
 * Formats ISO 8601 date to time
 * @param {string} date the provided date
 * @returns {string} a formatted date
 */
export const getFormattedTime = (date: string): string =>
  new Date(date).toLocaleTimeString(Config.Lang.Default);
