import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { map } from "ramda";
import { useState } from "react";

import "./simpleDropDown.scss";

const DropDownItem = ({ value, onSelect, isActive, dropdownId }) => (
  <a
    className={classnames(["dropdown-item", { "is-active": isActive }])}
    onMouseDown={() => onSelect(value)}
  >
    <span data-dropdown-entry-id={`${dropdownId}-${value}`}>{value}</span>
  </a>
);

export const DropDown = ({
  name,
  value,
  onChange,
  possibleItems,
  valueTextBold = false,
  valueTextItalic = false,
  dropdownId,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const toggleDropDown = () => setDropDownActive(!dropDownActive);
  const deactivateDropDown = () => setDropDownActive(false);

  const selectItem = (selectedItem) => {
    onChange({
      target: {
        name: name,
        value: selectedItem,
      },
    });
    setSelectedValue(selectedItem);
    deactivateDropDown();
  };

  return (
    <div
      className={classnames([
        "dropdown",
        "simple-dropdown",
        { "is-active": dropDownActive },
      ])}
    >
      <div
        className="dropdown-trigger"
        onClick={toggleDropDown}
        onBlur={deactivateDropDown}
      >
        <button
          className={classnames([
            "button",
            { "has-text-weight-bold": valueTextBold },
            { "is-italic": valueTextItalic },
          ])}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          data-dropdown-id={dropdownId}
        >
          <span data-dropdown-value={`${dropdownId}-${selectedValue}`}>
            {selectedValue}
          </span>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faAngleDown} size="xs" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu">
        <div className="dropdown-content">
          {map(
            (item) => (
              <DropDownItem
                value={item}
                key={item}
                onSelect={selectItem}
                isActive={item === selectedValue}
                dropdownId={dropdownId}
              />
            ),
            possibleItems,
          )}
        </div>
      </div>
    </div>
  );
};
