import { AxiosPromise } from "axios";

import { L10n } from "@encoway/l10n";

import { HeaderDataPayload } from "../pages/offerManagement/modifyOfferModal/modifyOfferModalActions";
import { CartArticleTO, CartTO, ExtendedTeckentrupCartTO } from "../types/cart";
import { UiAuthorities } from "../types/identity";
import { Quote } from "../types/offermanagement";
import { fetchDelete, fetchGet, fetchPost } from "./fetch";

const offerPath = "teckentrup-server/api/cart/quote";
const offerPathCustom = "teckentrup-server/api/cart/quote/custom";

export type QuoteRequestContext = {
  quoteRequest: QuoteRequest;
  seeAllQuotes: boolean;
};

export type QuoteRequest = {
  source?: string;
  sort?: {
    type?: string;
    order?: string;
    by?: string;
  };
  paging?: {
    page: number;
    pageSize: number;
  };
  filter?: {
    conjunction?: string;
    by: (
      | {
          key: string;
          operator: string;
          value: string;
        }
      | undefined
    )[];
  };
  search?: {
    term: string;
    in: string[];
  };
};

type ExtendedQuoteResultsTO = {
  metaInfo: {
    page: number;
    pageSize: number;
    pageCount: number;
    totalCount: number;
  };
  quotes: Quote[];
};

type CartResultTO = {
  cart: CartTO;
  article: CartArticleTO;
};

type ExtendedCartTO = {
  uiRelevantAuthorizationTO: UiAuthorities;
  cartTO: CartTO;
};

export type ExtendedCartResultTO = {
  cart: ExtendedCartTO;
  article: CartArticleTO;
};

export const getQuotes = (
  data: QuoteRequestContext,
): AxiosPromise<ExtendedQuoteResultsTO> =>
  fetchPost<ExtendedQuoteResultsTO>(
    `${offerPathCustom}/_search?map=QUOTE_LIST`,
  )()(data);

export const closeQuote = (quoteOrderNumber: string) =>
  fetchPost<{}>(
    `${offerPath}/closeEditHeaderData?quoteOrderNumber=${quoteOrderNumber}`,
  )()();

export const setHeader = (
  id: string,
  data: HeaderDataPayload,
): AxiosPromise<{}> =>
  fetchPost<{}>(`${offerPathCustom}/${id}/_header?map=NOTHING`)()(data);

export const createEmptyQuote = () =>
  fetchPost<{ cart: ExtendedTeckentrupCartTO }>(
    `${offerPath}/_create?map=ALL`,
  )()();

export const duplicateQuote = (id: string) =>
  fetchPost<CartResultTO>(`${offerPathCustom}/${id}/_copy?map=NOTHING`)()();

export const archiveQuote = (id: string, archived: boolean) =>
  fetchPost<{}>(
    `${offerPath}/${id}/_update?archived=${archived}&map=NOTHING`,
  )()();

export const deleteQuote = (id: string) =>
  fetchDelete<{}>(`${offerPath}/${id}?map=NOTHING`)()();

export const loadQuote = (id: string): AxiosPromise<ExtendedCartResultTO> =>
  fetchPost<ExtendedCartResultTO>(`${offerPathCustom}/${id}/_load?map=UI`)()();

export const getActiveQuote = () =>
  fetchGet<ExtendedCartResultTO>(`${offerPathCustom}/_getActiveCart?map=UI`)(
    {},
    { "Accept-Language": L10n.currentFullLocale() },
  )();

export const loadSharedQuote = (id: string) =>
  fetchPost<ExtendedCartResultTO>(`${offerPathCustom}/_share/${id}`)(
    {},
    { "Accept-Language": L10n.currentFullLocale() },
  )();

export const getSharedQuote = (id: string) =>
  fetchPost<ExtendedCartResultTO>(
    `${offerPathCustom}/viewSharedQuote/${id}?map=UI`,
  )()();

export const loadQuoteReadOnly = (id: string) =>
  fetchPost<ExtendedCartResultTO>(
    `${offerPathCustom}/${id}/_load?map=UI&readOnly=true`,
  )()();

export const isQuoteActive = (id: string) =>
  fetchGet<boolean>(`${offerPathCustom}/${id}/_isActive`)()();
