import classNames from "classnames";
import React from "react";

import { t } from "../../localizationUtils";

import "./warningBox.scss";

export const WarningBox = ({ className = null, children }) => (
  <div
    className={classNames([
      "warning-box",
      "box",
      "has-text-centered",
      className,
    ])}
  >
    <span
      data-label-id={`warning-head-cart_offer_cannot_be_ordered_again_head`}
      className="warning-box-head"
    >
      {t("cart_offer_cannot_be_ordered_again_head")}
    </span>
    {children}
  </div>
);
