import { compose, gt, isEmpty, keys, length, pathOr, test } from "ramda";

import IdentityStore from "../../../../http/identityStore";
import { AddressTO } from "../../../../types/cart";
import {
  DifferingShippingAddressState,
  FORKLIFT_AVAILABLE,
} from "./orderModal/differingShippingAddress";

const COUNTRY_CODE_LIST = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AN",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CS",
  "CU",
  "CV",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "EU",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NT",
  "NU",
  "NZ",
  "OM",
  "OR",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "ST",
  "SV",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TP",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "UN",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XK",
  "XM",
  "XS",
  "YE",
  "YT",
  "YU",
  "ZA",
  "ZM",
  "ZW",
];

const LIMITED_COUNTRY_CODE_LIST = ["DE", "LU"];
const COMPANY_COUNTRY_PATH = ["FirmadesNutzers", "FirmenLand"];

export type PlzRegex = { [a: string]: RegExp };
export type Plz = Array<keyof PlzRegex>;

const REGEX_PLZ: PlzRegex = {
  DE: /^\d{5}$/g,
};
const MAX_LENGTH_PLZ = {
  DE: 5,
};

export const getAvailableCountryCodes = (identityStore: IdentityStore) =>
  LIMITED_COUNTRY_CODE_LIST.includes(
    pathOr("", COMPANY_COUNTRY_PATH, identityStore.getIdentity()),
  )
    ? LIMITED_COUNTRY_CODE_LIST
    : COUNTRY_CODE_LIST;

export const isPostCodeValid = (
  countryCode: string,
  postCode: string,
): boolean => {
  if (keys(REGEX_PLZ).includes(countryCode)) {
    return test(REGEX_PLZ[countryCode], postCode);
  }

  return !isEmpty(postCode);
};

export function getPostCodeMaxLength(countryCode: string): number {
  return pathOr(10, [countryCode], MAX_LENGTH_PLZ);
}

export function isPostCodeMaxReached(
  maxLength: number,
  postCode: string,
): boolean {
  return compose(gt(maxLength), length)(postCode);
}

export const getCountryCode = (
  countryCode: string | undefined,
  identityStore: IdentityStore,
) => {
  if (countryCode) {
    return countryCode;
  }
  return pathOr("DE", COMPANY_COUNTRY_PATH, identityStore.getIdentity());
};

export const updateShippingAddress = (
  address: DifferingShippingAddressState | AddressTO | undefined,
  hasDifferingShippingAddress: boolean,
  countryCode: string,
) => ({
  hasDifferingShippingAddress: hasDifferingShippingAddress,
  companyName: pathOr("", ["companyName"], address),
  contactSalutation: pathOr("", ["contactSalutation"], address),
  contactFirstName: pathOr("", ["contactFirstName"], address),
  contactLastName: pathOr("", ["contactLastName"], address),
  telephoneNumber: pathOr("", ["telephoneNumber"], address),
  street: pathOr("", ["street"], address),
  houseNumber: pathOr("", ["houseNumber"], address),
  countryCode: countryCode,
  postCode: pathOr("", ["postCode"], address),
  city: pathOr("", ["city"], address),
  forklifter: FORKLIFT_AVAILABLE,
});
