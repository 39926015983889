import { faAngleDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { clamp, find, length } from "ramda";
import React from "react";

import { L10n } from "@encoway/l10n";

import { Config } from "../../../config/config";
import { mapIndexed } from "../../../utilities/utilities";
import { notNilEmpty } from "../../offerManagement/offerManagementUtils";
import {
  dropDownValuesSeparable,
  filterElementsByQuery,
  getSelectableDropDownValues,
  getSeparatorOffset,
  getTranslatedValue,
  getUnselectableDropDownValues,
  isSelected,
  sortBySelectable,
} from "./configuratorDropDownUtils";

import "./configuratorDropDown.scss";

const DropDownItemGroup = ({
  mediaLink,
  elements,
  separatorOffset = 0,
  selectedValue,
  displayUnit,
  cursor,
  listRefs,
  onValueChanged,
  parameterName,
}) =>
  mapIndexed(
    (value, index) => (
      <div
        ref={(ref) => {
          listRefs[index + separatorOffset] = ref;
        }}
        className={classNames([
          "dropdown-item",
          "columns",
          {
            "is-not-selectable":
              !value.selectable && !isSelected(selectedValue, value),
            "is-marked": index + separatorOffset === cursor,
            "is-selected": isSelected(selectedValue, value),
          },
        ])}
        key={index}
        onMouseDown={() => onValueChanged(value)}
        data-dropdown-id={`${parameterName}-${value.value}`}
      >
        {value.imageUrl && (
          <div className="column is-2">
            <img
              src={`${mediaLink(value.imageUrl)}${Config.ImageResolution.Medium}`}
            />
          </div>
        )}
        <div className="column">
          <p>{getTranslatedValue(value, displayUnit)}</p>
          {notNilEmpty(value.shortText) && value.shortText}
        </div>
      </div>
    ),
    elements,
  );

export default class SelectBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
      filteredElements: sortBySelectable(props.data.values),
      cursor: -1,
    };
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.listRefs = [];
    this.hook = React.createRef();
    this.dropDownRef = null;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onClickOutside);
  }

  onClickInside() {
    this.setState({
      listOpen: true,
      filteredElements: this.props.data.values,
      query: "",
      cursor: -1,
    });
  }

  onClickOutside(event) {
    if (this.hook.current && !this.hook.current.contains(event.target)) {
      this.setState({
        listOpen: false,
        cursor: -1,
      });
      this.props.onFocus?.();
    }
  }

  onValueChanged(value) {
    this.props.onChange(value);
    this.setState({ listOpen: false });
  }

  onFilterValueChanged({ target: { value } }) {
    const query = value;
    const filteredElements = filterElementsByQuery(
      this.props.data.values,
      query,
    );
    this.setState({
      query,
      filteredElements,
      cursor: -1,
    });
  }

  setCursor(newCursor) {
    const optionLength = length(this.state.filteredElements) - 1;
    const cursor = clamp(-1, optionLength, newCursor);
    const options = {
      behavior: "smooth",
      block: "end",
    };

    if (newCursor >= 0) {
      this.listRefs[cursor].scrollIntoView(options);
    } else {
      this.dropDownRef.scrollIntoView(options);
    }

    this.setState({ cursor });
  }

  onKeyDown(e) {
    const keyCodeActions = [
      { key: 27, fn: () => this.setState({ listOpen: false }) },
      { key: 9, fn: () => this.setState({ listOpen: false }) },
      {
        key: 13,
        fn: () =>
          this.onValueChanged(
            sortBySelectable(this.state.filteredElements)[this.state.cursor],
          ),
      },
      { key: 38, fn: () => this.setCursor(this.state.cursor - 1) },
      { key: 40, fn: () => this.setCursor(this.state.cursor + 1) },
    ];

    const action = find((code) => code.key === e.keyCode, keyCodeActions);
    action?.fn();
  }

  render() {
    const { listOpen, filteredElements } = this.state;
    const { loading, mediaLink } = this.props;
    const { selectedValue, mandatory, displayUnit, editable, name } =
      this.props.data;
    const indexOffset = getSeparatorOffset(
      getSelectableDropDownValues(this.state.filteredElements),
    );
    const selectedTranslatedValue = selectedValue
      ? getTranslatedValue(selectedValue, displayUnit)
      : this.props.data.placeholder;
    return (
      <div
        className={classNames([
          "control dropdown",
          { "is-active": listOpen },
          { "is-mandatory": mandatory && !selectedValue },
          { "is-loading": loading },
        ])}
        ref={this.hook}
      >
        <div
          className="dropdown-trigger"
          ref={(ref) => {
            this.dropDownRef = ref;
          }}
        >
          {listOpen ? (
            <div className="control has-icons-right">
              <input
                autoFocus
                className="filter input"
                type="text"
                placeholder={L10n.format("filter_placeholder")}
                onChange={(e) => this.onFilterValueChanged(e)}
                onKeyDown={(e) => this.onKeyDown(e)}
              />
              <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </div>
          ) : (
            <button
              className="control button"
              disabled={!editable}
              onClick={() => this.onClickInside()}
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              data-field-id={this.props.data.name}
            >
              <span
                className="button-title"
                data-selected-value-id={
                  selectedValue?.value && `${name}-${selectedValue?.value}`
                }
              >
                {selectedTranslatedValue}
              </span>
              <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faAngleDown} size="xs" />
              </span>
            </button>
          )}
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <DropDownItemGroup
              mediaLink={mediaLink}
              elements={getSelectableDropDownValues(filteredElements)}
              selectedValue={selectedValue}
              displayUnit={displayUnit}
              cursor={this.state.cursor}
              listRefs={this.listRefs}
              onValueChanged={this.onValueChanged}
              parameterName={name}
            />
            {dropDownValuesSeparable(this.state.filteredElements) && (
              <hr className="dropdown-divider" />
            )}
            <DropDownItemGroup
              mediaLink={mediaLink}
              elements={getUnselectableDropDownValues(filteredElements)}
              selectedValue={selectedValue}
              displayUnit={displayUnit}
              separatorOffset={indexOffset}
              cursor={this.state.cursor}
              listRefs={this.listRefs}
              onValueChanged={this.onValueChanged}
              parameterName={name}
            />
          </div>
        </div>
      </div>
    );
  }
}
