import React from "react";

import "./modalTag.scss";

export const ModalTag = ({ value = "", label, onResetCustomerNumberTag }) => (
  <div className="column-entry with-padding">
    <label className="label">{label}</label>
    <div className="input-field-fake" onClick={onResetCustomerNumberTag}>
      <span className="tag is-medium">
        {value}
        <button
          className="delete is-small"
          onClick={onResetCustomerNumberTag}
        />
      </span>
    </div>
  </div>
);
