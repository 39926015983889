import { isNil } from "ramda";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../components/modal/modal";
import { IdentityContext } from "../../hooks/useIdentity";
import { ROUTES } from "../../http/constants";
import { cartBelongsToCurrentUser } from "../../http/identityHelper";
import {
  loadQuote,
  loadQuoteReadOnly,
} from "../../service/offerManagementService";
import { TokenPayload } from "../../types/identity";
import { getNonOpenedQuoteByOrderNumber } from "../../utilities/cartUtils";
import { t } from "../shoppingCart/localizationUtils";

export const OfferManagementOpenOffer = () => {
  const { offerId } = useParams();
  const navigate = useNavigate();
  const { token, identityStore } = useContext(IdentityContext);
  const [showCantOpenOfferModal, setShowCantOpenOfferModal] = useState(false);

  async function openOfferAndNavigate() {
    if (isNil(token) || isNil(identityStore)) {
      return navigate("/");
    }
    try {
      if (isNil(offerId)) {
        throw new Error();
      }
      const user = identityStore.getUser() as TokenPayload["Nutzer"];
      const foundQuote = await getNonOpenedQuoteByOrderNumber(
        offerId,
        user.Email,
      );
      if (isNil(foundQuote)) {
        throw new Error();
      }
      cartBelongsToCurrentUser(identityStore, foundQuote.cartTO.headerData)
        ? await loadQuote(foundQuote.cartTO.id)
        : await loadQuoteReadOnly(foundQuote.cartTO.id);
      navigate(ROUTES.CART);
    } catch (error) {
      setShowCantOpenOfferModal(true);
    }
  }

  useEffect(() => {
    openOfferAndNavigate();
  }, []);

  function onAcceptNavigation() {
    setShowCantOpenOfferModal(false);
    navigate(ROUTES.OFFER_MANAGEMENT);
  }

  if (showCantOpenOfferModal) {
    return (
      <Modal dialogId="offer-management-offer-not-found">
        <ModalHeader label={t("offer_management_offer_not_found_title")} />
        <ModalBodyWrapper>
          {t("offer_management_offer_not_found_description", { offerId })}
        </ModalBodyWrapper>
        <ModalFooter saveLabel={t("ok")} onSave={onAcceptNavigation} />
      </Modal>
    );
  }

  return <Loading />;
};
