import getSymbolFromCurrency from "currency-symbol-map";
import {
  defaultTo,
  groupBy,
  last,
  mapObjIndexed,
  pathOr,
  prop,
  replace,
} from "ramda";

import { Config } from "../config/config";
import { UiAuthorities } from "../types/identity";

const defaultToDot = defaultTo(".");
const defaultToComma = defaultTo(",");
const defaultToEuro = defaultTo("€");

export function getLocale(uiAuthorities: UiAuthorities | null) {
  const locale = pathOr(Config.Lang.Default, ["locale"], uiAuthorities);
  return replace("_", "-", locale);
}

function transformParts(numberFormatParts: Intl.NumberFormatPart[]) {
  const groupedParts = groupBy(prop("type"), numberFormatParts);
  return mapObjIndexed((groupedPart) => last(groupedPart)?.value, groupedParts);
}

export function generateOptions(
  locale: string,
  currency?: string,
  validCurrencyConversionRateFound?: boolean,
) {
  const numberFormatParts = new Intl.NumberFormat(locale, {
    ...(currency && { style: "currency", currency }),
  }).formatToParts(11111.1);
  const { group, decimal } = transformParts(numberFormatParts);
  return {
    thousandSeparator: defaultToDot(group),
    decimalSeparator: defaultToComma(decimal),
    ...(currency && {
      fixedDecimalScale: true,
      decimalScale: 2,
      suffix: ` ${
        validCurrencyConversionRateFound
          ? defaultToEuro(getSymbolFromCurrency(currency))
          : defaultToEuro(null)
      }`,
    }),
  };
}
