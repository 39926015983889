import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

import { Config } from "../config/config";

type LoadingProps = {
  className?: string;
};

export const Loading = ({ className }: LoadingProps) => (
  <FontAwesomeIcon
    spin={true}
    className={classNames([className])}
    data-dialog-id="loading"
    color={Config.Visualization.settings.theme.palette.themePrimary}
    icon={faCircleNotch}
  />
);
