import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { equals } from "ramda";

import { Icon } from "@encoway/react-components";
import { isReady } from "@encoway/rest-api";

export default function ContainerState({ data, style }) {
  if (!isReady(data)) {
    return (
      <span className="icon is-small">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          data-icon-id={`warning-icon-${data.name}`}
        />
      </span>
    );
  } else if (equals(data.properties.TT_READY_STATE, "UNSPECIFIED")) {
    return (
      <span className="icon is-small">
        <Icon
          src="fragezeichen"
          data-icon-id={`unspecified-icon-${data.name}`}
        />
      </span>
    );
  } else if (style.readyIcon) {
    return (
      <span className="icon is-small">
        <Icon src={style.readyIcon} data-icon-id={`ready-icon-${data.name}`} />
      </span>
    );
  }
  return null;
}
