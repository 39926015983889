import {
  any,
  drop,
  equals,
  isEmpty,
  isNil,
  length,
  map,
  take,
  update,
} from "ramda";
import { useContext, useRef, useState } from "react";

import { LvRow, LvRowDTO } from "../types/lvTable";
import {
  convertUtf8Literals,
  parseQuantity,
  prepareParameters,
} from "../utilities/lvUtils";
import { AppContext } from "./useApp";
import { CartContext } from "./useCart";
import { IdentityContext } from "./useIdentity";
import { useLvConfiguration } from "./useLvConfiguration";

// Lower number doesn't work, because it results in duplicated positions (e.g. with 3 it duplicates 4, 5 and 6)
const PRODUCTS_PAGE_SIZE = 6;

export const useLvTable = () => {
  const { catalogService } = useContext(AppContext);
  const { identityStore } = useContext(IdentityContext);
  const { cart } = useContext(CartContext);
  const { initializeLvParameters } = useLvConfiguration();
  const lvHtmlRef = useRef("");
  const lvRowDTOsRef = useRef<LvRowDTO[]>([]);
  const [lvRows, setLvRows] = useState<LvRow[]>([]);
  const [areAllLvRowsChecked, setAreAllLvRowsChecked] =
    useState<boolean>(false);

  if (isNil(catalogService) || isNil(identityStore) || isNil(cart)) {
    throw new Error("Context should be used within ContextProvider!");
  }

  const lvRowDTOsLength = length(lvRowDTOsRef.current);

  const checkedLvRows = lvRows.filter((lvRow) => lvRow.checked);

  const isAnyLvRowChecked = any((lvRow) => lvRow.checked, lvRows);

  const areAllLvRowsLoaded = equals(lvRowDTOsLength, length(lvRows));

  async function prepareLvRows(
    lv: LvRowDTO[],
    pageSize: number = PRODUCTS_PAGE_SIZE,
  ): Promise<LvRow[]> {
    const pagedLvRowDTOs = take<LvRowDTO>(pageSize, drop(length(lvRows), lv));
    return await Promise.all(
      map(async ({ quantity, parameters, ...rest }) => {
        const preparedParameters = prepareParameters(parameters);
        const guiParameters = await initializeLvParameters(preparedParameters);
        return {
          checked: false,
          quantity: parseQuantity(quantity),
          product: guiParameters.product,
          parameters: guiParameters.parameters,
          ...rest,
        };
      }, pagedLvRowDTOs),
    );
  }

  async function initializeLvRows(lvHtml: string, lv: LvRowDTO[]) {
    lvHtmlRef.current = convertUtf8Literals(lvHtml);
    lvRowDTOsRef.current = lv;
    const preparedLvRows = await prepareLvRows(lv);
    setLvRows(preparedLvRows);
  }

  async function getUnloadedLvRows() {
    const preparedLvRows = await prepareLvRows(lvRowDTOsRef.current);
    setLvRows((prev) => [...prev, ...preparedLvRows]);
  }

  async function getAllUnloadedLvRows() {
    if (areAllLvRowsLoaded) {
      return [];
    }
    return await prepareLvRows(
      lvRowDTOsRef.current,
      lvRowDTOsLength - length(lvRows),
    );
  }

  function toggleLvRowChecked(rowIndex: number) {
    setLvRows((prev) => {
      const newRow: LvRow = {
        ...prev[rowIndex],
        checked: !prev[rowIndex].checked,
      };
      return update(rowIndex, newRow, prev);
    });
  }

  async function toggleAllLvRowsChecked() {
    const toggleChecked = (lvRow: LvRow): LvRow => {
      if (isEmpty(lvRow.product.value)) {
        return lvRow;
      }
      return { ...lvRow, checked: !areAllLvRowsChecked };
    };
    const allUnloadedLvRows = await getAllUnloadedLvRows();
    setLvRows((prev) => map(toggleChecked, [...prev, ...allUnloadedLvRows]));
    setAreAllLvRowsChecked(!areAllLvRowsChecked);
  }

  function updateLvRowValue(
    rowIndex: number,
    parameterKey: keyof Pick<LvRow, "position" | "reference" | "quantity">,
    value: string | number,
  ) {
    setLvRows((prev) => {
      const newRow: LvRow = {
        ...prev[rowIndex],
        [parameterKey]: {
          ...prev[rowIndex][parameterKey],
          value,
        },
      };
      return update(rowIndex, newRow, prev);
    });
  }

  function updateLvRowProduct(rowIndex: number, product: LvRow["product"]) {
    setLvRows((prev) => {
      const newRow: LvRow = {
        ...prev[rowIndex],
        product,
      };
      return update(rowIndex, newRow, prev);
    });
  }

  function updateLvRowParameters(
    rowIndex: number,
    parameters: LvRow["parameters"],
  ) {
    setLvRows((prev) => {
      const newRow: LvRow = {
        ...prev[rowIndex],
        parameters,
      };
      return update(rowIndex, newRow, prev);
    });
  }

  function clearLvTableState() {
    lvHtmlRef.current = "";
    lvRowDTOsRef.current = [];
    setLvRows([]);
    setAreAllLvRowsChecked(false);
  }

  return {
    lvHtml: lvHtmlRef.current,
    lvRows,
    areAllLvRowsChecked,
    lvRowDTOsLength,
    checkedLvRows,
    isAnyLvRowChecked,
    areAllLvRowsLoaded,
    initializeLvRows,
    getUnloadedLvRows,
    toggleLvRowChecked,
    toggleAllLvRowsChecked,
    updateLvRowValue,
    updateLvRowProduct,
    updateLvRowParameters,
    clearLvTableState,
  };
};

export type LvTable = ReturnType<typeof useLvTable>;
