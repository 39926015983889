import classNames from "classnames";
import { PropsWithChildren, useContext, useEffect } from "react";

import { ShoppingCartInput } from "../../../../../components/input/shoppingCartInput/shoppingCartInput";
import { CartContext } from "../../../../../hooks/useCart";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import { hasRightToSeeShippingMethodDropdown } from "../../../../../http/identityHelper";
import { CartPriceTO } from "../../../../../types/cart";
import { ViewsState } from "../../../../../utilities/cartUtils";
import { t } from "../../../localizationUtils";
import { calculationViews } from "./calculationUtils";
import { ShippingMethod } from "./shippingMethod";
import { TaxInput } from "./taxInput/taxInput";
import { determineInitialTaxRate } from "./taxInput/taxInputUtils";

import "./calculation.scss";

type CalculationTextBoxProps = {
  className?: string;
  label: string;
  value: CartPriceTO;
  isBold?: boolean;
  cellId: string;
};

const CalculationTextBox = ({
  className,
  label,
  value,
  isBold,
  cellId,
}: CalculationTextBoxProps) => {
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <div className={classNames(["columns calculation-box", className])}>
      <div
        className={classNames([
          "column is-three-quarters",
          { boldText: isBold },
        ])}
      >
        {t(label)}
      </div>
      <div
        data-cell-id={cellId}
        className={classNames([
          "column has-text-right is-one-quarter",
          { boldText: isBold },
        ])}
      >
        {formatPrice(value)}
      </div>
    </div>
  );
};

type CalculationInputBoxProps = {
  label: string;
};

const CalculationInputBox = ({
  label,
  children,
}: PropsWithChildren<CalculationInputBoxProps>) => (
  <div className="columns calculation-box">
    <div className="column is-three-fifths">{t(label)}</div>
    <div className="column is-two-fifths has-text-right">{children}</div>
  </div>
);

type CalculationProps = {
  cartViews: ViewsState;
};

export const Calculation = ({ cartViews }: CalculationProps) => {
  const { cart, cartActions } = useContext(CartContext);
  const { formatPrice } = useLocaleNumberFormat();
  const { hasExpressSurcharge, hasProcessingSurcharge } = calculationViews(
    cart,
    cartViews.isOrder,
  );
  const showShippingMethodDropdown =
    hasRightToSeeShippingMethodDropdown(cart.authorities.booleanAuthorities) &&
    cart.shippingMethodAvailable;
  const {
    setShippingMethod,
    setFinalSalesPrice,
    setFinalDiscount,
    setTaxRate,
  } = cartActions;

  useEffect(() => {
    determineInitialTaxRate(cart, setTaxRate);
  }, []);

  return (
    <div className="calculation columns">
      <div className="column is-three-fifths" />
      <div className="column is-two-fifths">
        {showShippingMethodDropdown && (
          <ShippingMethod
            cart={cart}
            setShippingMethod={setShippingMethod}
            cartViews={cartViews}
          />
        )}
        {hasProcessingSurcharge && (
          <CalculationTextBox
            label="shipping_costs"
            value={
              cartViews.isOrder
                ? cart.processingSurchargePartOrder
                : cart.processingSurcharge
            }
            cellId="cart-sum-shipping_costs"
          />
        )}
        {hasExpressSurcharge && (
          <CalculationTextBox
            label="express_charge"
            value={
              cartViews.isOrder
                ? cart.expresskostenZuschlagPartOrder
                : cart.expresskostenZuschlag
            }
            cellId="cart-sum-express_charge"
          />
        )}
        {cartViews.isBuyer || cartViews.isOrder ? (
          <CalculationTextBox
            label="total_sum_ek_netto_gp_incl_surcharges"
            value={
              cartViews.isOrder
                ? cart.ekNettoGpPlusSurchargeAbsolutePartOrder
                : cart.ekNettoGpPlusSurchargeAbsolute
            }
            cellId="cart-sum-total_sum_ek_netto_gp_incl_surcharges"
            isBold
          />
        ) : (
          <>
            <CalculationTextBox
              label="total_sum_netto"
              value={cart.totalArticlePrice}
              cellId="cart-sum-total_sum_netto"
              isBold
            />
            <hr className="end-price-section" />
            <CalculationInputBox label={"discount"}>
              <ShoppingCartInput
                className="is-small right cartInput"
                name="discountRate"
                setValue={setFinalDiscount}
                value={cart.discountRate}
                decimalScale={2}
                data-cell-id="cart-sum-discount"
              />
            </CalculationInputBox>
            <CalculationInputBox label={"discount_price"}>
              <ShoppingCartInput
                className="is-small right cartInput"
                name="discountedPrice"
                setValue={setFinalSalesPrice}
                value={cart.discountedPrice.price}
                isCurrency
                data-cell-id="cart-sum-discount_price"
              />
            </CalculationInputBox>
            <hr className="end-price-section" />
            <div className="columns calculation-box ">
              <div className="column is-three-fifths tax is-flex">
                {t("taxe")}
                <TaxInput cart={cart} setTaxRate={setTaxRate} />
              </div>
              <div
                data-cell-id="cart-sum-taxe-absolute"
                className="column is-two-fifths has-text-right"
              >
                {formatPrice(cart.taxesAbsolute)}
              </div>
            </div>
            <div className="has-bottom-double-line">
              <CalculationTextBox
                label="total_sum_brutto"
                value={cart.totalPrice}
                cellId="cart-sum-total_sum_brutto"
                isBold
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
