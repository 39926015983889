import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../components/modal/modal";
import { t } from "../shoppingCart/localizationUtils";

import "../shoppingCart/components/modals/reconfiguringArticleLoadingModal.scss";

const ProgressIcon = ({ className, icon }) => (
  <a className={classNames(["button is-progress", className])}>
    <span className="icon is-small">{icon}</span>
  </a>
);

const ProgressRow = ({ label, children }) => (
  <div className="reconfiguration-loading columns">
    <div className="reconfiguration-loading-state column">{children}</div>
    <div className="reconfiguration-loading-label column">{label}</div>
  </div>
);

export const ReconfiguringArticleLoadingModal = () => (
  <Modal className="create-a-new-quote">
    <ModalHeader
      className="reconfiguration-modal-header"
      label={t("cart_title_reconfiguration_warning")}
    />
    <ModalBodyWrapper label={t("cart_title_reconfiguration_validate_warning")}>
      <div className="reconfiguration-modal">
        <ProgressRow label={t("cart_reconfiguration_started")}>
          <ProgressIcon
            className="is-success"
            icon={<FontAwesomeIcon icon={faCheck} />}
          />
        </ProgressRow>
        <ProgressRow label={t("cart_reconfiguration_running")}>
          <ProgressIcon className="is-loading" />
        </ProgressRow>
        <ProgressRow label={t("cart_reconfiguration_finished")}>
          <ProgressIcon className="is-loading" />
        </ProgressRow>
      </div>
    </ModalBodyWrapper>
    <ModalFooter />
  </Modal>
);
