import Bacon from "baconjs";
import { isNil, pathOr } from "ramda";
import { createContext, useContext, useEffect, useState } from "react";

import { prepareInformationDTO } from "../pages/offerManagement/modifyOfferModal/modifyOfferModalActions";
import { CONFIGURATION_COMPLETE } from "../pages/shoppingCart/components/footer/footerUtils";
import { NewCheckoutInformation } from "../pages/shoppingCart/components/modals/orderModal/orderHelper";
import { t } from "../pages/shoppingCart/localizationUtils";
import {
  duplicateArticle,
  performCheckoutPartOrder,
  performCheckoutSharedQuote,
  performDbCheck,
} from "../service/cartRestService";
import {
  getActiveQuote,
  getSharedQuote,
  loadSharedQuote,
} from "../service/offerManagementService";
import { NonNullableValues } from "../types/app";
import {
  CartActions,
  CartService,
  CartStore,
  ExtendedTeckentrupCartTO,
} from "../types/cart";
import {
  ORDER_STATUS_FLAGGED,
  ORDER_STATUS_UNORDERD,
} from "../utilities/cartUtils";
import {
  createGroupEvent,
  orderRequestSentEvent,
  track,
  trackFunction,
} from "../utilities/eventTracking";
import { getPartOrderDetails } from "../utilities/rowUtils";
import { AppContext } from "./useApp";

const initialStore: CartStore = {
  loading: true,
  properties: new Map(),
  originalQuoteId: undefined,
  cart: {} as ExtendedTeckentrupCartTO,
  cartActions: {} as CartActions,
};

export const CartContext = createContext(initialStore);
export const CartProvider = CartContext.Provider;

export const useCart = (
  cartService: CartService,
): NonNullableValues<CartStore> => {
  const { language } = useContext(AppContext);

  const [cart, setCart] = useState<ExtendedTeckentrupCartTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState<Map<string, string>>(new Map());

  async function resolveCart(fn: Function) {
    const cartResult = await fn();
    const cartResultCart = pathOr(
      cartResult.cart,
      ["data", "cart", "cartTO"],
      cartResult,
    );
    const cartTo = {
      ...cartResultCart,
      loading: false,
      authorities: pathOr(
        cart?.authorities,
        ["data", "cart", "uiRelevantAuthorizationTO"],
        cartResult,
      ),
      reconfigurationPosition: null,
      articles: {
        ...cartResultCart.articles,
        subArticles: getPartOrderDetails(cartResultCart),
      },
    };
    setCart(cartTo);
    return cartResultCart;
  }

  async function resolveCartWithLoading(fn: Function) {
    setLoading(true);
    const cartResult = await resolveCart(fn);
    setLoading(false);
    return cartResult;
  }

  const getCart = async () => resolveCart(getActiveQuote);

  const setOptional =
    ({ articleId }: { articleId: string }) =>
    (optional: boolean) =>
      resolveCart(() => cartService.setOptional(articleId, optional));

  const setMargin =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setMargin", {
          margin: value,
          absolute: true,
        }),
      );

  const setAmount =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() => cartService.setAmount(articleId, value));

  const setMarginPerc =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setMargin", {
          margin: value,
          absolute: false,
        }),
      );

  const setComment = async (
    { articleId }: { articleId: string },
    value: string,
  ) =>
    resolveCart(() =>
      cartService.performArticleOperation(articleId, "setComment", {
        comment: value,
      }),
    );

  const setProperty = async (
    { articleId }: { articleId: string },
    property: string,
    value: string,
  ) =>
    resolveCart(() =>
      cartService.performArticleOperation(articleId, "setProperty", {
        key: property,
        [property]: value,
      }),
    );

  const setPropertyConfiguration = async (property: string, value: string) => {
    const map = properties;
    map.set(property, value);
    setProperties(map);
  };

  const setPropertiesOnSave = async (articleId: string) => {
    const keys = properties.keys();
    let key = keys.next();
    while (!key.done) {
      const prop = properties.get(key.value);
      if (!isNil(prop)) {
        await setProperty({ articleId: articleId }, key.value, prop);
        key = keys.next();
      }
    }
    setProperties(new Map());
  };

  const setDiscountPerc =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(
          articleId,
          "encoway.cart_service.SetArticleDiscount",
          {
            discount: value,
          },
        ),
      );

  const setPositionDiscountPerc =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setPositionDiscount", {
          discountRate: value,
          absolute: false,
        }),
      );

  const setPositionDiscount =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setPositionDiscount", {
          discountRate: value,
          absolute: true,
        }),
      );

  const setCampaignDiscountPerc =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(articleId, "setCampaignDiscount", {
          discount: value,
        }),
      );

  const setFinalSalesPrice = (value: number) =>
    resolveCart(() =>
      cartService.performOperation("setOveralDiscount", {
        absolute: true,
        discount: value,
      }),
    );

  const setFinalDiscount = (value: number) =>
    resolveCart(() =>
      cartService.performOperation("setOveralDiscount", {
        absolute: false,
        discount: value,
      }),
    );

  const setCalculationBasis = (calculationBasis: string | null) =>
    resolveCart(() =>
      cartService.performOperation("setCalculationBasis", {
        calculationBasis: calculationBasis,
      }),
    );

  const setGroupName = ({ articleId }: { articleId: string }, value: string) =>
    resolveCart(() => cartService.renameGroup(articleId, value));

  const setPriceListDiscount =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(
          articleId,
          "setDiscountOnPriceList",
          {
            discountOnPriceList: value,
            absolute: true,
          },
        ),
      );

  const setPriceListDiscountPerc =
    ({ articleId }: { articleId: string }) =>
    (value: number) =>
      resolveCart(() =>
        cartService.performArticleOperation(
          articleId,
          "setDiscountOnPriceList",
          {
            discountOnPriceList: value,
            absolute: false,
          },
        ),
      );

  const saveCheckoutInformation = async (checkoutInformation: unknown) =>
    resolveCart(() => cartService.setHeaderInformation(checkoutInformation));

  const performCheckout = async () => {
    await cartService.performCheckout("ALL");
    await getCart();
    track(orderRequestSentEvent);
  };

  const performSharedQuoteCheckout = async (
    checkoutInformation: NewCheckoutInformation,
  ) => {
    await performCheckoutSharedQuote(cart!.properties.originalQuoteId, {
      ...cart!.headerData,
      ...checkoutInformation,
    });
    await getCart();
  };

  const performPartOrderCheckout = async (
    checkoutInformation: NewCheckoutInformation,
  ) => {
    await performCheckoutPartOrder({
      ...cart!.headerData,
      ...checkoutInformation,
    });
    await cartService.performOperation("recalculation", {});
    await getCart();
  };

  const removeArticle = (articleId: string) =>
    resolveCart(() => cartService.removeArticle(articleId));

  const removeCartItem = (article: any) =>
    resolveCart(() =>
      article.group
        ? cartService.removeGroup(article.articleId)
        : cartService.removeArticle(article.articleId),
    );

  const reconfigureArticle = (articleId: string) =>
    resolveCart(async () => cartService.reconfigureArticle(articleId));

  const saveConfiguration = (configurationId: string) =>
    resolveCartWithLoading(() =>
      cartService.addConfiguredArticle(configurationId),
    );

  const saveQuoteAndClose =
    (offerManagementService: any, configurationService: any) => async () => {
      await offerManagementService.saveQuoteAndClose();
      await configurationService.remove();
      return getCart();
    };

  const saveQuote = async (
    offerManagementService: any,
    checkoutInformation: any,
  ) => {
    await saveCheckoutInformation(prepareInformationDTO(checkoutInformation));
    await offerManagementService.saveQuote();
    return getCart();
  };

  const setShippingMethod = (shippingMethodId: string, orderView: boolean) =>
    resolveCart(() =>
      cartService.performOperation("setShippingMethod", {
        shippingMethod: shippingMethodId,
        orderView,
      }),
    );

  const setTaxRate = (taxRate: number) => {
    resolveCart(() => cartService.performOperation("setTaxRate", { taxRate }));
  };

  const update = (
    articleId: string,
    configurationId: string,
    articleOrderStatus?: string,
    readyState?: string,
  ) => {
    const orderStatusFlagged = articleOrderStatus === ORDER_STATUS_FLAGGED;
    const configurationReady = readyState === CONFIGURATION_COMPLETE;
    return resolveCart(async () => {
      if (orderStatusFlagged && !configurationReady) {
        await cartService.performArticleOperation(
          articleId,
          "setFlagAsPartOrder",
          {
            orderStatus: ORDER_STATUS_UNORDERD,
            isGroup: false,
            allPositions: false,
          },
        );
      }
      return cartService.updateConfiguredArticle(articleId, configurationId);
    });
  };

  const dbCheck = async (quoteId: string) => performDbCheck(quoteId);

  useEffect(() => {
    cartService.touch("NOTHING");
    Bacon.interval(60000).onValue(() => cartService.touch("NOTHING"));
    getCart().then();
  }, []);

  return {
    loading,
    properties,
    originalQuoteId: cart?.properties?.originalQuoteId as string | undefined,
    cart: cart as ExtendedTeckentrupCartTO,
    cartActions: {
      add: (productId, amount = 1) =>
        resolveCartWithLoading(() =>
          cartService.addCatalogArticle(productId, amount),
        ),
      update,
      getArticleConfiguration: (articleId) =>
        cartService.getArticleConfiguration(articleId),
      removeAllCartEntries: () => resolveCart(() => cartService.resetCart()),
      touch: () => resolveCart(() => cartService.touch("NOTHING")),
      updateFreePosition: (position, articleId) =>
        resolveCartWithLoading(() =>
          cartService.updateFreeArticle(articleId, position),
        ),
      overwriteState: (cartObj) => setCart({ ...cart, ...cartObj }),
      getSharedCart: (id) => resolveCart(() => getSharedQuote(id)),
      openSharedCart: (id) => resolveCart(() => loadSharedQuote(id)),
      createGroup: trackFunction(
        () =>
          resolveCartWithLoading(() =>
            cartService.createGroup(t("cart_new_folder")),
          ),
        createGroupEvent,
      ),
      createFreePosition: (position, amount) =>
        resolveCartWithLoading(() =>
          cartService.addFreeArticle(position, amount),
        ),
      removeGroup: (group) =>
        resolveCart(() => cartService.removeGroup(group.articleId)),
      moveArticle: (dragItemId, dragEndItemId, index) =>
        resolveCart(() =>
          cartService.moveArticleToGroup(dragItemId, index, dragEndItemId),
        ),
      duplicateArticle: ({ articleId }) =>
        resolveCartWithLoading(() => duplicateArticle(articleId, language)),
      setPriceListDiscountPerc,
      setPriceListDiscount,
      removeCartItem,
      saveConfiguration,
      reconfigureArticle,
      removeArticle,
      performCheckout,
      saveCheckoutInformation,
      setCalculationBasis,
      setFinalDiscount,
      setFinalSalesPrice,
      setCampaignDiscountPerc,
      setPositionDiscount,
      setPositionDiscountPerc,
      setDiscountPerc,
      setComment,
      setPropertyConfiguration,
      setPropertiesOnSave,
      setProperty,
      setMarginPerc,
      setMargin,
      setOptional,
      setAmount,
      setShippingMethod,
      setGroupName,
      getCart,
      saveQuote,
      saveQuoteAndClose,
      setTaxRate,
      performSharedQuoteCheckout,
      dbCheck,
      resetProperties: () => setProperties(new Map()),
      performPartOrderCheckout,
    },
  };
};
