import {
  Modal,
  ModalHeader,
  ModalBodyWrapper,
  ModalFooter,
} from "../../components/modal/modal";

type CartWarningWrapperProps = {
  headerLabel: string;
  bodyLabel: JSX.Element | string;
  footerCancelLabel?: string;
  onCancel?: () => void;
  footerSaveLabel?: string;
  onSave?: () => void;
  saveIcon?: JSX.Element;
  isLoading?: boolean;
  dialogId?: string;
  disabled?: boolean;
};

export const CartWarningWrapper = ({
  headerLabel,
  bodyLabel,
  footerCancelLabel,
  onCancel,
  footerSaveLabel,
  onSave,
  saveIcon,
  isLoading = false,
  dialogId,
  disabled = false,
}: CartWarningWrapperProps) => (
  <Modal dialogId={dialogId}>
    <ModalHeader label={headerLabel} onCancel={onCancel} />
    <ModalBodyWrapper label={bodyLabel}>
      <div className="bottom-space" />
    </ModalBodyWrapper>
    <ModalFooter
      cancelLabel={footerCancelLabel}
      onCancel={onCancel}
      saveLabel={footerSaveLabel}
      onSave={onSave}
      saveIcon={saveIcon}
      isLoading={isLoading}
      disabled={disabled}
    />
  </Modal>
);
