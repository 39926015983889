import {
  descend,
  filter,
  includes,
  length,
  prop,
  sortWith,
  toLower,
} from "ramda";

export const getTranslatedValue = (value, displayUnit) =>
  value.translatedValue + (displayUnit ? ` ${displayUnit.translatedUnit}` : "");

export const getSelectableDropDownValues = filter(
  (element) => element.selectable,
);

export const getUnselectableDropDownValues = filter(
  (element) => !element.selectable,
);

/**
 * Checks if the entries in dropdown is separable in selectable and unselectable elements
 *
 * @param elements
 */
export const dropDownValuesSeparable = (elements) =>
  length(getSelectableDropDownValues(elements)) > 0 &&
  length(getUnselectableDropDownValues(elements)) > 0;

/**
 * Helper method for navigating with arrow keys through unselectable and selectable elements
 *
 * @param elements
 */
export const getSeparatorOffset = (elements) => length(elements);

/**
 * Sort Drop Down elements by the boolean prop selectable: true first then false
 *
 * @param elements
 */
export const sortBySelectable = (elements) =>
  sortWith([descend(prop("selectable"))], elements);

export const isSelected = (selectedValue, { translatedValue }) =>
  selectedValue && selectedValue.translatedValue === translatedValue;

export const filterElementsByQuery = (elements, query) =>
  sortBySelectable(
    filter(
      (element) => includes(toLower(query))(toLower(element.translatedValue)),
      elements,
    ),
  );
