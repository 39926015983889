import { isNil, isEmpty } from "ramda";

/**
 * Returns true, if the config-object is in the store and if unsuccessfulParameters or configurationStatus are also there.
 * @param {*} config
 */
const isConfigStartedComplete = (config) =>
  (!isNil(config) && !isNil(config?.cfg?.unsuccessfulParameters)) ||
  !isNil(config?.cfg?.configurationStatus);

/**
 * Returns true, if unsuccessfulParameters is filled.
 * @param {*} config
 */
export const isUnsuccessfulParametersFilled = (config) =>
  isConfigStartedComplete(config) &&
  !isEmpty(config?.cfg?.unsuccessfulParameters);

/**
 * Returns true, if the reconfiguration-Setup is failed.
 * @param {*} config
 */
export const isReconfigurationSetupFailed = (config) =>
  isConfigStartedComplete(config) &&
  config.configurationStatus?.configuration?.properties
    ?.ReconfigurationSetupSuccessful === "false";
