import { isNil } from "ramda";

import { FlexTableCell as Cell } from "../../../../../components/flexTable/flexTable";
import { ShoppingCartInput } from "../../../../../components/input/shoppingCartInput/shoppingCartInput";
import { Quantity } from "../../../../../types/cart";
import { t } from "../../../localizationUtils";

type InputCellProps = {
  value: number;
  setValue: (value: number) => void;
  minimalValue?: number;
  hasAmountWarning?: boolean;
  isCurrency?: boolean;
  decimalScale?: number;
  quantity?: Quantity;
  "data-cell-id"?: string;
};

export const InputCell = ({ quantity, ...props }: InputCellProps) => (
  <Cell className="has-shopping-cart-warning input-cell">
    <ShoppingCartInput
      className="table-input is-small right"
      quantity={quantity}
      {...props}
    />
    {!isNil(quantity) && (
      <span className="shopping-cart-amount-unit small-text">
        {quantity.quantityUnit === "M"
          ? t("cart_table_unit_meter")
          : t("cart_table_unit_quantity")}
      </span>
    )}
  </Cell>
);
