import { isNil } from "ramda";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import { ConfigurationContext } from "../../hooks/useConfiguration";
import { Configuration } from "./configuration";
import { isReconfigurationSetupFailed } from "./configurationUtils";
import { ReconfiguringWarningDialog } from "./reconfiguringWarningDialog";

export function ReconfigurationFromId() {
  const {
    articleId,
    articleName,
    cartPosition,
    configurationId,
    articleOrderStatus,
  } = useParams();
  const { restart, config, loading, reconfiguration } =
    useContext(ConfigurationContext);
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    (async () => {
      if (isNil(configurationId)) {
        await restart({
          configurationId,
          articleId,
          articleName,
          cartPosition,
          articleOrderStatus,
        });
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isReconfigurationSetupFailed(reconfiguration) && toggle ? (
        <ReconfiguringWarningDialog
          loading={loading}
          configObj={config?.cfg}
          onClickClose={() => setToggle(false)}
          isReConfSuccessful={!isReconfigurationSetupFailed(reconfiguration)}
        />
      ) : (
        <Configuration />
      )}
    </>
  );
}
