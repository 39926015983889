import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export const ProgressButton = ({ isSuccess = false, isLoading = true }) => {
  return (
    <a
      className={classNames([
        "button is-progress",
        {
          "is-loading": isLoading,
          "is-success": isSuccess,
          "is-warning": !isLoading && !isSuccess,
        },
      ])}
    >
      <span className="icon is-small">
        {!isLoading && isSuccess && <FontAwesomeIcon icon={faCheck} />}
        {!isLoading && !isSuccess && <FontAwesomeIcon icon={faXmark} />}
      </span>
    </a>
  );
};
