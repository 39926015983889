import { isNil } from "ramda";
import { PropsWithChildren, useEffect, useRef } from "react";

const scrollOptions = (alignToTop: boolean): ScrollIntoViewOptions => ({
  block: alignToTop ? "start" : "end",
  behavior: "smooth",
});

type ScrollWrapperProps = {
  alignToTop: boolean;
};

export const ScrollWrapper = (props: PropsWithChildren<ScrollWrapperProps>) => {
  const { alignToTop, children } = props;
  const scrollRef = useRef(null);

  const scrollElementIntoView = (element: HTMLDivElement | null) => {
    if (!isNil(element)) {
      element.scrollIntoView(scrollOptions(alignToTop));
    }
  };

  useEffect(() => {
    scrollElementIntoView(scrollRef.current);
  }, []);

  return (
    <div className="ScrollWrapper" ref={scrollRef}>
      {children}
    </div>
  );
};
