import { AxiosError, HttpStatusCode } from "axios";
import { T, always, cond, equals, isNil, lt } from "ramda";
import { useContext, useEffect, useRef, useState } from "react";

import { CartContext } from "../../../hooks/useCart";
import { ConfigurationContext } from "../../../hooks/useConfiguration";
import { downloadDynamicDrawing } from "../../../service/drawingService";
import { CartArticleTO, FreeArticleTO } from "../../../types/cart";
import {
  copyPositionEvent,
  deliveryTimeChangeEvent,
  requestDrawingEvent,
  track,
} from "../../../utilities/eventTracking";
import { t } from "../localizationUtils";
import { CART_MODALS, CartModalsReturn } from "./useCartModals";
import {
  isClickedOutsideOfNode,
  prepareFreeCartItem,
} from "./utils/cartDropDownUtils";

export const useCartDropDown = (
  cartItem: CartArticleTO,
  toggleModal: CartModalsReturn["modalActions"]["toggleModal"],
) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [toggle, setToggle] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangeDeliveryTimeModal, setShowChangeDeliveryTimeModal] =
    useState(false);
  const [showRequestDrawingModal, setShowRequestDrawingModal] = useState(false);
  const [showRequestDrawingNoCartId, setShowRequestDrawingNoCartId] =
    useState(false);
  const [itemMessage, setItemMessage] = useState(false);
  const [messageDetails, setMessageDetails] = useState({ title: "", text: "" });
  const { cartActions } = useContext(CartContext);
  const { remove, configurationId, reconfiguration } =
    useContext(ConfigurationContext);

  const toggleDropDown = (dropDownActive?: boolean) =>
    setToggle((prev) => (!isNil(dropDownActive) ? dropDownActive : !prev));

  const toggleDeleteModal = () => {
    toggleDropDown(false);
    setShowDeleteModal((prev) => !prev);
  };

  const toggleDeliveryChangeModal = () => {
    toggleDropDown(false);
    setShowChangeDeliveryTimeModal((prev) => !prev);
  };

  const toggleEditModal = () => {
    toggleDropDown(false);
    setShowEditModal((prev) => !prev);
  };

  const toggleCommentModal = () => {
    toggleDropDown(false);
    setShowCommentModal((prev) => !prev);
  };

  const toggleRequestDrawingModal = (unsavedCart?: boolean) => () => {
    toggleDropDown(false);
    if (unsavedCart) {
      setShowRequestDrawingNoCartId(true);
      return;
    }
    setShowRequestDrawingModal((prev) => !prev);
  };

  const toggleRequestDrawingNoCartId = () => {
    toggleDropDown(false);
    setShowRequestDrawingNoCartId((prev) => !prev);
  };

  const toggleMessageModal = (details?: typeof messageDetails) => {
    const newMessageDetails = details || { title: "", text: "" };

    toggleDropDown(false);
    setItemMessage((prev) => !prev);
    setMessageDetails(newMessageDetails);
  };

  const saveCartItemEdit =
    ({ articleId }: CartArticleTO) =>
    (freeItem: FreeArticleTO) =>
    async () => {
      toggleEditModal();
      await cartActions.updateFreePosition(
        prepareFreeCartItem(freeItem),
        articleId,
      );
      await cartActions.setAmount({ articleId })(freeItem.amount);
    };

  const saveCartItemCommentModal =
    (article: CartArticleTO) => (comment: string) => () => {
      toggleCommentModal();
      cartActions.setComment(article, comment).then();
    };

  const saveDeliveryTimeChangeModal = async () => {
    await cartActions.update(reconfiguration!.articleId, configurationId!);
    await remove();
    setShowChangeDeliveryTimeModal(false);
    track(deliveryTimeChangeEvent);
  };

  const deleteCartItemAndToggleDeleteWarning = () => {
    cartActions.removeCartItem(cartItem).then();
    toggleDeleteModal();
  };

  const createMessageDetailsFromDrawingResponse = (status?: HttpStatusCode) => {
    const title = t("download_error_title");
    const text = cond([
      [equals(201), always(t("download_in_progress_text"))],
      [lt(299), always(`${t("download_error_text")} ${status}`)],
      [T, always("")],
    ])(status || 0);
    return { title, text };
  };

  const requestDrawing = async (positionNumber: string, formats: string[]) => {
    toggleRequestDrawingModal()();
    try {
      const { status } = await downloadDynamicDrawing(positionNumber, formats);
      status >= 299 ||
        (status === 201 &&
          toggleMessageModal(createMessageDetailsFromDrawingResponse(status)));
      track(requestDrawingEvent);
      return true;
    } catch (error) {
      toggleMessageModal(
        createMessageDetailsFromDrawingResponse(
          (error as AxiosError)?.response?.status,
        ),
      );
      return false;
    }
  };

  const toggleModifyOfferModal = () => {
    toggleRequestDrawingNoCartId();
    toggleModal(CART_MODALS.MODIFY_OFFER)();
  };

  const duplicateCartItem = () => {
    toggleDropDown();
    cartActions.duplicateArticle(cartItem);
    track(copyPositionEvent);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (event.target && isClickedOutsideOfNode(nodeRef, event)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return {
    nodeRef,
    toggle,
    showCommentModal,
    showDeleteModal,
    showEditModal,
    showChangeDeliveryTimeModal,
    showRequestDrawingModal,
    showRequestDrawingNoCartId,
    itemMessage,
    messageDetails,
    actions: {
      toggleDropDown,
      toggleDeliveryChangeModal,
      toggleEditModal,
      toggleCommentModal,
      toggleDeleteModal,
      toggleRequestDrawingModal,
      toggleRequestDrawingNoCartId,
      toggleMessageModal,
      toggleModifyOfferModal,
      saveDeliveryTimeChangeModal,
      saveCartItemCommentModal,
      saveCartItemEdit,
      requestDrawing,
      duplicateCartItem,
      deleteCartItemAndToggleDeleteWarning,
    },
  };
};

export type UseCartDropDownReturn = ReturnType<typeof useCartDropDown>;
