import { Portal } from "../Portal";
import { NavigationLinks } from "./NavigationLinks";

/**
 * Wrapper for navigation links
 * @returns {React.ReactElement} returns portal element to be injected into "encoway-mock-inject-header"
 */
export function Mocks() {
  return (
    <Portal id={"encoway-mock-inject-header"}>
      <NavigationLinks />
    </Portal>
  );
}
