import { isNil, pathOr } from "ramda";
import { useContext, useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import { mandatoryFieldsIncomplete } from "../../../../components/input/shoppingCartInput/inputUtils";
import { ShoppingCartInput } from "../../../../components/input/shoppingCartInput/shoppingCartInput";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { CartContext } from "../../../../hooks/useCart";
import { hasRightToSeeFreeItemExtra } from "../../../../http/identityHelper";
import { FreeArticleTO, TeckentrupCartArticleTO } from "../../../../types/cart";
import {
  addFreePositionEvent,
  trackFunction,
} from "../../../../utilities/eventTracking";
import { t } from "../../localizationUtils";
import { Input } from "./orderModal/input";

import "./freeItemModal.scss";

const STANDARD_FREE_ITEM_TYPE = "Zukaufartikel";

type FreeItemModalProps = {
  cartItem?: TeckentrupCartArticleTO;
  isEditMode: boolean;
  toggleModal: () => void;
  saveFreeItem: (freeItem: FreeArticleTO) => () => Promise<void>;
};

type FreeItemState = {
  name: string;
  basePrice: number;
  amount: number;
  type: string;
  articleNumber: string;
  comment: string;
  referenceNo: string;
  productDescription: string;
};

const FREE_ITEM_INITIAL_STATE = {
  name: "",
  basePrice: 0,
  amount: 1,
  type: STANDARD_FREE_ITEM_TYPE,
  articleNumber: "",
  comment: "",
  referenceNo: "",
  productDescription: "",
} as const;

export function FreeItemModal(props: Readonly<FreeItemModalProps>) {
  const { cartItem, toggleModal, saveFreeItem, isEditMode = false } = props;

  const { cart } = useContext(CartContext);
  const [freeItem, setFreeItem] = useState<FreeItemState>(
    FREE_ITEM_INITIAL_STATE,
  );

  useEffect(() => {
    if (!isNil(cartItem)) {
      const {
        amount,
        articleNumber,
        comment,
        referenceNo,
        productDescription,
      } = cartItem;
      const singlePrice = pathOr(0, ["singlePrice", "price"], cartItem);
      setFreeItem({
        ...freeItem,
        name: cartItem.articleName,
        basePrice: singlePrice,
        type: cartItem.freeArticleType,
        amount: amount ?? 1,
        articleNumber,
        comment,
        referenceNo,
        productDescription,
      });
    }
  }, [cartItem]);

  const onInputChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget;
    setFreeItem({ ...freeItem, [name]: value });
  };

  const onNumberInputChange = (name: string) => (value: number) => {
    setFreeItem({ ...freeItem, [name]: value });
  };

  return (
    <Modal className="free-item-modal" dialogId="add-free-position">
      <ModalHeader
        label={t(
          isEditMode ? "free_article_dialog_title_edit" : "add_free_position",
        )}
        onCancel={toggleModal}
      >
        {hasRightToSeeFreeItemExtra(cart.authorities.booleanAuthorities) && (
          <span className="is-pulled-right modal-card-notice">
            {t("free_article_dialog_head_notice")}
          </span>
        )}
      </ModalHeader>
      <ModalBodyWrapper>
        <div className="content">{t("free_article_dialog_subtitle")}</div>
        <div className="columns is-multiline">
          <div className="column is-full">
            <div className="is-full">
              <Input
                name="name"
                value={freeItem.name}
                onChange={onInputChange}
                label={
                  t("free_article_dialog_article_name") + t("mandatory_symbol")
                }
                inputId="free_article_dialog_article_name"
              />
            </div>
          </div>
          <div className="column is-half">
            <div className="is-full has-shopping-cart-warning">
              <ShoppingCartInput
                value={freeItem.amount}
                setValue={onNumberInputChange("amount")}
                label={
                  t("free_article_dialog_article_amount") +
                  t("mandatory_symbol")
                }
                hasAmountWarning
                minimalValue={1}
                data-input-field-id="free_article_dialog_article_amount"
              />
            </div>
          </div>
          <div className="column is-half">
            <div className="is-full has-shopping-cart-warning">
              <ShoppingCartInput
                className="has-text-right"
                value={freeItem.basePrice}
                setValue={onNumberInputChange("basePrice")}
                label={
                  t("free_article_dialog_article_price") + t("mandatory_symbol")
                }
                isCurrency
                data-input-field-id="free_article_dialog_article_price"
              />
            </div>
          </div>
          <div className="column is-full">
            <div className="is-full">
              <Input
                name="articleNumber"
                value={freeItem.articleNumber}
                onChange={onInputChange}
                label={t("free_article_dialog_article_number")}
                placeholder={L10n.format(
                  "free_article_dialog_article_number_placeholder",
                )}
              />
            </div>
          </div>
          <div className="column is-full">
            <div className="is-full">
              <Input
                name="referenceNo"
                value={freeItem.referenceNo}
                onChange={onInputChange}
                label={t("reference_number")}
                placeholder={L10n.format(
                  "free_article_dialog_reference_number_placeholder",
                )}
              />
            </div>
          </div>
          <div className="column is-full">
            <label className="label">
              {L10n.format("free_article_dialog_product_description")}
            </label>
            <textarea
              name="productDescription"
              className="textarea"
              value={freeItem.productDescription}
              onChange={onInputChange}
              placeholder={L10n.format(
                "free_article_dialog_product_description_placeholder",
              )}
            />
          </div>
          <div className="column is-full">
            <label className="label">
              {L10n.format("comment")}
              {hasRightToSeeFreeItemExtra(
                cart.authorities.booleanAuthorities,
              ) && (
                <span className="smallText with-padding">
                  {L10n.format("will_not_be_transmitted_to_Teckentrup")}
                </span>
              )}
            </label>
            <textarea
              name="comment"
              className="textarea"
              value={freeItem.comment}
              onChange={onInputChange}
            />
          </div>
          <hr />
          <div className="smallText with-padding">
            {t("mandatory_symbol_information")}
          </div>
        </div>
      </ModalBodyWrapper>
      <ModalFooter
        saveLabel={t(
          isEditMode
            ? "free_article_dialog_update"
            : "new_free_article_dialog_create",
        )}
        saveButtonId={
          isEditMode ? "update_free_position" : "create_free_position"
        }
        cancelLabel={t("cancel")}
        onCancel={toggleModal}
        onSave={trackFunction(saveFreeItem(freeItem), addFreePositionEvent)}
        disabled={mandatoryFieldsIncomplete(freeItem)}
      />
    </Modal>
  );
}
