import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Config } from "../../../config/config";
import { getEnvironmentVariables } from "../../../utilities/urlUtils";

const buildProductImageUrl = (productImageUri) =>
  `${getEnvironmentVariables().baseUrl}/teckentrup-server/api/catalog/media?id=${productImageUri}`;

const AnchorText = ({
  characteristicId,
  characteristicName,
  documentationUrl,
  productImageUri,
}) => (
  <tr key={characteristicName} data-characteristic-key={characteristicId}>
    <td>
      <a
        href={documentationUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-href={documentationUrl?.replace(/[.:?=/]/g, "-")}
      >
        {characteristicName}
      </a>
    </td>
    <td>
      <a href={documentationUrl} target="_blank" rel="noopener noreferrer">
        {productImageUri ? (
          <img
            alt="product"
            src={`${buildProductImageUrl(productImageUri)}${Config.ImageResolution.Medium}`}
            className="fileFormatIcon"
          />
        ) : (
          <FontAwesomeIcon className="fileFormatIcon" icon={faFile} />
        )}
      </a>
    </td>
  </tr>
);

export const Attribute = ({ displayAsLink, characteristic }) => (
  <>
    {displayAsLink ? (
      <AnchorText
        characteristicId={characteristic.characteristicId}
        characteristicName={characteristic.characteristicName}
        documentationUrl={characteristic.documentationUrl}
        productImageUri={characteristic.productImageUri}
      />
    ) : (
      <tr
        key={characteristic.characteristicName}
        data-characteristic-key={characteristic.characteristicId}
      >
        <td>{characteristic.characteristicName}</td>
        <td>{characteristic.documentationUrl}</td>
      </tr>
    )}
  </>
);
