import { AxiosPromise } from "axios";
import { map } from "ramda";

import {
  AddressFieldsWithTitle,
  LvHeaderData,
} from "../pages/offerManagement/lvModal/LvOfferTab";
import { FirmadesNutzers, Nutzer } from "../types/identity";
import { LvRow, LvRowDTO } from "../types/lvTable";
import { fetchPost } from "./fetch";

type ImportedFile = {
  html: string;
  tableContent: LvRowDTO[];
};

export const importLvFile = (formData: FormData) =>
  fetchPost<ImportedFile>("teckentrup-server/api/cart/quote/custom/_import")()(
    formData,
  );

const buildDealer = (company: FirmadesNutzers, user: Nutzer) => ({
  customerID: company.FirmenSAPKundennummer,
  userID: user.Email,
});

const buildAddress = ({
  email,
  street,
  houseNumber,
  city,
  ...rest
}: AddressFieldsWithTitle) => ({
  email,
  street,
  houseNumber,
  city,
  contactSalutation: rest.title,
  contactFirstName: rest.firstname,
  contactLastName: rest.surname,
  telephoneNumber: rest.phone,
  postCode: rest.zip,
});

const buildConfigurableProducts = (selectedPositions: LvRow[]) =>
  map(
    ({ product, quantity, position, reference }) => ({
      productTO: {
        articleId: product.value,
        amount: quantity.value,
        lvPosition: position.value,
        referenceNo: reference.value,
      },
      characteristics: [],
    }),
    selectedPositions,
  );

type ProductsInProcess = {
  cartOrderNumber: string;
};

export const createProductsInProcess = (
  selectedPositions: LvRow[],
  lvHeaderData: LvHeaderData,
  company: FirmadesNutzers,
  user: Nutzer,
): AxiosPromise<ProductsInProcess> => {
  const { buildProject, referenceNumber, comment, ...addressFields } =
    lvHeaderData;
  const body = {
    headerDataTO: {
      buildProject,
      referenceNumber,
      comment,
      dealer: buildDealer(company, user),
      address: buildAddress(addressFields),
      thirdPartySystemName: "extern_import_lv",
    },
    discreteProducts: [],
    configurableProducts: buildConfigurableProducts(selectedPositions),
  };
  return fetchPost<ProductsInProcess>(
    "teckentrup-server/api/request/_importrequest?validationResponse=true",
  )()(body);
};
