import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  Method,
  RawAxiosRequestHeaders,
} from "axios";

import { Config } from "../config/config";

const defaultOptions: Pick<AxiosRequestConfig, "withCredentials"> = {
  withCredentials: true,
};

const fetchData =
  (method: Method) =>
  <T = object, V = object>(path: string, absolute = false) =>
  (options?: Partial<AxiosRequestConfig>, headers?: RawAxiosRequestHeaders) =>
  (data?: V): AxiosPromise<T> =>
    axios({
      method,
      ...defaultOptions,
      ...options,
      url: absolute ? path : `${Config.Service.BaseUrl}/${path}`,
      ...(headers && { headers }),
      ...(data && { data }),
    });

export const fetchPost = fetchData("POST");
export const fetchGet = fetchData("GET");
export const fetchPut = fetchData("PUT");
export const fetchDelete = fetchData("DELETE");
export const fetchPatch = fetchData("PATCH");
