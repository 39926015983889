/* eslint-disable valid-jsdoc */
import { isNil } from "ramda";

import {
  CartActions,
  ExtendedTeckentrupCartTO,
} from "../../../../../../types/cart";
import { isCartEmptyOrHasUserUnsavedCart } from "../../../../../../utilities/cartUtils";

const TAXRATE_LOCALSTORAGE_KEY = "taxRate_offer";
const TAXRATE_DEFAULT_BACKEND = -1;
const TAXRATE_DEFAULT = 19;

export const isReopenedOffer = (cart: ExtendedTeckentrupCartTO) =>
  !isCartEmptyOrHasUserUnsavedCart(cart);

/**
 * Sets initial value to cart, if localstorage has existing taxrate.
 * Background and motivation: if an user redefines a taxrate in an offer, it gets saved to localstorage.
 * So if the localstorage contains said tax rate, we can safely assume,
 * that this tax rate is valid for his next offer/cart.
 */
const setInitialTaxRateFromLocalStorageIfNeeded = (
  setTaxRate: CartActions["setTaxRate"],
  localStorageTaxRate: number,
  cartTaxRate: number,
) => localStorageTaxRate !== cartTaxRate && setTaxRate(localStorageTaxRate);

/**
 * Defines initial tax rate value.
 * We divide in three cases:
 * - a completely new cart is opened -> use default tax rate from TAXRATE_DEFAULT constant.
 * - a new cart is opened after user changed taxrate in different cart -> use new tax rate from localstorage.
 * - an existing cart is reopened -> use tax rate that is saved to database.
 */
export const determineInitialTaxRate = (
  cart: ExtendedTeckentrupCartTO,
  setTaxRate: CartActions["setTaxRate"],
) => {
  const cartTaxRate = cart.taxRate;
  const localStorageTaxRate = localStorage.getItem(TAXRATE_LOCALSTORAGE_KEY);
  if (cartTaxRate === TAXRATE_DEFAULT_BACKEND) {
    const taxRate = isNil(localStorageTaxRate)
      ? TAXRATE_DEFAULT
      : Number(localStorageTaxRate);
    setTaxRate(taxRate);
    return taxRate;
  }
  if (isReopenedOffer(cart) || isNil(localStorageTaxRate)) {
    return cartTaxRate;
  }

  setInitialTaxRateFromLocalStorageIfNeeded(
    setTaxRate,
    Number(localStorageTaxRate),
    cartTaxRate,
  );
  return Number(localStorageTaxRate);
};

export const setTaxRateToCartAndLocalstorage = (
  setTaxRate: CartActions["setTaxRate"],
  value: number,
) => {
  setTaxRate(value);
  localStorage.setItem(TAXRATE_LOCALSTORAGE_KEY, value.toString());
};
