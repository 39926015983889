import { L10n } from "@encoway/l10n";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../components/modal/modal";
import { t } from "../shoppingCart/localizationUtils";

export const CustomerRemoveDialog = ({ onDelete, onClose }) => (
  <Modal>
    <ModalHeader label={L10n.format("delete_customer")} onCancel={onClose} />
    <ModalBodyWrapper label={L10n.format("delete_customer_dialog_message")} />
    <ModalFooter
      cancelLabel={t("delete_customer_dialog_cancel")}
      saveLabel={t("delete_customer")}
      onCancel={onClose}
      onSave={onDelete}
    />
  </Modal>
);
