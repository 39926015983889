import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { map, pathOr } from "ramda";
import { ReactNode, useEffect, useRef, useState } from "react";

import { L10n } from "@encoway/l10n";

import { cartViews } from "../pages/shoppingCart/hooks/useCartView";

import "./plainDropdown.scss";

type DropdownValue = {
  label: string;
  value: string;
};

type PlainDropdownProps = {
  value: string;
  values: Array<DropdownValue>;
  isSelected: boolean;
  filterId: string;

  onChange: (value: string) => void;
};
export function PlainDropdown(props: Readonly<PlainDropdownProps>) {
  const {
    value,
    values,
    isSelected,
    filterId,

    onChange,
  } = props;

  const [active, setActive] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleChange = (value: string): void => {
    onChange(value);
    setTimeout(() => setActive(false), 200);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (!wrapperRef?.current?.contains(event.target as Node)) {
      setTimeout(() => setActive(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const createDropdownItems = (values: Array<DropdownValue>): ReactNode =>
    map(
      (current: DropdownValue) => (
        <div
          key={current.label}
          data-filter-entry-id={pathOr(
            "",
            ["view", current.value, "l10n"],
            cartViews,
          )}
          className={classnames({ "dropdown-item": true })}
          onClick={() => handleChange(current.value)}
        >
          <article className="media">
            <div className="media-content">
              <p className="is-headline">{L10n.format(current.label)}</p>
            </div>
          </article>
        </div>
      ),
      values,
    );

  return (
    <div
      ref={wrapperRef}
      className={classnames("dropdown shadow", { "is-active": active })}
    >
      <div className="dropdown-tigger">
        <button
          data-filter-id={filterId}
          className={classnames("button rounded", {
            "is-selected": isSelected,
          })}
          onClick={() => setActive((prev) => !prev)}
        >
          <span>{value}</span>
          <span className="icon is-small">
            <FontAwesomeIcon
              icon={faAngleDown}
              size="xs"
              style={{ color: "#ef7b22" }}
            />
          </span>
        </button>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">{createDropdownItems(values)}</div>
        </div>
      </div>
    </div>
  );
}
