import {
  all,
  equals,
  flatten,
  isEmpty,
  length,
  map,
  or,
  path,
  pathOr,
  reject,
  zipWith,
} from "ramda";

import {
  cartBelongsToCurrentUser,
  isAuthorityRole,
} from "../../../../http/identityHelper";
import IdentityStore from "../../../../http/identityStore";
import {
  ExtendedTeckentrupCartTO,
  HeaderData,
  TeckentrupCartArticleTO,
} from "../../../../types/cart";
import { UiAuthorities } from "../../../../types/identity";
import {
  configurationIncompleteOrHasNoBruttoEpIncTzPrice,
  getAllPartOrderFlaggedItems,
  hasPricing,
  hasTeoReadyStateUnspecified,
} from "../../../../utilities/cartUtils";

export const CONFIGURATION_COMPLETE = "READY";
export const TEO_READY_STATE_UNSPECIFIED = "UNSPECIFIED";
const CONFIGURATION_INCOMPLETE = "CONFIGURATION_INCOMPLETE";
const PRICING_STATUS_NO_PRICE = "NO_PRICE_FOUND";
const CART_ITEM_TYPE_SPAREPART = "SPAREPART";

const InitialOrderState = {
  buyable: false,
  dbCheckable: false,
  exportable: false,
  isPartiallyBuyable: false,
};

const deepCartItems =
  (optional = false, freeArticles = false) =>
  (cartItem: TeckentrupCartArticleTO): TeckentrupCartArticleTO[] => {
    if (cartItem.group) {
      if (isEmpty(cartItem.subArticles)) {
        return [];
      }
      return reject<TeckentrupCartArticleTO, TeckentrupCartArticleTO[]>(
        (subArticle) =>
          isEmpty(deepCartItems(optional, freeArticles)(subArticle)),
        cartItem.subArticles,
      );
    }
    if (freeArticles) {
      return [cartItem];
    }
    if (optional) {
      return !cartItem.freeArticle ? [cartItem] : [];
    }
    return !cartItem.optional && !cartItem.freeArticle ? [cartItem] : [];
  };

const configuredItems = (cartItem: TeckentrupCartArticleTO) => {
  const {
    configurable,
    pricingStatus,
    teoReadyState,
    bruttoEpIncTz = {},
  } = cartItem;
  return (
    configurable &&
    pathOr(0, ["price"], bruttoEpIncTz) > 0 &&
    equals(teoReadyState, CONFIGURATION_COMPLETE) &&
    !equals(pricingStatus, CONFIGURATION_INCOMPLETE) &&
    !cartItem.optional
  );
};

const discreteItems = (cartItem: TeckentrupCartArticleTO) => {
  const { specialArticleType, teoReadyState, bruttoEpIncTz = {} } = cartItem;
  return (
    pathOr(0, ["price"], bruttoEpIncTz) > 0 &&
    !equals(teoReadyState, TEO_READY_STATE_UNSPECIFIED) &&
    equals(specialArticleType, CART_ITEM_TYPE_SPAREPART) &&
    !cartItem.optional
  );
};

const configuredAndUnspecifiedItems = (cartItem: TeckentrupCartArticleTO) => {
  const {
    configurable,
    pricingStatus,
    teoReadyState,
    bruttoEpIncTz = {},
  } = cartItem;
  return (
    configurable &&
    pathOr(0, ["price"], bruttoEpIncTz) > 0 &&
    (equals(teoReadyState, CONFIGURATION_COMPLETE) ||
      equals(teoReadyState, TEO_READY_STATE_UNSPECIFIED)) &&
    !equals(pricingStatus, CONFIGURATION_INCOMPLETE) &&
    !cartItem.optional
  );
};

const discreteAndUnspecifiedItems = (cartItem: TeckentrupCartArticleTO) => {
  const { specialArticleType, bruttoEpIncTz = {} } = cartItem;
  return (
    pathOr(0, ["price"], bruttoEpIncTz) > 0 &&
    equals(specialArticleType, CART_ITEM_TYPE_SPAREPART) &&
    !cartItem.optional
  );
};

const exportableConfigurableItems = (cartItem: TeckentrupCartArticleTO) => {
  const { teoReadyState, pricingStatus = "", bruttoEpIncTz = {} } = cartItem;
  return (
    pathOr(0, ["price"], bruttoEpIncTz) > 0 &&
    !equals(pricingStatus, PRICING_STATUS_NO_PRICE) &&
    !equals(pricingStatus, CONFIGURATION_INCOMPLETE) &&
    (equals(teoReadyState, CONFIGURATION_COMPLETE) ||
      equals(teoReadyState, TEO_READY_STATE_UNSPECIFIED))
  );
};

const exportableDiscreteItems = (cartItem: TeckentrupCartArticleTO) => {
  const {
    specialArticleType,
    pricingStatus = "",
    bruttoEpIncTz = {},
  } = cartItem;
  return (
    pathOr(0, ["price"], bruttoEpIncTz) > 0 &&
    !equals(pricingStatus, PRICING_STATUS_NO_PRICE) &&
    equals(specialArticleType, CART_ITEM_TYPE_SPAREPART)
  );
};

const cartOrderStateForReadOnlyCart = (
  headerData: HeaderData,
  identityStore: IdentityStore,
  authorities: UiAuthorities,
  dbCheckable: boolean,
  exportable: boolean,
) => {
  if (
    isAuthorityRole(
      ["ADMIN", "INNENDIENST", "AUSSENDIENST"],
      identityStore,
      authorities,
    )
  ) {
    return { ...InitialOrderState, dbCheckable, exportable };
  }

  if (cartBelongsToCurrentUser(identityStore, headerData)) {
    return { ...InitialOrderState, exportable };
  }

  return InitialOrderState;
};

const filterValidCartItems = (
  items: TeckentrupCartArticleTO[],
  predicate: ReturnType<typeof deepCartItems>,
): TeckentrupCartArticleTO[] => flatten(map(predicate, items));

export const cartOrderState = (
  cart: ExtendedTeckentrupCartTO,
  identityStore: IdentityStore,
  authorities: UiAuthorities,
) => {
  const cartItems = filterValidCartItems(
    cart.articles.subArticles,
    deepCartItems(),
  );
  const cartItemsWithOptionals = filterValidCartItems(
    cart.articles.subArticles,
    deepCartItems(true),
  );
  const allCartItemsAreOptional = all(
    (item) => item.optional,
    cartItemsWithOptionals,
  );
  const hasQuestionMark = cartItemsWithOptionals.some((cartItem) =>
    hasTeoReadyStateUnspecified(cartItem),
  );

  let buyable =
    !allCartItemsAreOptional &&
    !hasQuestionMark &&
    all<boolean>((item) => item)(
      zipWith<boolean, boolean, boolean>(
        or,
        map(discreteItems, cartItems),
        map(configuredItems, cartItems),
      ),
    );

  let dbCheckable =
    !allCartItemsAreOptional &&
    all<boolean>((item) => item)(
      zipWith<boolean, boolean, boolean>(
        or,
        map(discreteAndUnspecifiedItems, cartItems),
        map(configuredAndUnspecifiedItems, cartItems),
      ),
    );

  let exportable = all<boolean>((item) => item)(
    zipWith<boolean, boolean, boolean>(
      or,
      map(exportableConfigurableItems, cartItemsWithOptionals),
      map(exportableDiscreteItems, cartItemsWithOptionals),
    ),
  );

  if (isEmpty(cartItems) && isEmpty(cartItemsWithOptionals)) {
    const cartItemsWithOptionalsAndFreeArticles = filterValidCartItems(
      cart.articles.subArticles,
      deepCartItems(true, true),
    );
    exportable =
      Array.isArray(cartItemsWithOptionalsAndFreeArticles) &&
      cartItemsWithOptionalsAndFreeArticles.length > 0 &&
      cartItemsWithOptionalsAndFreeArticles.every(
        (item) => item.freeArticle && !item.group,
      );
    dbCheckable = false;
    buyable = false;
  }

  const isPartiallyBuyable =
    length(getAllPartOrderFlaggedItems(cart.articles)) > 0;
  const cartItemsWithWarning = cartItemsWithOptionals.filter((cartItem) =>
    configurationIncompleteOrHasNoBruttoEpIncTzPrice(cartItem),
  );
  const allCarItemsWithWarningHavePriceOnRequest = cartItemsWithWarning.every(
    (cartItem) => !hasPricing(cartItem.pricingStatus),
  );

  if (!allCarItemsWithWarningHavePriceOnRequest) {
    return { ...InitialOrderState, isPartiallyBuyable };
  }

  const hasPriceOnRequest = cartItemsWithWarning.some(
    (cartItem) => !hasPricing(cartItem.pricingStatus),
  );
  if (hasPriceOnRequest) {
    exportable = false;
  }

  if (isEmpty(path(["articles", "subArticles"], cart))) {
    return InitialOrderState;
  }

  if (cart.readOnly) {
    return cartOrderStateForReadOnlyCart(
      cart.headerData,
      identityStore,
      authorities,
      dbCheckable,
      exportable,
    );
  }

  return { buyable, dbCheckable, exportable, isPartiallyBuyable };
};
