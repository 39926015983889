import { AxiosError } from "axios";
import { isNil, range } from "ramda";
import { useContext, useEffect, useReducer, useState } from "react";

import { TablePagination } from "../../components/flexTable/tablePagination";
import { Loading } from "../../components/loading";
import { CartContext } from "../../hooks/useCart";
import { IdentityContext } from "../../hooks/useIdentity";
import { createEmptyQuote } from "../../service/offerManagementService";
import { PartialBy } from "../../types/app";
import { t } from "../shoppingCart/localizationUtils";
import { CartWarningWrapper } from "./cartWarningWrapper";
import { UploadLvModal } from "./lvModal/uploadLvModal";
import { ModifyOfferModal } from "./modifyOfferModal/modifyOfferModal";
import {
  ModifyOfferDialogReducedState,
  prepareInformationDTO,
} from "./modifyOfferModal/modifyOfferModalActions";
import {
  ChangePage,
  GetQuotes,
  InitialOfferManagementStore,
  preparePayload,
} from "./offerManagementActions";
import { OfferManagementHeader } from "./offerManagementHeader";
import { OfferManagementTable } from "./offerManagementTable/offerManagementTable";
import { InitialOfferManagementTableState } from "./offerManagementTable/offerManagementTableActions";
import {
  OfferManagementTableRow,
  determineLoadFunction,
} from "./offerManagementTable/offerManagementTableUtils";
import { OfferManagementStore } from "./offerManagementUtils";
import { useOfferManagementDispatcherActions } from "./useOfferManagementDispatcherActions";

import "./offerManagement.scss";

export const INITIAL_DIALOG_STATE = {
  modifyOfferDialogVisible: false,
  uploadLvVisible: false,
  cartAlreadyLoadedWarningVisible: false,
  cartAlreadyOrderedWarningVisible: false,
  cartIsSharedWarningVisible: false,
  offerNotFoundDialogVisible: false,
  offerLoadErrorDialogVisible: false,
  showExistingCartWarning: false,
  loading: true,
  loadingQuotes: true,
  item: InitialOfferManagementTableState,
};

export type OfferManagementDialogState = PartialBy<
  typeof INITIAL_DIALOG_STATE,
  "item"
> & {
  item: OfferManagementTableRow;
};

const reducer = (
  store: OfferManagementStore,
  action: (store: OfferManagementStore) => OfferManagementStore,
) => action(store);

export const OfferManagement = () => {
  const { identityStore, companyId } = useContext(IdentityContext);
  const { cart } = useContext(CartContext);
  const [dialogState, setDialogState] = useState(INITIAL_DIALOG_STATE);
  const [store, dispatch] = useReducer(reducer, InitialOfferManagementStore);

  useEffect(() => {
    setDialogState((prev) => ({ ...prev, loadingQuotes: true }));
    GetQuotes(
      preparePayload(store, store.showOnlyUserOffers, store.searchQuery),
    )
      .then((result) => dispatch(result))
      .catch((error) => error);
    setDialogState((prev) => ({
      ...prev,
      loading: false,
      loadingQuotes: false,
    }));
  }, [
    store.currentPage,
    store.currentPageSize,
    store.sortKey,
    store.sortDirection,
    store.filter,
    store.showOnlyUserOffers,
    store.filterArchived,
    store.searchQuery,
    store.showOnlyThirdPartySystemOffers,
  ]);

  const toggleOffAllDialogs = ({
    item,
    loading,
  }: OfferManagementDialogState) => ({
    ...INITIAL_DIALOG_STATE,
    item,
    loading,
  });
  const onQuoteAlreadyLoadedByOtherUser = () => {
    setDialogState({
      ...toggleOffAllDialogs(dialogState),
      cartAlreadyLoadedWarningVisible: true,
    });
  };

  const onQuoteNotFound = () => {
    setDialogState({
      ...toggleOffAllDialogs(dialogState),
      offerNotFoundDialogVisible: true,
    });
  };

  const onQuoteLoadError = () => {
    setDialogState({ ...dialogState, offerLoadErrorDialogVisible: true });
  };

  const handleQuoteError = (e: unknown) => {
    const { response } = e as AxiosError;

    switch (response?.status) {
      case 404:
        onQuoteNotFound();
        break;
      case 500:
        onQuoteLoadError();
        break;
      case 403:
        onQuoteAlreadyLoadedByOtherUser();
        break;
      default:
        throw e;
    }
  };

  const dispatcherActions = {
    ...useOfferManagementDispatcherActions(
      handleQuoteError,
      dialogState,
      setDialogState,
      dispatch,
      store,
    ),
    handleQuoteError,
  };

  const onChangePage = (currentPage: number) =>
    dispatch(ChangePage({ currentPage }));

  const onCreateAndSaveNewQuote = async (
    checkoutInformation: ModifyOfferDialogReducedState,
  ) => {
    const {
      data: { cart: emptyCart },
    } = await createEmptyQuote();
    await dispatcherActions.onSetHeader(
      emptyCart.id,
      prepareInformationDTO(checkoutInformation),
    );
    setDialogState({ ...dialogState, modifyOfferDialogVisible: false });
  };

  if (isNil(cart?.authorities)) {
    return (
      <>
        <h2 className="h2">{t("offer_management_overview")}</h2>
        <Loading />
      </>
    );
  }

  return (
    <div className="offer-management" data-page-id="offer-management">
      <h2 className="h2">{t("offer_management_overview")}</h2>
      <OfferManagementHeader
        offerManagementState={dialogState}
        offerManagementSetState={setDialogState}
        offerManagementStore={store}
        dispatcherActions={dispatcherActions}
        cart={cart}
      />
      {dialogState.loadingQuotes ? (
        <Loading />
      ) : (
        <>
          <OfferManagementTable
            offerManagementState={dialogState}
            offerManagementSetState={setDialogState}
            offerManagementStore={store}
            dispatcherActions={dispatcherActions}
          />
          <TablePagination
            currentIndex={store.currentPage}
            items={range(0, store.pageCount)}
            onClick={onChangePage}
            isPaginationWithDots={true}
          />
        </>
      )}

      {dialogState.offerNotFoundDialogVisible && (
        <CartWarningWrapper
          headerLabel={t("offer_management_quote_not_found_header")}
          bodyLabel={t("offer_management_quote_not_found_warning")}
          footerCancelLabel={t("close")}
          onCancel={() =>
            setDialogState({
              ...dialogState,
              offerNotFoundDialogVisible: false,
            })
          }
        />
      )}

      {dialogState.modifyOfferDialogVisible && (
        <ModifyOfferModal
          closeDialogMethod={() =>
            setDialogState({ ...dialogState, modifyOfferDialogVisible: false })
          }
          saveQuote={onCreateAndSaveNewQuote}
          companyId={companyId}
        />
      )}

      {dialogState.uploadLvVisible && (
        <UploadLvModal
          onCancel={() =>
            setDialogState({ ...dialogState, uploadLvVisible: false })
          }
        />
      )}

      {dialogState.cartAlreadyLoadedWarningVisible && (
        <CartWarningWrapper
          headerLabel={t("offer_management_quote_already_loaded_header")}
          bodyLabel={t("offer_management_quote_already_loaded_warning")}
          footerCancelLabel={t("close")}
          onCancel={() =>
            setDialogState({
              ...dialogState,
              cartAlreadyLoadedWarningVisible: false,
              loadingQuotes: false,
            })
          }
        />
      )}

      {dialogState.cartAlreadyOrderedWarningVisible &&
        !dialogState.showExistingCartWarning && (
          <CartWarningWrapper
            headerLabel={t("offer_management_quote_already_ordered_header")}
            bodyLabel={t("offer_management_quote_already_ordered_warning")}
            footerSaveLabel={t("ok")}
            onSave={() =>
              determineLoadFunction(
                dialogState.item,
                identityStore,
                cart.authorities,
                dispatcherActions,
              )
            }
            onCancel={() =>
              setDialogState({
                ...dialogState,
                cartAlreadyOrderedWarningVisible: false,
              })
            }
            isLoading={dispatcherActions.isLoadingQuote}
            disabled={dispatcherActions.isLoadingQuote}
          />
        )}

      {dialogState.cartIsSharedWarningVisible && (
        <CartWarningWrapper
          headerLabel={t("offer_management_quote_shared_header")}
          bodyLabel={t("offer_management_quote_shared_warning")}
          footerSaveLabel={t("ok")}
          onSave={() =>
            determineLoadFunction(
              dialogState.item,
              identityStore,
              cart.authorities,
              dispatcherActions,
            )
          }
          onCancel={() =>
            setDialogState({
              ...dialogState,
              cartIsSharedWarningVisible: false,
            })
          }
          isLoading={dispatcherActions.isLoadingQuote}
          disabled={dispatcherActions.isLoadingQuote}
        />
      )}

      {dialogState.showExistingCartWarning &&
        !dialogState.cartAlreadyOrderedWarningVisible && (
          <CartWarningWrapper
            headerLabel={t("offer_management_unsaved_changes_header")}
            bodyLabel={t("cart_create_new_quote_warn")}
            footerCancelLabel={t("cancel")}
            onCancel={() =>
              setDialogState({ ...dialogState, showExistingCartWarning: false })
            }
            footerSaveLabel={t("offer_management_unsaved_changes_ok")}
            onSave={() =>
              determineLoadFunction(
                dialogState.item,
                identityStore,
                cart.authorities,
                dispatcherActions,
              )
            }
            saveIcon={
              <i className="image-icon warenkorb-leeren" aria-hidden="true" />
            }
            isLoading={dispatcherActions.isLoadingQuote}
            disabled={dispatcherActions.isLoadingQuote}
          />
        )}

      {dialogState.showExistingCartWarning &&
        dialogState.cartAlreadyOrderedWarningVisible && (
          <CartWarningWrapper
            headerLabel={t("offer_management_unsaved_changes_header")}
            bodyLabel={t("cart_create_new_quote_warn")}
            footerCancelLabel={t("cancel")}
            onSave={() =>
              setDialogState({ ...dialogState, showExistingCartWarning: false })
            }
            footerSaveLabel={t("offer_management_unsaved_changes_ok")}
            onCancel={() =>
              setDialogState({
                ...dialogState,
                cartAlreadyOrderedWarningVisible: false,
                showExistingCartWarning: false,
              })
            }
            isLoading={dispatcherActions.isLoadingQuote}
            disabled={dispatcherActions.isLoadingQuote}
          />
        )}

      {dialogState.offerLoadErrorDialogVisible && (
        <CartWarningWrapper
          headerLabel={t("offer_management_general_quote_load_error_header")}
          bodyLabel={t("offer_management_general_quote_load_error_message")}
          footerCancelLabel={t("ok")}
          onCancel={() =>
            setDialogState({
              ...dialogState,
              offerLoadErrorDialogVisible: false,
            })
          }
        />
      )}
    </div>
  );
};
