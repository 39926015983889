import { find, length, remove, update, append } from "ramda";

import { isComplete } from "../customerManagementUtils";

export const InitialCustomerDialogState = {
  customerNumber: "",
  initialCustomerNumber: null,
  customerName: "",
  quoteAddressOpen: -1, // -1 means closed otherwise it's used as index
  quoteAddressEditOpen: -1, // same here
  quoteAddressDeleteModalToggle: false,
  modalCancelToggle: false,
  isComplete: false,
  customerNumberExists: false,
  customerNumberTimeout: null,
  companyContacts: [],
  edited: false,
};

export const InitialAddQuoteToCustomerState = {
  title: "",
  firstname: "",
  surname: "",
  street: "",
  houseNumber: "",
  zip: "",
  city: "",
  phone: "",
  email: "",
};

// Reducer Actions
export const CUSTOMER_MANAGEMENT_ACTIONS = {
  MODAL_ON_CHANGE_LOADING_STATE: "MODAL_ON_CHANGE_LOADING_STATE",
  MODAL_ON_INPUT_CHANGE: "MODAL_ON_INPUT_CHANGE",
  MODAL_ON_CANCEL_OPEN: "MODAL_ON_CANCEL_OPEN",
  MODAL_ON_CANCEL_CLOSE: "MODAL_ON_CANCEL_CLOSE",
  QUOTE_ADDRESS_TOGGLE: "QUOTE_ADDRESS_TOGGLE",
  QUOTE_ADDRESS_ADD: "QUOTE_ADDRESS_ADD",
  QUOTE_ADDRESS_DELETE: "QUOTE_ADDRESS_DELETE",
  QUOTE_ADDRESS_EDIT: "QUOTE_ADDRESS_EDIT",
  QUOTE_ADDRESS_INPUT_CHANGE: "QUOTE_ADDRESS_INPUT_CHANGE",
  QUOTE_ADDRESS_CLOSE: "QUOTE_ADDRESS_CLOSE",
  QUOTE_ADDRESS_DELETE_MODAL_OPEN: "QUOTE_ADDRESS_DELETE_MODAL_OPEN",
  QUOTE_ADDRESS_DELETE_MODAL_CLOSE: "QUOTE_ADDRESS_DELETE_MODAL_CLOSE",
  MODAL_CHECK_IF_CUSTOMER_NUMBER_EXISTS:
    "MODAL_CHECK_IF_CUSTOMER_NUMBER_EXISTS",
};

// Dispatcher
const CustomerModalSetIsLoading =
  (state) =>
  ({ isLoading }) => ({ ...state, isLoading });

const CustomerModalInputChange =
  (state) =>
  ({ name, value }) => {
    const newState = { ...state, [name]: value };
    return { ...newState, edited: true, isComplete: isComplete(newState) };
  };

const CustomerModalCancelModalOpen = (state) => () => ({
  ...state,
  modalCancelToggle: true,
});

const CustomerModalCancelModalClose = (state) => () => ({
  ...state,
  modalCancelToggle: false,
});

const CustomerModalCheckIfCustomerNumberExists =
  (state) =>
  ({ customerNumberExists }) => {
    const newState = { ...state, customerNumberExists };
    return { ...newState, isComplete: isComplete(newState) };
  };

const QuoteAddressAdd = (state) => () => ({
  ...state,
  companyContacts: append(
    InitialAddQuoteToCustomerState,
    state.companyContacts,
  ),
  quoteAddressOpen: length(state.companyContacts), // sets Address to new QuoteAddress
  quoteAddressEditOpen: -1,
});

const QuoteAddressEdit =
  (state) =>
  ({ id }) => ({ ...state, quoteAddressEditOpen: id, quoteAddressOpen: -1 });

const QuoteAddressDelete =
  (state) =>
  ({ id }) => ({
    ...state,
    companyContacts: remove(id, 1, state.companyContacts),
    quoteAddressOpen: -1,
    quoteAddressEditOpen: -1,
    quoteAddressDeleteModalToggle: false,
    edited: true,
  });

const QuoteAddressInputChange =
  (state) =>
  ({ name, value, id }) => ({
    ...state,
    edited: true,
    companyContacts: update(
      id,
      {
        ...state.companyContacts[id],
        [name]: value,
      },
      state.companyContacts,
    ),
  });

const QuoteAddressClose = (state) => () => ({
  ...state,
  quoteAddressEditOpen: -1,
  quoteAddressOpen: -1,
});

const QuoteAddressDeleteModalOpen = (state) => () => ({
  ...state,
  quoteAddressDeleteModalToggle: true,
});

const QuoteAddressDeleteModalClose = (state) => () => ({
  ...state,
  quoteAddressDeleteModalToggle: false,
});

export const CustomerDialogActions = (state, action) => {
  const actionTypes = [
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.MODAL_ON_INPUT_CHANGE,
      fn: CustomerModalInputChange(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.MODAL_ON_CHANGE_LOADING_STATE,
      fn: CustomerModalSetIsLoading(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.MODAL_ON_CANCEL_OPEN,
      fn: CustomerModalCancelModalOpen(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.MODAL_ON_CANCEL_CLOSE,
      fn: CustomerModalCancelModalClose(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.MODAL_CHECK_IF_CUSTOMER_NUMBER_EXISTS,
      fn: CustomerModalCheckIfCustomerNumberExists(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_ADD,
      fn: QuoteAddressAdd(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_EDIT,
      fn: QuoteAddressEdit(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_DELETE,
      fn: QuoteAddressDelete(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_INPUT_CHANGE,
      fn: QuoteAddressInputChange(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_CLOSE,
      fn: QuoteAddressClose(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_DELETE_MODAL_OPEN,
      fn: QuoteAddressDeleteModalOpen(state),
    },
    {
      type: CUSTOMER_MANAGEMENT_ACTIONS.QUOTE_ADDRESS_DELETE_MODAL_CLOSE,
      fn: QuoteAddressDeleteModalClose(state),
    },
  ];
  return find(({ type }) => action.type === type, actionTypes).fn(action);
};
