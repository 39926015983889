import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useRef, useState } from "react";

import { L10n } from "@encoway/l10n";
import { Constants, Icon } from "@encoway/react-components";
import { specState } from "@encoway/rest-api";

import { AppContext } from "../../../hooks/useApp";
import { ConfigurationContext } from "../../../hooks/useConfiguration";
import {
  setConfigurationParameter,
  undoConfigurationParameter,
} from "../../../service/configurationService";
import { ConfigurationUndoModal } from "../configurationUndoModal";

const defaultStyle = {
  undoIcon: "fa-times",
  defaultIcon: "fa-television",
  userIcon: "fa-user",
  systemIcon: "fa-cog",
  unspecifiedIcon: "fragezeichen",
};

export default function ParameterState(props) {
  const { configurationId } = useContext(ConfigurationContext);
  const { language } = useContext(AppContext);
  const prevValueRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const parameterStyle = { ...defaultStyle, ...props.style };

  const isUndoable =
    props.style.hasUndo &&
    props.data.undoable &&
    props.data.editable &&
    specState(props.data) === "SET_BY_USER";

  const IconMap = {
    SET_BY_USER: (
      <FontAwesomeIcon
        icon={faUser}
        style={{ color: "#ef7b22" }}
        data-icon-id={`SET_BY_USER-${props.data.id}`}
      />
    ),
    SET_BY_DEFAULT: (
      <Icon
        src={parameterStyle.defaultIcon}
        mediaLink={props.mediaLink}
        title={L10n.format("SET_BY_DEFAULT")}
        iconId={`SET_BY_DEFAULT-${props.data.id}`}
      />
    ),
    SET_BY_SYSTEM: (
      <Icon
        src={parameterStyle.systemIcon}
        mediaLink={props.mediaLink}
        title={L10n.format("SET_BY_SYSTEM")}
        iconId={`SET_BY_SYSTEM-${props.data.id}`}
      />
    ),
    SET_BY_EXTERNAL: (
      <Icon
        src={parameterStyle.externalIcon}
        mediaLink={props.mediaLink}
        title={L10n.format("SET_BY_EXTERNAL")}
        iconId={`SET_BY_EXTERNAL-${props.data.id}`}
      />
    ),
  };

  async function onValueUndo() {
    props.setLoading(true);
    prevValueRef.current = props.data.selectedValues[0];
    // manual undo to get the response
    const { data: undoData } = await undoConfigurationParameter(
      configurationId,
      props.data.id,
      language,
    );
    if (undoData.status === "CONFLICTED") {
      props.setLoading(false);
      return setShowModal(true);
    }
    // reset the previous value so that the correct undo can be performed afterwards
    await setConfigurationParameter(
      configurationId,
      props.data.id,
      language,
      prevValueRef.current,
      props.data.viewPort,
    );
    props.onValueChanged(props.data, Constants.Undo);
    props.setLoading(false);
  }

  return (
    <span className="is-pulled-right">
      {isUndoable && (
        <a className="is-undo" onClick={onValueUndo}>
          <FontAwesomeIcon
            icon={faTimes}
            title={L10n.format("undo")}
            data-icon-id={`UNDO-${props.data.name}`}
            style={{ color: "#ef7b22" }}
          />
        </a>
      )}
      {parameterStyle.showSpecState !== false && (
        <span className="icon is-small is-spec-state is-pulled-right">
          {props.data.properties.TT_READY_STATE === "UNSPECIFIED" ? (
            <Icon
              src={parameterStyle.unspecifiedIcon}
              mediaLink={props.mediaLink}
              title={L10n.format("TO_BE_DETERMINED")}
              iconId={`TO_BE_DETERMINED-${props.data.name}`}
            />
          ) : (
            IconMap[specState(props.data)]
          )}
        </span>
      )}
      {showModal && (
        <ConfigurationUndoModal
          conflictedValue={prevValueRef.current.translatedValue}
          conflictedCharacteristic={props.data.translatedName}
          onCancel={() => setShowModal(false)}
        />
      )}
    </span>
  );
}
