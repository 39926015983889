import { isNil } from "ramda";

import { CartArticleTO, CartTO } from "../../../../types/cart";
import { DragCartArticleTO } from "../useCartDragAndDrop";

/**
 * Checks if the targetItem is a groupItem or not and returns the right articleId to handle drag and drop via rest-call
 * @param {CartTO} cart the cart object
 * @param {CartArticleTO} targetItem the item to check with
 * @returns {string} parentId
 */
export const determineParentId = (
  cart: CartTO,
  targetItem: CartArticleTO,
): string =>
  targetItem.group ? targetItem.articleId : cart.articles.articleId;

/**
 * Checks if the targetItem is a groupItem or not and returns the right index to handle drag and drop via rest-call
 * @param {CartArticleTO} targetItem the item to check with
 * @returns {number} parentIndex
 */
export const determineParentIndex = (targetItem: CartArticleTO): number =>
  targetItem.group ? 0 : targetItem.index;

export const isBothGroup = (
  draggedItem: CartArticleTO | null,
  targetItem: DragCartArticleTO,
  rootId: string,
): boolean =>
  !isNil(draggedItem) &&
  draggedItem.group &&
  (targetItem?.group || targetItem?.parentId !== rootId);
