import { AxiosPromise } from "axios";
import { filter } from "ramda";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import {
  archiveQuote,
  deleteQuote,
  duplicateQuote,
  ExtendedCartResultTO,
  getQuotes,
  getSharedQuote,
  loadQuote,
  loadQuoteReadOnly,
  QuoteRequestContext,
} from "../../service/offerManagementService";
import { CartActions } from "../../types/cart";
import { OfferManagementDialogState } from "./offerManagement";
import { OfferManagementStore } from "./offerManagementUtils";

export const InitialOfferManagementStore: OfferManagementStore = {
  quotes: [],
  currentPage: 0,
  currentPageSize: 10,
  pageCount: 0,
  sortKey: "_cartCreationDate",
  sortType: "DATE",
  sortDirection: "DESC",
  conjunction: "AND",
  filter: { key: "", operator: "", value: "__undo__" },
  showOnlyUserOffers: true,
  showOnlyThirdPartySystemOffers: false,
  filterArchived: "ACTIVE",
  searchQuery: "",
};

export type ChangeSortProps = {
  sortKey: string;
  sortDirection: string;
  sortType?: string;
};
export type ChangeFilterProps = {
  name: string;
  operator: string;
  value: string;
};

const prepareFilter = (
  isFilterValueNotUndo: boolean,
  offerManagementStore: OfferManagementStore,
) => {
  const valueFilter = isFilterValueNotUndo
    ? {
        key: offerManagementStore.filter.key,
        operator: offerManagementStore.filter.operator,
        value: offerManagementStore.filter.value,
      }
    : undefined;

  return filter((value) => value !== undefined, [valueFilter]);
};

export const preparePayload = (
  offerManagementStore: OfferManagementStore,
  showOnlyUserOffers: boolean,
  searchQuery: string,
) => {
  const isFilterValueNotUndo = offerManagementStore.filter.value !== "__undo__";
  const sort = {
    by: offerManagementStore.sortKey,
    order: offerManagementStore.sortDirection,
    type: offerManagementStore.sortType,
  };
  const paging = {
    page: offerManagementStore.currentPage,
    pageSize: offerManagementStore.currentPageSize,
  };
  const offerFilter = {
    by: prepareFilter(isFilterValueNotUndo, offerManagementStore),
    conjunction: offerManagementStore.conjunction,
  };
  const search = {
    term: searchQuery,
    in: [
      "_headerData.buildProject",
      "_headerData.address.companyName",
      "_headerData.referenceNumber",
      "_headerData.createdBy",
      "_headerData.lastModifiedBy",
      "_quoteOrderNumber",
    ],
  };

  return {
    quoteRequest: {
      source: offerManagementStore.filterArchived,
      sort,
      paging,
      ...((showOnlyUserOffers || isFilterValueNotUndo) && {
        filter: offerFilter,
      }),
      ...(searchQuery && { search }),
    },
    seeAllQuotes: !showOnlyUserOffers,
  };
};

export const ToggleShowOnlyThirdPartySystemOffers =
  () => (state: OfferManagementStore) => ({
    ...state,
    ...(!state.showOnlyThirdPartySystemOffers
      ? {
          filter: {
            key: "_headerData.isRequest",
            operator: "=",
            value: "true",
          },
        }
      : {
          filter: {
            key: "",
            operator: "",
            value: "__undo__",
          },
        }),
    currentPage: 0,
    showOnlyThirdPartySystemOffers: !state.showOnlyThirdPartySystemOffers,
  });

export const ToggleShowOnlyUserOffers =
  () => (state: OfferManagementStore) => ({
    ...state,
    currentPage: 0,
    showOnlyUserOffers: !state.showOnlyUserOffers,
  });

export const SetFilterArchived =
  (target: ChangeEvent<HTMLSelectElement>["target"]) =>
  (state: OfferManagementStore) => ({
    ...state,
    currentPage: 0,
    filterArchived: target.value,
  });

export const OfferSearch =
  (target: EventTarget & HTMLInputElement) =>
  (state: OfferManagementStore) => ({
    ...state,
    currentPage: 0,
    searchQuery: target.value,
  });

export const GetQuotes = async (data: QuoteRequestContext) => {
  const {
    status,
    data: { metaInfo, quotes },
  } = await getQuotes(data);

  return (state: OfferManagementStore) => ({
    ...state,
    status,
    quotes: quotes,
    pageCount: metaInfo.pageCount,
  });
};

export const ChangeSort =
  ({ sortKey, sortDirection, sortType }: ChangeSortProps) =>
  (state: OfferManagementStore) => ({
    ...state,
    sortKey: sortKey,
    sortDirection: sortDirection,
    sortType: sortType,
  });

export const ChangePage =
  ({ currentPage }: { currentPage: number }) =>
  (state: OfferManagementStore) => ({
    ...state,
    currentPage: currentPage,
  });

export const ChangeFilter =
  ({ name, operator, value }: ChangeFilterProps) =>
  (state: OfferManagementStore) => ({
    ...state,
    currentPage: 0,
    filter: {
      key: name,
      operator: operator,
      value: value,
    },
  });

export const DuplicateQuote = async (id: string) => {
  const { status } = await duplicateQuote(id);
  return (state: OfferManagementStore) => ({
    ...state,
    status,
    currentPage: 0,
  });
};

export const ArchiveQuote = async (id: string, archived: boolean) => {
  const { status } = await archiveQuote(id, archived);
  return (state: OfferManagementStore) => ({
    ...state,
    status,
  });
};

export const DeleteQuote = async (id: string) => {
  const { status } = await deleteQuote(id);
  return (state: OfferManagementStore) => ({
    ...state,
    status,
  });
};
const loadTheQuote = async (
  cartActions: CartActions,
  offerManagementState: OfferManagementDialogState,
  offerManagementSetState: Dispatch<SetStateAction<OfferManagementDialogState>>,
  loadFunction: () => AxiosPromise<ExtendedCartResultTO>,
  count: number = 0,
): Promise<(store: OfferManagementStore) => OfferManagementStore> => {
  try {
    const {
      status,
      data: { cart },
    } = await loadFunction();
    cartActions.overwriteState({
      ...cart.cartTO,
      authorities: cart.uiRelevantAuthorizationTO,
    });
    status === 409 &&
      offerManagementSetState({
        ...offerManagementState,
        cartAlreadyLoadedWarningVisible: true,
      });
    return (state: OfferManagementStore) => ({
      ...state,
      status,
    });
  } catch (e) {
    if (count === 1) {
      throw e;
    }
    return loadTheQuote(
      cartActions,
      offerManagementState,
      offerManagementSetState,
      loadFunction,
      1,
    );
  }
};

export const LoadQuote = async (
  id: string,
  cartActions: CartActions,
  offerManagementState: OfferManagementDialogState,
  offerManagementSetState: Dispatch<SetStateAction<OfferManagementDialogState>>,
  count: number = 0,
) =>
  loadTheQuote(
    cartActions,
    offerManagementState,
    offerManagementSetState,
    () => loadQuote(id),
    count,
  );

export const LoadQuoteReadOnly = async (
  id: string,
  cartActions: CartActions,
  offerManagementState: OfferManagementDialogState,
  offerManagementSetState: Dispatch<SetStateAction<OfferManagementDialogState>>,
  count: number = 0,
) =>
  loadTheQuote(
    cartActions,
    offerManagementState,
    offerManagementSetState,
    () => loadQuoteReadOnly(id),
    count,
  );

export const LoadSharedQuote = async (
  id: string,
  cartActions: CartActions,
  offerManagementState: OfferManagementDialogState,
  offerManagementSetState: Dispatch<SetStateAction<OfferManagementDialogState>>,
  count: number = 0,
): Promise<(state: OfferManagementStore) => OfferManagementStore> => {
  try {
    const {
      status,
      data: { cart },
    } = await getSharedQuote(id);
    cartActions.overwriteState({
      ...cart.cartTO,
      authorities: cart.uiRelevantAuthorizationTO,
    });
    status === 409 &&
      offerManagementSetState({
        ...offerManagementState,
        cartAlreadyLoadedWarningVisible: true,
      });
    return (state: OfferManagementStore) => ({
      ...state,
      status,
    });
  } catch (e) {
    if (count === 1) {
      throw e;
    }
    return LoadSharedQuote(
      id,
      cartActions,
      offerManagementState,
      offerManagementSetState,
      1,
    );
  }
};
