import { find, flatten, map, update } from "ramda";
import { useContext, useEffect, useState } from "react";

import { ConfigurationContext } from "../../../../../hooks/useConfiguration";
import { setPriceDate } from "../../../../../service/cartRestService";
import {
  CartActions,
  ExtendedTeckentrupCartTO,
  TeckentrupCartArticleTO,
} from "../../../../../types/cart";
import {
  updatePricesEvent,
  trackFunction,
} from "../../../../../utilities/eventTracking";
import { getCartItemValidationState } from "../../warnings/validateCartModal/validateCartUtils";
import {
  getPriceFromCartItem,
  validateArticles,
} from "./updatePricesModalUtils";

export type ValidatedCartItem = TeckentrupCartArticleTO & {
  validation: {
    finished?: boolean;
    valid: boolean;
    running: boolean;
    error: boolean;
    errorMsg: string;
    loadingResult?: any;
    newPrice?: number;
  };
};

export type PriceModalStatus = {
  loading: boolean;
  finished: boolean;
  error: boolean;
  errorMsg: string;
};

export type UpdatePriceModalSave = {
  saving: boolean;
  saved: boolean;
};

const INITIAL_PRICE_MODAL_STATUS_STATE = {
  loading: false,
  finished: false,
  error: false,
  errorMsg: "",
} as const;

const INITIAL_PRICE_MODAL_SAVE_STATE = {
  saving: false,
  saved: false,
} as const;

export const useUpdatePricesModal = (
  cart: ExtendedTeckentrupCartTO,
  cartActions: CartActions,
) => {
  const { configurationId, remove } = useContext(ConfigurationContext);
  const [cartItemsToValidate, setCartItemsToValidate] = useState<
    ValidatedCartItem[][]
  >([]);
  const [status, setStatus] = useState<PriceModalStatus>(
    INITIAL_PRICE_MODAL_STATUS_STATE,
  );
  const [save, setSave] = useState<UpdatePriceModalSave>(
    INITIAL_PRICE_MODAL_SAVE_STATE,
  );

  async function updatePriceData(cartItems: ValidatedCartItem[][]) {
    let errorStatus: Pick<
      ValidatedCartItem["validation"],
      "error" | "errorMsg"
    > = {
      error: false,
      errorMsg: "",
    };
    for (const [index, items] of cartItems.entries()) {
      const validatedSubItems = await Promise.all(
        map(getPriceFromCartItem, items),
      );
      const error = find<ValidatedCartItem>(
        ({ validation }) => validation.error,
      )(validatedSubItems);
      if (!errorStatus.error && error) {
        errorStatus = { error: true, errorMsg: error.validation.errorMsg };
      }
      setCartItemsToValidate((prev) => update(index, validatedSubItems, prev));
    }
    return {
      loading: false,
      finished: true,
      error: errorStatus.error,
      errorMsg: errorStatus.errorMsg,
    };
  }

  const onSave = (cartItems: ValidatedCartItem[]) => async () => {
    if (status.finished) {
      setSave({ saving: true, saved: false });
      await setPriceDate(Date.now());
      for (const item of cartItems.values()) {
        if (!item?.validation?.error) {
          await validateArticles(item);
        }
      }
      setSave({ saving: false, saved: true });
      await cartActions.getCart();
    }
  };

  useEffect(() => {
    (async () => {
      if (configurationId) {
        remove();
      }
      const itemsToGetPrice = getCartItemValidationState(cart.articles);
      setCartItemsToValidate(itemsToGetPrice);
      setStatus(await updatePriceData(itemsToGetPrice));
    })();
  }, []);

  const cartItemsWithNewPrices = flatten(cartItemsToValidate);

  return {
    status,
    cartItems: cartItemsWithNewPrices,
    save,
    onSave: trackFunction(onSave(cartItemsWithNewPrices), updatePricesEvent),
  };
};
