import { isEmpty, isNil } from "ramda";

/**
 * Checks all mandatory fields of freeItemModal
 * @param {object} freeItem the freeItem to create from freeItemModal
 * @returns {boolean} if true, all mandatory fields are set
 */
export const mandatoryFieldsIncomplete = (freeItem) => {
  const { name, basePrice, amount } = freeItem;
  return (
    isNil(name) ||
    isEmpty(name) ||
    isNil(basePrice) ||
    isEmpty(basePrice) ||
    basePrice < 0 ||
    isNil(amount) ||
    isEmpty(amount) ||
    amount <= 0
  );
};
