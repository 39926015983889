import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Link } from "react-router-dom";

import { FlexTableCell } from "../../../../../../components/flexTable/flexTable";
import { CartContext } from "../../../../../../hooks/useCart";
import { hasRightToSeeBurgerMenuEntriesCart } from "../../../../../../http/identityHelper";
import { TeckentrupCartArticleTO } from "../../../../../../types/cart";
import { ItemState } from "../../../../../../utilities/cartUtils";
import {
  reconfigureEvent,
  track,
} from "../../../../../../utilities/eventTracking";
import { useCartDropDown } from "../../../../hooks/useCartDropDown";
import { CartModalsReturn } from "../../../../hooks/useCartModals";
import { t } from "../../../../localizationUtils";
import { DownloadNotPossibleModal } from "../../../modals/DownloadNotPossibleModal";
import { DownloadModal } from "../../../modals/downloadModal";
import { FreeItemModal } from "../../../modals/freeItemModal";
import { MessageModal } from "../../../modals/messageModal";
import { CartItemCommentModal } from "./cartItemCommentModal";
import { DeleteCartItemWarningPopup } from "./deleteCartItemWarningPopup";
import { DeliveryTimeChangeModal } from "./deliveryTimeChangeModal";

import "./dropDownCell.scss";

type DropDownCellProps = {
  unsavedCart: boolean;
  cartItem: TeckentrupCartArticleTO;
  itemRole: ItemState;
  toggleModal: CartModalsReturn["modalActions"]["toggleModal"];
};

export function DropDownCell({
  unsavedCart,
  cartItem,
  itemRole,
  toggleModal,
}: Readonly<DropDownCellProps>) {
  const {
    cart: {
      authorities: { valueAuthorities },
    },
  } = useContext(CartContext);
  const {
    nodeRef,
    toggle,
    showCommentModal,
    showDeleteModal,
    showEditModal,
    showChangeDeliveryTimeModal,
    showRequestDrawingModal,
    showRequestDrawingNoCartId,
    itemMessage,
    messageDetails,
    actions,
  } = useCartDropDown(cartItem, toggleModal);
  const {
    isGroupItem,
    isFreeItem,
    isConfigurableItem,
    isDrawingItem,
    isSparePartItem,
  } = itemRole;
  const rights = hasRightToSeeBurgerMenuEntriesCart(valueAuthorities);

  return (
    <FlexTableCell>
      <div className="dropdown-cell" ref={nodeRef}>
        <button
          className="dropdown-cell button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => actions.toggleDropDown()}
          data-button-id={`dropdownCellButton-${cartItem.positionNr}`}
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={faEllipsisV} />
          </span>
        </button>
        {toggle && (
          <div
            className="dropdown-menu"
            id="dropdown-menu"
            data-context-menu="dropdown-menu"
            role="menu"
          >
            <div className="dropdown-content">
              {isConfigurableItem && rights.reconfigure && (
                <Link
                  data-context-menu-id={"cart_title_reconfigure"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  to={`/reconfiguration/product/${cartItem.articleNumber}/${cartItem.articleId}/${cartItem.positionNr}/${cartItem.articleOrderStatus}`}
                  onClick={() => track(reconfigureEvent)}
                >
                  <span className={"image-icon schraubenschluessel"} />
                  <span>{t("cart_title_reconfigure")}</span>
                </Link>
              )}
              {isConfigurableItem && rights.deliveryChange && (
                <a
                  data-context-menu-id={"cart_title_delivery_time_change"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  onClick={actions.toggleDeliveryChangeModal}
                >
                  <span className={"image-icon reload"} />
                  <span>{t("cart_title_delivery_time_change")}</span>
                </a>
              )}
              {isFreeItem && (
                <a
                  data-context-menu-id={"cart_title_edit_position"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  onClick={actions.toggleEditModal}
                >
                  <span className={"image-icon schraubenschluessel"} />
                  <span>{t("cart_title_edit_position")}</span>
                </a>
              )}
              {isDrawingItem && rights.drawing && (
                <a
                  data-context-menu-id={"cart_title_get_drawing"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  onClick={actions.toggleRequestDrawingModal(unsavedCart)}
                >
                  <span className={"image-icon zeichnung-anfordern"} />
                  <span>{t("cart_title_get_drawing")}</span>
                </a>
              )}
              {!isGroupItem && rights.comment && (
                <a
                  data-context-menu-id={"cart_title_comment"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  onClick={actions.toggleCommentModal}
                >
                  <span className={"image-icon notiz"} />
                  <span>{t("cart_title_comment")}</span>
                </a>
              )}
              {!isGroupItem && !isSparePartItem && rights.copy && (
                <a
                  data-context-menu-id={"cart_title_copy_article"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  onClick={actions.duplicateCartItem}
                >
                  <span className={"image-icon position-duplizieren"} />
                  <span>{t("cart_title_copy_article")}</span>
                </a>
              )}
              {rights.delete && (
                <a
                  data-context-menu-id={"cart_title_delete"}
                  className={"dropdown-item"}
                  aria-hidden="true"
                  onClick={actions.toggleDeleteModal}
                >
                  <span className={"image-icon loeschen"} />
                  <span>{t("cart_title_delete")}</span>
                </a>
              )}
            </div>
          </div>
        )}
        {showDeleteModal && (
          <DeleteCartItemWarningPopup
            label={t(
              isGroupItem ? "cart_delete_folder_warn" : "cart_delete_warn",
              {
                article: cartItem.articleName,
                posNo: cartItem.positionNr,
              },
            )}
            onDelete={actions.deleteCartItemAndToggleDeleteWarning}
            onCancel={actions.toggleDeleteModal}
          />
        )}
        {showCommentModal && (
          <CartItemCommentModal
            onSubmit={actions.saveCartItemCommentModal(cartItem)}
            onCancel={actions.toggleCommentModal}
            comment={cartItem.comment}
          />
        )}
        {showEditModal && (
          <FreeItemModal
            cartItem={cartItem}
            toggleModal={actions.toggleEditModal}
            saveFreeItem={actions.saveCartItemEdit(cartItem)}
            isEditMode={true}
          />
        )}
        {showChangeDeliveryTimeModal && (
          <DeliveryTimeChangeModal
            cartItem={cartItem}
            onCancel={actions.toggleDeliveryChangeModal}
            onSave={actions.saveDeliveryTimeChangeModal}
          />
        )}
        {showRequestDrawingModal && (
          <DownloadModal
            closeDialogMethod={actions.toggleRequestDrawingModal(unsavedCart)}
            requestDrawingMethod={actions.requestDrawing}
            cartItem={cartItem}
          />
        )}
        {showRequestDrawingNoCartId && (
          <DownloadNotPossibleModal
            onCancel={actions.toggleRequestDrawingNoCartId}
            onSave={actions.toggleModifyOfferModal}
          />
        )}
        {itemMessage && (
          <MessageModal
            messageBoxTitle={messageDetails.title}
            messageLabel={messageDetails.text}
            saveAndCloseDialog={actions.toggleMessageModal}
          />
        )}
      </div>
    </FlexTableCell>
  );
}
