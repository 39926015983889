type RequestDrawingOptions = {
  pdf: boolean;
  dwg: boolean;
};

/**
 * Builds the array containing the requested formats, dependent on whether the checkboxes are selected.
 *
 * @param {object} object The object containing, which formats are selected.
 * @returns {string[]} the format array.
 */
export const buildFormatsArray = ({ pdf, dwg }: RequestDrawingOptions) => [
  ...(pdf ? ["pdf"] : []),
  ...(dwg ? ["dwg"] : []),
];

/**
 * Checks, whether no format is selected.
 *
 * @param {object} object The object containing, which formats are selected.
 * @returns {boolean} true, if no format is selected. Otherwise, false.
 */
export const noFormatSelected = ({ pdf, dwg }: RequestDrawingOptions) =>
  !pdf && !dwg;
