import classNames from "classnames";
import { compose, curry, map } from "ramda";

import {
  FlexTableCell as Cell,
  FlexTableCellProps,
  FlexTableRow,
} from "../../../../../components/flexTable/flexTable";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import { TeckentrupCartArticleTO } from "../../../../../types/cart";
import { UserRoles } from "../../../../../utilities/cartUtils";
import { mapIndexed } from "../../../../../utilities/utilities";
import { CartViews } from "../../../hooks/useCartView";
import { t } from "../../../localizationUtils";

import "./rowPriceDetails.scss";

const createEmptyCells = compose(
  curry(mapIndexed)((_, index) => <Cell key={index} />),
  Array,
);

type CellExpandProps = {
  tableView: CartViews;
  userRoles: UserRoles;
};

const CellExpand = ({ tableView, userRoles }: CellExpandProps) => {
  const { hasGraduatedDiscount, hasTeoDiscount } = userRoles;
  const { isSales, isCustomer, isBuyer, surcharge, discount } = tableView;
  return (
    <>
      {isSales && surcharge && createEmptyCells(9)}
      {isSales && discount && createEmptyCells(8)}
      {isCustomer && createEmptyCells(4)}
      {isBuyer && createEmptyCells(5)}
      {isBuyer && hasGraduatedDiscount && <Cell />}
      {isBuyer && hasTeoDiscount && <Cell />}
    </>
  );
};

const CellWithBorder = ({ className, ...props }: FlexTableCellProps) => (
  <Cell className={classNames(["has-border", className])} {...props} />
);

type RowPriceDetailsProps = {
  cartItem: TeckentrupCartArticleTO;
  tableViews: CartViews;
  userRoles: UserRoles;
};

export const RowPriceDetails = ({
  cartItem,
  tableViews,
  userRoles,
}: RowPriceDetailsProps) => {
  const {
    priceConditions,
    tzInPercent,
    bruttoEpIncTz,
    ekNettoEp,
    vkNettoEp2,
    vkNettoEp,
  } = cartItem;
  const { surcharge } = tableViews;
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <div className="price-details">
      <FlexTableRow className="price-details-row">
        <Cell />
        <Cell />
        <Cell />
        <Cell className="price-details-label" data-label-id="price_details">
          <span className="is-size-5">{t("price_details")}</span>
        </Cell>
        <Cell data-label-id="price_details_bruttoEp">
          <span className="is-size-6">{t("price_details_brutto_ep")}</span>
        </Cell>
        <Cell data-label-id="price_details_ekNettoEp">
          <span className="is-size-6">{t("price_details_ek_netto_ep")}</span>
        </Cell>
        <Cell data-label-id="price_details_vkNettoEp2">
          <span className="is-size-6">{t("price_details_vk_netto_ep")}</span>
        </Cell>
        <CellExpand tableView={tableViews} userRoles={userRoles} />
      </FlexTableRow>
      {map(
        (condition) => (
          <FlexTableRow key={condition.label} className="price-details-row">
            <Cell />
            <Cell />
            <Cell />
            <CellWithBorder
              value={condition.label}
              data-cell-id={condition.label}
            />
            <CellWithBorder
              data-cell-id="total-price"
              value={formatPrice(condition.conditionTotalPrice)}
            />
            <CellWithBorder
              data-cell-id="total-price-ekNetto"
              value={formatPrice(condition.totalPriceEKNetto)}
            />
            <CellWithBorder
              data-cell-id="total-price-vkNetto"
              value={formatPrice(condition.totalPriceVKNetto)}
            />
            <CellExpand tableView={tableViews} userRoles={userRoles} />
          </FlexTableRow>
        ),
        priceConditions.variantConditions,
      )}
      {tzInPercent > 0 && (
        <FlexTableRow className="price-details-row">
          <Cell />
          <Cell />
          <Cell />
          <CellWithBorder
            data-cell-id="price_details_surcharge_in_percent"
            value={t("price_details_surcharge_in_percent")}
          />
          <CellWithBorder data-cell-id="tzInPercent" value={tzInPercent} />
          <CellWithBorder value="-" />
          <CellWithBorder value="-" />
          <CellExpand tableView={tableViews} userRoles={userRoles} />
        </FlexTableRow>
      )}
      <FlexTableRow className="price-details-row">
        <Cell />
        <Cell />
        <Cell />
        <Cell value={t("price_details_sum")} />
        <Cell data-cell-id="bruttoEpIncTz" value={formatPrice(bruttoEpIncTz)} />
        <Cell data-cell-id="ekNettoEp" value={formatPrice(ekNettoEp)} />
        {surcharge ? (
          <Cell data-cell-id="vkNettoEp" value={formatPrice(vkNettoEp)} />
        ) : (
          <Cell data-cell-id="vkNettoEp2" value={formatPrice(vkNettoEp2)} />
        )}
        <CellExpand tableView={tableViews} userRoles={userRoles} />
      </FlexTableRow>
    </div>
  );
};
