import { AxiosPromise } from "axios";
import { equals, head, length, not, without } from "ramda";

import { ProductsContainer } from "@encoway/rest-api";

import { isStringArray } from "../utilities/utilities";
import { fetchGet, fetchPost } from "./fetch";

type DocumentationCharacteristicTO = {
  characteristicId: string;
  characteristicName: string;
  documentationUrl: string;
  productImageUri: string;
};
type ProductTechnicalDocumentationTO = {
  documentationCharacteristics: DocumentationCharacteristicTO[];
};
type PreparedPayload = {
  filter: { productsFilter: { ids: string[] } };
  characteristics: { characteristicIds: string[] };
};

const PATH_CATALOG_CUSTOMIZED = "teckentrup-server/api/custom/catalog";

/**
 * Gets an article selection based on the selection parameters given.
 *
 * @param {object} data The object holding the condition parameters for the selection.
 * @param {string} lang The language for the selection.
 * @returns {AxiosPromise} the art
 */
export const getProductSelection = (
  data: object,
  lang: string,
): AxiosPromise<ProductsContainer> =>
  fetchPost<ProductsContainer>(
    "teckentrup-server/api/catalog/products/_selection",
  )(
    {},
    { "Accept-Language": lang },
  )(data);

export const getTechnicalDocumentation = (
  id: string,
  lang: string,
): AxiosPromise<ProductTechnicalDocumentationTO> =>
  fetchGet<ProductTechnicalDocumentationTO>(
    `${PATH_CATALOG_CUSTOMIZED}/_technicaldocumentation?productId=${id}`,
  )({}, { "Accept-Language": lang })();

export const getCountryAvailability = (
  productId: string,
  auart: string,
): AxiosPromise<boolean> =>
  fetchGet<boolean>(
    `${PATH_CATALOG_CUSTOMIZED}/_deliveryTimeChangePossible?productId=${productId}&auart=${auart} `,
  )({})({});

const preparePayload = (articleNumber: string): PreparedPayload => ({
  filter: {
    productsFilter: {
      ids: [articleNumber],
    },
  },
  characteristics: {
    characteristicIds: ["ALL_VBAK_AUART"],
  },
});

export const getDeliveryClassFromCatalog = async (
  articleNumber: string,
  deliveryClass: string,
  lang: string,
) => {
  const {
    data: { products },
  } = await getProductSelection(preparePayload(articleNumber), lang);
  const { values } = products[0].characteristicValues.ALL_VBAK_AUART || {
    values: [],
  };

  if (isStringArray(values) && equals(length(values), 2)) {
    const changeDeliveryTimePossible = await getCountryAvailability(
      articleNumber,
      head(without([deliveryClass], values)) ?? "",
    );
    if (not(changeDeliveryTimePossible.data)) {
      return ["error: not available in country"];
    }
    return values;
  }
  return [];
};
