import classNames from "classnames";
import { pathOr } from "ramda";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";

import { t } from "../../../pages/shoppingCart/localizationUtils";
import { Quantity } from "../../../types/cart";
import {
  DEFAULT_QUANTITY_MAX,
  DEFAULT_QUANTITY_MIN,
} from "../../../utilities/cartUtils";
import { NumberInput } from "../NumberInput";

import "./input.scss";

type ShoppingCartInputProps = Omit<
  ComponentPropsWithoutRef<"input">,
  "value"
> & {
  value: number;
  label?: string;
  setValue: (value: number) => void;
  minimalValue?: number;
  hasAmountWarning?: boolean;
  isCurrency?: boolean;
  decimalScale?: number;
  quantity?: Quantity;
  "data-cell-id"?: string;
  "data-input-field-id"?: string;
};

export const INPUT_WARNING_ACTIVE_TIME = 3000;

export const ShoppingCartInput = ({
  label,
  setValue,
  minimalValue = 0,
  hasAmountWarning,
  quantity,
  className,
  ...props
}: ShoppingCartInputProps) => {
  const [activeInputWarning, setActiveInputWarning] = useState({
    msg: "",
    toggle: false,
  });
  const [resetInternalValue, setResetInternalValue] = useState(false);

  const quantityMin = pathOr(DEFAULT_QUANTITY_MIN, ["quantityMin"], quantity);
  const quantityMax = pathOr(DEFAULT_QUANTITY_MAX, ["quantityMax"], quantity);

  useEffect(() => {
    if (resetInternalValue) {
      setResetInternalValue(false);
    }
  }, [resetInternalValue]);

  const closeInputWarning = () =>
    setActiveInputWarning({ ...activeInputWarning, toggle: false });

  const openAndCloseInputWarning = (
    inputWarning: typeof activeInputWarning,
  ) => {
    setActiveInputWarning(inputWarning);
    setTimeout(() => {
      closeInputWarning();
    }, INPUT_WARNING_ACTIVE_TIME);
  };

  const setInputWarningTooSmallAndToggle = (inputValue: number, min: number) =>
    openAndCloseInputWarning({
      msg: t("cart_warning_input_too_small", { inputValue, min, max: 1 }),
      toggle: true,
    });

  const setInputWarningTooSmallForAmountAndToggle = () =>
    openAndCloseInputWarning({
      msg: t("cart_warning_amount_too_small"),
      toggle: true,
    });

  const setInputWarningAmountNotInRangeAndToggle = () =>
    openAndCloseInputWarning({
      msg: t("cart_warning_amount_not_in_range", { quantityMin, quantityMax }),
      toggle: true,
    });

  const onNumberChange = (inputValue: number) => {
    if (inputValue < minimalValue) {
      hasAmountWarning
        ? setInputWarningTooSmallForAmountAndToggle()
        : setInputWarningTooSmallAndToggle(inputValue, minimalValue);
      setResetInternalValue(true);
      return;
    }
    setValue(inputValue);
  };

  const onMeterChange = (inputValue: number) => {
    const roundedAmount = Math.ceil(inputValue);
    if (roundedAmount < quantityMin || roundedAmount > quantityMax) {
      setInputWarningAmountNotInRangeAndToggle();
      setResetInternalValue(true);
      return;
    }
    setValue(roundedAmount);
  };

  const onSetValue = (inputValue: number) => {
    if (quantity?.quantityUnit === "M") {
      onMeterChange(inputValue);
      return;
    }
    onNumberChange(inputValue);
  };

  return (
    <div className="shopping-cart-input-wrapper is-relative">
      {label && <span className="label">{label}</span>}
      <NumberInput
        className={classNames("input", className)}
        setValue={onSetValue}
        minimalValue={minimalValue}
        resetInternalValue={resetInternalValue}
        {...props}
      />
      {activeInputWarning.toggle && (
        <div className="inputWarning" onClick={closeInputWarning}>
          {activeInputWarning.msg}
        </div>
      )}
    </div>
  );
};
