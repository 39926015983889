import { equals, isEmpty, isNil, path, trim } from "ramda";

import { L10n } from "@encoway/l10n";

import { AddressTO, HeaderData } from "../../../../../types/cart";
import { PrintOptionsValues } from "../../../../../types/print";

/**
 * Creates the printOption dto
 *
 * @param state the state to transform to dto
 * @returns {PrintOptionsValues}
 */
export function createNewPrintOptionsInformation(
  state: PrintOptionsValues,
): PrintOptionsValues {
  return {
    documentType: state.documentType,
    price: state.price,
    techSpec: state.techSpec,
    dimDrawing: state.dimDrawing,
    logo: state.logo,
    vat: state.vat,
    manufacturer: state.manufacturer,
    priceDetails: state.priceDetails,
    language: state.language,
    short: state.short,
    long: state.long,
    salutation: state.salutation,
    offerText:
      state.offerText ||
      L10n.format("printoptions_dialog_offerText_placeholder"),
    coverLetter:
      state.coverLetter ||
      L10n.format("printoptions_dialog_coverLetter_placeholder"),
    appendix: state.appendix,
  };
}

function createGenderSpecificSalutation(salutation: string): string {
  if (
    L10n.format("printoptions_dialog_salutation_male_tags")
      .toUpperCase()
      .split("|")
      .includes(salutation.toUpperCase())
  ) {
    return `${L10n.format("printoptions_dialog_salutation_male")} ${salutation}`;
  }
  if (
    L10n.format("printoptions_dialog_salutation_female_tags")
      .toUpperCase()
      .split("|")
      .includes(salutation.toUpperCase())
  ) {
    return `${L10n.format("printoptions_dialog_salutation_female")} ${salutation}`;
  }
  return salutation;
}

function isSalutationOrLastNameFilled(address: AddressTO): boolean {
  return (
    !isEmpty(address.contactSalutation) || !isEmpty(address.contactLastName)
  );
}

function determineSalutation(customerAddress?: AddressTO): string {
  if (customerAddress && isSalutationOrLastNameFilled(customerAddress)) {
    const completeSalutation = trim(
      `${createGenderSpecificSalutation(customerAddress.contactSalutation)} ${customerAddress.contactLastName}`,
    );
    return `${completeSalutation},`;
  }
  return L10n.format("printoptions_dialog_sal_placeholder");
}

function createSalutation(
  printOptions?: PrintOptionsValues,
  customerAddress?: AddressTO,
): string {
  const printOptionSalutation = path<string>(["salutation"], printOptions);
  if (
    !isNil(printOptionSalutation) &&
    !equals(
      printOptionSalutation,
      L10n.format("printoptions_dialog_sal_placeholder"),
    ) &&
    !equals(printOptionSalutation, determineSalutation(customerAddress))
  ) {
    return printOptionSalutation;
  }

  if (customerAddress && isSalutationOrLastNameFilled(customerAddress)) {
    const completeSalutation = trim(
      `${createGenderSpecificSalutation(customerAddress.contactSalutation)} ${customerAddress.contactLastName}`,
    );
    return `${completeSalutation},`;
  }
  return L10n.format("printoptions_dialog_sal_placeholder");
}

/**
 * Determines the print options based on local storage and header data of the cart object.
 * Both are evaluated using the documentation on confluence related to the issue TT-1125/TEO-2667.
 * If not set in headerData.address, the salutation is also created based on the header data
 *
 * @param storedPrintOptions printOptions from browser localStorage
 * @param headerData from cart object
 * @returns {{(*=): (*), salutation: ((function(*=): (*))|string)}|undefined|{salutation: ((function(*=): (*))|string)}}
 */
export function determinePrintOptions(
  storedPrintOptions: PrintOptionsValues,
  headerData?: HeaderData,
): PrintOptionsValues | null {
  const printOptions = path<PrintOptionsValues>(["printOptions"], headerData);
  const customerAddress = path<AddressTO>(["address"], headerData);

  if (printOptions && storedPrintOptions) {
    const { offerText, coverLetter, appendix = "" } = printOptions;
    return {
      ...storedPrintOptions,
      ...(!isEmpty(offerText) && { offerText }),
      ...(!isEmpty(coverLetter) && { coverLetter }),
      ...(!isEmpty(appendix) && { appendix }),
      salutation: createSalutation(printOptions, customerAddress),
    };
  }
  if (storedPrintOptions) {
    return {
      ...storedPrintOptions,
      salutation: createSalutation(printOptions, customerAddress),
    };
  }
  if (printOptions) {
    return {
      ...printOptions,
      salutation: createSalutation(printOptions, customerAddress),
    };
  }
  return null;
}
