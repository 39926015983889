import classNames from "classnames";
import { isEmpty, isNil, pathOr } from "ramda";
import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactNode,
} from "react";

import { mapIndexed } from "../../utilities/utilities";

import "./flexTable.scss";

type DivProps = ComponentPropsWithoutRef<"div">;

const Div = ({ children, ...props }: PropsWithChildren<DivProps>) => (
  <div {...props}>{children}</div>
);

export const tableConfiguration = {
  props: {
    className: "flex-table",
    role: "table",
  },
  component: Div,
  tHead: {
    props: {
      className: "flex-row flex-row-head",
      role: "row",
    },
    component: Div,
    row: {
      props: {
        className: "flex-cell",
        role: "columnheader",
      },
      component: Div,
    },
  },
  tBody: {
    props: {
      className: "flex-row flex-row-body",
      role: "row",
    },
    component: Div,
    row: {
      props: {
        className: "flex-cell",
        role: "cell",
      },
      component: Div,
    },
  },
  tFoot: {
    props: {
      className: "flex-row flex-row-foot",
      role: "rowgroup",
    },
    component: Div,
    row: {
      props: {
        className: "flex-cell",
        role: "columnfooter",
      },
      component: Div,
    },
  },
};

export type FlexTableCellProps = DivProps & {
  value?: string | number;
};

export const FlexTableCell = ({
  className = "",
  value = "",
  children,
  ...props
}: PropsWithChildren<FlexTableCellProps>) => (
  <div
    {...{
      ...tableConfiguration.tBody.row.props,
      ...props,
      className: classNames([
        tableConfiguration.tBody.row.props.className,
        className,
      ]),
    }}
  >
    {isNil(children) && !isEmpty(value) ? value : children}
  </div>
);

export const FlexTableRow = ({
  className,
  children,
  ...props
}: PropsWithChildren<DivProps>) => (
  <div
    {...{
      ...tableConfiguration.tBody.props,
      ...props,
      className: classNames([
        tableConfiguration.tBody.props.className,
        className,
      ]),
    }}
  >
    {children}
  </div>
);

export const FlexTableHead = ({
  className,
  children,
  ...props
}: PropsWithChildren<DivProps>) => (
  <div
    {...{
      ...tableConfiguration.tHead.props,
      ...props,
      className: classNames([
        tableConfiguration.tHead.props.className,
        className,
      ]),
    }}
  >
    {children}
  </div>
);

export const FlexTableFoot = ({
  className,
  children,
  ...props
}: PropsWithChildren<DivProps>) => (
  <div
    {...{
      ...tableConfiguration.tFoot.props,
      ...props,
      className: classNames([
        tableConfiguration.tFoot.props.className,
        className,
      ]),
    }}
  >
    {children}
  </div>
);

export const FlexTableWrapper = ({
  className,
  children,
  ...props
}: PropsWithChildren<DivProps>) => (
  <div
    {...{
      ...tableConfiguration.props,
      ...props,
      className: classNames([tableConfiguration.props.className, className]),
    }}
  >
    {children}
  </div>
);

type TableItemProps = {
  cells: any[];
  component: (typeof tableConfiguration)["tBody"]["component"];
  row: (typeof tableConfiguration)["tBody"]["row"];
  props: (typeof tableConfiguration)["tBody"]["props"];
  indexRow?: number;
  tHead?: ReactNode[];
};

const TableItem = ({
  cells,
  component: Container,
  row,
  props,
  indexRow,
  tHead,
}: TableItemProps) => {
  const lastCell = cells[cells.length - 1];
  const rowProps = isNil(lastCell.$$typeof) && cells.pop();
  const referenceNumber = pathOr("", ["props", "firstData"], cells[0]);
  const cellId = pathOr("", ["props", "firstData"], cells[0]);

  const cellIdHead = (index: number) =>
    isNil(tHead)
      ? `${cellId}`
      : `${cellId}-${pathOr("", ["props", "sortKey"], tHead[index])}`;

  return (
    <Container
      data-row-id={indexRow}
      data-reference-number-value={`${referenceNumber}`}
      {...{ ...props, ...rowProps }}
    >
      {cells.map((cell, index) =>
        cell.type && cell.type === FlexTableCell ? (
          <React.Fragment key={`${referenceNumber + index}`}>
            {cell}
          </React.Fragment>
        ) : (
          <row.component
            data-cell-id={cellIdHead(index)}
            key={`${referenceNumber + index}`}
            {...row.props}
          >
            {cell}
          </row.component>
        ),
      )}
    </Container>
  );
};

type FlexTableProps = {
  tHead: ReactNode[];
  tBody: any[];
  config?: object;
};

export const FlexTable = ({ tHead, tBody, config }: FlexTableProps) => {
  const conf = { ...tableConfiguration, ...config };
  const Table = conf.component;

  function getTheadLabel(index: number) {
    return pathOr("", ["props", "label"], tHead[index]) + index;
  }

  return (
    <Table {...tableConfiguration.props}>
      {tHead && <TableItem cells={tHead} {...conf.tHead} />}
      {mapIndexed<any, JSX.Element>(
        (cells, index) => (
          <TableItem
            key={`${getTheadLabel(index)}`}
            tHead={tHead}
            indexRow={index + 1}
            cells={cells}
            {...conf.tBody}
          />
        ),
        tBody,
      )}
    </Table>
  );
};
