import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toLower } from "ramda";
import { useState } from "react";

import { OfferManagementStore } from "../offerManagementUtils";
import { DispatcherActions } from "../useOfferManagementDispatcherActions";

type SortableTableHeadProps = {
  label: string;
  type?: string;
  sortKey: string;
  store: OfferManagementStore;
  onChangeSort: DispatcherActions["onChangeSort"];
};

export const SortableTableHead = ({
  label,
  type,
  sortKey,
  store,
  onChangeSort,
}: SortableTableHeadProps) => {
  const [sortDirectionState, setSortDirectionState] = useState("DESC");

  const isSelected = sortKey === store.sortKey;

  const toggleSortDirection = () => {
    const sortDirection =
      isSelected && sortDirectionState === "DESC" ? "ASC" : "DESC";
    onChangeSort(sortKey, sortDirection, type);
    setSortDirectionState(sortDirection);
  };

  const ARROW_DIRECTIONS: Record<string, JSX.Element> = {
    DESC: (
      <FontAwesomeIcon
        icon={faSortDown}
        style={{ color: "#ef7b22" }}
        width="8px"
        data-button-id={`sort-${toLower(sortDirectionState)}-${sortKey}`}
      />
    ),
    ASC: (
      <FontAwesomeIcon
        icon={faSortUp}
        style={{ color: "#ef7b22" }}
        width="8px"
        data-button-id={`sort-${toLower(sortDirectionState)}-${sortKey}`}
      />
    ),
  };

  return (
    <div className="sortable-column has-pointer" onClick={toggleSortDirection}>
      <span className="icon is-small">
        {!isSelected && (
          <FontAwesomeIcon
            icon={faSort}
            width="8px"
            data-button-id={`sort-${toLower(sortDirectionState)}-${sortKey}`}
          />
        )}
        {isSelected && ARROW_DIRECTIONS[sortDirectionState]}
      </span>
      {label}
    </div>
  );
};
