import { isNil } from "ramda";
import React, { useContext } from "react";

import { Loading } from "../../components/loading";
import { ConfigurationContext } from "../../hooks/useConfiguration";
import { ConfiguratorContainer } from "./configuratorContainer";
import { ResultContainer } from "./resultContainer";

export function Configuration() {
  const { configurationId, guiTO, config } = useContext(ConfigurationContext);

  if (isNil(guiTO) || isNil(configurationId) || isNil(config)) {
    return <Loading />;
  }

  return (
    <div className="columns" data-page-id="configuration">
      <ConfiguratorContainer />
      <ResultContainer />
    </div>
  );
}
