import { equals } from "ramda";
import React, { useContext } from "react";

import { AppContext } from "../hooks/useApp";
import { MODE } from "../hooks/useIdentity";

export function CurrentUserDisplay() {
  const { mode } = useContext(AppContext);

  if (equals(mode, MODE.DEVELOP)) {
    return (
      <span>
        Aktuell geladener User:
        <span className={"boldText"}>
          {window.localStorage.getItem("currentUser")}
        </span>
      </span>
    );
  }
  return null;
}
