import { useContext, useEffect } from "react";

import { L10n } from "@encoway/l10n";
import { Configurator, Sticky } from "@encoway/react-components";

import { AppContext } from "../../hooks/useApp";
import { ConfigurationContext } from "../../hooks/useConfiguration";
import { IdentityContext } from "../../hooks/useIdentity";
import { hasRightToSeeConexLink } from "../../http/identityHelper";
import { configure } from "./customizing/config";

const ConexLink = ({ baseUrl, config }) => (
  <a
    href={`${baseUrl}/conex/?configurationId=${config.id()}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    Conex
  </a>
);

export const ConfiguratorContainer = () => {
  const { baseUrl } = useContext(AppContext);
  const { conex, booleanAuthorities } = useContext(IdentityContext);
  const { config, bus, reconfiguration } = useContext(ConfigurationContext);
  const options = { hideLinkedTree: true, hideInternalTree: true };
  const articleName = reconfiguration
    ? reconfiguration.configurationStatus?.configuration?.articleName
    : config?.cfg?.configuration?.articleName;

  useEffect(() => {
    configure();
  }, []);

  return (
    <div
      className="configurator column is-three-quarters"
      data-configuration-product-id={articleName}
    >
      <Sticky source=".is-configurator" target=".is-configurator .tabs">
        <Configurator
          config={config}
          lang={L10n.currentLocale()}
          eventBus={bus}
          options={options}
        />
      </Sticky>
      {(hasRightToSeeConexLink(booleanAuthorities) || conex) && (
        <ConexLink baseUrl={baseUrl} config={config} />
      )}
    </div>
  );
};
