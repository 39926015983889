import { equals, filter, isNil } from "ramda";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";

import {
  cartBelongsToCurrentUser,
  hasRightToSeeBurgerMenuEntries,
  isAuthorityRole,
} from "../../../http/identityHelper";
import IdentityStore from "../../../http/identityStore";
import {
  closeQuote,
  isQuoteActive,
} from "../../../service/offerManagementService";
import {
  CartActions,
  ExtendedTeckentrupCartTO,
  TeckentrupCartTO,
} from "../../../types/cart";
import { ConfigurationStore } from "../../../types/configuration";
import { UiAuthorities } from "../../../types/identity";
import { Quote } from "../../../types/offermanagement";
import {
  INITIAL_DIALOG_STATE,
  OfferManagementDialogState,
} from "../offerManagement";
import {
  OfferManagementStore,
  getOfferManagementUIRights,
} from "../offerManagementUtils";
import { DispatcherActions } from "../useOfferManagementDispatcherActions";
import {
  InitialOfferManagementTableState,
  onLoadCart,
  onToArchive,
} from "./offerManagementTableActions";

export type OfferManagementTableRow = typeof InitialOfferManagementTableState;

export type BurgerMenuAction = {
  action: MouseEventHandler<HTMLButtonElement>;
  icon: string;
  label: string;
};

export type DialogState = {
  quoteId: string;
  visible: boolean;
};

export type SortHeaderProp = {
  label: string;
  sortKey: string;
  type?: string;
};

export type OfferManagement = {
  store: OfferManagementStore;
  state: typeof INITIAL_DIALOG_STATE;
  setState: Dispatch<SetStateAction<OfferManagementDialogState>>;
};

type PrepareActionsProps = {
  item: OfferManagementTableRow;
  setModifyOfferDialogVisible: Dispatch<SetStateAction<DialogState>>;
  setDeleteCartWarningVisible: Dispatch<SetStateAction<DialogState>>;
  dispatcherActions: DispatcherActions;
  configuration: ConfigurationStore;
  cartActions: CartActions;
  identityStore: IdentityStore | null;
  cart: ExtendedTeckentrupCartTO;
  offerManagement: OfferManagement;
};

export const SortHeader: SortHeaderProp[] = [
  {
    label: "offer_management_table_order_number",
    sortKey: "_quoteOrderNumber",
  },
  {
    label: "offer_management_table_creation_date",
    type: "DATE",
    sortKey: "_cartCreationDate",
  },
  {
    label: "offer_management_table_modification_date",
    type: "DATE",
    sortKey: "_cartChangedDate",
  },
  {
    label: "building_project",
    sortKey: "_headerData.buildProject",
  },
  {
    label: "reference_number",
    sortKey: "_headerData.referenceNumber",
  },
  {
    label: "customer",
    sortKey: "_headerData.address.companyName",
  },
];

const isQuoteBelongingToOtherUser = (item: OfferManagementTableRow) =>
  item.creatorUserId !== item.currentUserId;

/**
 * Checks whether a quote was already ordered.
 *
 * @param {OfferManagementTableRow} item The quote whose status is being checked.
 */
export const isQuoteOrdered = equals("ORDERED");

/**
 * Checks whether a quote is shared.
 *
 * @param {OfferManagementTableRow} item The quote whose status is being checked.
 */
export const isQuoteShared = equals("SHARED");

/**
 * Checks whether a quote is read-only or not.
 * @param {OfferManagementTableRow} item The quote whose status is being checked.
 * @param {IdentityStore} identityStore The store holding the current data.
 * @param {UiAuthorities} cartAuthorities The store holding the current data.
 */
export const isQuoteReadOnly = (
  item: OfferManagementTableRow,
  identityStore: IdentityStore | null,
  cartAuthorities: UiAuthorities,
) =>
  (isAuthorityRole(
    ["ADMIN", "INNENDIENST", "AUSSENDIENST"],
    identityStore,
    cartAuthorities,
  ) &&
    isQuoteBelongingToOtherUser(item) &&
    !cartBelongsToCurrentUser(identityStore, item)) ||
  isQuoteOrdered(item.status) ||
  isQuoteShared(item.status);

/**
 * Checks whether the current user is an admin or has the same client id as the item.
 *
 * @param {IdentityStore} identityStore The store holding the current user information.
 * @param {OfferManagementTableRow} item The item to be checked.
 * @param {UiAuthorities} authorities The authorities object with roles, valueAuthorities and booleanAuthorities
 */
export const isAdminOrClientIdEqual = (
  identityStore: IdentityStore | null,
  item: OfferManagementTableRow,
  authorities?: UiAuthorities,
) =>
  isAuthorityRole("ADMIN", identityStore, authorities) ||
  (!isNil(item) && cartBelongsToCurrentUser(identityStore, item));

/**
 * Checks whether a quote is currently loaded by another user.
 *
 * @param {OfferManagementTableRow} item The quote whose status is being checked.
 */
export const isQuoteLoadedByOtherUser = (item: OfferManagementTableRow) =>
  item.active && item.userId !== item.currentUserId;

/**
 * Checks wheter a quote is currently open by the user in the cart.
 *
 * @param {OfferManagementTableRow} item The quote whose status is being checked.
 * @param {TeckentrupCartTO} cart The quote whose status is being checked.
 */
export const isQuoteOpenByCurrentUserInCart = (
  item: OfferManagementTableRow,
  cart: TeckentrupCartTO,
) => item.id === cart.id;

/**
 * Find the desired quote in the list, if the contains it.
 *
 * @param {Array<object>} quotes All quotes to be searched.
 * @param {string} quoteId The id of the desired quote.
 */
export const findQuote = (quotes: Quote[], quoteId: string) =>
  filter((quote) => quote.cartTO.id === quoteId, quotes)[0];

/**
 * Determines the correct dispatcher for loading the quote.
 *
 * @param {OfferManagementTableRow} item The quote to be loaded.
 * @param {IdentityStore} identityStore The store containing the current data.
 * @param {UiAuthorities} cartAuthorities cartAuthorities
 * @param {OfferManagementDispatcherActions} dispatcherActions The actions, that can be performed.
 */
export const determineLoadFunction = (
  item: OfferManagementTableRow,
  identityStore: IdentityStore | null,
  cartAuthorities: UiAuthorities,
  dispatcherActions: DispatcherActions,
) => {
  isQuoteReadOnly(item, identityStore, cartAuthorities)
    ? dispatcherActions.onLoadQuoteReadOnly(item)
    : dispatcherActions.onLoadQuote(item.id);
};

const returnPreparedActions = (
  item: OfferManagementTableRow,
  identityStore: IdentityStore | null,
  burgerMenuActions: Record<string, BurgerMenuAction>,
  offerManagementUIRights: UiAuthorities,
) => {
  const rights = hasRightToSeeBurgerMenuEntries(
    offerManagementUIRights.valueAuthorities,
    identityStore,
    item.creatorUserId,
  );
  return [
    ...(rights.viewCart ? [burgerMenuActions.viewCart] : []),
    ...(rights.inspectCart ? [burgerMenuActions.inspectCart] : []),
    ...(rights.edit ? [burgerMenuActions.modify] : []),
    ...(rights.archive ? [burgerMenuActions.toArchive] : []),
    ...(rights.reactive ? [burgerMenuActions.reactivate] : []),
    ...(rights.duplicate ? [burgerMenuActions.duplicate] : []),
    ...(rights.delete ? [burgerMenuActions.deleteOffer] : []),
  ];
};

export const prepareActions = ({
  item,
  setModifyOfferDialogVisible,
  setDeleteCartWarningVisible,
  dispatcherActions,
  configuration,
  cartActions,
  identityStore,
  cart,
  offerManagement,
}: PrepareActionsProps) => {
  const performActionOnQuote = async (
    onQuoteNotLoadedByOtherUser: Function,
  ) => {
    if (isQuoteOpenByCurrentUserInCart(item, cart)) {
      await closeQuote(item.orderNumber);
    }

    if (isQuoteLoadedByOtherUser(item)) {
      dispatcherActions.handleQuoteError({ response: { status: 403 } });
      return;
    }

    const quoteActiveResponse = await isQuoteActive(item.id);
    if (quoteActiveResponse.data) {
      dispatcherActions.handleQuoteError({ response: { status: 403 } });
    } else {
      onQuoteNotLoadedByOtherUser();
    }
  };

  const inspectCart = {
    action: () =>
      onLoadCart({
        item,
        dispatcherActions,
        configuration,
        cartActions,
        identityStore,
        cart,
        offerManagement,
      }),
    icon: "schraubenschluessel",
    label: "offer_management_menu_view_cart",
  };

  const viewCart = {
    action: () =>
      onLoadCart({
        item,
        dispatcherActions,
        configuration,
        cartActions,
        identityStore,
        cart,
        offerManagement,
      }),
    icon: "schraubenschluessel",
    label: "offer_management_menu_load_cart",
  };

  const modify = {
    action: () =>
      setModifyOfferDialogVisible({ quoteId: item.id, visible: true }),
    icon: "notiz",
    label: "offer_management_menu_modify",
  };

  const toArchive = {
    action: () =>
      onToArchive({
        item,
        dispatcherActions,
        archive: true,
        identityStore,
        cart,
        offerManagement,
      }),
    icon: "vorgang-archivieren",
    label: "offer_management_menu_to_archive",
  };

  const reactivate = {
    action: () =>
      onToArchive({
        item,
        dispatcherActions,
        archive: false,
        identityStore,
        cart,
        offerManagement,
      }),
    icon: "vorgang-reaktivieren",
    label: "offer_management_menu_reactivate",
  };

  const duplicate = {
    action: () => dispatcherActions.onDuplicateQuote(item.id),
    icon: "vorgang-duplizieren",
    label: "offer_management_menu_duplicate",
  };

  const deleteOffer = {
    action: () =>
      performActionOnQuote(() =>
        setDeleteCartWarningVisible({ quoteId: item.id, visible: true }),
      ),
    icon: "loeschen",
    label: "offer_management_menu_delete",
  };

  const offerManagementUIRights = getOfferManagementUIRights(
    item,
    offerManagement.store,
  );

  const burgerMenuActions = {
    inspectCart,
    viewCart,
    modify,
    toArchive,
    reactivate,
    duplicate,
    deleteOffer,
  };
  return returnPreparedActions(
    item,
    identityStore,
    burgerMenuActions,
    offerManagementUIRights,
  );
};
