import { forEachObjIndexed, match, pathOr } from "ramda";

export type RemoveCustomerPayload = {
  companyId: string;
  customerId: string;
};

type CompanyContactPayload = {
  title: string;
  firstname: string;
  surname: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
};

export type AddCustomerPayload = {
  customerName: string;
  customerNumber: string;
  companyContacts: CompanyContactPayload[];
};

export type CustomerExistsPayload = {
  customerNumber: string;
};

export const convertToFormData = (data: RemoveCustomerPayload): FormData => {
  const formRequest = new FormData();
  forEachObjIndexed((val, key) => formRequest.append(key, val), data);
  return formRequest;
};

const getCompanyId = (): string => {
  const authToken = window.localStorage.getItem("authToken") ?? "";
  const matched = match(/\.(.*)\./, authToken);
  let extractedToken = "";
  if (matched?.length && matched.length > 1) {
    extractedToken = atob(matched[1]);
  }

  const decodedToken = decodeURIComponent(encodeURIComponent(extractedToken));
  return pathOr(
    "",
    ["FirmadesNutzers", "FirmenSAPKundennummer"],
    JSON.parse(decodedToken),
  );
};

export const withCompanyId = (
  data: AddCustomerPayload | CustomerExistsPayload,
) => ({
  companyNumber: getCompanyId(),
  ...data,
});
