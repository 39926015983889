import { AxiosPromise } from "axios";

import { fetchPost } from "./fetch";

export const downloadDynamicDrawing = (
  position: string,
  formats: string[],
): AxiosPromise<{}> =>
  fetchPost<{}>("teckentrup-server/api/cart/download/drawing")()({
    position,
    formats,
  });
