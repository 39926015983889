import { ChangeEvent } from "react";

type TextAreaProps = {
  attributeId: string;
  value: string | number | readonly string[] | undefined;
  label?: string;
  subLabel?: string;
  onInputChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

export const TextArea = (props: TextAreaProps) => {
  const {
    attributeId,
    value,
    label = "",
    subLabel = "",
    onInputChange,
  } = props;

  return (
    <div className="two-columns custom-margin-2">
      <div className="column-entry with-padding">
        <label className="label">
          {label}
          <span className="smallText with-padding">{subLabel}</span>
        </label>
        <textarea
          name="comment"
          className="textarea"
          value={value}
          onChange={onInputChange}
          data-text-area-id={attributeId}
        />
      </div>
    </div>
  );
};
