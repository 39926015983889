import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { FlexTableCell as Cell } from "../../../../../components/flexTable/flexTable";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import { TeckentrupCartArticleTO } from "../../../../../types/cart";
import { hasPricing } from "../../../../../utilities/cartUtils";
import { CartItemDetailsReturn } from "../../../hooks/useCartItemDetails";
import { t } from "../../../localizationUtils";

import "./GrossGpIncTzSalesCell.scss";

type GrossGpIncTzSalesCellProps = {
  hasCartItemDetails: boolean;
  toggleCartItemDetails: CartItemDetailsReturn["cartItemDetailsActions"]["toggleAllCartItemDetails"];
  pricingStatus?: TeckentrupCartArticleTO["pricingStatus"];
  bruttoGpIncTz: TeckentrupCartArticleTO["bruttoGpIncTz"];
  showCartItemDetails: boolean;
  labelId: string;
};

export const GrossGpIncTzSalesCell = ({
  hasCartItemDetails,
  toggleCartItemDetails,
  pricingStatus,
  bruttoGpIncTz,
  showCartItemDetails,
  labelId,
}: GrossGpIncTzSalesCellProps) => {
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <Cell
      className={classNames({ "has-pointer": hasCartItemDetails })}
      data-cell-id={labelId}
      {...(hasCartItemDetails && { onClick: toggleCartItemDetails })}
    >
      {hasPricing(pricingStatus)
        ? formatPrice(bruttoGpIncTz)
        : t("price_on_request")}
      {hasCartItemDetails && (
        <span className="pricing-status-toggler">
          {showCartItemDetails ? (
            <FontAwesomeIcon
              icon={faSortUp}
              style={{ color: "#ef7b22" }}
              height="13px"
            />
          ) : (
            <FontAwesomeIcon
              icon={faSortDown}
              style={{ color: "#ef7b22" }}
              height="13px"
            />
          )}
        </span>
      )}
    </Cell>
  );
};
