import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import { getDeliveryClassFromCatalog } from "../../../../../../service/catalogService";
import { TeckentrupCartArticleTO } from "../../../../../../types/cart";
import { CartWarningWrapper } from "../../../../../offerManagement/cartWarningWrapper";
import { t } from "../../../../localizationUtils";
import { isChangePossible } from "../../../modals/deliveryTimeChangeModalUtils";
import { DeliveryTimeChangeCheckModal } from "./deliveryTimeChangeCheckModal";

type DeliveryTimeChangeModalProps = {
  onCancel: () => void;
  onSave: () => void;
  cartItem: TeckentrupCartArticleTO;
};

export const DeliveryTimeChangeModal = ({
  onCancel,
  onSave,
  cartItem,
}: DeliveryTimeChangeModalProps) => {
  const [deliveryClassRangeFromCatalog, setDeliveryClassRangeFromCatalog] =
    useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { articleNumber, deliveryClass } = cartItem;
      if (!isEmpty(deliveryClass)) {
        const deliveryClassFromCatalog = await getDeliveryClassFromCatalog(
          articleNumber,
          deliveryClass,
          L10n.currentFullLocale(),
        );
        setDeliveryClassRangeFromCatalog(deliveryClassFromCatalog);
      }
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return (
      <CartWarningWrapper
        headerLabel={t("cart_title_delivery_time_change")}
        bodyLabel={<FontAwesomeIcon icon={faCircleNotch} />}
      />
    );
  }

  const onSaveWithLoading = () => {
    setLoading(true);
    onSave();
  };

  return isChangePossible(deliveryClassRangeFromCatalog) ? (
    <DeliveryTimeChangeCheckModal
      cartItem={cartItem}
      isLoading={loading}
      onCancel={onCancel}
      onSave={onSaveWithLoading}
      deliveryClassRange={deliveryClassRangeFromCatalog}
    />
  ) : (
    <CartWarningWrapper
      headerLabel={t("cart_title_delivery_time_change")}
      onCancel={onCancel}
      bodyLabel={t("cart_delivery_time_modal_no_change")}
      footerSaveLabel={t("ok")}
      onSave={onCancel}
    />
  );
};
