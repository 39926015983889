import { L10n } from "@encoway/l10n";

import { mapIndexed } from "../../../utilities/utilities";
import { Attribute } from "./attribute";

export const OrderedProductAttributes = ({
  headline,
  displayAsLink,
  documentationCharacteristics,
}) =>
  documentationCharacteristics ? (
    <div className="attribute">
      <div className="headline">{L10n.format(headline)}</div>
      <div className="value-container">
        <table data-documentation-characteristics={true}>
          <tbody>
            {mapIndexed(
              (char, index) => (
                <Attribute
                  key={index}
                  displayAsLink={displayAsLink}
                  characteristic={char}
                />
              ),
              documentationCharacteristics,
            )}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
