import { equals, isNil, test } from "ramda";
import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { Timeout } from "react-number-format/types/types";

import { randomName } from "../../utilities/smartInputUtils";

type SmartInputProps = Omit<
  ComponentPropsWithoutRef<"input">,
  "value" | "onBlur" | "onKeyPress"
> & {
  attributeId: string;
  autoComplete?: string;
  onChangeDelay?: number;
  pattern?: string;
  persistOnChange?: boolean;
  selectOnFocus?: boolean;
  value: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => Promise<string> | string;
  onKeyPress?: (
    event: KeyboardEvent<HTMLInputElement>,
  ) => Promise<string> | string;
};

export const SmartInput = ({
  value,
  onChange = undefined,
  onBlur,
  persistOnChange = false,
  autoComplete = "",
  onKeyPress,
  pattern = "",
  selectOnFocus = false,
  onChangeDelay = 0,
  attributeId,
  ...props
}: SmartInputProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [timerOn, setTimerOn] = useState(false);
  const [timer, setTimer] = useState<Timeout | null>(null);

  const setCurrentValueWithDefault = (inputValue: string = "") =>
    setCurrentValue(inputValue);

  const delaySetValue = () =>
    new Promise<void>((resolve) => {
      !isNil(timer) && clearTimeout(timer);
      setTimerOn(true);
      setTimer(
        setTimeout(() => {
          setTimerOn(false);
          resolve();
        }, onChangeDelay),
      );
    });

  const onChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const storedEvent = event;
    if (test(RegExp(pattern), event.target.value)) {
      setCurrentValueWithDefault(event.target.value);
      if (persistOnChange) {
        await delaySetValue();
        onChange?.(storedEvent);
      }
    }
  };

  const clearKeyPressTimer = () => {
    if (!isNil(timer)) {
      clearTimeout(timer);
      setTimerOn(false);
    }
  };

  const onBlurHandler = async (event: FocusEvent<HTMLInputElement>) => {
    if (
      onBlur &&
      test(RegExp(pattern), event.target.value) &&
      !equals(value, event.target.value)
    ) {
      setCurrentValueWithDefault(await onBlur(event));
      clearKeyPressTimer();
    }
  };

  const onKeyPressHandler = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      onKeyPress &&
      test(RegExp(pattern), event.currentTarget.value) &&
      Boolean(onKeyPress) &&
      event.key === "Enter"
    ) {
      setCurrentValueWithDefault(await onKeyPress(event));
      clearKeyPressTimer();
    }
  };

  useEffect(() => {
    !timerOn && setCurrentValueWithDefault(value);
  }, [value]);

  useEffect(() => clearKeyPressTimer, []);

  return (
    <input
      data-input-field-id={attributeId}
      {...props}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      onKeyPress={onKeyPressHandler}
      value={currentValue}
      {...(selectOnFocus && { onFocus: (e) => e.target.select() })}
      {...(autoComplete === "off" && {
        name: `${props.name || ""}_${randomName()}`,
        autoComplete: "off",
        autoCorrect: "off",
        autoCapitalize: "off",
        spellCheck: "false",
      })}
    />
  );
};
