import {
  append,
  filter,
  findIndex,
  flatten,
  isEmpty,
  isNil,
  remove,
  splitEvery,
  test,
  update,
} from "ramda";

import {
  addCustomer,
  getOrCreateCompany,
  getCustomers,
  removeCustomer,
  updateCustomer,
} from "../../service/customerManagementService";
import { escapeRegExCharacters } from "../offerManagement/offerManagementUtils";
import { SortCustomerList } from "./customerManagementUtils";

export const InitialCustomerManagementStore = {
  sorting: {
    paging: 10,
    page: 0,
    sort: "asc",
    sortCell: 1,
    sortKey: "customerName",
  },
  customers: [],
  customerSearch: null,
  searchValue: "",
};

export const GetCustomers = async (companyId) => {
  const {
    status,
    data: { pagedCustomers },
  } = await getCustomers();
  return (state) => ({
    ...state,
    status,
    customers: SortCustomerList(state.sorting.sortKey, state, pagedCustomers),
    companyId,
    sorting: {
      ...state.sorting,
      sort: "asc",
    },
  });
};

export const AddCustomer = async (companyId, customer) => {
  const {
    status,
    data: { returnValue },
  } = await addCustomer({ ...customer, ...companyId });
  return (state) => ({
    ...state,
    status,
    customers: splitEvery(
      state.sorting.paging,
      append(returnValue, flatten(state.customers)),
    ),
  });
};

const getEditedCustomerList = (customerList, newCustomerData, paging) => {
  if (isNil(customerList)) {
    return null;
  }

  return splitEvery(
    paging,
    update(
      findIndex(({ id }) => id === newCustomerData.id, flatten(customerList)),
      newCustomerData,
      flatten(customerList),
    ),
  );
};

export const EditCustomer = async (customer, companyId) => {
  const {
    status,
    data: { returnValue },
  } = await updateCustomer({ ...customer, ...companyId });
  return (state) => ({
    ...state,
    status,
    customers: getEditedCustomerList(
      state.customers,
      returnValue,
      state.sorting.paging,
    ),
    customerSearch: getEditedCustomerList(
      state.customerSearch,
      returnValue,
      state.sorting.paging,
    ),
  });
};

const removedCustomerFromList = (customerId, customerList, paging) => {
  if (isNil(customerList)) {
    return null;
  }

  return splitEvery(
    paging,
    remove(
      findIndex(({ id }) => id === customerId, flatten(customerList)),
      1,
      flatten(customerList),
    ),
  );
};

export const DeleteCustomer = async ({ customerId, companyId }) => {
  const { status } = await removeCustomer({ customerId, companyId });
  return (state) => ({
    ...state,
    status,
    customers: removedCustomerFromList(
      customerId,
      state.customers,
      state.sorting.paging,
    ),
    customerSearch: removedCustomerFromList(
      customerId,
      state.customerSearch,
      state.sorting.paging,
    ),
  });
};

export const PagingCustomers = (index) => (state) => ({
  ...state,
  sorting: {
    ...state.sorting,
    page: index,
  },
});

export const ChangeSortingState = (index, key) => (state) => ({
  ...state,
  sorting: {
    ...state.sorting,
    sort: state.sorting.sort === "asc" ? "desc" : "asc",
    sortCell: index,
    sortKey: key,
  },
});

export const SortCustomer = (key) => (state) => {
  const customerSearch = isEmpty(state.searchValue)
    ? state.customers
    : state.customerSearch;
  return {
    ...state,
    customers: SortCustomerList(key, state, state.customers),
    customerSearch: SortCustomerList(key, state, customerSearch),
  };
};

export const SearchCustomers = (searchString) => (state) => ({
  ...state,
  sorting: {
    ...state.sorting,
    page: 0,
  },
  searchValue: searchString,
  customerSearch: isEmpty(searchString)
    ? null
    : splitEvery(
        state.sorting.paging,
        filter(({ customerNumber, customerName }) => {
          const regExString = new RegExp(
            `${escapeRegExCharacters(searchString)}.*`,
            "gi",
          );
          return (
            test(regExString, customerNumber.toLowerCase()) ||
            test(regExString, customerName.toLowerCase())
          );
        }, flatten(state.customers)),
      ),
});

export const GetOrCreateCompany = async () => {
  const {
    data: { returnValue },
  } = await getOrCreateCompany();
  return { companyId: returnValue.id };
};
