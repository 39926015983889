import { getEnvironmentVariables, getFrontendUrl } from "../utilities/urlUtils";

declare const configuratorSettings: {
  baseUrl: string;
};

/**
 * Config
 */
export const Config = {
  Service: {
    BaseUrl:
      typeof configuratorSettings !== "undefined"
        ? configuratorSettings.baseUrl
        : getEnvironmentVariables().baseUrl,
    BackendPath: "/teckentrup-server",
    CustomerManagementBaseUrl:
      getEnvironmentVariables().customerManagementBaseUrl,
  },
  CustomerManagementService: {
    Credentials: {
      user: "user",
      password: "pass",
    },
  },
  Credentials: {
    user: "qaBaseAPI",
    password: "qa_base.46_krd",
  },
  Lang: {
    Default: "de-DE",
    German: "de-DE",
    English: "en-US",
    French: "fr-FR",
    Dutch: "nl-NL",
    Spanish: "es-ES",
  },
  DateFormat: {
    Default: "dd.MM.yyyy",
  },
  ImageResolution: {
    Medium: "&resolution=medium",
  },
  Visualization: {
    baseUrl: "https://visualization.encoway.cloud/vcs",
    version: "1.7.1",
    exporterVersion: "1.7.1",
    token:
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aXN1YWxpemF0aW9uIiwiYXVkIjoiaHR0cHM6Ly93d3cudGVja2VudHJ1cC5iaXovIiwiaXNzIjoiVGVja2VudHJ1cCIsImlhdCI6MTY1MjE3OTA0NSwiZXhwIjoxNzQ2ODM1MjAwLCJjYWQiOlsiZHhmIiwiZHdnIiwiaWZjIl0sInRvb2xzIjpudWxsLCJyZW5kZXJpbmciOiJoZCIsImFyIjoiMTQifQ.HcHrTYPszKohogKLBqh92PSA4jEQKJLfwjRpEOncZzs",
    settings: {
      theme: {
        palette: {
          themePrimary: "#ef7b22",
          themeLighterAlt: "#fef9f6",
          themeLighter: "#fde9da",
          themeLight: "#fad6ba",
          themeTertiary: "#f6ae77",
          themeSecondary: "#f28a3b",
          themeDarkAlt: "#d86f1e",
          themeDark: "#b65d1a",
          themeDarker: "#864513",
          neutralLighterAlt: "#faf9f8",
          neutralLighter: "#f3f2f1",
          neutralLight: "#edebe9",
          neutralQuaternaryAlt: "#e1dfdd",
          neutralQuaternary: "#d0d0d0",
          neutralTertiaryAlt: "#c8c6c4",
          neutralTertiary: "#a19f9d",
          neutralSecondary: "#605e5c",
          neutralPrimaryAlt: "#3b3a39",
          neutralPrimary: "#323130",
          neutralDark: "#201f1e",
          black: "#000000",
          white: "#ffffff",
        },
      },
      features: {
        Gizmo: "off",
        OrientationIndicator: "on",
        Selection: "off",
        HighlightHoverItem: "off",
        ContextMenu: "right",
      },
      ui: {
        controls: {
          rotation: true,
          autozoom: true,
          minDistance: 10,
          maxDistance: 10000,
          minAzimuthAngle: -Infinity,
          maxAzimuthAngle: Infinity,
          minPolarAngle: 0,
          maxPolarAngle: Math.PI / 2,
        },
        sidebar: {
          type: "panel",
        },
        camera: {
          near: 10,
          far: 100000.0,
          fov: 60.0,
          position: {
            x: 100,
            y: 100,
            z: 100,
          },
        },
        contextMenu: {
          info: false,
          state: true,
          configure: false,
          configure_structure: false,
          change_product: false,
          clone: false,
          delete: false,
          delete_structure: false,
          rotation_roll: false,
          rotation_flip: false,
          fill_line_anchor: false,
        },
        contextActions: false,
        globalDrop: false,
        background: 0xffffff,
        lights: [
          {
            type: "ambient",
            name: "#AmbientLight",
            color: "#404040",
          },
          {
            type: "directional",
            position: {
              x: 2.6,
              y: 1,
              z: -3,
            },
            name: "#BackLight",
            intensity: 0.75,
          },
          {
            type: "directional",
            position: {
              x: -2,
              y: -1,
              z: 1,
            },
            name: "#FrontLight",
            intensity: 0.75,
          },
          {
            type: "directional",
            position: {
              x: 1,
              y: 4,
              z: 4,
            },
            name: "#TopFrontLight",
            intensity: 0.5,
          },
          {
            type: "directional",
            position: {
              x: 0,
              y: 5,
              z: 0,
            },
            name: "#TopLight",
            intensity: 0.5,
          },
        ],
        components: {
          catalog: false,
          header: false,
          toolbar: "east",
        },
        toolbar: {
          ViewIsometric: true,
          ViewTop: true,
          ViewFront: true,
          select: true,
          measure_distance: true,
          dimensioning: {
            singleDimensioning: false,
            datumDimensioning: false,
            customDimensioning: true,
          },
          grid: false,
          snap_objects: false,
          help: false,
          configuration: false,
          autozoom: true,
        },
        dimensioning: {
          autoEnabled: false,
          lineColor: "#ef7b22",
          textColor: "#000000",
        },
      },
    },
  },
  ArService: {
    baseUrl: "https://ar-service.eu.encoway.cloud",
    placement: "floor",
    retentionTimeInDays: 7,
  },
  LvImport: {
    baseUrl:
      typeof configuratorSettings !== "undefined"
        ? configuratorSettings.baseUrl
        : getFrontendUrl(),
    // is needed to render the selected PDF file using react-pdf (the imported worker file is in the public folder)
    // see https://github.com/wojtekmaj/react-pdf/issues/782#issuecomment-1407556622
    pdfWorkerSrc: "/pdf.worker.min.3.11.174.js",
    studioModel: "LV_TEST_TUER01",
  },
} as const;

export type AppLocale = (typeof Config.Lang)[keyof typeof Config.Lang];
