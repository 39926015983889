import classnames from "classnames";
import { pathOr } from "ramda";
import { useEffect, useState } from "react";

import { renderPrice } from "@encoway/react-components";

import { ParameterTO } from "../../../types/@encoway/Parameter";
import { ConfiguratorComponentProps } from "../../../types/configuration";

/**
 * An image button view.
 * @class ImageButton
 */

export default function ImageButton(
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) {
  const [loading, setLoading] = useState("");
  const [selectedButton, setSelectedButton] = useState("");

  useEffect((): void => {
    props.loading ? setLoading(selectedButton) : setLoading("");
  }, [props.loading]);

  /**
   * Helper to handle value set events.
   * @param value {string} the selected value
   */
  function selected(value: string): void {
    props.onValueChanged(props.data, value);
    setLoading(value);
    setSelectedButton(value);
  }

  const items = props.data.values?.map((value) => {
    const classNames = classnames({
      button: true,
      "is-image": true,
      "is-loading":
        (!value.selected && loading === value.value) ||
        (value.selected && props.loading),
      "is-primary": value.selected,
      "is-selectable": !value.selected && value.selectable,
      "is-not-selectable": !value.selected && !value.selectable,
      s: props.style.size === "s" || !props.style.size,
      m: props.style.size === "m",
      l: props.style.size === "l",
      xl: props.style.size === "xl",
    });
    const price = renderPrice(
      pathOr(0, ["price"], value),
      props.options.currency,
      "price tag is-small",
    );
    const dataIdValue = `${props.data.name}-${value.value}`;
    return (
      <button
        key={value.value}
        className={classNames}
        onClick={() => selected(value.value)}
        title={value.translatedValue}
        data-image-button-id={dataIdValue}
        {...(value.selected && {
          "data-selected-image-button-id": dataIdValue,
        })}
      >
        <figure className="image">
          <img src={props.mediaLink(value.imageUrl)} alt="" />
        </figure>
        <p className="caption">
          {value.translatedValue}
          {price}
        </p>
      </button>
    );
  });

  return <p className="control is-image-button">{items}</p>;
}
