import { isNil } from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { OfferManagementService } from "@encoway/cart-service-js-client";

import { Loading } from "../../components/loading";
import { AppContext } from "../../hooks/useApp";
import { CartContext } from "../../hooks/useCart";
import { ConfigurationContext } from "../../hooks/useConfiguration";
import { ROUTES } from "../../http/constants";
import { AppStore } from "../../types/app";
import { CartStore } from "../../types/cart";
import { hasUserUnsavedCart } from "../../utilities/cartUtils";
import { ClearCartWarningModal } from "./components/modals/clearCartWarningModal";
import { useCartModals } from "./hooks/useCartModals";

export const SharedShoppingCart = () => {
  const { shareId } = useParams();
  const navigate = useNavigate();
  const [sharingCart, setSharingCart] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { cart, cartActions } = useContext<CartStore>(CartContext);
  const { serviceFactory } = useContext<AppStore>(AppContext);
  const configuration = useContext(ConfigurationContext);
  const offerManagementService = useMemo<OfferManagementService>(
    () => serviceFactory.createQuoteService(),
    [],
  );
  const { modalActions } = useCartModals(
    cartActions,
    offerManagementService,
    configuration,
  );

  const openSharedCartAndNavigate = (): void => {
    setLoading(true);
    if (isNil(shareId)) {
      navigate("/");
      return;
    }
    modalActions.openSharedCart(shareId).then(() => {
      setLoading(false);
      setSharingCart(false);
    });
  };

  useEffect(() => {
    if (!hasUserUnsavedCart(cart)) {
      openSharedCartAndNavigate();
    }
  }, []);

  if (sharingCart) {
    return (
      <>
        <Loading />
        {hasUserUnsavedCart(cart) && (
          <ClearCartWarningModal
            onSubmit={openSharedCartAndNavigate}
            onCancel={() => setSharingCart(false)}
            sharing={true}
            isLoading={loading}
          />
        )}
      </>
    );
  }
  return <Navigate to={ROUTES.CART} />;
};
