import { useEffect, useState } from "react";

import { Config } from "../../config/config";

import "./extendableImage.scss";

const ESCAPE_KEY_CODE = 27;

const ImageOverlay = ({ src, onKeyDown }) => {
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);

  return (
    <div className="overlay">
      <img
        className="bigImage"
        src={`${src}${Config.ImageResolution.Medium}`}
        alt="big overlay"
      />
    </div>
  );
};

export const ExtendableImage = ({ src }) => {
  const [isBig, setIsBig] = useState(false);
  const toggleIsBig = () => setIsBig(!isBig);
  const onKeyDownImageOverlay = (e) =>
    e.keyCode === ESCAPE_KEY_CODE && setIsBig(false);

  return (
    <div onClick={() => toggleIsBig()} className="extendableImage">
      <img
        className="image"
        data-product-image-url={src.replace(/[.:?=/]/g, "-")}
        src={`${src}${Config.ImageResolution.Medium}`}
        alt="overlay"
      />
      {isBig && <ImageOverlay src={src} onKeyDown={onKeyDownImageOverlay} />}
    </div>
  );
};
