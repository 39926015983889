import { omit } from "ramda";
import { RefObject } from "react";

import { FreeArticleTO } from "../../../../types/cart";

export const isClickedOutsideOfNode = (
  nodeRef: RefObject<HTMLDivElement>,
  event: MouseEvent,
) =>
  Boolean(nodeRef && !nodeRef.current?.contains(event.target as HTMLElement));

export const prepareFreeCartItem = (freeItem: FreeArticleTO) => {
  const formattedBasePrice = freeItem.basePrice.toString();
  const freeItemWithoutAmount = omit(["amount"], freeItem);
  return { ...freeItemWithoutAmount, basePrice: formattedBasePrice };
};
