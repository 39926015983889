import {
  any,
  equals,
  find,
  includes,
  is,
  isNil,
  path,
  pathOr,
  propEq,
} from "ramda";

import { OfferManagementTableRow } from "../pages/offerManagement/offerManagementTable/offerManagementTableUtils";
import { cartViews } from "../pages/shoppingCart/hooks/useCartView";
import { HeaderData } from "../types/cart";
import { TokenPayload, UiAuthorities, ValueAuthority } from "../types/identity";
import { AUTHORITY_ROLE, Authorities, AuthorityRoleKey } from "./authorities";
import IdentityStore from "./identityStore";

const AUTHORITY_ID = "authorityId";
export const UNKNOWN = "Unknown";

const noIdentity = (identity?: TokenPayload, authorities?: UiAuthorities) =>
  isNil(identity) || isNil(path(["roles"], authorities));

export const isAuthorityRole = (
  authorityRoleKey: AuthorityRoleKey | AuthorityRoleKey[],
  identityStore: IdentityStore | null,
  authorities?: UiAuthorities,
) => {
  if (noIdentity(identityStore?.getIdentity(), authorities)) {
    return false;
  }
  const authorityRoleKeyArray = is(Array, authorityRoleKey)
    ? authorityRoleKey
    : [authorityRoleKey];
  return any(
    (key) => includes(AUTHORITY_ROLE[key], authorities!.roles),
    authorityRoleKeyArray,
  );
};

export const cartBelongsToCurrentUser = (
  identityStore: IdentityStore | null,
  headerData: OfferManagementTableRow | HeaderData,
) => {
  if (!headerData) {
    return false;
  }
  const { clientId, accountId } = headerData;
  const company = identityStore?.getCompany();
  if (isNil(company)) {
    return false;
  }
  if (!accountId || equals(accountId, UNKNOWN)) {
    return equals(company.FirmenSAPKundennummer, clientId);
  } else if (
    !path(["FirmenSAPKundennummer"], company) ||
    equals(company.FirmenSAPKundennummer, UNKNOWN)
  ) {
    return equals(accountId, company.AccountId);
  }
  return (
    equals(accountId, company.AccountId) ||
    equals(company.FirmenSAPKundennummer, clientId)
  );
};

const isUserCreatorOfQuote = (
  identityStore: IdentityStore | null,
  itemUserId: string,
) => equals(pathOr("", ["Email"], identityStore?.getUser()), itemUserId);

export const isCurrentUserAdminOrFromSameCompanyAsCreator = (
  identityStore: IdentityStore,
  headerData: HeaderData,
  authorities: UiAuthorities,
) =>
  isAuthorityRole("ADMIN", identityStore, authorities) ||
  cartBelongsToCurrentUser(identityStore, headerData);

export const hasRight = (
  permission: string,
  booleanAuthorities: string[] = [],
) => includes(permission, booleanAuthorities);

export const getValueAuthorities = (
  permission: string,
  valueAuthorities: ValueAuthority[] = [],
) =>
  find(propEq(AUTHORITY_ID, permission), valueAuthorities)?.authorityValues ||
  [];

export const hasNoRightToOrderCart = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_BUTTON_DEACTIVATE_ORDER, booleanAuthorities);

export const hasRightToSeeOrderButton = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_BUTTON_VISIBLE_ORDER, booleanAuthorities) ||
  hasNoRightToOrderCart(booleanAuthorities);

export const hasRightToSeeDbCheck = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_BUTTON_VISIBLE_DB_CHECK, booleanAuthorities);

export const hasRightToShareCart = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_BUTTON_VISIBLE_SHARE, booleanAuthorities);

export const hasNoRightToPrintDocument = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_BUTTON_DEACTIVATE_EXPORT_QUOTE, booleanAuthorities);

export const hasNoRightToExportExcel = (booleanAuthorities: string[]) =>
  hasRight(
    Authorities.CART_BUTTON_DEACTIVATED_EXPORT_EXCEL,
    booleanAuthorities,
  );

export const hasNoRightToModifyOffer = (booleanAuthorities: string[]) =>
  hasRight(
    Authorities.CART_BUTTON_DEACTIVATE_HEADER_DATA_EDIT,
    booleanAuthorities,
  );

export const hasRightToSeeFreeItem = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_BUTTON_VISIBLE_FREE_POSITION, booleanAuthorities);

export const hasRightToSeeConexLink = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CONFIGURATION_VISIBLE_CONEX, booleanAuthorities);

export const hasRightToSeeBuildingProject = (booleanAuthorities: string[]) =>
  hasRight(
    Authorities.CART_INFORMATION_VISIBLE_BUILDING_PROJECT,
    booleanAuthorities,
  );

export const hasRightToSeeVisibleReference = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_INFORMATION_VISIBLE_REFERENCE, booleanAuthorities);

export const hasRightToSeeCommentaryExtra = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_TEXT_VISIBLE_COMMENTARY_EXTRA, booleanAuthorities);

export const hasRightToSeeFreeItemExtra = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_TEXT_VISIBLE_FREE_ITEM_EXTRA, booleanAuthorities);

export const hasRightToSeeCommentaryExtraOM = (booleanAuthorities: string[]) =>
  hasRight(
    Authorities.OFFER_MANAGEMENT_VISIBLE_COMMENTARY_EXTRA,
    booleanAuthorities,
  );

export const hasRightToImportLv = (booleanAuthorities: string[]) =>
  hasRight(Authorities.OFFER_MANAGEMENT_IMPORT_LV, booleanAuthorities);

export const hasRightToConfigureLv = (booleanAuthorities: string[]) =>
  hasRight(Authorities.OFFER_MANAGEMENT_Configure_LV, booleanAuthorities);

export const hasRightToSeeExtendedHeaderData = (booleanAuthorities: string[]) =>
  hasRight(Authorities.HEADER_DATA_EXTENDED_INFORMATION, booleanAuthorities);

export const hasRightToSeeShareQuoteHeaderData = (
  booleanAuthorities: string[],
) => hasRight(Authorities.OFFER_SHARED_METADATA, booleanAuthorities);

export const hasRightToPrintXML = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_PRINT_XML_VISIBLE, booleanAuthorities);

export const hasRightToPrintEnDocXML = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_PRINT_EN_DOC_XML_VISIBLE, booleanAuthorities);

export const hasRightToSeeVisualization = (booleanAuthorities: string[]) =>
  hasRight(Authorities.VISUALIZATION_VISIBLE, booleanAuthorities);

export const hasRightToUseAr = (booleanAuthorities: string[]) =>
  hasRight(Authorities.VISUALIZATION_AR_VISIBLE, booleanAuthorities);

export const hasRightToSeeShippingMethodDropdown = (
  booleanAuthorities: string[],
) =>
  hasRight(
    Authorities.CART_DROPDOWN_VISIBLE_SHIPPING_METHOD,
    booleanAuthorities,
  );

export const hasRightToSeeBurgerMenuEntries = (
  valueAuthorities: ValueAuthority[],
  identityStore: IdentityStore | null,
  itemCreatorUserId: string,
) => {
  const values = getValueAuthorities(
    Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.NAME,
    valueAuthorities,
  );
  return {
    viewCart: includes(
      Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.VIEW_CART,
      values,
    ),
    edit: includes(
      Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.EDIT,
      values,
    ),
    archive: includes(
      Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.ARCHIVE,
      values,
    ),
    duplicate: includes(
      Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.DUPLICATE,
      values,
    ),
    delete:
      includes(
        Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.DELETE,
        values,
      ) && isUserCreatorOfQuote(identityStore, itemCreatorUserId),
    inspectCart: includes(
      Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.INSPECT_CART,
      values,
    ),
    reactive: includes(
      Authorities.OFFER_MANAGEMENT_VISIBLE_BURGER_MENU.VALUES.REACTIVE,
      values,
    ),
  };
};

export const isInReadOnlyMode = (booleanAuthorities: string[]) =>
  hasRight(Authorities.CART_READ_ONLY_MODE, booleanAuthorities);

export const hasRightToSeeBurgerMenuEntriesCart = (
  valueAuthorities: ValueAuthority[],
) => {
  const values = getValueAuthorities(
    Authorities.CART_VISIBLE_BURGER_MENU.NAME,
    valueAuthorities,
  );
  return {
    reconfigure: includes(
      Authorities.CART_VISIBLE_BURGER_MENU.VALUES.RECONFIGURE,
      values,
    ),
    deliveryChange: includes(
      Authorities.CART_VISIBLE_BURGER_MENU.VALUES.DELIVERY_CHANGE,
      values,
    ),
    drawing: includes(
      Authorities.CART_VISIBLE_BURGER_MENU.VALUES.DRAWING,
      values,
    ),
    comment: includes(
      Authorities.CART_VISIBLE_BURGER_MENU.VALUES.COMMENT,
      values,
    ),
    copy: includes(Authorities.CART_VISIBLE_BURGER_MENU.VALUES.COPY, values),
    delete: includes(
      Authorities.CART_VISIBLE_BURGER_MENU.VALUES.DELETE,
      values,
    ),
  };
};

export const hasRightToSeeQuoteCalculationView = (
  valueAuthorities: ValueAuthority[],
) => {
  const values = getValueAuthorities(
    Authorities.CART_VISIBLE_CALCULATION_VIEW.NAME,
    valueAuthorities,
  );
  return {
    [cartViews.view.buyerView.name]: includes(
      Authorities.CART_VISIBLE_CALCULATION_VIEW.VALUES.BUYER,
      values,
    ),
    [cartViews.view.customerView.name]: includes(
      Authorities.CART_VISIBLE_CALCULATION_VIEW.VALUES.CUSTOMER,
      values,
    ),
    [cartViews.view.salesView.name]: includes(
      Authorities.CART_VISIBLE_CALCULATION_VIEW.VALUES.SALES,
      values,
    ),
    [cartViews.view.orderView.name]: includes(
      Authorities.CART_VISIBLE_CALCULATION_VIEW.VALUES.ORDER,
      values,
    ),
  };
};

/**
 * Will be moved to the backend at a later date if necessary, clarification with PD ongoing.
 *
 * @param booleanAuthorities of logged in role.
 * @returns true if the logged in role has the right, otherwise false
 */
export const hasRightToSeeAllProducts = (booleanAuthorities: string[] = []) =>
  hasRight(Authorities.CATALOG_VISIBLE_ALL_PRODUCTS, booleanAuthorities);
