import { isNil, pathOr } from "ramda";
import { ChangeEvent, useEffect, useReducer, useRef, useState } from "react";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
  SaveButton,
} from "../../../components/modal/modal";
import { customerExists } from "../../../service/customerManagementService";
import { ExtendedTeckentrupCartTO } from "../../../types/cart";
import {
  saveProcessAndGoToQuoteManagementEvent,
  saveProcessEvent,
  track,
} from "../../../utilities/eventTracking";
import {
  ContactDto,
  CustomerDto,
} from "../../customerManagement/customerManagementUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { CustomerSearchTable } from "./customerSearchTable/customerSearchTable";
import { CancelModal } from "./modifyOffer/cancelModal";
import { ModifyOffer } from "./modifyOffer/modifyOffer";
import {
  isComplete,
  isNotEmptyAndWithoutWhitespaces,
  isSelectedCustomerChangedOrSearchValueEmpty,
} from "./modifyOfferDialogUtils";
import {
  AddCustomer,
  CustomerInputChange,
  CustomerNumberChange,
  CustomerSearchSave,
  EditCustomer,
  GetCustomers,
  InitialModifyOfferDialogState,
  InputChange,
  ModifyOfferDialogReducedState,
  ModifyOfferDialogState,
  ModifyOfferDialogStateObjectKeys,
  PreSelectCustomer,
  ResetCustomerNumberTag,
  ResetSearchValue,
  SearchValueChange,
  SetCustomerAddress,
  SetCustomerNumberExists,
  SetShippingAddress,
  ShippingAddressChange,
  ShippingAddressMandatoriesCompleted,
  ToggleAddCustomerLoading,
  ToggleCancelDialog,
  ToggleCustomerAddedToolTip,
  ToggleCustomerNumberNotSetWarning,
  ToggleSelectedCustomer,
  prepareModifyOfferDialogState,
} from "./modifyOfferModalActions";

import "./modifyOfferModal.scss";

const reducer = (
  state: ModifyOfferDialogReducedState,
  action: (
    state: ModifyOfferDialogReducedState,
  ) => ModifyOfferDialogReducedState,
) => action(state);

type ModifyOfferModalProps = {
  closeDialogMethod: () => void;
  saveQuote: (
    checkoutInformation: ModifyOfferDialogReducedState,
    isNavigate?: boolean,
  ) => void;
  quote?: ExtendedTeckentrupCartTO;
  companyId?: number | null;
  isSaveAndNavigateButton?: boolean;
};

export const ModifyOfferModal = ({
  closeDialogMethod,
  saveQuote,
  quote,
  companyId,
  isSaveAndNavigateButton = false,
}: Readonly<ModifyOfferModalProps>) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [toggleCustomerSearch, setToggleCustomerSearch] = useState(false);
  const [searchFilterLoading, setSearchFilterLoading] = useState(false);
  const initialState = quote
    ? prepareModifyOfferDialogState(quote)
    : InitialModifyOfferDialogState;
  const [saved, setSaved] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isComplete: isComplete(
      initialState.projectInformation,
      initialState.shippingAddressMandatoriesCompleted,
    ),
  });
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      dispatch(await GetCustomers(companyId));
      dispatch(SetCustomerNumberExists);
      dispatch(PreSelectCustomer);
    })();
  }, []);

  const onInputChange =
    (prop: ModifyOfferDialogStateObjectKeys) =>
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      dispatch(InputChange(name, value, prop));

  const onSetShippingAddress = (
    value: ModifyOfferDialogState["shippingAddress"],
  ) => dispatch(SetShippingAddress(value));

  const onCustomerInputChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    dispatch(CustomerInputChange(name, value));

  const onShippingAddressMandatoriesCompleted = (value: boolean) =>
    dispatch(ShippingAddressMandatoriesCompleted(value));

  const onShippingAddressChange = (name: string, value: string) =>
    dispatch(ShippingAddressChange(name, value));

  const onCustomerNumberChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => dispatch(CustomerNumberChange(value));

  const onTitleChange = (title: string) =>
    dispatch(InputChange("title", title, "customerInformation"));

  const onCancel = () =>
    state.edited ? dispatch(ToggleCancelDialog) : closeDialogMethod();

  const onCustomerSearchToggle = () => {
    const { customerInformation, customerSearch } = state;
    dispatch(
      SearchValueChange(
        pathOr("", ["searchValue"], customerSearch.searchValue),
      ),
    );
    isSelectedCustomerChangedOrSearchValueEmpty(
      customerInformation,
      customerSearch,
    ) && dispatch(PreSelectCustomer);
    setToggleCustomerSearch(true);
  };

  const onSearchValueChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterLoading(true);
    dispatch(SearchValueChange(value));
    setSearchFilterLoading(false);
  };

  const onToggleSelectedCustomer = (customer: CustomerDto) => () =>
    dispatch(ToggleSelectedCustomer(customer));

  const onSetCustomerAddress = (address: ContactDto) =>
    dispatch(SetCustomerAddress(address));

  const onCustomerSearchSave = () => {
    dispatch(CustomerSearchSave);
    setToggleCustomerSearch(false);
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    if (!toggleCustomerSearch && !isNil(bottomRef.current)) {
      // Scrolls to the end of the modal to see all input fields after saving
      // the address without scrolling by hand
      bottomRef.current.scrollIntoView();
    }
  }, [toggleCustomerSearch]);

  const onCustomerSearchReset = () => dispatch(ResetSearchValue);

  const onAddCustomer = async () => {
    const {
      customerSearch: { selectedCustomer, customers },
      customerInformation,
    } = state;
    const customerNumberIsValid = isNotEmptyAndWithoutWhitespaces(
      customerInformation.customerNumber,
    );
    const customerNameIsValid = isNotEmptyAndWithoutWhitespaces(
      customerInformation.customerName,
    );

    if (customerNumberIsValid && customerNameIsValid) {
      dispatch(ToggleAddCustomerLoading(false));
      const { customerNumber } = customerInformation;
      const { data } = await customerExists({ customerNumber });
      data
        ? dispatch(
            await EditCustomer(
              customerInformation,
              selectedCustomer!,
              customers,
            ),
          )
        : dispatch(await AddCustomer(customerInformation, companyId));
      dispatch(ToggleAddCustomerLoading(true));
      setTimeout(() => dispatch(ToggleCustomerAddedToolTip(false)), 3000);
    } else {
      dispatch(
        ToggleCustomerNumberNotSetWarning(
          !customerNumberIsValid,
          !customerNameIsValid,
        ),
      );
    }
  };

  const onResetCustomerAddedToolTip = () =>
    dispatch(ToggleCustomerAddedToolTip(false));

  const onResetCustomerNumberTag = () => dispatch(ResetCustomerNumberTag);

  const saveQuoteAndTrack = (
    state: ModifyOfferDialogReducedState,
    navigate?: boolean,
  ) => {
    track(navigate ? saveProcessAndGoToQuoteManagementEvent : saveProcessEvent);
    saveQuote(state, navigate);
  };

  return (
    <>
      <Modal
        dialogId={
          toggleCustomerSearch ? "select_customer" : "modify_dialog_title"
        }
      >
        <ModalHeader
          label={t(
            toggleCustomerSearch ? "select_customer" : "modify_dialog_title",
          )}
          onCancel={
            toggleCustomerSearch
              ? () => setToggleCustomerSearch(false)
              : onCancel
          }
        />
        <ModalBodyWrapper overflowY={true} className="modify-offer-modal">
          {toggleCustomerSearch ? (
            <CustomerSearchTable
              onSearchValueChange={onSearchValueChange}
              onToggleSelectedCustomer={onToggleSelectedCustomer}
              onSetCustomerAddress={onSetCustomerAddress}
              onCustomerSearchReset={onCustomerSearchReset}
              searchFilterLoading={searchFilterLoading}
              state={state.customerSearch}
            />
          ) : (
            <>
              <ModifyOffer
                quote={quote}
                onAddCustomer={onAddCustomer}
                onInputChange={onInputChange}
                onCustomerInputChange={onCustomerInputChange}
                onCustomerNumberChange={onCustomerNumberChange}
                onCustomerSearchToggle={onCustomerSearchToggle}
                onTitleChange={onTitleChange}
                onResetCustomerAddedToolTip={onResetCustomerAddedToolTip}
                onResetCustomerNumberTag={onResetCustomerNumberTag}
                onShippingAddressMandatoriesCompleted={
                  onShippingAddressMandatoriesCompleted
                }
                onShippingAddressChange={onShippingAddressChange}
                onSetShippingAddress={onSetShippingAddress}
                state={state}
              />
              <div className="bottom-anchor" ref={bottomRef} />
            </>
          )}
        </ModalBodyWrapper>
        <ModalFooter
          cancelLabel={t("cancel")}
          saveLabel={t(
            toggleCustomerSearch ? "customer_search_accept" : "save",
          )}
          saveButtonId={
            toggleCustomerSearch ? "customer_search_accept" : "save"
          }
          onCancel={
            toggleCustomerSearch
              ? () => setToggleCustomerSearch(false)
              : onCancel
          }
          onSave={
            toggleCustomerSearch
              ? onCustomerSearchSave
              : () => {
                  saveQuoteAndTrack(state, false);
                  setSaved(true);
                }
          }
          disabled={
            toggleCustomerSearch
              ? !state.customerSearch.selectedAddress
              : !state.isComplete || saved
          }
        >
          {isSaveAndNavigateButton && !toggleCustomerSearch && (
            <SaveButton
              saveLabel={t("modify_dialog_save_to_quote_management")}
              saveButtonId="modify_dialog_save_to_quote_management"
              onSave={
                toggleCustomerSearch
                  ? onCustomerSearchSave
                  : () => saveQuoteAndTrack(state, true)
              }
              disabled={
                toggleCustomerSearch
                  ? !state.customerSearch.selectedAddress
                  : !state.isComplete
              }
            />
          )}
        </ModalFooter>
      </Modal>
      {state.toggleCancelDialog && (
        <CancelModal
          onClose={() => dispatch(ToggleCancelDialog)}
          onCancel={closeDialogMethod}
          onSave={() => dispatch(ToggleCancelDialog)}
        />
      )}
    </>
  );
};
