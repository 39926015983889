import classNames from "classnames";
import { find, map } from "ramda";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { L10n } from "@encoway/l10n";
import { Sticky } from "@encoway/react-components";

import { ConfigurationContext } from "../../hooks/useConfiguration";
import { useLocaleNumberFormat } from "../../hooks/useLocaleNumberFormat";
import { ROUTES } from "../../http/constants";
import { VariantConditions } from "../../types/app";
import { VariantConditionPrices } from "../../types/configuration";
import { addPositionEvent, trackFunction } from "../../utilities/eventTracking";
import { VisualizationContainer } from "./visualizationContainer";

type BasePriceProps = {
  variantConditionPrices: VariantConditionPrices;
};

type TotalPriceProps = {
  variantConditionPrices: VariantConditionPrices;
};

type VariantConditionProps = {
  variantCondition: VariantConditions;
};

type VariantConditionPriceBarProps = {
  variantConditionPrices: VariantConditionPrices | null;
};

function BasePrice({ variantConditionPrices }: Readonly<BasePriceProps>) {
  const { formatPrice } = useLocaleNumberFormat();
  const basePriceConditions = find(
    (variantCondition) => variantCondition.zvag,
    variantConditionPrices.variantConditions,
  );
  if (basePriceConditions) {
    return (
      <a key={basePriceConditions.id}>
        <div className="is-pulled-right" data-label-id="condition-basic-price">
          {formatPrice(variantConditionPrices.conditionZVAGPriceSum!)}
        </div>
        <div data-label-id="basic-price">{basePriceConditions.label}</div>
      </a>
    );
  }
  return null;
}

function TotalPrice({ variantConditionPrices }: Readonly<TotalPriceProps>) {
  const { formatPrice } = useLocaleNumberFormat();
  const { priceTZ, percentageTZ, totalPriceSum, totalPriceSumWithTZ } =
    variantConditionPrices;
  if (percentageTZ) {
    return (
      <div>
        <a>
          <div
            className="boldText is-pulled-right"
            data-label-id={"condition-total-gross-price"}
          >
            {formatPrice(totalPriceSum!)}
          </div>
          <div className="boldText">
            {L10n.format("pricing_total_price_gross")}
          </div>
        </a>
        <a>
          <div className="is-pulled-right" data-label-id={"condition-tz-price"}>
            {formatPrice(priceTZ!)}
          </div>
          <div
            className="calculationSymbol"
            data-label-id="condition-calculation-tz"
          >
            +
          </div>
          <div>
            {L10n.format("price_details_surcharge")} ({percentageTZ}%)
          </div>
        </a>
        <hr />
        <hr />
        <a>
          <div
            className="boldText is-pulled-right"
            data-label-id={"condition-total-gross-tz-price"}
          >
            {formatPrice(totalPriceSumWithTZ!)}
          </div>
          <div className="boldText">
            {L10n.format("pricing_total_price_gross_inkl_tz")}
          </div>
        </a>
      </div>
    );
  }
  return (
    <a>
      <div
        className="boldText is-pulled-right"
        data-label-id={"condition-total-price"}
      >
        {formatPrice(totalPriceSum!)}
      </div>
      <div className="boldText" data-label-id="total-price">
        {L10n.format("pricing_total_price_gross")}
      </div>
    </a>
  );
}

function VariantCondition({
  variantCondition,
}: Readonly<VariantConditionProps>) {
  const { formatPrice } = useLocaleNumberFormat();
  if (variantCondition.zagp || variantCondition.zva0 || variantCondition.zvam) {
    return (
      <a key={variantCondition.id}>
        <div
          className="calculationSymbol"
          data-label-id={`condition-calculation-symbol-${variantCondition.id}`}
        >
          +
        </div>
        <div
          className="is-pulled-right"
          data-label-id={`condition-${variantCondition.id}-price`}
        >
          {formatPrice(variantCondition.conditionTotalPrice!)}
        </div>
        <div
          className="variantCondition-label"
          data-label-id={`condition-${variantCondition.id}`}
        >
          {variantCondition.label}
        </div>
      </a>
    );
  }
  return null;
}

function VariantConditionPriceBar({
  variantConditionPrices = null,
}: Readonly<VariantConditionPriceBarProps>) {
  if (variantConditionPrices && variantConditionPrices.state !== "CANCELLED") {
    return (
      <aside className="menu" style={{ fontSize: "0.8rem" }}>
        <ul className="menu-list">
          <li>
            <BasePrice variantConditionPrices={variantConditionPrices} />
            {map(
              (variantCondition) => (
                <VariantCondition variantCondition={variantCondition} />
              ),
              variantConditionPrices.variantConditions,
            )}
            <hr />
            <hr />
            <TotalPrice variantConditionPrices={variantConditionPrices} />
          </li>
        </ul>
      </aside>
    );
  }
  return null;
}

export function ResultContainer() {
  const navigate = useNavigate();
  const {
    save,
    loading,
    configurationId,
    guiTO,
    variantConditionPrices,
    articleName,
  } = useContext(ConfigurationContext);
  const { translatedName, rootContainer } = guiTO!;

  async function onSave(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    save(configurationId!);
    navigate(ROUTES.CART, { replace: true });
  }

  return (
    <div className="column is-one-quarter">
      <div className="is-source">
        <Sticky source=".is-source" target=".result">
          <div className="result" style={{ height: "80%" }}>
            <div className="resultContainer">
              <div className="boldText" data-label-id="result-title">
                {translatedName}
              </div>
              {rootContainer.readyState === "READY" &&
                variantConditionPrices!.state === "CANCELLED" && (
                  <div className="smallText">
                    {L10n.format("pricing_no_calculation_possible")}
                  </div>
                )}
              {rootContainer.readyState === "NOT_READY" && (
                <div className="smallText">
                  {L10n.format(
                    "pricing_no_calculation_possible_not_all_mandatory_parameters_selected",
                  )}
                </div>
              )}
              <VariantConditionPriceBar
                variantConditionPrices={variantConditionPrices}
              />
            </div>
            <div className="resultButtonContainer">
              <a
                className={classNames([
                  "button is-info is-pulled-right is-orange is-disabled",
                  { "is-loading": loading },
                ])}
                onClick={trackFunction(onSave, addPositionEvent)}
                data-button-id="catalogue_add_to_shopping_cart"
              >
                <div className="is-pulled-left is-hidden-tablet-only is-hidden-desktop-only">
                  {L10n.format("catalogue_add_to_shopping_cart")}
                </div>
                <div className="is-pulled-right">
                  <i className="image-icon warenkorb-leer" aria-hidden="true" />
                </div>
              </a>
            </div>
            <VisualizationContainer
              articleName={articleName!}
              configurationId={configurationId!}
            />
          </div>
        </Sticky>
      </div>
    </div>
  );
}
