import { isNil } from "ramda";
import { useContext, useEffect, useMemo } from "react";

import { Loading } from "../../components/loading";
import { AppContext } from "../../hooks/useApp";
import { CartContext } from "../../hooks/useCart";
import { ConfigurationContext } from "../../hooks/useConfiguration";
import { IdentityContext } from "../../hooks/useIdentity";
import { isCurrentUserAdminOrFromSameCompanyAsCreatorWrapper } from "../../utilities/cartUtils";
import { isQuoteShared } from "../offerManagement/offerManagementTable/offerManagementTableUtils";
import { ButtonRow } from "./components/footer/buttonRow";
import { Calculation } from "./components/footer/calculation/calculation";
import { ButtonRow as HeaderButtonRow } from "./components/header/buttonRow";
import { CartInformation } from "./components/header/cartInformation";
import { CartView } from "./components/header/cartView";
import { PartialOrderCheckbox } from "./components/header/partialOrderCheckbox";
import { Modals } from "./components/modals/modals";
import { Table } from "./components/table/table";
import { Warnings } from "./components/warnings/warnings";
import { useCartModals } from "./hooks/useCartModals";
import { useCartView } from "./hooks/useCartView";

import "./shoppingCart.scss";

export const ShoppingCart = () => {
  const { identityStore } = useContext(IdentityContext);
  const { serviceFactory } = useContext(AppContext);
  const { cart, cartActions } = useContext(CartContext);
  const configuration = useContext(ConfigurationContext);
  const offerManagementService = useMemo(
    () => serviceFactory.createQuoteService(),
    [],
  );
  const {
    cartView,
    cartViews,
    changeCartView,
    isAlreadyPartiallyOrdered,
    partialOrderActivated,
    viewActions,
  } = useCartView(cart, cartActions);
  const { modals, modalActions, modalData, modalLoadingStates } = useCartModals(
    cartActions,
    offerManagementService,
    configuration,
  );
  const { isOrder } = cartViews;

  useEffect(() => {
    if (cart && !isQuoteShared(cart.headerData?.status)) {
      cartActions.getCart().then();
    }
  }, []);

  if (isNil(cart)) {
    return <Loading />;
  }

  const hasRights = isCurrentUserAdminOrFromSameCompanyAsCreatorWrapper(
    cart,
    identityStore!,
  );
  return (
    <div className="shopping-cart" data-page-name="shopping-cart">
      <div className="shopping-cart-head">
        <CartInformation />
        <div className="shopping-cart-view columns">
          <CartView changeCartView={changeCartView} cartView={cartView} />
          <HeaderButtonRow
            saveQuoteAndClose={cartActions.saveQuoteAndClose(
              offerManagementService,
              configuration,
            )}
            hasRights={hasRights}
            toggleModal={modalActions.toggleModal}
            toggleModalLoading={modalActions.toggleModalLoading}
            modalLoadingStates={modalLoadingStates}
          />
        </div>
        <Warnings />
      </div>
      {isOrder && (
        <PartialOrderCheckbox
          partialOrder={partialOrderActivated}
          isPartiallyOrdered={isAlreadyPartiallyOrdered}
          togglePartialOrder={viewActions.togglePartialOrder}
          isReadOnly={cart.readOnly}
        />
      )}
      <Table
        cartViews={cartViews}
        hasRights={hasRights}
        modalActions={modalActions}
        modalLoadingStates={modalLoadingStates}
        partialOrderActivated={partialOrderActivated}
        viewActions={viewActions}
      />
      <Calculation cartViews={cartViews} />
      <ButtonRow
        modalActions={modalActions}
        quoteOrderNumber={cart.orderNumber}
        changeCartView={changeCartView}
        isPartialOrder={partialOrderActivated}
        isOrderView={isOrder}
      />
      <Modals
        modals={modals}
        modalActions={modalActions}
        modalData={modalData}
        isPartialOrder={partialOrderActivated}
      />
    </div>
  );
};
