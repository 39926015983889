import { useContext, useMemo } from "react";

import { AppContext } from "../hooks/useApp";
import { CartContext } from "../hooks/useCart";
import { ConfigurationContext } from "../hooks/useConfiguration";
import { IdentityContext } from "../hooks/useIdentity";
import {
  hasRightToSeeBuildingProject,
  hasRightToSeeVisibleReference,
} from "../http/identityHelper";
import { t } from "../pages/shoppingCart/localizationUtils";
import { isCurrentUserAdminOrFromSameCompanyAsCreatorWrapper } from "../utilities/cartUtils";

import "./cartInformation.scss";

const InfoTextWrapper = ({ label, value, labelDataName, valueDataName }) => (
  <>
    <span
      className="info-text-label"
      data-label-id={labelDataName}
    >{`${t(label)}:`}</span>
    <span className="info-text-value" data-label-id={valueDataName}>
      {value}
    </span>
  </>
);

export const CartInformation = () => {
  const { systemName } = useContext(AppContext);
  const { identityStore } = useContext(IdentityContext);
  const { reconfiguration } = useContext(ConfigurationContext);
  const { cart } = useContext(CartContext);
  const hasRight =
    hasRightToSeeBuildingProject(cart.authorities?.booleanAuthorities) &&
    hasRightToSeeVisibleReference(cart.authorities?.booleanAuthorities);
  const isAdminOrFromSameCompany = useMemo(
    () =>
      isCurrentUserAdminOrFromSameCompanyAsCreatorWrapper(cart, identityStore),
    [cart, identityStore],
  );

  return (
    <div className="cart-information-columns" data-page-id="cart">
      <div className="system-info">{systemName}</div>
      <div className="column" data-div-id="cart-information">
        <div className="cart-information">
          <InfoTextWrapper
            label={"offer_management_operation_number"}
            value={cart?.orderNumber ? cart.orderNumber : "-"}
            labelDataName="operation-number"
            valueDataName="operation-number-value"
          />
          {reconfiguration && (
            <InfoTextWrapper
              label={"offer_management_table_position"}
              value={reconfiguration?.cartPosition}
            />
          )}
          <InfoTextWrapper
            label={"offer_management_table_status"}
            value={
              cart?.orderNumber && cart?.headerData?.status
                ? t(`offer_management_table_status_${cart.headerData.status}`)
                : "-"
            }
            labelDataName="status"
            valueDataName="status-value"
          />
          {hasRight && (
            <>
              <InfoTextWrapper
                label={"building_project"}
                value={
                  cart?.orderNumber &&
                  cart?.headerData?.buildProject &&
                  isAdminOrFromSameCompany
                    ? cart.headerData.buildProject
                    : "-"
                }
                labelDataName="building-project"
                valueDataName="building-project-value"
              />
              <InfoTextWrapper
                label={"reference_number"}
                value={
                  cart?.orderNumber &&
                  cart?.headerData?.referenceNumber &&
                  isAdminOrFromSameCompany
                    ? cart.headerData.referenceNumber
                    : "-"
                }
                labelDataName="reference-number"
                valueDataName="reference-number-value"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
