import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { filter, isEmpty, isNil, not, split, test } from "ramda";
import React, { ReactNode } from "react";

import { L10n } from "@encoway/l10n";

import {
  FlexTableCell as Cell,
  FlexTableRow as Row,
} from "../../../../components/flexTable/flexTable";
import { mapIndexed } from "../../../../utilities/utilities";
import { CustomerDto } from "../../../customerManagement/customerManagementUtils";
import { escapeRegExCharacters } from "../../offerManagementUtils";

function filterSearchValues(highlightValue: string, value: string) {
  return filter(
    (currentValue) => not(isEmpty(currentValue)),
    split(generateRegExp(highlightValue), value),
  );
}

function generateRegExp(highlightValue: string) {
  return new RegExp(`(${escapeRegExCharacters(highlightValue)})`, "gi");
}

type ExpandAddressButtonProps = {
  label: string;
  icon: JSX.Element;
  disabled: boolean;
  attributeId: number;
  onClick: () => void;
};

const ExpandAddressButton = ({
  label,
  onClick,
  icon,
  disabled,
  attributeId,
}: ExpandAddressButtonProps) => (
  <button
    className="button is-small expand-button"
    onClick={onClick}
    disabled={disabled}
    data-button-id={attributeId}
  >
    <div className="is-pulled-left">{icon}</div>
    <div className="is-pulled-right">{label}</div>
  </button>
);

type TextHighlighterProps = {
  highlightValue: string;
  value: string;
};

type TextHighlighterComponentProps = {
  highlightValue: string | undefined;
  part: string;
  index: number;
};

function TextHighlighterComponent(
  props: Readonly<TextHighlighterComponentProps>,
) {
  const { highlightValue, part, index } = props;
  const isValid = highlightValue && test(generateRegExp(highlightValue), part);

  if (isValid) {
    return (
      <span className="highlight-orange" key={index}>
        {part}
      </span>
    );
  }

  return <React.Fragment key={index}>{part}</React.Fragment>;
}

const TextHighlighter = ({ highlightValue, value }: TextHighlighterProps) => {
  return (
    <>
      {mapIndexed<string, ReactNode>(
        (part, index) => (
          <TextHighlighterComponent
            part={part}
            highlightValue={highlightValue}
            index={index}
          />
        ),
        filterSearchValues(highlightValue, value),
      )}
    </>
  );
};

type CustomerSearchTableResultsProps = {
  disableSearchResult: boolean;
  searchResult: CustomerDto[] | null;
  searchValue: string;
  selectedCustomer: CustomerDto | null;
  onToggleSelectedCustomer: (customer: CustomerDto) => () => void;
};

export function CustomerSearchTableResults(
  props: Readonly<CustomerSearchTableResultsProps>,
) {
  const {
    disableSearchResult,
    searchResult,
    searchValue,
    selectedCustomer,
    onToggleSelectedCustomer,
  } = props;

  const noSearchResults =
    isNil(searchResult) || (isEmpty(searchResult) && !isEmpty(searchValue));

  if (noSearchResults) {
    return (
      <Row>
        <Cell value={L10n.format("customer_search_no_entries_found")} />
      </Row>
    );
  }

  if (disableSearchResult) {
    return null;
  }

  return (
    <div className="customer-search-fields">
      {mapIndexed(
        (customer, index) => (
          <Row
            key={index}
            className={classNames({
              "is-active":
                selectedCustomer && selectedCustomer.id === customer.id,
            })}
            data-cell-id={customer.id}
          >
            <Cell>
              <TextHighlighter
                highlightValue={searchValue}
                value={customer.customerNumber}
              />
            </Cell>
            <Cell>
              <TextHighlighter
                highlightValue={searchValue}
                value={customer.customerName}
              />
            </Cell>
            <Cell className="expand-cell">
              <ExpandAddressButton
                onClick={onToggleSelectedCustomer(customer)}
                disabled={
                  customer.companyContacts &&
                  customer.companyContacts.length <= 0
                }
                label={L10n.format("customer_search_edit")}
                icon={<FontAwesomeIcon icon={faSearch} />}
                attributeId={customer.id}
              />
            </Cell>
          </Row>
        ),
        searchResult,
      )}
    </div>
  );
}
