import { Constants } from "@encoway/react-components";

import { determineActiveTab } from "../utils/tabsUtils";

export const notify = (eventBus, tab) =>
  eventBus.push({
    event: Constants.Notify,
    payload: { event: Constants.Events.TabChanged, name: tab },
  });

export const selectTab = (eventBus, currentTab, setCurrentTab, newTab) => {
  if (currentTab !== newTab) {
    notify(eventBus, newTab);
    setCurrentTab(newTab);
  }
};

export const getCurrentTab = (
  eventBus,
  currentTab,
  inject,
  children,
  containerFilter,
) => {
  const current = children.find((tab) => tab.name === currentTab);
  if (current) {
    return current;
  }
  // Check before and after
  if (inject?.before) {
    return inject.before.find((tab) => tab.name === currentTab);
  }
  if (inject?.after) {
    return inject.after.find((tab) => tab.name === currentTab);
  }
  const activeTab = determineActiveTab(inject, children, containerFilter);
  notify(eventBus, activeTab);
  return activeTab;
};
