import { includes, without, append, map, isNil, not, isEmpty } from "ramda";
import { useState, useContext } from "react";

import { CartContext } from "../../../hooks/useCart";
import { fetchGet } from "../../../service/fetch";
import { ParameterTO } from "../../../types/@encoway/Parameter";
import { CartArticleTO, TeckentrupCartArticleTO } from "../../../types/cart";
import {
  getAllCartItemIds,
  isCartItemDetailsVisible,
} from "./utils/cartItemDetailsUtils";

const ADDITIONAL_PARAMETERS_URL =
  "teckentrup-server/api/cart/quote/getAdditionalParameters";
const FETCH_ERROR = "Error fetching additional parameters for cart item";

export const useCartItemDetails = (cartItems: TeckentrupCartArticleTO[]) => {
  const { cart } = useContext(CartContext);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [toggleAll, setToggleAll] = useState<boolean>(false);
  const [toggle, setToggle] = useState<string[]>([]);
  const [cartDetailsParameter, setCartDetailsParameter] = useState<
    Record<string, ParameterTO[]>
  >({});

  const cartItemIds = getAllCartItemIds(cartItems);

  function isCartDetailsParameterSet(articleId: string) {
    return (
      not(isNil(cartDetailsParameter[articleId])) &&
      not(isEmpty(cartDetailsParameter[articleId]))
    );
  }

  /**
   * Toggles a specific cart item detail
   * @param {CartArticleTO} cartItem - The cart item to toggle
   * @returns {Promise<void>} A Promise that resolves when the toggle is complete
   */
  const toggleCartItemDetails = (cartItem: CartArticleTO) => async () => {
    /**
     * True if you close the product for price details and product details
     */
    if (includes(cartItem.articleId, toggle)) {
      const withoutId = without([cartItem.articleId], toggle);
      if (withoutId.length === 0) {
        setToggleAll(false);
      }
      setToggle(withoutId);
      return;
    }

    setToggleAll(true);
    setToggle(append(cartItem.articleId, toggle));

    /**
     * Fetch additional parameters for the cart item
     */
    if (not(isFetching)) {
      setIsFetching(true);
      try {
        if (isCartDetailsParameterSet(cartItem.articleId)) {
          return;
        }
        const response = await fetchGet<ParameterTO[]>(
          `${ADDITIONAL_PARAMETERS_URL}/${cartItem.articleId}`,
        )()();
        if (response.status === 200) {
          setCartDetailsParameter((prevState) => ({
            ...prevState,
            [cartItem.articleId]: response.data,
          }));
        } else {
          console.error(FETCH_ERROR);
        }
      } catch (error) {
        console.error(FETCH_ERROR);
      } finally {
        setIsFetching(false);
      }
    }
  };

  /**
   * Asynchronously fetches additional parameters for a given cart item. Otherwise, logs an error to the console.
   *
   * @param {CartArticleTO} secondFolderItem - The folder item to process, with a subArticles property containing an
   * array of sub-articles to process.
   * @returns {void} A Promise that resolves when all sub-articles have been processed.
   */
  function processSecondFolder(secondFolderItem: CartArticleTO) {
    map(async (folderItem) => {
      if (isCartDetailsParameterSet(folderItem.articleId)) {
        return;
      }
      const response = await fetchGet<ParameterTO[]>(
        `${ADDITIONAL_PARAMETERS_URL}/${folderItem.articleId}`,
      )()();
      if (response.status === 200) {
        setCartDetailsParameter((prevState) => ({
          ...prevState,
          [folderItem.articleId]: response.data,
        }));
      } else {
        console.error(FETCH_ERROR);
      }
    }, secondFolderItem.subArticles);
  }

  /**
   * Asynchronously fetches additional parameters for all sub-articles of a given folder item. If an article is
   * configurable, delegates processing to {@link processSecondFolder} for its sub-articles. Otherwise, processes
   * the article itself using an API call.
   *
   * @param {CartArticleTO} firstFolderItem - The folder item to process, with a subArticles property containing an
   * array of sub-articles to process.
   * @returns {void} A Promise that resolves when all sub-articles have been processed.
   */
  function processFirstFolder(firstFolderItem: CartArticleTO) {
    map(async (folderItem) => {
      if (isCartDetailsParameterSet(folderItem.articleId)) {
        return;
      }

      /**
       * Checks for second folder, article is configurable
       */
      if (folderItem.configurable === false) {
        processSecondFolder(folderItem);
      } else {
        const response = await fetchGet<ParameterTO[]>(
          `${ADDITIONAL_PARAMETERS_URL}/${folderItem.articleId}`,
        )()();
        if (response.status === 200) {
          setCartDetailsParameter((prevState) => ({
            ...prevState,
            [folderItem.articleId]: response.data,
          }));
        } else {
          console.error(FETCH_ERROR);
        }
      }
    }, firstFolderItem.subArticles);
  }

  /**
   * Toggles all cart item details
   * @returns {void}
   */
  const toggleAllCartItemDetails = () => {
    if (isNil(cart)) {
      return;
    }

    map(async (cartItem) => {
      if (!isFetching && !toggleAll) {
        setIsFetching(true);
        try {
          if (isCartDetailsParameterSet(cartItem.articleId)) {
            return;
          }

          /**
           * Checks for first folder, article is configurable
           */
          if (cartItem.configurable === false) {
            processFirstFolder(cartItem);
          } else {
            const response = await fetchGet<ParameterTO[]>(
              `${ADDITIONAL_PARAMETERS_URL}/${cartItem.articleId}`,
            )()();
            if (response.status === 200) {
              setCartDetailsParameter((prevState) => ({
                ...prevState,
                [cartItem.articleId]: response.data,
              }));
            } else {
              console.error(FETCH_ERROR);
            }
          }
        } catch (error) {
          console.error(FETCH_ERROR);
        } finally {
          setIsFetching(false);
        }
      }
    }, cart.articles.subArticles);

    const isArticleAlreadyToggled = toggle.length > 0;
    setToggleAll(!isArticleAlreadyToggled);
    setToggle(isArticleAlreadyToggled ? [] : cartItemIds);
  };

  /**
   * Determines if an item is toggled already
   * @param {CartArticleTO} cartItem the given cartItem
   * @returns {boolean} if an item is toggled
   */
  const isToggle = (cartItem: TeckentrupCartArticleTO) =>
    isCartItemDetailsVisible(cartItem, toggle);

  return {
    cartDetailsParameter,
    isToggle,
    isToggleAll: toggleAll,
    cartItemDetailsActions: {
      toggleCartItemDetails,
      toggleAllCartItemDetails,
    },
  };
};

export type CartItemDetailsReturn = ReturnType<typeof useCartItemDetails>;
