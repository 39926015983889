import { length, pathOr } from "ramda";
import { useState } from "react";

import { t } from "../pages/shoppingCart/localizationUtils";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "./modal/modal";

import "./simpleTable.scss";

const DetailsModal = ({ files = "", onCancel }) => (
  <Modal>
    <ModalHeader label="" onCancel={onCancel} />
    <ModalBodyWrapper overflowY={true}>
      <textarea
        className="textarea has-fixed-size"
        rows="12"
        value={JSON.stringify(files, null, 2)}
        readOnly
      />
    </ModalBodyWrapper>
    <ModalFooter cancelLabel={t("cancel")} onCancel={onCancel} />
  </Modal>
);

const DeleteModal = ({ files = "", onDelete, onCancel }) => (
  <Modal>
    <ModalHeader
      label="Soll dieser Eintrag wirklich gelöscht werden?"
      onCancel={onCancel}
    />
    <ModalBodyWrapper>
      Soll der Eintrag {files} wirklich gelöscht werden?
    </ModalBodyWrapper>
    <ModalFooter
      cancelLabel={t("cancel")}
      onCancel={onCancel}
      saveLabel={t("delete")}
      onSave={onDelete}
    />
  </Modal>
);

const SimpleTableRows = ({
  files,
  loadAction,
  setShowDetailsModal,
  setShowDeleteModal,
}) => {
  if (length(files) > 0) {
    return files.map((data) => (
      <tr key={data.fileName} data-user-id={data.fileName.slice(0, -5)}>
        <td>{data.fileName}</td>
        <td>
          <p>{pathOr("-", ["FirmenSAPKundennummer"], data.FirmadesNutzers)}</p>
        </td>
        <td>
          <p>{pathOr("-", ["AccountId"], data.FirmadesNutzers)}</p>
        </td>
        <td className="breakable-cell">
          <p>{pathOr("-", ["Email"], data.Nutzer)}</p>
        </td>
        <td>
          <p>{pathOr("-", ["FirmenLand"], data.FirmadesNutzers)}</p>
        </td>
        <td>
          <button
            className="width-100-percent"
            onClick={() => setShowDetailsModal(data)}
          >
            Details
          </button>
        </td>
        <td>
          <button
            className="width-100-percent"
            onClick={() => setShowDeleteModal(data.fileName)}
          >
            Löschen
          </button>
        </td>
        <td>
          <button
            className="width-100-percent"
            onClick={() => loadAction(data.fileName)}
          >
            Laden
          </button>
        </td>
      </tr>
    ));
  }
  return (
    <tr>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
  );
};

export const SimpleTable = ({ files, loadAction, deleteAction }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  return (
    <>
      <table className="simple-table is-fullwidth">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>Name des Users</th>
            <th>Kundennummer</th>
            <th>Account-ID</th>
            <th className="width-50-percent">User-ID</th>
            <th>Country-Code</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
          <SimpleTableRows
            {...{
              files,
              loadAction,
              setShowDeleteModal: setShowDeleteModal,
              setShowDetailsModal: setShowDetailsModal,
            }}
          />
        </tbody>
      </table>
      {showDetailsModal && (
        <DetailsModal
          files={showDetailsModal}
          onCancel={() => setShowDetailsModal(null)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          files={showDeleteModal}
          onDelete={() => {
            deleteAction(showDeleteModal);
            setShowDeleteModal(null);
          }}
          onCancel={() => setShowDeleteModal(null)}
        />
      )}
    </>
  );
};
