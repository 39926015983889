import { L10n } from "@encoway/l10n";

import "./partialOrderCheckbox.scss";

type PartialOrderCheckboxProps = {
  partialOrder: boolean;
  isPartiallyOrdered: boolean;
  togglePartialOrder: () => void;
  isReadOnly: boolean;
};

export function PartialOrderCheckbox(
  props: Readonly<PartialOrderCheckboxProps>,
) {
  const { partialOrder, isPartiallyOrdered, togglePartialOrder, isReadOnly } =
    props;
  const checked = partialOrder || isPartiallyOrdered;

  if (isReadOnly) {
    return null;
  }

  return (
    <div className="control partial-order">
      <div className="checkbox-wrapper">
        <input
          className="checkbox"
          type="checkbox"
          onChange={togglePartialOrder}
          checked={checked}
          data-checkbox-id="ordered_part"
        />
        <span className="boldText">
          {L10n.format("cart_partial_order_label")}
        </span>
      </div>
      <div className="smallText">
        <span>{L10n.format("cart_partial_order_hint")}</span>
      </div>
      {checked && (
        <div className="smallText">
          <span className="boldText">{`${L10n.format("cart_partial_order_hint_label")}: `}</span>
          <span>{L10n.format("cart_partial_order_hint_description")}</span>
        </div>
      )}
    </div>
  );
}
