import { filter, isNil, propEq } from "ramda";
import { useContext, useState } from "react";

import { Config, ConfigurationService, Constants } from "@encoway/rest-api";

import { Config as AppConfig } from "../config/config";
import { createHttp } from "../http/setupHttp";
import { getConflictFreeParameterTO } from "../service/configurationService";
import { LvRow, LvRowDTO } from "../types/lvTable";
import { getGuiParameters, setConfigValues } from "../utilities/lvUtils";
import { AppContext } from "./useApp";
import { IdentityContext } from "./useIdentity";

export const useLvConfiguration = () => {
  const { baseUrl, language } = useContext(AppContext);
  const { identityStore } = useContext(IdentityContext);
  const [config, setConfig] = useState<Config | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  async function initializeLvParameters(lvParameters: LvRowDTO["parameters"]) {
    const cs = await ConfigurationService.create(
      createHttp(null),
      baseUrl,
      { articleName: AppConfig.LvImport.studioModel },
      language,
    );
    cs.settings({
      mappingOptions: { mappingProfile: Constants.MappingProfile.Maximum },
    });
    await setConfigValues(cs, lvParameters, identityStore!);
    const guiParameters = await getGuiParameters(cs, lvParameters);
    cs.stop();
    return guiParameters;
  }

  async function startConfAndSetValues(
    parameters: LvRow["parameters"],
    product: LvRow["product"],
  ) {
    if (config) return;
    setIsLoading(true);
    const cs = await ConfigurationService.create(
      createHttp(null),
      baseUrl,
      { articleName: AppConfig.LvImport.studioModel },
      language,
    );
    cs.settings({
      mappingOptions: { mappingProfile: Constants.MappingProfile.Maximum },
    });
    const parametersSetByUser = filter(
      propEq("selectionSource", "SET_BY_USER"),
      parameters,
    );
    await setConfigValues(
      cs,
      parametersSetByUser,
      identityStore!,
      product.selectionSource === "SET_BY_USER" ? product : undefined,
    );
    setConfig(cs);
    setIsLoading(false);
  }

  async function stopConf(
    parameters: LvRow["parameters"],
    product: LvRow["product"],
  ) {
    if (isNil(config)) return { parameters, product };
    setIsLoading(true);
    const guiParameters = await getGuiParameters(
      config,
      parameters,
      getConflictFreeParameterTO(config.id(), language),
    );
    config.stop();
    setConfig(null);
    setIsLoading(false);
    return guiParameters;
  }

  return {
    config,
    isLoading,
    initializeLvParameters,
    startConfAndSetValues,
    stopConf,
  };
};

export type LvConfiguration = ReturnType<typeof useLvConfiguration>;
