import { omit } from "ramda";

import {
  InitialModifyOfferDialogState,
  ShippingAddress,
} from "../../../../offerManagement/modifyOfferModal/modifyOfferModalActions";
import { DifferingShippingAddressState } from "./differingShippingAddress";

export type OrderModalState = {
  orderNumber: string;
  buildProject: string;
  dateOfDelivery: string;
  furtherNotes: string;
  allMandatoriesCompleted: boolean;
  isLoading: boolean;
  failed: boolean;
};

export type NewCheckoutInformation = {
  referenceNumber: string;
  buildProject: string;
  dateOfDelivery: string;
  furtherNotes: string;
  orderedDate: Date;
  shippingAddress: ShippingAddress;
  forklifter?: string;
};

export const createNewCheckoutInformation = (
  state: OrderModalState,
  address: DifferingShippingAddressState | undefined,
): NewCheckoutInformation => {
  const result: NewCheckoutInformation = {
    referenceNumber: state.orderNumber,
    buildProject: state.buildProject,
    dateOfDelivery: state.dateOfDelivery,
    furtherNotes: state.furtherNotes,
    orderedDate: new Date(),
    shippingAddress: InitialModifyOfferDialogState.shippingAddress,
  };

  if (address) {
    const { forklifter } = address;
    result.shippingAddress = omit(["forklifter"], address);
    result.forklifter = forklifter;
  } else {
    result.shippingAddress = InitialModifyOfferDialogState.shippingAddress;
  }

  return result;
};
