import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { t } from "../../../shoppingCart/localizationUtils";

type CancelModalProps = {
  onSave: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export const CancelModal = ({
  onSave,
  onCancel,
  onClose,
}: CancelModalProps) => (
  <Modal>
    <ModalHeader
      label={t("modify_offer_dialog_cancel_modal_title")}
      onCancel={onClose}
    />
    <ModalBodyWrapper label={t("modify_offer_dialog_cancel_modal_message")} />
    <ModalFooter
      cancelLabel={t("modify_offer_dialog_cancel_modal_cancel_button")}
      saveLabel={t("modify_offer_dialog_cancel_modal_save_button")}
      onCancel={onCancel}
      onSave={onSave}
    />
  </Modal>
);
