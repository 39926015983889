import { equals, not } from "ramda";
import { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";

type TextInputProps = ComponentPropsWithoutRef<"input"> & {
  value: string;
  setValue: (value: string) => void;
};

export const TextInput = ({ value, setValue, ...props }: TextInputProps) => {
  const [internalValue, setInternalValue] = useState<string>(value);
  const initialValueRef = useRef(value);

  useEffect(() => {
    setInternalValue(value);
    initialValueRef.current = value;
  }, [value]);

  function onSetValue() {
    if (not(equals(internalValue, initialValueRef.current))) {
      setValue(internalValue);
    }
  }

  return (
    <input
      type="text"
      value={internalValue}
      onChange={(event) => setInternalValue(event.target.value)}
      onBlur={onSetValue}
      onKeyDown={(event) => event.key === "Enter" && onSetValue()}
      {...props}
    />
  );
};
