import { keys, map, mapObjIndexed, path, values } from "ramda";
import { PropsWithChildren } from "react";

import { InputMaxLength } from "../../../components/input/inputMaxLength";
import { InputTitle } from "../../../components/input/inputTitle";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { InputConstants } from "../../offerManagement/modifyOfferModal/inputConstants";
import { t } from "../../shoppingCart/localizationUtils";
import {
  ContactDto,
  prepareQuoteAddressInputState,
  prepareQuoteAddressState,
} from "../customerManagementUtils";
import { QuoteAddressRows as Address } from "./quoteAddressRows";

const Wrapper = ({ children }: PropsWithChildren) => (
  <div className="quote-address-wrapper">{children}</div>
);

type InputFieldsProps = {
  state: Partial<ContactDto>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

function InputFields({ state, onChange }: Readonly<InputFieldsProps>) {
  return (
    <div className="quote-address-inputs">
      {map(
        (value) => (
          <>{value}</>
        ),
        keys(state),
      )}
      {values(
        mapObjIndexed(
          (value, key: string) => (
            <div className="flex-cell" key={key}>
              <InputMaxLength
                name={key}
                inputId={`customer-address-${key}`}
                placeholder={t(`modify_dialog_contact_${key}`)}
                label={t(`modify_dialog_contact_${key}`)}
                value={String(value) || ""}
                onChange={onChange}
                maxLength={path(
                  ["length", `modify_dialog_contact_${key}`],
                  InputConstants,
                )}
              />
            </div>
          ),
          state,
        ),
      )}
    </div>
  );
}

type DeleteAddressModalProps = {
  onDelete: () => void;
  onCancel: () => void;
};

function DeleteAddressModal(props: Readonly<DeleteAddressModalProps>) {
  const { onDelete, onCancel } = props;
  return (
    <Modal>
      <ModalHeader
        label={t("modify_dialog_contact_delete_modal_label")}
        onCancel={onCancel}
      />
      <ModalBodyWrapper label={t("modify_dialog_contact_delete_modal_text")} />
      <ModalFooter
        onCancel={onCancel}
        cancelLabel={t("cancel")}
        saveLabel={t("modify_dialog_contact_delete_modal_delete")}
        onSave={onDelete}
      />
    </Modal>
  );
}

type QuoteAddressWrapperProps = {
  quoteAddressState: ContactDto;
  onDelete: () => void;
  onClose: () => void;
  onInputChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onTitleChange: (title: string) => void;
  onDeleteModalOnCancel: () => void;
  onDeleteModalOpen: () => void;
  onDeleteModalToggle: boolean;
};

export function QuoteAddressWrapper(props: Readonly<QuoteAddressWrapperProps>) {
  const {
    quoteAddressState,
    onDelete,
    onClose,
    onInputChange,
    onTitleChange,
    onDeleteModalOnCancel,
    onDeleteModalOpen,
    onDeleteModalToggle = false,
  } = props;
  return (
    <Wrapper>
      <Address
        isOpen
        mutatedState={prepareQuoteAddressState(quoteAddressState)}
        onCloseOrEdit={onClose}
      />
      <InputTitle
        title={quoteAddressState.title}
        onClick={onTitleChange}
        onDeleteModalOpen={onDeleteModalOpen}
      />
      <InputFields
        state={prepareQuoteAddressInputState(quoteAddressState)}
        onChange={onInputChange}
      />
      {onDeleteModalToggle && (
        <DeleteAddressModal
          onCancel={onDeleteModalOnCancel}
          onDelete={onDelete}
        />
      )}
    </Wrapper>
  );
}
