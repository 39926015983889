import { decodeJwt } from "jose";
import React, { useEffect, useState } from "react";

import { SimpleTable } from "../components/simpleTable";
import { fetchGet, fetchPost } from "../service/fetch";

import "./devLogin.scss";

const DevLogin = () => {
  const [files, setFiles] = useState([]);
  const [filesPath, setFilesPath] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    notification: "",
    requestIs: "",
  });
  const [requestHeaderData, setRequestHeaderData] = useState();
  const BASE_PATH = "teckentrup-server/api/custom/security/token/";

  const getFiles = async (fileNames) => {
    const fileData = [];
    for (const fileName of fileNames) {
      try {
        const response = await fetchGet(BASE_PATH + fileName.fileName)()();
        fileData.push({
          fileName: fileName.fileName,
          ...decodeJwt(response.data),
        });
      } catch {}
    }
    setFiles(fileData);
  };

  const synchronize = async () => {
    const response = await fetchGet(`${BASE_PATH}fileNames`)()();
    const currentFiles = response.data;

    await getFiles(currentFiles);
  };

  const uploadFile = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = file.name;
      let fileContent = {};
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = async (e) => {
        fileContent = e.target.result;
        await fetchPost(BASE_PATH + fileName)(
          {},
          { "Content-Type": "application/json" },
        )(fileContent);
        await synchronize();
      };
    }
  };

  const loadFilesPath = async () => {
    const response = await fetchGet(`${BASE_PATH}filesPath`)()();
    const path = response.data;

    setFilesPath(path);
  };

  const loadToken = async (name) => {
    const response = await fetchGet(BASE_PATH + name)()();
    if (response.status === 500) {
      return;
    }
    const token = response.data;
    const userName = files.find((fileName) => fileName.fileName === name);
    window.localStorage.setItem("authToken", token);
    window.localStorage.setItem("currentUser", userName.fileName);
    document.location = window.location.origin;
  };

  const deleteFile = async (name) => {
    await fetchGet(BASE_PATH + "delete/" + name)()();
    await synchronize();
  };

  const simulateRequest = async () => {
    const PATH = "teckentrup-server/api/request/_importrequest";

    setRequestStatus({
      notification: "Anfrage wird erstellt...",
      requestIs: "request-is-sending",
    });

    let headerData;
    try {
      headerData = JSON.parse(requestHeaderData);
    } catch (e) {
      setRequestStatus({
        notification: "JSON nicht valide.",
        requestIs: "request-was-not-successful",
      });
      return;
    }

    try {
      const response = await fetchPost(PATH)({
        "Content-Type": "application/json",
      })(headerData);

      setRequestStatus({
        notification: `Anfrage erstellt. Vorgangsnummer: ${response.data.cartOrderNumber}`,
        requestIs: "request-was-successful",
      });
    } catch (e) {
      setRequestStatus({
        notification: e.message,
        requestIs: "request-was-not-successful",
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (window.location.hostname !== "localhost") {
        const { data } = await fetchGet("devUtils.json")()();
        setRequestHeaderData(JSON.stringify(data, null, 2));
      }
      await synchronize();
      await loadFilesPath();
    })();
  }, []);

  return (
    <>
      <div className="dev-login">
        <h1 className="custom-headline has-text-weight-bold has-text-centered">
          Einstieg in den TEO und Auswahl des Users
        </h1>
        <div className={"w-100 d-flex justify-content-between"}>
          <div>
            <span>Neue Datei Hochladen: </span>
            <input
              type="file"
              name="newFile"
              className={"input-with-margin"}
              onChange={uploadFile}
            />
          </div>
          <button
            className="button-with-margin-and-float-right"
            onClick={synchronize}
          >
            Synchronisieren
          </button>
        </div>
        <SimpleTable
          files={files}
          loadAction={loadToken}
          deleteAction={deleteFile}
        />
        <span>Serverpfad zum Tokenordner: </span>
        <span className="is-italic has-text-weight-bold">{filesPath}</span>
      </div>
      <div className="simulate-request">
        <h1 className="custom-headline has-text-weight-bold has-text-centered has-margin-top">
          Simulieren einer Anfrage
        </h1>
        <textarea
          rows="10"
          cols="100"
          onChange={(e) => setRequestHeaderData(e.target.value)}
          value={requestHeaderData}
        />
        <div className="button-wrapper">
          <button
            className="button-with-margin-right"
            onClick={simulateRequest}
          >
            Anfrage simulieren
          </button>
          <span className={requestStatus.requestIs}>
            {requestStatus.notification}
          </span>
        </div>
      </div>
    </>
  );
};

export default DevLogin;
