import classNames from "classnames";
import { isNil, pathOr } from "ramda";
import React, { useContext } from "react";

import { FlexTableWrapper } from "../../../../components/flexTable/flexTable";
import { ScrollWrapper } from "../../../../components/scrollWrapper";
import { CartContext } from "../../../../hooks/useCart";
import { IdentityContext } from "../../../../hooks/useIdentity";
import { CartActions, TeckentrupCartArticleTO } from "../../../../types/cart";
import {
  UserRoles,
  allPartOrderAllowedItemsFlagged,
  hasUserUnsavedCart,
  itemRole,
  specialUserRoles,
} from "../../../../utilities/cartUtils";
import { mapIndexed } from "../../../../utilities/utilities";
import {
  CartDragAndDropReturn,
  useCartDragAndDrop,
} from "../../hooks/useCartDragAndDrop";
import {
  CartItemDetailsReturn,
  useCartItemDetails,
} from "../../hooks/useCartItemDetails";
import { CART_MODALS, CartModalsReturn } from "../../hooks/useCartModals";
import {
  CartViewReturn,
  CartViews,
  ViewActions,
} from "../../hooks/useCartView";
import { determineParentId } from "../../hooks/utils/cartDragAndDropUtils";
import { Row, RowLoading } from "./rows/row";
import { RowFoot } from "./rows/rowFoot";
import { RowHead } from "./rows/rowHead";

import "./table.scss";

type RecursiveRowProps = {
  userRoles: UserRoles;
  cartDragAndDrop: CartDragAndDropReturn;
  cartItem: TeckentrupCartArticleTO;
  tableViews: CartViews;
  cartActions: CartActions;
  viewActions: ViewActions;
  hasRights: boolean;
  modalActions: CartModalsReturn["modalActions"];
  cartItemDetails: CartItemDetailsReturn;
  clientId: string;
  unsavedCart: boolean;
};

const RecursiveRow = ({
  userRoles,
  cartDragAndDrop,
  cartItem,
  tableViews,
  cartActions,
  viewActions,
  hasRights,
  modalActions,
  cartItemDetails,
  clientId,
  unsavedCart,
}: RecursiveRowProps) => (
  <>
    {mapIndexed(
      (subCartItem, index) => (
        <React.Fragment key={index}>
          <Row
            unsavedCart={unsavedCart}
            userRoles={userRoles}
            parentId={cartItem.articleId}
            tableViews={tableViews}
            cartItem={subCartItem}
            cartActions={cartActions}
            viewActions={viewActions}
            hasRights={hasRights}
            modalActions={modalActions}
            itemRole={itemRole(subCartItem)}
            cartItemDetails={cartItemDetails}
            cartDragAndDrop={cartDragAndDrop}
          />
          <RecursiveRow
            clientId={clientId}
            unsavedCart={unsavedCart}
            userRoles={userRoles}
            cartItem={subCartItem}
            tableViews={tableViews}
            cartActions={cartActions}
            viewActions={viewActions}
            modalActions={modalActions}
            hasRights={hasRights}
            cartItemDetails={cartItemDetails}
            cartDragAndDrop={cartDragAndDrop}
          />
        </React.Fragment>
      ),
      pathOr<TeckentrupCartArticleTO[]>([], ["subArticles"], cartItem),
    )}
  </>
);

type TableProps = {
  cartViews: CartViews;
  hasRights: boolean;
  modalActions: CartModalsReturn["modalActions"];
  modalLoadingStates: CartModalsReturn["modalLoadingStates"];
  viewActions: CartViewReturn["viewActions"];
  partialOrderActivated: CartViewReturn["partialOrderActivated"];
};

export const Table = ({
  cartViews,
  hasRights,
  modalActions,
  modalLoadingStates,
  viewActions,
  partialOrderActivated,
}: TableProps) => {
  const { identityStore } = useContext(IdentityContext);
  const { cart, cartActions, loading } = useContext(CartContext);
  const cartItems = cart.articles.subArticles;
  const tableViews = cartViews;
  const cartItemDetails = useCartItemDetails(cartItems);
  const cartDragAndDrop = useCartDragAndDrop(cartActions);
  const unsavedCart = hasUserUnsavedCart(cart);
  const showLoadingAtEndOfTable = loading && !cart?.reconfigurationPosition;

  if (isNil(cart) || isNil(identityStore)) {
    return null;
  }

  const userRoles = specialUserRoles(identityStore);

  return (
    <FlexTableWrapper className={classNames(tableViews)}>
      <RowHead
        userRoles={userRoles}
        tableViews={tableViews}
        cartItemDetails={cartItemDetails}
        partialOrderActivated={partialOrderActivated}
        partOrderAll={allPartOrderAllowedItemsFlagged(cart.articles)}
        viewActions={viewActions}
      />
      {!modalLoadingStates[CART_MODALS.CLEAR_CART] ? (
        mapIndexed(
          (cartItem, index) => (
            <React.Fragment key={index}>
              <Row
                unsavedCart={unsavedCart}
                userRoles={userRoles}
                parentId={determineParentId(cart, cartItem)}
                tableViews={tableViews}
                cartItem={cartItem}
                cartActions={cartActions}
                viewActions={viewActions}
                hasRights={hasRights}
                modalActions={modalActions}
                itemRole={itemRole(cartItem)}
                cartItemDetails={cartItemDetails}
                cartDragAndDrop={cartDragAndDrop}
              />
              <RecursiveRow
                clientId={cart.headerData?.clientId}
                unsavedCart={unsavedCart}
                userRoles={userRoles}
                cartItem={cartItem}
                tableViews={tableViews}
                cartActions={cartActions}
                viewActions={viewActions}
                hasRights={hasRights}
                modalActions={modalActions}
                cartItemDetails={cartItemDetails}
                cartDragAndDrop={cartDragAndDrop}
              />
            </React.Fragment>
          ),
          cartItems,
        )
      ) : (
        <RowLoading />
      )}
      {showLoadingAtEndOfTable && (
        <ScrollWrapper alignToTop={true}>
          <RowLoading />
        </ScrollWrapper>
      )}
      <RowFoot
        tableViews={tableViews}
        toggleModal={modalActions.toggleModal}
        userRoles={userRoles}
      />
    </FlexTableWrapper>
  );
};
