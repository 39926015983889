import { defaultTo, isNil, pathOr } from "ramda";

const isDefaultPort = (protocol: string, port: string) =>
  (protocol === "http:" && port === "") ||
  (protocol === "https:" && port === "") ||
  (protocol === "http:" && port === "80") ||
  (protocol === "https:" && port === "443");

const hasLocationInWindow = () => window.location?.hostname;

const DEFAULT_BASE_URL = defaultTo(
  "http://localhost:8080",
  process.env.REACT_APP_BACKEND_URL,
);
const DEFAULT_CUSTOMER_MANAGEMENT_URL = defaultTo(
  "http://localhost:8080",
  process.env.REACT_APP_CUSTOMER_MANAGEMENT_BASE_URL,
);
const DEFAULT_REACT_APP_ENVIRONMENT = defaultTo(
  "local",
  process.env.REACT_APP_ENVIRONMENT,
);

const BASE_URL_PATH = ["encoway", "baseUrl"];
const CUSTOMER_MANAGEMENT_PATH = ["encoway", "customerManagementBaseUrl"];
const REACT_APP_ENVIRONMENT_PATH = ["encoway", "reactAppEnvironment"];

export const getEnvironmentVariables = () => {
  const runsInDocker = pathOr(false, ["encoway"], window);

  const baseUrl = runsInDocker
    ? pathOr<string>(DEFAULT_BASE_URL, BASE_URL_PATH, window)
    : getBackendUrl();
  const customerManagementBaseUrl = runsInDocker
    ? pathOr<string>(
        DEFAULT_CUSTOMER_MANAGEMENT_URL,
        CUSTOMER_MANAGEMENT_PATH,
        window,
      )
    : getBackendUrl();
  const reactAppEnvironment = runsInDocker
    ? pathOr<string>(
        DEFAULT_REACT_APP_ENVIRONMENT,
        REACT_APP_ENVIRONMENT_PATH,
        window,
      )
    : "prod";

  return {
    baseUrl,
    customerManagementBaseUrl,
    reactAppEnvironment,
  };
};

/*
 * Function to get backend url
 * returns backend url as string
 */
const getBackendUrl = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT === "prod" &&
    !isNil(window) &&
    hasLocationInWindow()
  ) {
    if (isDefaultPort(window.location.protocol, window.location.port)) {
      return `${window.location.protocol}//${window.location.hostname}`;
    }
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  }
  return process.env.REACT_APP_BACKEND_URL;
};

export const getFrontendUrl = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT === "prod" &&
    !isNil(window) &&
    hasLocationInWindow()
  ) {
    if (isDefaultPort(window.location.protocol, window.location.port)) {
      return `${window.location.protocol}//${window.location.hostname}`;
    }
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  }
  return "//localhost:3000";
};
