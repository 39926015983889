import { Link, useLocation } from "react-router-dom";

import { L10n } from "@encoway/l10n";

import { CONSTANTS, ROUTES } from "../http/constants";

export function ErrorPage() {
  const { pathname } = useLocation();
  const isConfiguration =
    pathname.indexOf("#/configuration") === 0 ||
    pathname.indexOf("#/reconfiguration") === 0;

  return (
    <div>
      <div className="notification is-warning">
        {L10n.format(isConfiguration ? "error_configuration" : "error_general")}
      </div>
      <Link to={ROUTES.PRODUCTS}>
        {L10n.format(CONSTANTS.Tabs.productSelection)}
      </Link>
    </div>
  );
}
