import { curry, equals, map, path, pick } from "ramda";
import { Dispatch, SetStateAction } from "react";

import IdentityStore from "../../../http/identityStore";
import {
  CartActions,
  CartTO,
  ExtendedTeckentrupCartTO,
} from "../../../types/cart";
import { ConfigurationStore } from "../../../types/configuration";
import { Quote } from "../../../types/offermanagement";
import {
  getAllArticles,
  hasUserUnsavedCart,
} from "../../../utilities/cartUtils";
import {
  HeaderDataPayload,
  ModifyOfferDialogReducedState,
  prepareInformationDTO,
} from "../modifyOfferModal/modifyOfferModalActions";
import { OfferManagementDialogState } from "../offerManagement";
import { OfferManagementStore } from "../offerManagementUtils";
import { DispatcherActions } from "../useOfferManagementDispatcherActions";
import {
  OfferManagement,
  OfferManagementTableRow,
  determineLoadFunction,
  isQuoteLoadedByOtherUser,
  isQuoteOrdered,
  isQuoteReadOnly,
  isQuoteShared,
} from "./offerManagementTableUtils";

export const InitialOfferManagementTableState = {
  orderNumber: "",
  creationDate: "",
  createdBy: "",
  modificationDate: "",
  lastModifiedBy: "",
  buildProject: "",
  referenceNumber: "",
  companyName: "",
  status: "",
  comment: "",
  id: "",
  userId: "",
  creatorUserId: "",
  currentUserId: "",
  clientId: "",
  orderedDate: "",
  isRequest: false,
  active: false,
  archived: false,
  accountId: "",
};

type LoadCartProps = {
  item: OfferManagementTableRow;
  dispatcherActions: DispatcherActions;
  configuration: ConfigurationStore;
  cartActions: CartActions;
  identityStore: IdentityStore | null;
  cart: ExtendedTeckentrupCartTO;
  offerManagement: OfferManagement;
};

type ArchiveProps = {
  item: OfferManagementTableRow;
  dispatcherActions: DispatcherActions;
  archive: boolean;
  identityStore: IdentityStore | null;
  cart: ExtendedTeckentrupCartTO;
  offerManagement: OfferManagement;
};

type SetDialogAction = Dispatch<
  SetStateAction<{
    quoteId: string;
    visible: boolean;
  }>
>;

const prepareOfferManagementTableRowState = (quote: Quote) => ({
  ...InitialOfferManagementTableState,
  ...pick(
    [
      "id",
      "userId",
      "creatorUserId",
      "active",
      "archived",
      "modificationDate",
      "creationDate",
      "orderNumber",
    ],
    quote.cartTO,
  ),
  ...pick(["currentUserId"], quote.cartTO.properties),
  ...pick(
    [
      "buildProject",
      "referenceNumber",
      "orderedDate",
      "comment",
      "status",
      "clientId",
      "lastModifiedBy",
      "createdBy",
      "isRequest",
      "accountId",
    ],
    quote.cartTO.headerData,
  ),
  ...pick(
    ["companyName"],
    quote.cartTO.headerData.address
      ? quote.cartTO.headerData.address
      : quote.cartTO,
  ),
});

export const prepareOfferManagementTableState = (
  offerManagementStore: OfferManagementStore,
) => map(prepareOfferManagementTableRowState, offerManagementStore.quotes);

export const onCommentModalInputChange = curry(
  (
    { id }: OfferManagementTableRow,
    onSetHeader: (id: string, data: HeaderDataPayload) => Promise<void>,
    commentState: string,
    setCommentModalVisible: Dispatch<SetStateAction<boolean>>,
  ) => {
    onSetHeader(id, { comment: commentState }).then(() =>
      setCommentModalVisible(false),
    );
  },
);

const checkLVPositionAndReferenceNo = (
  cartActions: CartActions,
  cart: CartTO,
) => {
  Promise.all(
    getAllArticles(cart.articles).map(async (article) => {
      const lvPosition = path(["lvPosition"], article);
      if (equals("-", lvPosition)) {
        await cartActions.setProperty(
          { articleId: article.articleId },
          "_lv_pos",
          "",
        );
      }
      const referenceNo = path(["referenceNo"], article);
      if (equals("-", referenceNo)) {
        await cartActions.setProperty(
          { articleId: article.articleId },
          "_reference_no",
          "",
        );
      }
    }),
  ).then();
};

export const onLoadCart = async ({
  item,
  dispatcherActions,
  configuration,
  cartActions,
  identityStore,
  cart,
  offerManagement,
}: LoadCartProps) => {
  const quoteReadOnly = isQuoteReadOnly(item, identityStore, cart.authorities);
  const quoteOrdered = isQuoteOrdered(item.status);
  const quoteShared = isQuoteShared(item.status);
  const unsavedCart = await cartActions
    .getCart()
    .then((cart) => hasUserUnsavedCart(cart));

  if (!quoteReadOnly && isQuoteLoadedByOtherUser(item)) {
    offerManagement.setState((prevState) => ({
      ...prevState,
      cartAlreadyLoadedWarningVisible: true,
    }));
  } else if (!unsavedCart && !quoteOrdered && !quoteShared) {
    determineLoadFunction(
      item,
      identityStore,
      cart.authorities,
      dispatcherActions,
    );
  } else if (quoteShared) {
    offerManagement.setState((prevState: OfferManagementDialogState) => ({
      ...prevState,
      showExistingCartWarning: unsavedCart,
      cartIsSharedWarningVisible: true,
      item: item,
    }));
  } else {
    offerManagement.setState((prevState: OfferManagementDialogState) => ({
      ...prevState,
      showExistingCartWarning: unsavedCart,
      cartAlreadyOrderedWarningVisible: quoteOrdered,
      item: item,
    }));
  }

  const activeCart = await cartActions.getCart();
  if (
    activeCart.headerData.status === "OPEN" &&
    new Date(activeCart.modificationDate) < new Date(2023, 3, 23) &&
    !isQuoteReadOnly(item, identityStore, activeCart.authorities)
  ) {
    // TODO: This code must be removed at the end of 2023. See https://jira.encoway-services.de/browse/TT-2066
    checkLVPositionAndReferenceNo(cartActions, activeCart);
  }

  configuration.remove();
};

export const onToArchive = ({
  item,
  dispatcherActions,
  archive,
  identityStore,
  cart,
  offerManagement,
}: ArchiveProps) => {
  if (
    !isQuoteReadOnly(item, identityStore, cart.authorities) &&
    isQuoteLoadedByOtherUser(item)
  ) {
    offerManagement.setState((prevState: OfferManagementDialogState) => ({
      ...prevState,
      cartAlreadyLoadedWarningVisible: true,
    }));
  } else {
    dispatcherActions.onArchiveQuote(item.id, archive).then();
  }
};

export const onSaveQuote = curry(
  (
    quote: Quote,
    setModifyOfferDialogVisible: SetDialogAction,
    onSetHeader: DispatcherActions["onSetHeader"],
    checkoutInformation: ModifyOfferDialogReducedState,
  ) => {
    onSetHeader(
      quote.cartTO.id,
      prepareInformationDTO(checkoutInformation),
    ).then(() => setModifyOfferDialogVisible({ quoteId: "", visible: false }));
  },
);

export const onDeleteQuote = (
  itemId: string,
  setDeleteCartWarningVisible: SetDialogAction,
  dispatcherActions: DispatcherActions,
) => {
  dispatcherActions
    .onDeleteQuote(itemId)
    .then(() => setDeleteCartWarningVisible({ quoteId: "", visible: false }));
};
