import { eqProps } from "ramda";

import { CartTO, ExtendedTeckentrupCartTO } from "../../../../types/cart";
import { CART_MODALS } from "../../hooks/useCartModals";

const RETRY_TIMEOUT = 5000;

export function getOrderModal(
  cart: ExtendedTeckentrupCartTO,
  actualCart: CartTO,
) {
  if (!eqProps("id", actualCart, cart)) {
    return {
      modalName: CART_MODALS.CART_ID_CHANGED_MODAL,
      modalData: {
        orderNumberOld: cart.orderNumber,
        orderNumberNew: actualCart.orderNumber,
      },
    };
  }

  if (!eqProps("modificationDate", actualCart, cart)) {
    return {
      modalName: CART_MODALS.CART_ARTICLES_CHANGED_MODAL,
      modalData: {},
    };
  }

  return {
    modalName: CART_MODALS.ORDER_MODAL,
    modalData: {},
  };
}

const withRetry = async (
  f: () => Promise<void>,
  retryCount: number,
  retryIndex = 0,
) => {
  try {
    return await f();
  } catch (error) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (retryCount === retryIndex) {
          reject(error);
        } else {
          resolve(withRetry(f, retryCount, retryIndex + 1));
        }
      }, RETRY_TIMEOUT);
    });
  }
};

export const withRetryOnce = async (f: () => Promise<void>) =>
  await withRetry(f, 1);
