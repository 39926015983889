import { not } from "ramda";
import { RefObject } from "react";

import { VisualEditor, Visualization } from "@encoway/visual-editor";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { t } from "../../shoppingCart/localizationUtils";

type VisualizationModalProps = {
  show: boolean;
  showWall: boolean;
  context: Visualization;
  settings: object;
  locale: string;
  modalRef: RefObject<any>;
  closeModal: () => void;
  showArModal: () => void;
  toggleWall: () => void;
};

export function VisualizationModal(props: Readonly<VisualizationModalProps>) {
  const {
    context,
    settings,
    locale,
    modalRef,
    closeModal,
    showArModal,
    show,
    showWall,
    toggleWall,
  } = props;

  if (not(show)) {
    return null;
  }

  return (
    <Modal
      dialogId="visualization-fullscreen"
      className="vis-modal modal-large"
    >
      <ModalBodyWrapper>
        <ModalHeader
          label={t("configuration_visualization_modal_header")}
          onCancel={closeModal}
        />
        <VisualEditor
          visualization={context}
          settings={settings}
          locale={locale}
          ref={modalRef}
          onReady={() => modalRef.current!.zoom()}
        />
      </ModalBodyWrapper>
      <ModalFooter>
        <button
          data-button-id="configuration_visualization_modal_toggle_wall"
          className="button is-info is-blue-dark"
          onClick={toggleWall}
        >
          {t(
            showWall
              ? "configuration_visualization_hide_wall"
              : "configuration_visualization_show_wall",
          )}
        </button>
        <button
          data-button-id="configuration_visualization_modal_show_ar_code"
          className="button is-info is-blue-dark"
          onClick={showArModal}
        >
          {t("configuration_visualization_show_ar")}
        </button>
        <button
          data-button-id="configuration_visualization_close_modal"
          className="button is-info is-orange"
          onClick={closeModal}
        >
          {t("configuration_visualization_close_modal")}
        </button>
      </ModalFooter>
    </Modal>
  );
}
