import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../../../http/constants";
import { CartActions } from "../../../../types/cart";
import { CART_MODALS, CartModalsReturn } from "../../hooks/useCartModals";
import { t } from "../../localizationUtils";

import "./buttonRow.scss";

type ButtonRowProps = {
  cartActions: CartActions;
  toggleModal: CartModalsReturn["modalActions"]["toggleModal"];
  hasRightToSeeFreeItem: boolean;
};

export const ButtonRow = ({
  cartActions,
  toggleModal,
  hasRightToSeeFreeItem,
}: ButtonRowProps) => {
  const navigate = useNavigate();
  return (
    <div className="button-row-footer is-fullwidth">
      <button
        className="button cartButton"
        onClick={() => navigate(ROUTES.PRODUCTS)}
        data-button-id="new_free_article_dialog_create"
      >
        <span>
          <i className="image-icon artikel-anlegen" aria-hidden="true" />
        </span>
        <span>{t("new_free_article_dialog_create")}</span>
      </button>
      <button
        className="button cartButton"
        onClick={cartActions.createGroup}
        data-button-id="create_group"
      >
        <span>
          <i className="image-icon gruppe-anlegen" aria-hidden="true" />
        </span>
        <span>{t("create_group")}</span>
      </button>
      {hasRightToSeeFreeItem && (
        <button
          className="button cartButton"
          onClick={() => toggleModal(CART_MODALS.FREE_ITEM)()}
          data-button-id="add_free_position"
        >
          <span>
            <i className="image-icon gruppe-anlegen" aria-hidden="true" />
          </span>
          <span>{t("add_free_position")}</span>
        </button>
      )}
    </div>
  );
};
