import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { map } from "ramda";
import React from "react";

import { L10n } from "@encoway/l10n";

const RenderSteps = ({ step, isReConfSuccessful }) => {
  const classNameButton = isReConfSuccessful
    ? {
        "is-success": step === "started" || step === "finished",
        "is-warning": step === "running",
      }
    : {
        "is-success": step === "started",
        "is-warning": step === "running" || step === "finished",
      };
  const classNameIcon = isReConfSuccessful
    ? {
        "fa-check": step === "started" || step === "finished",
        "fa-exclamation-triangle": step === "running",
      }
    : {
        "fa-check": step === "started",
        "fa-exclamation-triangle": step === "running" || step === "finished",
      };

  return (
    <React.Fragment key={step}>
      <tr>
        <td>
          <a className={classNames(["button is-progress"], classNameButton)}>
            <span className="icon is-small">
              <i className={classNames(["fa"], classNameIcon)} />
            </span>
          </a>
        </td>
        <td>{L10n.format(`cart_reconfiguration_${step}`)}</td>
      </tr>
    </React.Fragment>
  );
};

const FailedTable = ({ configObj }) => (
  <table className="table failed-table" align="center">
    <thead>
      <tr>
        <th>{L10n.format("characteristic")}</th>
        <th>{L10n.format("value")}</th>
      </tr>
    </thead>
    <tbody>
      {map(
        (unsuccessfulParameter) => (
          <tr key={unsuccessfulParameter.translatedName}>
            <td>{unsuccessfulParameter.translatedName}</td>
            <td>{unsuccessfulParameter.selectedValues[0].translatedValue}</td>
          </tr>
        ),
        configObj?.unsuccessfulParameters || [],
      )}
    </tbody>
  </table>
);

export const ReconfiguringWarningDialog = ({
  configObj,
  onClickClose,
  isReConfSuccessful = true,
}) => (
  <div className="modal is-active">
    <div className="modal-background" />
    <div className="modal-card create-a-new-quote">
      <header className="modal-card-head">
        <p className="modal-card-title">
          {L10n.format("cart_title_reconfiguration_warning_loaded")}
        </p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => onClickClose(true)}
        />
      </header>
      <section className="modal-card-body">
        <div className="modal-card-subtitle">
          {L10n.format("cart_title_reconfiguration_validate_warning")}
        </div>
        <div className="bottom-space" />
        <table className="table validation-table" align="center">
          <tbody>
            {map(
              (step) => (
                <RenderSteps
                  step={step}
                  isReConfSuccessful={isReConfSuccessful}
                />
              ),
              ["started", "running", "finished"],
            )}
          </tbody>
        </table>
        <div className="modal-card-subtitle">
          {isReConfSuccessful
            ? L10n.format("cart_title_reconfiguration_failed_characteristics")
            : L10n.format("cart_title_reconfiguration_broken")}
        </div>
        <div className="bottom-space" />
        {!isReConfSuccessful && <FailedTable configObj={configObj} />}
      </section>
      <footer className="modal-card-foot">
        {!isReConfSuccessful && (
          <a
            className="button is-info is-blue-dark"
            onClick={() => onClickClose(true)}
          >
            <div className="is-pulled-left">{L10n.format("cancel")}</div>
            <div className="is-pulled-right">
              <FontAwesomeIcon icon={faBan} className="pl-2" />
            </div>
          </a>
        )}
        <a
          className="button is-info is-orange"
          onClick={() => onClickClose(!isReConfSuccessful)}
        >
          <div className="is-pulled-left">{L10n.format("ok")}</div>
        </a>
      </footer>
    </div>
  </div>
);
