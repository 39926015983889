import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "ramda";
import { useState } from "react";

import { DEFAULT_CATEGORY } from "../products";
import { CategorySelectionItem } from "./categorySelectionItem";

export const CategorySelection = ({
  currentCategory,
  setCurrentCategory,
  productGroups,
}) => {
  const [toggle, setToggle] = useState(currentCategory === DEFAULT_CATEGORY);

  const onClick = (id) => {
    setCurrentCategory(id);
    setToggle(false);
  };
  if (!productGroups) {
    return null;
  }

  return (
    <div
      className="categorySelection"
      data-category-selection-unfolded-status={toggle}
    >
      <div className="categorySelectionCards">
        {map(
          (category) => (
            <CategorySelectionItem
              key={category.id}
              isActive={currentCategory === category.id}
              category={category}
              opened={toggle}
              onClickMethod={onClick}
            />
          ),
          productGroups,
        )}
      </div>
      <a
        className="card categorySelectionDropdown"
        onClick={() => setToggle((prev) => !prev)}
        title={toggle ? "Hide further information" : "Show further information"}
        aria-label="more options"
        data-button-id="category_selection_dropdown"
      >
        <span className="icon">
          {toggle ? (
            <FontAwesomeIcon icon={faAngleDoubleUp} width="9px" />
          ) : (
            <FontAwesomeIcon icon={faAngleDoubleDown} width="9px" />
          )}
        </span>
      </a>
    </div>
  );
};
