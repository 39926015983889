import { equals, find, flatten, isNil, map, path, update } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../../../../http/constants";
import {
  refreshConfiguration,
  validateCartArticle,
  validateConfigurable,
  validateSparePartWithSave,
} from "../../../../../service/cartRestService";
import { itemRole } from "../../../../../utilities/cartUtils";
import { t } from "../../../localizationUtils";
import {
  determineCartItemValidationStatus,
  getCartItemValidationState,
} from "./validateCartUtils";

export const useValidateCartModal = (cartActions) => {
  const navigate = useNavigate();
  const [cartItemsToValidate, setCartItemsToValidate] = useState([]);
  const [status, setStatus] = useState({
    loading: true,
    finished: false,
    error: false,
  });

  const validateConfigurableItem = async (cartItem) => {
    if (!path(["validationNeeded"], cartItem)) {
      return {
        finished: true,
        valid: true,
        running: false,
        error: false,
        errorMsg: null,
      };
    }
    const loadingResult = await refreshConfiguration(cartItem.articleId);
    if (isNil(loadingResult) || loadingResult.status !== 200) {
      return {
        finished: true,
        valid: false,
        running: false,
        error: true,
        errorMsg: t("cart_validation_invalid_not_found", cartItem),
      };
    }

    const validationResult = await validateConfigurable(
      cartItem.articleId,
      loadingResult.data,
    );
    if (isNil(validationResult) || validationResult.status !== 200) {
      return {
        finished: true,
        valid: false,
        running: false,
        error: true,
        errorMsg: t("cart_validation_invalid_not_found", cartItem),
      };
    }
    return determineCartItemValidationStatus(cartItem, loadingResult.data);
  };

  const validateSparePartItem = async (cartItem) => {
    const validationResult = await validateSparePartWithSave(
      cartItem.articleId,
    );
    if (isNil(validationResult) || validationResult.status !== 200) {
      return {
        finished: true,
        valid: false,
        running: false,
        error: true,
        errorMsg: t("cart_validation_invalid_sparepart", cartItem),
      };
    }
    const validationResultBody = await validationResult.data;
    return {
      finished: true,
      valid: validationResultBody.valid,
      running: false,
      error: false,
      errorMsg: null,
    };
  };

  const validateCartItem = async (cartItem) => {
    const { isConfigurableItem, isSparePartItem } = itemRole(cartItem);
    if (isConfigurableItem) {
      return {
        ...cartItem,
        validation: await validateConfigurableItem(cartItem),
      };
    }
    if (isSparePartItem) {
      return { ...cartItem, validation: await validateSparePartItem(cartItem) };
    }
    return {
      ...cartItem,
      validation: {
        valid: false,
        running: false,
        error: true,
        errorMsg: t("cart_validation_invalid_not_found", cartItem),
      },
    };
  };

  const validateCartItems = async (cartItems) => {
    let errorStatus = { error: false, errorMsg: "" };
    for (const [index, items] of cartItems.entries()) {
      const validatedSubItems = await Promise.all(map(validateCartItem, items));
      const error = find(({ validation }) => validation.error)(
        validatedSubItems,
      );
      if (!errorStatus.error && error) {
        errorStatus = { error: true, errorMsg: error.validation.errorMsg };
      }
      setCartItemsToValidate((prev) => update(index, validatedSubItems, prev));
    }
    return {
      loading: false,
      finished: true,
      error: errorStatus.error,
      errorMsg: errorStatus.errorMsg,
    };
  };

  const onSave = async () => {
    const { finished, error, errorMsg } = status;
    if (
      finished &&
      error &&
      equals(errorMsg, "cart_validation_global_error_message")
    ) {
      navigate(ROUTES.OFFER_MANAGEMENT, { replace: true });
    } else if (finished) {
      await cartActions.getCart();
    }
  };

  useEffect(() => {
    (async () => {
      const { error, cart } = await validateCartArticle();
      if (error) {
        setStatus({
          ...status,
          loading: false,
          error: true,
          errorMsg: "cart_validation_global_error_message",
        });
      }
      setStatus({ loading: false, finished: false, error: false });
      const itemsToValidate = getCartItemValidationState(cart.articles);
      setCartItemsToValidate(itemsToValidate);
      setStatus(await validateCartItems(itemsToValidate));
    })();
  }, []);

  return [status, flatten(cartItemsToValidate), onSave];
};
