import { NumberInput } from "../../../../components/input/NumberInput";
import { TextInput } from "../../../../components/input/TextInput";
import { LvTable } from "../../../../hooks/useLvTable";
import { LvRow } from "../../../../types/lvTable";
import { t } from "../../../shoppingCart/localizationUtils";

type LvInputFieldProps = {
  rowIndex: number;
  parameterKey: keyof Pick<LvRow, "position" | "reference" | "quantity">;
  lvTable: LvTable;
  isNumber?: boolean;
};

export const LvInputField = ({
  rowIndex,
  parameterKey,
  lvTable,
  isNumber,
}: LvInputFieldProps) => {
  const { lvRows, updateLvRowValue } = lvTable;
  const { name, value } = lvRows[rowIndex][parameterKey];

  return (
    <div className="field has-addons is-align-items-center" style={{ gap: 8 }}>
      <span className="is-size-7 has-text-weight-bold">
        {t(`offer_management_${name}`)}
      </span>
      <div className="control is-expanded">
        {isNumber ? (
          <NumberInput
            className="input is-small is-fullwidth"
            value={Number(value)}
            setValue={(value: number) =>
              updateLvRowValue(rowIndex, parameterKey, value)
            }
          />
        ) : (
          <TextInput
            className="input is-small is-fullwidth"
            value={String(value)}
            setValue={(value: string) =>
              updateLvRowValue(rowIndex, parameterKey, value)
            }
          />
        )}
      </div>
    </div>
  );
};
