import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  and,
  equals,
  gt,
  includes,
  isEmpty,
  isNil,
  length,
  map,
  not,
  reduce,
} from "ramda";
import { useEffect, useState } from "react";

import { CharacteristicViewport } from "@encoway/catalog-components";
import { L10n } from "@encoway/l10n";
import { valueOf as getValueOf } from "@encoway/rest-api";

import { mapIndexed } from "../../utilities/utilities";
import { ProductView } from "./productView/productView";

import "./productSelectionTable.scss";

function ProductSelectionTableRow(props) {
  const {
    characteristics,
    characteristicViews,
    favoriteProductIds,
    indexProduct,
    isOpen,
    language,
    product,

    toggleFavorite,
    toggleTableRow,
  } = props;

  const ICONS = {
    blueFilled: (id) => (
      <FontAwesomeIcon
        className="fa-star"
        data-button-id={id}
        color={"#adc6c9"}
        icon={faStar}
        width="13.75px"
      />
    ),
    blueOutline: (id) => (
      <FontAwesomeIcon
        className="fa-star-o"
        data-button-id={id}
        icon={faStarRegular}
        width="13.75px"
        style={{ color: "#adc6c9" }}
      />
    ),
    blackOutline: (id) => (
      <FontAwesomeIcon
        className="fa-star-o"
        data-button-id={id}
        icon={faStarRegular}
        width="13.75px"
      />
    ),
  };

  const ICON_STATES = [
    [(isFavourited) => isFavourited, ICONS.blueFilled],
    [(isFavourited) => not(isFavourited), ICONS.blackOutline],
    [
      (isFavourited, isHovered) => and(not(isFavourited), isHovered),
      ICONS.blueFilled,
    ],
    [
      (isFavourited, isHovered) => and(isFavourited, isHovered),
      ICONS.blueOutline,
    ],
  ];

  const FavoriteIcon = ({ id }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {reduce(
          (acc, [checkFn, elementFn]) =>
            checkFn(includes(id, favoriteProductIds), isHovered)
              ? elementFn(id)
              : acc,
          null,
          ICON_STATES,
        )}
      </div>
    );
  };

  const characteristicOverviewRows = isNil(
    characteristicViews.property_type_group_overview,
  )
    ? null
    : map((entry, indexGroupOverview) => {
        const characteristic = characteristics.find(
          (char) => char.id === entry,
        );
        const value = getValueOf({ product }, entry);
        if (value === null) {
          return (
            <td
              data-column-id={`${indexGroupOverview + 3}-${product.id}-${characteristic.id}`}
              key={entry}
            />
          );
        }

        const valueId =
          product.characteristicValues[characteristic.id].values[0];
        if (
          characteristic.possibleValues?.[valueId].characteristicValues
            .benennung
        ) {
          return (
            <td
              data-column-id={`${indexGroupOverview + 3}-${product.id}-${characteristic.id}`}
              key={entry}
            >
              {
                characteristic.possibleValues[valueId].characteristicValues
                  .benennung.values[0]
              }
            </td>
          );
        }

        return (
          <td
            data-column-id={`${indexGroupOverview + 3}-${product.id}-${characteristic.id}`}
            key={entry}
          >
            {CharacteristicViewport.render(characteristic, value)}
          </td>
        );
      }, characteristicViews.property_type_group_overview);

  if (!isOpen) {
    return [
      <tr
        key={product.id}
        data-product-id={`${product.id}`}
        data-row-id={`${indexProduct + 1}`}
        onClick={toggleTableRow}
      >
        <td
          data-column-id={`1-${product.id}-Favourite`}
          onClick={(e) => toggleFavorite(e, product)}
        >
          <FavoriteIcon id={product.id} />
        </td>
        <td data-column-id={`2-${product.id}-catalogue_product_column_title`}>
          {product.name}
        </td>
        {characteristicOverviewRows}
      </tr>,
    ];
  }

  return [
    <tr
      key={product.id}
      data-product-id={`${product.id}`}
      data-row-id={`${indexProduct + 1}`}
      className="selected"
      onClick={toggleTableRow}
    >
      <td
        data-column-id={`1-${product.id}-Favourite`}
        onClick={(e) => toggleFavorite(e, product)}
      >
        <FavoriteIcon id={product.id} />
      </td>
      <td data-column-id={`2-${product.id}-catalogue_product_column_title`}>
        {product.name}
      </td>
      {characteristicOverviewRows}
    </tr>,
    <tr
      key={`${product.id}productView`}
      data-product-view-product-id={product.id}
      className="productView"
    >
      <td></td>
      <ProductView
        product={product}
        colSize={characteristicOverviewRows.length + 1}
        language={language}
      />
    </tr>,
  ];
}

export default function ProductSelectionTable(props) {
  const {
    language,
    products,
    characteristics,
    characteristicViews,
    favoriteProductIds,
  } = props;

  const [openProduct, setOpenProduct] = useState(
    gt(length(products), 0) ? products[0].id : "",
  );

  useEffect(() => {
    setOpenProduct(products.length === 1 ? products[0].id : "");
  }, [products]);

  /**
   * Handle table row click.
   * @param {any} id
   * @memberOf ProductSelectionTable
   * @return {void}
   */
  function toggleTableRow(id) {
    setOpenProduct((prev) => (equals(prev, id) ? "" : id));
  }

  function toggleFavorite(e, product) {
    e.stopPropagation();
    props.toggleFavorite(product);
  }

  function renderRows() {
    return mapIndexed(
      (product, indexProduct) => (
        <ProductSelectionTableRow
          key={indexProduct}
          indexProduct={indexProduct}
          product={product}
          language={language}
          characteristicViews={characteristicViews}
          characteristics={characteristics}
          favoriteProductIds={favoriteProductIds}
          isOpen={equals(openProduct, product.id)}
          toggleFavorite={(e) => toggleFavorite(e, product)}
          toggleTableRow={() => toggleTableRow(product.id)}
        />
      ),
      products,
    );
  }

  let columnHeaders = [];
  if (characteristicViews.property_type_group_overview !== undefined) {
    columnHeaders = characteristicViews.property_type_group_overview.map(
      (entry) => {
        const chara = characteristics.find((char) => char.id === entry);
        return (
          <th key={entry}>
            {chara.characteristicValues.benennung
              ? chara.characteristicValues.benennung.values[0]
              : chara.name}
          </th>
        );
      },
    );
  }
  return (
    <table
      data-table-id="product-selection"
      className="table product-selection-table is-fullwidth"
    >
      <thead>
        <tr>
          <th></th>
          <th>{L10n.format("catalogue_product_column_title")}</th>
          {columnHeaders}
        </tr>
      </thead>
      <tbody>
        {isEmpty(products) ? (
          <div className="no-products-found">
            {L10n.format("catalogue_no_products_found")}
          </div>
        ) : (
          renderRows()
        )}
      </tbody>
    </table>
  );
}
