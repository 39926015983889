import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PropsWithChildren } from "react";

import { mapIndexed } from "../../../utilities/utilities";

type QuoteAddressRowsProps = PropsWithChildren<{
  mutatedState: string[][];
  className?: string;
  isOpen?: boolean;
  onCloseOrEdit: () => void;
}>;

export function QuoteAddressRows(props: Readonly<QuoteAddressRowsProps>) {
  const {
    mutatedState,
    children,
    className,
    isOpen = false,
    onCloseOrEdit,
  } = props;
  return (
    <>
      <div className={classNames(["quote-address-input-header", className])}>
        <div className="quote-address-two-cells">
          <div className="quote-address-fields">
            {mapIndexed(
              (group, key) => (
                <div className="flex-cell" key={key}>
                  <p>
                    {mapIndexed(
                      (value, valueKey) => (
                        <span key={valueKey}>{value}</span>
                      ),
                      group,
                    )}
                  </p>
                </div>
              ),
              mutatedState,
            )}
          </div>
        </div>
        <button
          className="quote-address-two-cells no-border transparent"
          onClick={onCloseOrEdit}
        >
          {isOpen ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </button>
      </div>
      {children}
    </>
  );
}
