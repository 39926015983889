/* Input Field length defined in TEO-1213 || TT-513 */

export const InputConstants = {
  length: {
    defaultLength: 10,

    DE: {
      order_dialog_post_code: 5,
    },

    // Dialog "Zu den Bestelldaten"
    order_dialog_build_project: 100, // Bauvorhaben
    order_dialog_reference: 35, // Referenz
    order_dialog_further_notes: 1333, // Hinweise an meinen Sachbearbeiter
    order_dialog_company_name: 35, // Name der Firma
    order_dialog_telephone_number: 30, // Telefonnummer
    order_dialog_contact_salutation: 15, // Anrede Ansprechpartner
    order_dialog_contact_firstName: 40, // Vorname Ansprechpartner
    order_dialog_contact_lastName: 40, // Nachname Ansprechpartner
    order_dialog_street: 60, // Straße
    order_dialog_house_number: 10, // Hausnummer
    order_dialog_post_code: 10, // Postleitzahl
    order_dialog_city: 40, // Ort

    // Kopfdaten bearbeiten / Vorgang Speichern
    building_project: 100, // Bauvorhaben
    modify_dialog_reference: 35, // Referenz
    modify_dialog_company_name: 35, // Name der Firma
    modify_dialog_contact_companyName: 30,
    modify_dialog_phone_number: 30, // Telefonnummer
    modify_dialog_contact_phone: 30,
    modify_dialog_contact_salutation: 15, // Anrede Ansprechpartner
    modify_dialog_contact_title: 15,
    modify_dialog_contact_firstName: 40, // Vorname Ansprechpartner
    modify_dialog_contact_firstname: 40,
    modify_dialog_contact_lastName: 40, // Nachname Ansprechpartner
    modify_dialog_contact_surname: 40,
    modify_dialog_street: 60, // Straße
    modify_dialog_contact_street: 60,
    modify_dialog_house_number: 10, // Hausnummer
    modify_dialog_contact_houseNumber: 10,
    modify_dialog_zip_code: 10, // Postleitzahl
    modify_dialog_contact_zip: 10,
    modify_dialog_city: 40, // Ort
    modify_dialog_contact_city: 40,
  },
} as const;
