import { isEmpty, isNil, map } from "ramda";

import {
  FlexTableCell as Cell,
  FlexTableRow,
} from "../../../../../components/flexTable/flexTable";
import { Loading } from "../../../../../components/loading";
import { ParameterTO } from "../../../../../types/@encoway/Parameter";
import { t } from "../../../localizationUtils";

import "./rowProductDetails.scss";

const RowProductDetailsHeader = () => (
  <FlexTableRow className="product-details-row pt-5">
    <Cell />
    <Cell />
    <Cell />
    <Cell className="product-details-label" data-cell-id={"product_details"}>
      <span className="is-size-5">{t("product_details")}</span>
    </Cell>
    <Cell />
  </FlexTableRow>
);

const RowProductDetailsEmpty = () => (
  <FlexTableRow className="product-details-row">
    <Cell />
    <Cell />
    <Cell>
      <Loading />
    </Cell>
    <Cell />
  </FlexTableRow>
);

type RowProductDetailProps = {
  cartItemDetail: ParameterTO;
  positionNr: string;
};

const RowProductDetail = ({
  cartItemDetail,
  positionNr,
}: RowProductDetailProps) => (
  <FlexTableRow className="product-details-row">
    <Cell />
    <Cell />
    <Cell />
    <Cell data-cell-id={cartItemDetail.name}>
      {cartItemDetail.translatedName}
    </Cell>
    <Cell
      className={"cell-expand"}
      data-cell-id={`${positionNr}-${cartItemDetail.selectedValues![0].value}`}
    >
      {cartItemDetail.selectedValues![0].translatedValue}{" "}
      {cartItemDetail.displayUnit?.translatedUnit}
    </Cell>
  </FlexTableRow>
);

type RowProductDetailsProps = {
  cartItemDetails: ParameterTO[];
  positionNr: string;
};

export function RowProductDetails({
  cartItemDetails,
  positionNr,
}: Readonly<RowProductDetailsProps>) {
  return (
    <div className="product-details">
      <RowProductDetailsHeader />
      {isNil(cartItemDetails) || isEmpty(cartItemDetails) ? (
        <RowProductDetailsEmpty />
      ) : (
        map(
          (cartItemDetail) => (
            <RowProductDetail
              key={cartItemDetail.id}
              cartItemDetail={cartItemDetail}
              positionNr={positionNr}
            />
          ),
          cartItemDetails,
        )
      )}
    </div>
  );
}
