import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { t } from "../../localizationUtils";

type MessageModalProps = {
  messageBoxTitle?: string;
  messageLabel?: string;
  messageContent?: ReactNode;
  saveAndCloseDialog: () => void;
};

export function MessageModal(props: Readonly<MessageModalProps>) {
  const { messageBoxTitle, messageContent, messageLabel, saveAndCloseDialog } =
    props;

  return (
    <Modal>
      <ModalHeader label={messageBoxTitle} onCancel={saveAndCloseDialog} />
      <ModalBodyWrapper label={messageLabel}>
        <div className="enc-px-5">{messageContent}</div>
      </ModalBodyWrapper>
      <ModalFooter
        saveLabel={t("ok")}
        onSave={saveAndCloseDialog}
        saveIcon={<FontAwesomeIcon icon={faCheck} />}
      />
    </Modal>
  );
}
