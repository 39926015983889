import { equals, isNil } from "ramda";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { AppProvider, useApp } from "./hooks/useApp";
import { IdentityProvider, MODE, useIdentity } from "./hooks/useIdentity";
import DevLogin from "./pages/devLogin";

function Entry() {
  const appStore = useApp();
  const identityStore = useIdentity(appStore);

  if (identityStore.loading || appStore.loading) {
    return null;
  }

  if (
    equals(appStore.mode, MODE.DEVELOP) &&
    isNil(window.localStorage.getItem("authToken"))
  ) {
    return <DevLogin />;
  }

  return (
    <React.StrictMode>
      <AppProvider value={appStore}>
        <IdentityProvider value={identityStore}>
          <App />
        </IdentityProvider>
      </AppProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById("content") as HTMLElement);

root.render(<Entry />);
