import classnames from "classnames";
import { path } from "ramda";
import { useEffect, useMemo, useState } from "react";

import { Constants, containsParameter } from "@encoway/react-components";

import { getCurrentTab, selectTab } from "../helper/tabsHelper";
import {
  containerIsEmpty,
  determineActiveTab,
  determineNextTab,
  determinePreviousTab,
} from "../utils/tabsUtils";

const defaultStyle = {
  boxed: false,
  toggle: false,
  readyIcon: null,
  iconPosition: "left",
  hideSingleMenu: false,
  showNavigation: false,
  navSkipOptionalTabs: false,
};

const onEvent = (currentTab, props, setCurrentTab, event) => {
  const filtered = props.data.children.filter((tab) =>
    containsParameter(tab, event.parameter),
  );
  filtered.length >= 1 &&
    selectTab(
      props.options.eventBus,
      currentTab,
      setCurrentTab,
      filtered[0].name,
    );
};

export const useTabs = (props) => {
  const containerFilter = path(["options", "filter", "container"], props);

  const [currentTab, setCurrentTab] = useState(
    determineActiveTab(props.inject, props.data.children, containerFilter),
  );

  const style = { ...defaultStyle, ...props.style };

  const tabStyle = classnames({
    tabs: true,
    "is-boxed": style.boxed,
    "is-toggle": style.toggle,
  });

  const current = useMemo(
    () =>
      getCurrentTab(
        props.options.eventBus,
        currentTab,
        props.inject,
        props.data.children,
        containerFilter,
      ),
    [props.data, props.inject, currentTab, containerFilter],
  );

  const previous = useMemo(
    () =>
      determinePreviousTab(
        props.inject,
        props.data.children,
        containerFilter,
        currentTab,
        style.navSkipOptionalTabs,
      ),
    [containerFilter, currentTab, style],
  );

  const next = useMemo(
    () =>
      determineNextTab(
        props.inject,
        props.data.children,
        containerFilter,
        currentTab,
        style.navSkipOptionalTabs,
      ),
    [containerFilter, currentTab, style],
  );

  const previousClickHandler =
    previous !== null
      ? () =>
          selectTab(props.options.eventBus, currentTab, setCurrentTab, previous)
      : null;

  const nextClickHandler =
    next !== null
      ? () => selectTab(props.options.eventBus, currentTab, setCurrentTab, next)
      : null;

  const tabs = useMemo(
    () =>
      props.data.children.filter(
        (child) =>
          !(
            (containerFilter && containerFilter(child) === false) ||
            containerIsEmpty(child)
          ),
      ),
    [props.data.children],
  );

  props.options.eventBus
    .filter((eve) => eve.event === Constants.Commands.GotoParameter)
    .onValue((eve) => onEvent(currentTab, props, setCurrentTab, eve));

  useEffect(() => {
    const activeTab = determineActiveTab(
      props.inject,
      props.data.children,
      containerFilter,
    );
    selectTab(props.options.eventBus, currentTab, setCurrentTab, activeTab);
  }, [props.data.id]);

  return {
    style,
    tabStyle,
    containerFilter,
    current,
    currentTab,
    setCurrentTab,
    previous,
    next,
    previousClickHandler,
    nextClickHandler,
    tabs,
  };
};
