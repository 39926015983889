import { mapObjIndexed, values } from "ramda";
import { ComponentPropsWithoutRef } from "react";

import { L10n } from "@encoway/l10n";

import { Input } from "../../pages/shoppingCart/components/modals/orderModal/input";
import { InputMaxLength } from "../input/inputMaxLength";

type ModalInputGroupProps = ComponentPropsWithoutRef<"input"> & {
  inputFieldObj: Record<string, string>;
  label: string;
};

export const ModalInputGroup = ({
  inputFieldObj,
  maxLength,
  label,
  readOnly = false,
  ...props
}: ModalInputGroupProps) => {
  const prepareProps = (value: string, key: string) => ({
    ...props,
    readOnly,
    key,
    value,
    placeHolder: L10n.format(`${label}_${key}`),
    label: L10n.format(`${label}_${key}`),
    maxLength,
    inputId: "modal-group-input",
  });
  return (
    <div className="quote-address-inputs is-flex">
      {values(
        mapObjIndexed(
          (value, key) => (
            <div className="flex-cell" key={key}>
              {maxLength ? (
                <InputMaxLength {...prepareProps(value, key)} />
              ) : (
                <Input {...prepareProps(value, key)} />
              )}
            </div>
          ),
          inputFieldObj,
        ),
      )}
    </div>
  );
};
