import { faGears, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { count, find, isEmpty, isNil, map, not, propEq } from "ramda";

import { L10n } from "@encoway/l10n";
import { Configurator } from "@encoway/react-components";

import { useLvConfiguration } from "../../../../hooks/useLvConfiguration";
import { LvTable } from "../../../../hooks/useLvTable";
import { LvRow } from "../../../../types/lvTable";
import {
  isOriginalValue,
  updateSelectionSource,
} from "../../../../utilities/lvUtils";
import { t } from "../../../shoppingCart/localizationUtils";
import { ICON_MAP } from "./LvConfiguratorDropdownField";
import { LvDropdown } from "./LvDropdown";

type LvParameterListProps = {
  parameters: LvRow["parameters"];
};

const LvParameterList = ({ parameters }: LvParameterListProps) => (
  <ul style={{ lineHeight: 2.25 }}>
    {map(
      ({
        name,
        value,
        translatedName,
        translatedValue,
        originalValue,
        recognized,
        selectionSource,
      }) => {
        const isOriginal = isOriginalValue(originalValue, value);
        return (
          <li
            key={name}
            className="is-size-7 has-text-bold is-flex is-align-items-center"
            style={{ gap: 8 }}
          >
            <span className="icon is-small mr-2">
              {ICON_MAP[selectionSource]}
            </span>
            <span
              className={classNames("is-flex-grow-1", {
                "has-text-success": isOriginal,
              })}
            >
              {translatedName}
            </span>
            <span>{translatedValue}</span>
            {not(isEmpty(recognized)) && (
              <span className={classNames({ "has-text-success": isOriginal })}>
                ({recognized})
              </span>
            )}
          </li>
        );
      },
      parameters,
    )}
  </ul>
);

type LvProductFieldProps = {
  rowIndex: number;
  lvTable: LvTable;
};

export const LvProductField = ({ rowIndex, lvTable }: LvProductFieldProps) => {
  const { config, isLoading, startConfAndSetValues, stopConf } =
    useLvConfiguration();
  const { lvRows, updateLvRowProduct, updateLvRowParameters } = lvTable;
  const { parameters, product } = lvRows[rowIndex];
  const { values } = product;

  const selectableCount = count((value) => value.selectable, values);

  function onSelectValue(value: string) {
    const selectedValue = find(propEq("value", value), values);
    if (isNil(selectedValue)) return;
    updateLvRowProduct(rowIndex, {
      ...selectedValue,
      selectionSource: "SET_BY_USER",
      values: updateSelectionSource(selectedValue.value, values),
    });
  }

  async function onSaveChanges() {
    const guiParameters = await stopConf(parameters, product);
    updateLvRowParameters(rowIndex, guiParameters.parameters);
    updateLvRowProduct(rowIndex, guiParameters.product);
  }

  return (
    <>
      <div className="field has-addons">
        <div className="control is-expanded">
          <LvDropdown
            values={values}
            selectedValue={product}
            onSelectValue={onSelectValue}
            isDisabled={not(isNil(config))}
          />
        </div>
        <div className="control">
          <button className="button is-static is-small">
            {selectableCount}
          </button>
        </div>
        <div className="control">
          <button
            className={classNames("button is-orange is-small", {
              "is-loading": isLoading,
            })}
            onClick={
              config
                ? onSaveChanges
                : () => startConfAndSetValues(parameters, product)
            }
          >
            <span>{t(config ? "submit" : "change")}</span>
            <span className="icon is-small">
              <FontAwesomeIcon icon={config ? faSave : faGears} />
            </span>
          </button>
        </div>
      </div>
      {config ? (
        <Configurator
          config={config}
          lang={L10n.currentLocale()}
          options={{
            hideLinkedTree: true,
            hideInternalTree: true,
            deleteOnUnmount: false,
            dontDeleteOnIdChange: true,
            lvParameters: parameters,
          }}
        />
      ) : (
        <LvParameterList parameters={parameters} />
      )}
    </>
  );
};
