import {
  comparator,
  flatten,
  isEmpty,
  isNil,
  keys,
  last,
  length,
  omit,
  pick,
  remove,
  sortWith,
  splitEvery,
  test,
  toLower,
} from "ramda";
import { Dispatch, SetStateAction } from "react";
import { Timeout } from "react-number-format/types/types";

import { customerExists } from "../../service/customerManagementService";
import {
  InitialAddQuoteToCustomerState,
  InitialCustomerDialogState,
} from "./customerModal/customerModalActions";

export type ContactDto = {
  city: string;
  companyId: number;
  contactTyp: "COMPANY" | "DELIVERY" | "STORAGE";
  country: string | null;
  customerid: number;
  email: string;
  firstname: string;
  houseNumber: string;
  id: number;
  phone: string;
  street: string;
  surname: string;
  title: string;
  zip: string;
};

export type CustomerDto = {
  accountId: string | null;
  companyContacts: ContactDto[];
  companyId: number;
  companyNumber: string;
  customerName: string;
  customerNumber: string;
  deliveryContacts: ContactDto[];
  id: number;
};

export type CompanyDto = {
  companyNumber: string;
  accountId: string;
  id: number;
  customers: CustomerDto[];
  storageContacts: ContactDto[];
};

type CustomerManagementSorting = {
  paging: number;
  page: number;
  sort: string;
  sortCell: number;
  sortKey: string;
};

type CustomerManagementState = {
  sorting: CustomerManagementSorting;
  customers: CustomerDto[][];
  customerSearch: CustomerDto[][];
  searchValue: string;
};

type SortKey = "customerName" | "customerNumber";

type CustomerDialogState = {
  customerNumber: string;
  initialCustomerNumber?: string;
  customerName: string;
  quoteAddressOpen: number;
  quoteAddressEditOpen: number;
  quoteAddressDeleteModalToggle: boolean;
  modalCancelToggle: boolean;
  isComplete: boolean;
  customerNumberExists: boolean;
  customerNumberTimeout: Timeout;
  companyContacts: ContactDto[];
  edited: boolean;
};

export const SortCustomerList = (
  key: SortKey,
  state: CustomerManagementState,
  customerList: CustomerDto[][],
) =>
  !isNil(customerList)
    ? [
        ...splitEvery(
          state.sorting.paging,
          sortWith([
            state.sorting.sort === "asc"
              ? comparator(
                  (a: CustomerDto, b: CustomerDto) =>
                    toLower(a[key]) < toLower(b[key]),
                )
              : comparator(
                  (a: CustomerDto, b: CustomerDto) =>
                    toLower(a[key]) > toLower(b[key]),
                ),
          ])(flatten(customerList)),
        ),
      ]
    : null;

export const prepareQuoteAddressState = (state: ContactDto) => [
  [state.title],
  [state.street, state.houseNumber],
  [state.phone],
  [state.firstname, state.surname],
  [state.zip, state.city],
  [state.email],
];

export const prepareQuoteAddressInputState = (state: ContactDto) =>
  pick(keys(omit(["title"], InitialAddQuoteToCustomerState)), {
    ...InitialAddQuoteToCustomerState,
    ...state,
  });

export const checkIfCustomerNumberExists = (
  customerNumberTimeout: number,
  setCustomerNumberTimeout: Dispatch<SetStateAction<Timeout>>,
  value: string,
) => {
  customerNumberTimeout && clearTimeout(customerNumberTimeout);
  return new Promise((resolve) => {
    setCustomerNumberTimeout(
      setTimeout(
        () =>
          customerExists({ customerNumber: value }).then(
            ({ status, data }) => status === 200 && resolve(data),
          ),
        500,
      ),
    );
  });
};

export const mergeInitialCustomerDialogState = (
  customerState: CustomerDto,
) => ({
  ...InitialCustomerDialogState,
  ...customerState,
  ...(customerState && { initialCustomerNumber: customerState.customerNumber }),
});

export const isComplete = ({
  initialCustomerNumber,
  customerNumber,
  customerName,
  customerNumberExists,
}: CustomerDialogState) =>
  (!isEmpty(customerNumber) &&
    !isEmpty(customerName) &&
    !customerNumberExists &&
    !test(/^\s*$/g, customerNumber)) ||
  initialCustomerNumber === customerNumber;

export const quoteStateWithoutActiveElement = (state: CustomerDialogState) =>
  state.quoteAddressOpen === -1
    ? state.companyContacts
    : remove(state.quoteAddressOpen, 1, state.companyContacts);

export const createCustomerDialogDTO = (state: CustomerDialogState) =>
  pick(["customerNumber", "customerName", "companyContacts"], state);

export const noCustomersFound = ({
  searchValue,
  customerSearch,
  customers,
}: CustomerManagementState) =>
  (!isEmpty(searchValue) && isEmpty(customerSearch)) || isEmpty(customers);

export const willPageSizeReduce = (
  pageNumber: number,
  pagedCustomerList: CustomerDto[][] | null,
): boolean => {
  if (isNil(pagedCustomerList)) {
    return false;
  }
  return (
    pageNumber === pagedCustomerList.length - 1 &&
    length(last(pagedCustomerList) || []) === 1
  );
};
