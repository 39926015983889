import classNames from "classnames";
import { not, pathOr } from "ramda";
import { useState } from "react";

import {
  FlexTableCell as Cell,
  FlexTableRow as Row,
  FlexTableWrapper as Table,
} from "../../../../../components/flexTable/flexTable";
import { Loading } from "../../../../../components/loading";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { ProgressButton } from "../../../../../components/progressButton";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import {
  CartActions,
  ExtendedTeckentrupCartTO,
} from "../../../../../types/cart";
import { mapIndexed } from "../../../../../utilities/utilities";
import { ToggleModalAction } from "../../../hooks/useCartModals";
import { t } from "../../../localizationUtils";
import { determineItemValidationLabel } from "../../warnings/validateCartModal/validateCartUtils";
import { hasPricingChanged } from "./updatePricesModalUtils";
import {
  PriceModalStatus,
  ValidatedCartItem,
  useUpdatePricesModal,
} from "./useUpdatePricesModal";

import "./updatePricesModal.scss";

type UpdatePricesModalProps = {
  cart: ExtendedTeckentrupCartTO;
  cartActions: CartActions;
  toggleModal: ToggleModalAction;
};

type AskForUpdateModalBodyProps = {
  cancelPriceUpdate: boolean;
  onSave: () => void;
  toggleModal: ToggleModalAction;
};

type UpdatePricesModalBodyProps = {
  cart: ExtendedTeckentrupCartTO;
  cartActions: CartActions;
  toggleModal: ToggleModalAction;
  onCancel: () => void;
};

function AskForUpdateModalBody(props: Readonly<AskForUpdateModalBodyProps>) {
  const { onSave, toggleModal, cancelPriceUpdate } = props;
  const [allowedByUser, setAllowedByUser] = useState<boolean>(true);
  const allowedByUserAndNotCancelled = allowedByUser && !cancelPriceUpdate;

  return (
    <>
      <ModalHeader
        label={t("update_price_title_ask_for_update")}
        onCancel={
          allowedByUserAndNotCancelled
            ? () => setAllowedByUser(false)
            : () => toggleModal()
        }
      />
      <ModalBodyWrapper
        label={t(
          allowedByUserAndNotCancelled
            ? "update_price_body_ask_for_update"
            : "update_price_body_ask_for_update_cancel",
        )}
      />
      <ModalFooter
        saveLabel={t(allowedByUserAndNotCancelled ? "yes" : "ok")}
        onSave={allowedByUserAndNotCancelled ? onSave : () => toggleModal()}
        {...(allowedByUserAndNotCancelled && {
          onCancel: () => setAllowedByUser(false),
          cancelLabel: t("no"),
        })}
      />
    </>
  );
}

type UpdatePriceTableProps = {
  cartItems: ValidatedCartItem[];
  status: PriceModalStatus;
};

function UpdatePriceTable({
  cartItems,
  status,
}: Readonly<UpdatePriceTableProps>) {
  const { formatPrice } = useLocaleNumberFormat();

  if (status.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  return (
    <Table>
      <Row key="firstRow">
        <Cell value={t("update_price_table_status")} />
        <Cell value={t("update_price_table_pos")} />
        <Cell value={t("update_price_table_article")} />
        <Cell value={t("update_price_table_price_old")} />
        <Cell value={t("update_price_table_price_new")} />
      </Row>
      {mapIndexed(
        (cartItem, index) => (
          <Row key={cartItem.articleId}>
            <Cell>
              <ProgressButton
                isLoading={cartItem.validation.running}
                isSuccess={
                  cartItem.validation.finished && cartItem.validation.valid
                }
              />
            </Cell>
            <Cell value={index + 1} />
            <Cell value={determineItemValidationLabel(cartItem)} />
            <Cell value={formatPrice(cartItem.bruttoEpIncTz)} />
            <Cell
              className={classNames({
                "pricing-has-changed": hasPricingChanged(cartItem),
              })}
              value={formatPrice(
                pathOr("", ["validation", "newPrice"], cartItem),
              )}
            />
          </Row>
        ),
        cartItems,
      )}
    </Table>
  );
}

const UpdatePricesModalBody = (props: Readonly<UpdatePricesModalBodyProps>) => {
  const { cart, toggleModal, cartActions, onCancel } = props;
  const { status, cartItems, save, onSave } = useUpdatePricesModal(
    cart,
    cartActions,
  );
  const savedLabel = t(
    status.error
      ? "update_price_body_running_done_with_error"
      : "update_price_body_running_done",
  );

  function handleSave() {
    if (status.finished && not(save.saved)) {
      onSave();
      return;
    }
    if (save.saved) {
      toggleModal();
    }
  }

  return (
    <>
      <ModalHeader
        label={t("update_price_title_ask_for_update")}
        {...(not(save.saved) && { onCancel })}
      />
      {save.saved ? (
        <ModalBodyWrapper label={savedLabel} />
      ) : (
        <ModalBodyWrapper label={t("update_price_body_running_update")}>
          <UpdatePriceTable status={status} cartItems={cartItems} />
        </ModalBodyWrapper>
      )}
      <ModalFooter
        disabled={!status.finished || (status.finished && !status)}
        saveLabel={t("ok")}
        isLoading={save.saving}
        onSave={handleSave}
      />
    </>
  );
};

export function UpdatePricesModal(props: Readonly<UpdatePricesModalProps>) {
  const { toggleModal, cartActions, cart } = props;
  const [acceptPriceUpdate, setAcceptPriceUpdate] = useState<boolean>(false);
  const [cancelPriceUpdate, setCancelPriceUpdate] = useState<boolean>(false);

  if (acceptPriceUpdate && !cancelPriceUpdate) {
    return (
      <Modal
        className="validate-cart-warning update-price"
        dialogId="update-price"
      >
        <UpdatePricesModalBody
          cart={cart}
          toggleModal={toggleModal}
          cartActions={cartActions}
          onCancel={() => setCancelPriceUpdate(true)}
        />
      </Modal>
    );
  }

  return (
    <Modal
      className="validate-cart-warning update-price"
      dialogId="update-price"
    >
      <AskForUpdateModalBody
        toggleModal={toggleModal}
        onSave={() => setAcceptPriceUpdate(true)}
        cancelPriceUpdate={cancelPriceUpdate}
      />
    </Modal>
  );
}
