import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { t } from "../../localizationUtils";

type ClearCartWarningModalProps = {
  isLoading?: boolean;
  sharing?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export function ClearCartWarningModal(
  props: Readonly<ClearCartWarningModalProps>,
) {
  const { onCancel, onSubmit, isLoading = false, sharing = false } = props;

  return (
    <Modal dialogId="cart-clear-positions">
      <ModalHeader
        label={t("offer_management_unsaved_changes_header")}
        onCancel={onCancel}
      />
      <ModalBodyWrapper>
        {sharing
          ? t("cart_create_new_quote_warn")
          : t("cart_discard_unsaved_positions")}
      </ModalBodyWrapper>
      <ModalFooter
        saveLabel={
          sharing
            ? t("offer_management_unsaved_changes_ok")
            : t("cart_clear_positions")
        }
        cancelLabel={t("cancel")}
        onCancel={onCancel}
        onSave={onSubmit}
        isLoading={isLoading}
        disabled={isLoading}
      />
    </Modal>
  );
}
