import {
  comparator,
  equals,
  filter,
  isEmpty,
  isNil,
  map,
  none,
  pathOr,
  replace,
  sortWith,
  toLower,
} from "ramda";

import { Quote } from "../../types/offermanagement";
import {
  ContactDto,
  CustomerDto,
} from "../customerManagement/customerManagementUtils";
import { InitialOfferManagementTableState } from "./offerManagementTable/offerManagementTableActions";

export type OfferManagementStore = {
  quotes: Quote[];
  currentPage: number;
  currentPageSize: number;
  pageCount: number;
  sortKey: string;
  sortType?: string;
  sortDirection?: string;
  conjunction?: string;
  filter: {
    key: string;
    operator: string;
    value: string;
  };
  showOnlyUserOffers: boolean;
  showOnlyThirdPartySystemOffers: boolean;
  filterArchived?: string;
  searchQuery: string;
};

/**
 * escapes various regEx-Characters to include a value inside a regEx-String
 * @param value to escape
 * @returns escaped value
 */
export const escapeRegExCharacters = (value: string) =>
  value && replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&", value);

/**
 * checks if one or more variables are empty or not.
 * @param {*} values multiple values to check
 * @returns {boolean} true if all values are not empty or nil, false if any is empty or nil
 */
export const notNilEmpty = <T>(...values: T[]) =>
  none(
    equals(false),
    map((value) => !isNil(value) && !isEmpty(value), values),
  );

const toComparator = <T>(key: keyof T, first: T, second: T) =>
  toLower(pathOr("", [key as string], first)) >
  toLower(pathOr("", [key as string], second));

/**
 * Sorts a list of items.
 *
 * @param key or property to sort with
 * @param sort asc or desc
 * @param unsortedItems list to sort
 * @returns {*} sorted list
 */
export const sortItems = <T>(
  key: keyof T,
  sort: string,
  unsortedItems: T[],
): T[] | null =>
  notNilEmpty(unsortedItems)
    ? sortWith(
        [
          equals(sort, "desc")
            ? comparator((a, b) => toComparator<T>(key, a, b))
            : comparator((a, b) => toComparator<T>(key, b, a)),
        ],
        unsortedItems,
      )
    : null;

/**
 * Gets the first id of the selectedAddress or the first of the selectedCustomer,
 * defaults to -2, because the comparison value gets defaulted to -1 from the backend,
 * so we use -2 to avoid unexpected matching.
 *
 * @param selectedAddress
 * @param selectedCustomer
 * @returns {*} id of the address
 */
export const getFirstOrPreSelectedAddressIndex = (
  selectedAddress: ContactDto | null,
  selectedCustomer: CustomerDto | null,
): number =>
  notNilEmpty(selectedAddress)
    ? pathOr(-2, ["id"], selectedAddress)
    : pathOr(-2, ["companyContacts", 0, "id"], selectedCustomer);

export const getOfferManagementUIRights = (
  item: typeof InitialOfferManagementTableState,
  offerManagementStore: OfferManagementStore,
) =>
  filter((quote) => quote.cartTO.id === item.id, offerManagementStore.quotes)[0]
    ?.uiRelevantAuthorizationTO;
