import { find } from "ramda";
import { useEffect, useRef } from "react";

import { L10n } from "@encoway/l10n";

import ConfiguratorDropDown from "./configuratorDropDown";

export default function ConfiguratorDropDownWrapper(props) {
  const prevProps = useRef(props);
  const { loading, setLoading } = props;

  useEffect(() => {
    if (prevProps.current.data?.selectedValues !== props.data?.selectedValues) {
      setLoading(false);
    }
    prevProps.current = props;
  }, [props]);

  function onChange(selectedValue) {
    if (selectedValue?.value) {
      setLoading(true);
      props.onValueChanged(props.data, selectedValue.value);
    }
  }

  function prepareProps() {
    return {
      data: {
        ...props.data,
        label: props.data.translatedName,
        mandatory: !props.data.terminal && props.data.mandatory,
        placeholder: L10n.format("configuration_dropdown_placeholder"),
        selectedValue: find(
          (value) => value.selectionSource !== "NOT_SET",
          props.data.values,
        ),
      },
      onChange,
      loading,
      mediaLink: props.mediaLink,
    };
  }

  return (
    <div className="configuratorDropDownWrapper">
      <ConfiguratorDropDown {...prepareProps()} />
    </div>
  );
}
