import { addIndex, all, either, equals, isEmpty, isNil, map } from "ramda";

const fallBackCopyToClipboard = (text: string): void => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

/**
 * Copies text to clipboard
 *
 * @param {string} text the text to be copied
 * @returns {void}
 */
export const copyToClipboard = (text: string): void => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    fallBackCopyToClipboard(text);
  }
};

export const isNilOrEmpty = either(isNil, isEmpty);

export const mapIndexed = <T, U>(
  fn: (item: T, idx: number, list: T[]) => U,
  list: readonly T[],
): U[] => addIndex<T>(map)(fn, list);

export function isStringArray(values: unknown[]): values is string[] {
  return all((value) => equals(typeof value, "string"), values);
}
