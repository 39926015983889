import { FlexTableCell } from "../../../../../components/flexTable/flexTable";

import "./checkboxCell.scss";

type PartOrderCheckboxCellProps = {
  optional: boolean;
  setOptional: () => void;
  wrapperClassName: string;
  className: string;
  show: boolean;
  dataCheckboxId: string;
};

export const PartOrderCheckboxCell = ({
  optional,
  setOptional,
  wrapperClassName,
  className,
  show = true,
  dataCheckboxId,
}: PartOrderCheckboxCellProps) => (
  <FlexTableCell>
    {show && (
      <div className={wrapperClassName}>
        <input
          type="checkbox"
          checked={optional}
          onChange={setOptional}
          className={className}
          data-checkbox-id={dataCheckboxId}
        />
      </div>
    )}
  </FlexTableCell>
);
