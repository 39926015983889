import classNames from "classnames";
import { isNil } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import {
  FlexTableCell as Cell,
  FlexTableRow as Row,
  FlexTableWrapper as Table,
} from "../../../../components/flexTable/flexTable";
import { RadioButton } from "../../../../components/input/radioButton";
import { ModalInputGroup } from "../../../../components/modal/modalInputGroup";
import { mapIndexed } from "../../../../utilities/utilities";
import {
  ContactDto,
  CustomerDto,
} from "../../../customerManagement/customerManagementUtils";

import "./customerSearchTableAddress.scss";

type CustomerSearchTableAddressProps = {
  customer: CustomerDto | null;
  presetId: number;
  toggleCustomerAddress: boolean;
  onClick: (address: ContactDto) => void;
};

export const CustomerSearchTableAddress = ({
  customer,
  toggleCustomerAddress,
  presetId,
  onClick,
}: CustomerSearchTableAddressProps) => {
  const [checkedId, setCheckedId] = useState<number>(presetId);
  const onAddressChecked = (address: ContactDto) => {
    setCheckedId(address.id);
    onClick(address);
  };

  useEffect(() => {
    setCheckedId(presetId);
  }, [presetId]);

  if (!toggleCustomerAddress || isNil(customer)) {
    return null;
  }

  const { companyContacts, customerNumber, customerName } = customer;

  return (
    <Row className="no-hover-bg customer-results">
      <div className="customer-search-address">
        <h3 className="subtitle has-text-weight-bold">
          {L10n.format("customer")}
        </h3>
        <ModalInputGroup
          readOnly={true}
          inputFieldObj={{ customerNumber, customerName }}
          label={"modify_dialog_contact"}
        />
        <hr className="customer-search-break" />
        <h3 className="subtitle has-text-weight-bold address">
          {L10n.format("customer_management_offer_address")}
        </h3>
        <Table>
          {mapIndexed((address, index) => {
            const {
              title,
              firstname,
              surname,
              street,
              houseNumber,
              zip,
              city,
              phone,
              email,
              id,
            } = address;
            return (
              <Row
                key={index}
                className={classNames([
                  "customer-address no-hover-bg",
                  { "is-active": checkedId === id },
                ])}
                onClick={() =>
                  address.id !== checkedId ? onAddressChecked(address) : null
                }
              >
                <Cell className="radio-btn">
                  <RadioButton
                    checked={checkedId === id}
                    onChange={() => onClick(address)}
                  />
                </Cell>
                <Cell className="address-cell">
                  <Table>
                    <Row className="no-hover-bg">
                      <Cell>{title}</Cell>
                      <Cell>
                        {street} {houseNumber}
                      </Cell>
                      <Cell>{phone}</Cell>
                    </Row>
                    <Row className="no-hover-bg">
                      <Cell>
                        {surname}, {firstname}
                      </Cell>
                      <Cell>
                        {zip} {city}
                      </Cell>
                      <Cell>{email}</Cell>
                    </Row>
                  </Table>
                </Cell>
              </Row>
            );
          }, companyContacts)}
        </Table>
      </div>
    </Row>
  );
};
