import { includes, reduce } from "ramda";

import { TeckentrupCartArticleTO } from "../../../../types/cart";

export const hasCartItemDetails = (cartItem: TeckentrupCartArticleTO) =>
  Boolean(cartItem.priceConditions);

export const isCartItemDetailsVisible = (
  cartItem: TeckentrupCartArticleTO,
  toggle: string[],
) => hasCartItemDetails(cartItem) && includes(cartItem.articleId, toggle);

const hasSubArticles = (cartItem: TeckentrupCartArticleTO) =>
  Boolean(cartItem.subArticles) && cartItem.subArticles.length > 0;

export const getAllCartItemIds = reduce(
  (acc: string[], cartItem: TeckentrupCartArticleTO): string[] => {
    if (hasSubArticles(cartItem)) {
      return [...acc, ...getAllCartItemIds(cartItem.subArticles)];
    }
    if (cartItem.configurable) {
      return [...acc, cartItem.articleId];
    }
    return acc;
  },
  [],
);
