import { length, pathOr } from "ramda";

import { isReady } from "@encoway/rest-api";

const beforeTabs = pathOr([], ["before"]);
const afterTabs = pathOr([], ["after"]);

export const containerIsEmpty = (container) => {
  if (
    Array.isArray(container.parameters) &&
    length(container.parameters) !== 0
  ) {
    return false;
  }
  if (!Array.isArray(container.children)) {
    return true;
  }
  return container.children.every(containerIsEmpty);
};

export const determineActiveTab = (inject, children, containerFilter) => {
  if (inject?.before) {
    return inject.before[0].name;
  }
  const f = containerFilter || (() => true);
  const filtered = children.filter((tab) => {
    if (f(tab) === false || containerIsEmpty(tab)) {
      return null;
    }
    return tab;
  });
  if (filtered.length >= 1) {
    return filtered[0].name;
  }
  return children.length >= 1 ? children[0].name : null;
};

const getIndexOfSelectedTab = (inject, children, currentTab) => {
  if (!currentTab) {
    return -1;
  }
  const before = beforeTabs(inject);
  for (let i = 0; i < before.length; i++) {
    if (before[i].name === currentTab) {
      return i;
    }
  }
  for (let i = 0; i < children.length; i++) {
    if (children[i].name === currentTab) {
      return before.length + i;
    }
  }
  const after = afterTabs(inject);
  for (let i = 0; i < after.length; i++) {
    if (after[i].name === currentTab) {
      return before.length + children.length + i;
    }
  }
  return -1;
};

const determineTab = (
  ix,
  afterOffset,
  after,
  before,
  navSkipOptionalTabs,
  children,
  containerFilter,
) => {
  const f = containerFilter || (() => true);
  if (ix >= afterOffset) {
    return after[ix - afterOffset].name;
  }
  if (ix < before.length) {
    return before[ix].name;
  }
  const tab = children[ix - before.length];
  const isFiltered = f(tab) === false;
  const isEmpty = containerIsEmpty(tab);
  const shouldSkip = navSkipOptionalTabs ? isReady(tab) : false;
  if (!isFiltered && !isEmpty && !shouldSkip) {
    return tab.name;
  }
  return null;
};

const getTabInfos = (inject, children, currentTab) => {
  const before = beforeTabs(inject);
  const after = afterTabs(inject);
  const afterOffset = before.length + children.length;
  const index = getIndexOfSelectedTab(inject, children, currentTab);
  const count = before.length + children.length + after.length;
  return { before, after, afterOffset, index, count };
};

export const determineNextTab = (
  inject,
  children,
  containerFilter,
  currentTab,
  navSkipOptionalTabs,
) => {
  const { before, after, afterOffset, index, count } = getTabInfos(
    inject,
    children,
    currentTab,
  );
  if (index === -1 || index === count - 1) {
    return null;
  }
  for (let i = index + 1; i < count; i++) {
    const tab = determineTab(
      i,
      afterOffset,
      after,
      before,
      navSkipOptionalTabs,
      children,
      containerFilter,
    );
    if (tab) {
      return tab;
    }
  }
  return null;
};

export const determinePreviousTab = (
  inject,
  children,
  containerFilter,
  currentTab,
  navSkipOptionalTabs,
) => {
  const { before, after, afterOffset, index } = getTabInfos(
    inject,
    children,
    currentTab,
  );
  if (index <= 0) {
    return null;
  }
  for (let i = index - 1; i >= 0; i--) {
    const tab = determineTab(
      i,
      afterOffset,
      after,
      before,
      navSkipOptionalTabs,
      children,
      containerFilter,
    );
    if (tab) {
      return tab;
    }
  }
  return null;
};
