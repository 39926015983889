import { useEffect, useState } from "react";

import { NumberInput } from "../../../../../../components/input/NumberInput";
import {
  CartActions,
  ExtendedTeckentrupCartTO,
} from "../../../../../../types/cart";
import {
  determineInitialTaxRate,
  setTaxRateToCartAndLocalstorage,
} from "./taxInputUtils";

import "./taxInput.scss";

type CartInputProps = {
  cart: ExtendedTeckentrupCartTO;
  setTaxRate: CartActions["setTaxRate"];
};

export const TaxInput = ({ cart, setTaxRate }: CartInputProps) => {
  const [initialValue, setInitialValue] = useState(cart.taxRate);
  const [currentCartId, setCurrentCartId] = useState(
    cart.articles.articleId || null,
  );

  useEffect(() => {
    if (currentCartId !== cart.articles.articleId) {
      setInitialValue(Number(determineInitialTaxRate(cart, setTaxRate)));
      setCurrentCartId(cart.articles.articleId);
    }
  }, [cart]);

  const setTaxRateValue = (value: number) => {
    setInitialValue(value);
    setTaxRateToCartAndLocalstorage(setTaxRate, value);
  };

  return (
    <NumberInput
      className="input is-small taxInput"
      value={initialValue}
      setValue={setTaxRateValue}
      data-cell-id="cart-sum-taxe-percentage"
      suffix=" %"
      decimalScale={2}
    />
  );
};
