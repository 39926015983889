import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { t } from "../../localizationUtils";

type DownloadNotPossibleModalProps = {
  onCancel: () => void;
  onSave?: () => void;
};

export function DownloadNotPossibleModal(
  props: Readonly<DownloadNotPossibleModalProps>,
) {
  const { onCancel, onSave } = props;

  return (
    <Modal>
      <ModalHeader
        onCancel={onCancel}
        label={t("force_new_offer_dialog_title")}
      />
      <ModalBodyWrapper label={t("force_new_offer_dialog_text")} />
      <ModalFooter
        onCancel={onCancel}
        onSave={onSave}
        cancelLabel={t("cancel")}
        saveLabel={t("force_new_offer_dialog_create")}
        saveIcon={<FontAwesomeIcon icon={faCheck} />}
      />
    </Modal>
  );
}
